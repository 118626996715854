export const hireIonicDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Ionic is a free and open-source framework for creating mobile applications. The Framework is suitable for hybrid mobile apps and employs HTML5 for the development of mobile applications. For front-end user interaction that oversees and defines the layout and other elements of mobile applications, the Ionic framework stands out. Ionic offers an exceptionally captivating and attractive platform for mobile applications, known for its ease of use, flexibility, and versatility. However, the real strength of the Ionic Framework lies in the hands of an adept Ionic developer.",
            "What Ionic Developers Do: Ionic developers specialize in crafting angular-based apps, whereas some developers leverage Ionic code for the construction of Progressive Web apps. The expansive codebase allows the Ionic developer to repurpose it for subsequent developments. One of the essential responsibilities of Ionic developers is designing a UI grid suitable for mobile devices, like smartphones and tablets. Ionic developers also facilitate the creation and deployment on cloud-based platforms. Through the integration of an Ionic plugin, these developers can tap into the device's file system. Additionally, Ionic developers have the capability to employ third-party plugins formulated in native code, and they ensure that only optimal binary scenarios are selected for the project."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Service Alta Tech Mark Ionic Developers Offer:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                title: "Web Application Development",
                description: "Our Ionic developers are skilled in the creation of Progressive Web Apps. Employing our Ionic specialists will allow your company to take advantage of offline experiences and reap the rewards of a top-notch PWA. We are experts in developing and improving Progressive Web Apps."
            },
            {
                title: "Blended Ionic Creation",
                description: "Reputable for its outstanding Hybrid Ionic Development is Alta Tech Mark. Our team uses the strength of local languages to achieve peak performance. Our Ionic developers provide complete server application solutions since they understand the unique requirements of various platforms."
            },
            {
                title: "Angular Mobile Development using Ionic",
                description: "Angular and Ionic make a powerful team. This combo is used by our Ionic developers to enhance the functionality of your site or app. They provide superior mobile and online solutions by using the advantages of both Angular and Ionic."
            },
            {
                title: "Ionic Testing & Debugging",
                description: "Tests and debugging are the foundation of an app's dependability. With extensive knowledge in Ionic testing and debugging, our team ensures flawless deployment. They have a strong enthusiasm for careful Ionic testing."
            },
            {
                title: "Development of Ionic Apps Offshore",
                description: "The area of expertise for Alta Tech Mark is offshore Ionic app development. We provide specialized solutions that address your particular business requirements and foster innovation and development across a range of frameworks and areas."
            },
            {
                title: "Third-Party Integration for Ionic Apps",
                description: "Our group has experience creating Ionic apps that are connected with external systems. Acknowledging the potential of hybrid mobile applications, we use state-of-the-art techniques to develop Ionic applications that include features from third parties."
            },
            {
                title: "Ionic Application Maintenance",
                description: "Our Ionic developers supervise every aspect of the project, from conception to deployment. We promise thorough upkeep, upgrades, and assistance to make sure your company grows steadily."
            }
        ]
    },
    {
        id: 3,
        title: "Why Work with Alta Tech Mark's Ionic Developers?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                title: "Committed and Trustworthy Group",
                description: "Alta Tech Mark has a team of developers that are exclusively focused on creating Ionic apps. Providing comprehensive solutions, our staff skillfully handles the Framework's complexities."
            },
            {
                title: "Novel Method",
                description: "With a wealth of Ionic development knowledge, our developers use a unique approach. We promise that your Ionic-based application will provide a faultless user experience in addition to making a statement."
            },
            {
                title: "On-Time Delivery",
                description: "Our first priority is being on time. Our Ionic developers create precise schedules that guarantee projects are finished on time. Regardless of geographic distances, our staff adjusts to your time zone, demonstrating adaptability and dedication."
            },
            {
                title: "Effective Solution",
                description: "Our Ionic developers' years of expertise enable them to provide realistic, excellent, and affordable solutions. Our mobile and web deployments are always effective because of our strategic preparation."
            },
            {
                title: "Modest Scaling",
                description: "Transparency is what sets us apart. We keep lines of contact open and provide you with updates on the project's development. Our Ionic developers use the newest technology and an agile methodology to build solutions that advance enterprises. The unmatched quality that Alta Tech Mark offers is assured."
            }
        ]
    },
]

export const hireIonicDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Ionic developers?",
        answer: "The dedicated Ionic developers will work only for you to deliver outstanding results with the highest optimization. The team of Ionic developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Ionic developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Ionic developers. Just provide project details and the technical expertise you need. The team of Ionic developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Ionic developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Ionic developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Ionic developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Ionic developer, then you can hire dedicated Ionic developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Ionic developers?",
        answer: "The cost estimation for dedicated Ionic developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]