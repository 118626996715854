import img1 from '../../../assets/icons/mobilePage/1.png';
import img2 from '../../../assets/icons/mobilePage/2.png';
import img3 from '../../../assets/icons/mobilePage/3.png';
import img4 from '../../../assets/icons/mobilePage/4.png';
import img5 from '../../../assets/icons/mobilePage/5.png';
import img6 from '../../../assets/icons/mobilePage/6.png';

export const mobileGridBlockData = [
    "Increased visibility",
    "Customer Engagement",
    "Improves sale",
    "Easier Data Collection",
    "Personalized User Experience",
    "Brand Recognition & Trust"
]

export const mobileServicesData = [
    {
        id: 1,
        title: 'Education',
        description: "These days, learning is easy and reasonably priced because to mobile innovation. During the e-learning phase, students' interest in studying and comprehending courses is enhanced through mobile applications quickly and efficiently.",
        src: img1
    },
    {
        id: 2,
        title: "Games & Entertainment",
        description: "According to a Statista summary, the gaming business is among the top sectors in the list of the most popular mobile apps, with an estimated global revenue of $20.5 billion.",
        src: img2
    },
    {
        id: 3,
        title: "Travel & Tourism",
        description: "Applications related to travel and tourism have also emerged as one of the most widely used globally. These days, companies in the travel sector have started seeking the help of mobile app developers",
        src: img3
    },
    {
        id: 4,
        title: "Logistic",
        description: "Transport-related mobile applications can support the logistics sector by advancing workforce management, client administration, and a host of other features, such as real-time tracking of vehicles, deliveries, and staff location.",
        src: img4
    },
    {
        id: 5,
        title: "Manufacturing",
        description: "As technology is used more and more in every aspect of life, the manufacturing sector has advanced in using the newest innovations in technology, such as mobile app development, to its advantage.",
        src: img5
    },
    {
        id: 6,
        title: "Healthcare",
        description: "The smartphone application serves as a wizard for the healthcare business, which is beset by ever-growing challenges. Everything from important clinical tests to research procedures, surveys, and storage of vital research-based data.",
        src: img6
    }
]