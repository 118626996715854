export const hireShopifyDevData = [
    {
        id: 1,
        title: "Shopify Development: Boosting Growth in E-Commerce",
        description: "Leading the way in e-commerce solutions, Shopify Development helps companies transition to the digital age. It combines cutting-edge technology with an abundance of features to produce e-commerce websites that operate very well. In addition to improving sales, marketing, and optimization, using Shopify provides a clear and rich e-commerce experience. It provides highly functioning procedures that are simplified. Additionally, Shopify creates captivating online businesses that attract clients. Shopify not only provides you with a beautifully designed website, but it also helps your company expand in revolutionary ways. Although Shopify is user-friendly for beginners, to increase the value of your brand for more complex implementations, think about employing seasoned Shopify developers.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Creating Excellent Shopify Solutions with Alta Tech Mark",
        description: "Our company, Alta Tech Mark, provides creative, excellent Shopify development services. Our knowledge guarantees that e-commerce companies get effective websites that guarantee a smooth customer experience. Not only do our solutions have a pleasing appearance, but they also guarantee strong functionality. Our customized approaches provide an e-commerce platform that is scalable. With its extensive understanding of Shopify, our team creates outstanding websites that are customized to meet your company's demands.",
        onlyBullets: false,
        data: []
    },
    {
        id: 3,
        title: "Why Choose Development for Shopify?",
        description: "Selecting Shopify for online sales is a calculated move. The comprehensive features together with its simplicity make managing an online business an easy task. Shopify allows companies to offer a wide range of items with minimum customisation because of its efficiency, adaptability, and variety. And Shopify guarantees a better return on investment. Furthermore, Shopify-developed websites may be seamlessly connected across several platforms without sacrificing performance, opening the door to higher profits and more efficient operations.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Alta Tech Mark Provides Shopify Development Services:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Shopify Extension Development",
                description: "We provide themes, customizations, and extensions to support company growth. Our skilled developers guarantee flawless operation."
            },
            {
                id: 2,
                title: "Shopify Store Development",
                description: "We handle every aspect of e-commerce solutions customized to meet customer needs, from setup to connections with third-party apps."
            },
            {
                id: 3,
                title: "Shopify SEO Strategy",
                description: "Develop your Shopify SEO strategy to stay ahead of the competition. This is particularly helpful for startups and small and medium-sized businesses."
            },
            {
                id: 4,
                title: "Shopify Theme Development",
                description: "Take advantage of unique, feature-rich, interactive themes that are tailored to fit your brand."
            },
            {
                id: 5,
                title: "Shopify App Development",
                description: "Your online shop is made more accessible with the help of our app development services and third-party integrations."
            },
            {
                id: 6,
                title: "Shopify SAP Connection",
                description: "Our SAP connection helps with inventory management, order tracking, and other business procedures in real-time."
            },
            {
                id: 7,
                title: "Shopify Mobile App Development",
                description: "We guarantee that your company stays ubiquitous across platforms with Shopify integration, being leaders in mobile app development."
            },
            {
                id: 8,
                title: "Shopify Quality Assurance and Testing",
                description: "Our thorough testing guarantees a faultless operation, providing a perfect user experience."
            },
            {
                id: 9,
                title: "Shopify Maintenance and Support",
                description: "Constant functioning is guaranteed by committed support. Any problems that may arise are resolved quickly, guaranteeing an excellent customer experience."
            }
        ]
    },
    {
        id: 5,
        title: "Why Choose Alta Tech Mark as Your Shopify Development Partner?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "100% Error & Bug-Free Promise",
                description: "Maintaining quality is critical. We promise an online shop that is 100% error-free."
            },
            {
                id: 2,
                title: "Shopify Heavy Lifters",
                description: "Our staff is well-versed in Shopify and is equipped to handle any obstacles that come their way."
            },
            {
                id: 3,
                title: "Evolutionary Development",
                description: "At Alta Tech Mark, we stay ahead of the curve by using cutting-edge tactics and technology to get the greatest outcomes."
            },
            {
                id: 4,
                title: "Unique Expertise",
                description: "Our seasoned specialists utilize Shopify to promote e-commerce growth by using their unique knowledge."
            }
        ]
    },
    {
        id: 6,
        title: "",
        descriptions: [
            "You can get help from Alta Tech Mark to transform your dreams of being a Shopify pro into real success."
        ],
        onlyBullets: false,
        data: []
    }
]

export const hireShopifyDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Shopify developers?",
        answer: "The dedicated Shopify developers will work only for you to deliver outstanding results with the highest optimization. The team of Shopify developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Shopify developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Shopify developers. Just provide project details and the technical expertise you need. The team of Shopify developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Shopify developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Shopify developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Shopify developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Shopify developer, then you can hire dedicated Shopify developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Shopify developers?",
        answer: "The cost estimation for dedicated Shopify developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]