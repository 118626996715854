import React from 'react'

export default function LogoGrid(props) {
    let currentIndex = 0;

    return (
        <div className='tw-grid tw-grid-cols-3 tw-w-full'>
            {props.data.map((media) => {
                currentIndex ++;
                return (
                    <div key={media.id} className={`tw-flex tw-py-8 tw-box-content tw-justify-center tw-p-[16px] tw-border-slate-300 ${currentIndex % 3 === 0 ? `${props.data.length - currentIndex >= 3 && 'tw-border-b-[1px]'}` : `${props.data.length - currentIndex >= 3 && 'tw-border-b-[1px]'} tw-border-r-[1px]`}`}>
                        <img src={media.src} alt='media' />
                    </div>
                )
            })}
        </div>
    )
}
