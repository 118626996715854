import React from 'react';

export default function HiringStepCard(props) {
    return (
        <div className={`tw-col-span-1 tw-flex tw-flex-col tw-items-center tw-bg-${props.bgColor} tw-text-white tw-py-[2.083vw] ${props.className}`}>
            <img src={props.src} alt="lock" />
            <h1 className='tw-px-[3.125vw] tw-w-full tw-text-center tw-text-lg tw-mt-8'>{props.title}</h1>
            <h1 className='tw-px-[1.806vw] tw-w-full tw-text-center tw-mt-8'>{props.description}</h1>
        </div>
    )
}
