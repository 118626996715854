import React from 'react';

// assets
import bg from '../../../assets/brands/citizen/bg.svg';
import logo from '../../../assets/brands/citizen/logo.svg';
import right from '../../../assets/brands/citizen/right.svg';
import overviewFullSpan from '../../../assets/brands/citizen/overviewFullSpan.svg';
import pi from '../../../assets/brands/citizen/pi.svg';
import innovation1 from '../../../assets/brands/citizen/innovation1.svg';
import innovation2 from '../../../assets/brands/citizen/innovation2.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function Citizen() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="CITIZEN"
                simpleTitle="Watch"
                description="CITIZEN desired an app to exhibit their products to customers, and the team at Alta Tech Mark delivered by presenting their offerings online through a seamless interface."
                rightClasses="md:tw-w-[30vw] md:tw-h-[20vw]"
            />

            <ProjectOverview
                overview="The internationally acclaimed Citizen Watch Company sought to develop a showcase app for displaying their products online."
                overviewFullSpan={overviewFullSpan}
            />

            {/* product information */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-h-[75vw] tw-text-white'
                style={{
                    backgroundImage: `url(${pi})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <h1 className='md:tw-text-[2.635vw] tw-font-bold tw-text-center'>Product Information</h1>
                <h1 className='tw-text-[1.171vw] tw-text-center'>Complete information about each and every product is listed on the app. It liberates the customers to search for their desired product along with making their decision to choose and purchase the product easily.</h1>
            </div>
            {/* product information */}

            {/* innovation */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2'>
                <h1 className='md:tw-text-[2.635vw] tw-text-red-600 tw-font-bold tw-text-center'>Innovation at its best</h1>
                <h1 className='tw-text-[1.171vw]'>The app is digitally interactive, crafted in line with the most current benchmarks of contemporary app development practices. It successfully fulfills the primary objective of presenting the full range with comprehensive details, as specified in the client's brief.</h1>
                <img className='tw-mx-auto' src={innovation1} alt="innovation" />
                <img className='tw-mx-auto' src={innovation2} alt="innovation" />
            </div>
            {/* innovation */}

            <ContactUsNoForm />
        </div>
    )
}
