import React from 'react';
// import { useSelector } from 'react-redux';

// swiper
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Pagination } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';

// components
import IndustriesCard from './IndustriesCard';
import HeaderText from '../../headerText/HeaderText';

// data
import { industriesData } from '../../../data/industriesData';

export default function Industries() {
    // globals
    // const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-pt-[8.138vw] tw-bg-[#FCFCFC] tw-px-[9.115vw]'>
            <HeaderText
                colorTitleStart="INDUSTRIES"
                title='WE SERVE'
                className='tw-text-center tw-mb-8'
                description="Here, we create apps across nearly all categories. You just mention it, and we'll craft it."
            />

            {/* {screenWidth <= 450 ?
                <Swiper
                    modules={[Autoplay, Pagination]}
                    spaceBetween={150}
                    slidesPerView={1.2}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {industriesData.map(industry =>
                        <SwiperSlide key={industry.id}>
                            <div className='tw-h-[220px]'>
                                <IndustriesCard
                                    src={industry.src}
                                    title={industry.title}
                                    description={industry.description}
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
                : */}
            <div className='tw-grid md:tw-grid-cols-5 tw-grid-cols-3 tw-w-full tw-gap-[1.628vw]'>
                {industriesData.map(industry => <IndustriesCard
                    key={industry.id}
                    src={industry.src}
                    title={industry.title}
                />)}
            </div>
            {/* } */}
        </div>
    )
}
