import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    userSliceLoading: false,
    _id: "",
    email: "",
    isAdmin: false,
    isAuthenticated: false
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: (state, action) => {
            state._id = "";
            state.email = "";
            state.isAdmin = false;
            state.isAuthenticated = false;
        }
    },
    extraReducers: (builder) => {
        // signup
        builder.addCase(userSignup.pending, (state, action) => {
            state.userSliceLoading = true;
        })
        builder.addCase(userSignup.fulfilled, (state, action) => {
            state._id = action.payload._id;
            state.email = action.payload.email;
            state.isAdmin = action.payload.isAdmin;
            state.isAuthenticated = true;
            state.userSliceLoading = false;
        })
        builder.addCase(userSignup.rejected, (state, action) => {
            state.isAuthenticated = false;
            state.userSliceLoading = false;
            alert(action.payload)
        })

        // login
        builder.addCase(userLogin.pending, (state, action) => {
            state.userSliceLoading = true;
        })
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state._id = action.payload._id;
            state.email = action.payload.email;
            state.isAdmin = action.payload.isAdmin;
            state.isAuthenticated = true;
            state.userSliceLoading = false;
        })
        builder.addCase(userLogin.rejected, (state, action) => {
            state.isAuthenticated = false;
            state.userSliceLoading = false;
            alert(action.payload)
        })
    }
})

export const userSignup = createAsyncThunk('user/signup', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.post('https://altatechmark.com/server/user/signup', userData);

        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.message)
    }
})

export const userLogin = createAsyncThunk("user/login", async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.post('https://altatechmark.com/server/user/login', userData);

        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.message)
    }
})

export const { logout } = userSlice.actions;