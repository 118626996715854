import React from 'react';

// assets
import ourTeamBg from '../../../assets/background/ourTeamBg.svg';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import MeetOurTeam from '../../../components/aboutUs/meetOurTeam/MeetOurTeam';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function OurTeam() {
    return (
        <div>
            <HeroTitleImage
                bg={ourTeamBg}
                title='Our Team'
                titleDesc='Meet Our Dedicated & Proficient Team'
                simpleTextDesc={true}
            />

            <MeetOurTeam />

            <ContactUsNoForm />
        </div>
    )
}
