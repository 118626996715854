import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import iot1 from '../../../assets/pages/service/iot/iot1.png';
import iot2 from '../../../assets/pages/service/iot/iot2.png';
import iot3 from '../../../assets/pages/service/iot/iot3.png';
import { iotGridBlockData, iotServicesData, sectorSpecificIotServicesData, iotTechStackData } from '../../../data/services/iotDevData/iotData';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import GridBlock from '../../../components/services/gridBlock/GridBlock';
import KeyFeaturesCard from '../../../components/home/keyFeatures/KeyFeaturesCard';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function IotAndEmbedded() {
    // initialization
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet ||
                <div>
                    <HeroTitleImage
                        bg={iot1}
                        title="Internet of Things"
                        // descriptionBold="Internet of Things"
                        simpleTextBg={true}
                        inWrapper={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-grid tw-gap-4'
                        style={{
                            backgroundImage: `linear-gradient(${screenWidth < 768 ? '0deg' : '-90deg'}, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${iot2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className='tw-text-white'>
                            <HeaderText
                                title="WHY GO FOR IoT DEVELOPMENT?"
                                description="IoT offers an effective approach for using smart technologies and connecting a variety of items to the internet."
                                className='tw-text-white tw-mb-[2vw] md:tw-text-left tw-text-center'
                                descriptionClassName='tw-text-left'
                            />
                            <h1>It will make it possible for devices to communicate and exchange data, which has many applications. IoT is transforming how companies operate. Advanced analytics may be integrated with IoT data to provide organizations with insightful information that will improve their operations.</h1>
                        </div>
                        <div className='tw-flex md:tw-flex-row tw-flex-col tw-items-start tw-justify-between tw-w-full tw-gap-6'>
                            <img src={iot3} alt="pic" />
                            <div className='tw-grid tw-grid-cols-3 tw-gap-[1.628vw] tw-justify-items-center tw-w-full'>
                                {iotGridBlockData.map((data, index) =>
                                    <GridBlock
                                        key={index}
                                        text={data}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'>
                        <HeaderText
                            colorTitleEnd="ALTA TECH MARK"
                            title="ABOUT"
                            className="tw-text-center tw-mb-8"
                        />
                        <h1>
                            The greatest Internet of Things applications are provided by Alta Tech Mark to assist smart initiatives from a variety of sectors, including security, home automation, and industrial IoT that have the power to transform both company operations and people's lives. We create intelligent, networked solutions that use sophisticated analytics and machine learning to increase your effectiveness. We also provide integrated services for the delivery and management of Internet of Things (IoT) services.
                        </h1>
                        <br />
                        <h1>
                            Our highly qualified IoT app developers are committed to providing customers with value and a logical experience. They have experience with the IoT-specific UI and UX. Your operational procedures will reach new heights of intelligence and connection with the help of a smart, IoT-enabled company. We think that by fusing cutting-edge technology with our industrial understanding, the possibility identification phase may be expedited and made more active.
                        </h1>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple'>
                        <HeaderText
                            colorTitleStart="SERVICES"
                            title="FOR DEVELOPING IOT APPS"
                            className="tw-text-center tw-mb-8"
                            description="Services That We Deliver Makes Us Dignified As One of The Top App Development Companies."
                        />
                        <div className='md:tw-grid md:tw-grid-cols-3 tw-flex tw-items-center tw-justify-center md:tw-flex-wrap md:tw-overflow-x-hidden tw-py-4 tw-w-full tw-overflow-x-scroll tw-gap-[25px]'>
                            {iotServicesData.map(service =>
                                <KeyFeaturesCard
                                    key={service.id}
                                    title={service.title}
                                    description={service.description}
                                    src={service.src}
                                    width={'max'}
                                />
                            )}
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'>
                        <HeaderText
                            colorTitleStart="SECTOR SPECIFIC"
                            title="IoT SERVICES"
                            className="tw-text-center tw-mb-8"
                        />

                        <div className='tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4'>
                            {sectorSpecificIotServicesData.map(data =>
                                <div className='tw-grid-cols-1 tw-flex tw-flex-col tw-items-start tw-gap-y-2 tw-w-full' key={data.id}>
                                    <h1>{data.title}</h1>
                                    {data.children.map((item, index) =>
                                        <div className='tw-flex tw-items-center tw-gap-x-1' key={index}>
                                            <FiberManualRecordIcon sx={{ scale: '0.5' }} />
                                            <h1>{item}</h1>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'>
                        <HeaderText
                            colorTitleEnd="Alta Tech Mark"
                            title="IoT Technology Stack at"
                            className="tw-text-center tw-mb-8"
                        />

                        <div className='tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4'>
                            {iotTechStackData.map(data =>
                                <div className='tw-grid-cols-1 tw-flex tw-flex-col tw-items-start tw-gap-y-2 tw-w-full' key={data.id}>
                                    <h1>{data.title}</h1>
                                    {data.children.map((item, index) =>
                                        <div className='tw-flex tw-items-center tw-gap-x-1' key={index}>
                                            <FiberManualRecordIcon sx={{ scale: '0.5' }} />
                                            <h1>{item}</h1>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
