import React from 'react';
import { useSelector } from 'react-redux';

// data
import { servicesData } from '../../../data/servicesData';

// components
import ServicesCard from './ServicesCard';
import HeaderText from '../../headerText/HeaderText';

// swiper
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Pagination } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';

export default function Services() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-px-[9.115vw] tw-py-[8.138vw]'>
            <HeaderText colorTitleStart='SERVICES' title="WE OFFER" className='tw-text-center tw-mb-8' description="We offer end-to-end product development services." />
            {screenWidth < 768 ?
                <div className='tw-flex tw-w-full tw-gap-x-4 tw-overflow-x-scroll tw-h-[600px]'>
                    {servicesData.map(service => {
                        return (
                            <div className='tw-h-[550px]'>
                                <ServicesCard
                                    key={service.id}
                                    src={service.src}
                                    title={service.title}
                                    colorTitleStart={service.colorTitleStart}
                                    colorTitleEnd={service.colorTitleEnd}
                                    children={service.children}
                                    description={service.description}
                                    // inSwiper={true}
                                    url={service.url}
                                />
                            </div>
                        )
                    })}
                </div>
                :
                <div className='tw-grid tw-grid-cols-4 tw-justify-items-center tw-gap-4'>
                    {servicesData.map(service => <ServicesCard
                        key={service.id}
                        src={service.src}
                        title={service.title}
                        colorTitleStart={service.colorTitleStart}
                        colorTitleEnd={service.colorTitleEnd}
                        children={service.children}
                        description={service.description}
                        span={service.span}
                        url={service.url}
                    />)}
                </div>
            }
        </div>
    )
}
