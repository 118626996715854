export const cyberSecurityPolicyData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 19,
                title: "Network Security",
                description: "In-Office and Remote Network Access: Utilizing advanced firewall technologies, we ensure secure and monitored internet access within our office premises. Remote access to our network requires secure VPN connections and multi-factor authentication."
            },
            {
                id: 20,
                title: "Cloud and Data Security",
                description: "Protected Cloud Services: All our cloud services, including data storage and APIs, are secured with SSL encryption. Access to these services is stringently controlled."
            },
            {
                id: 21,
                title: "Mobile Device Security",
                description: "Device Usage and Management: Company-provided mobile devices are strictly for official use. These devices are centrally managed and monitored for security."
            },
            {
                id: 22,
                title: "Protection of Personal Data",
                description: "Secure Data Handling: Personal data stored in our systems is managed in secure cloud environments, accessible only to authorized personnel. We adhere to strict data protection regulations."
            },
            {
                id: 23,
                title: "Social Media and External Platform Usage",
                description: "Controlled Access: Access to social media and external platforms is restricted and monitored, ensuring it's used for official purposes only."
            },
            {
                id: 24,
                title: "Wi-Fi Security",
                description: "Secured Access: Our Wi-Fi networks adhere to the latest security protocols, with access provided based on necessity and job role."
            },
            {
                id: 25,
                title: "Data Backup and Recovery",
                description: "Robust Backup Strategies: Regular, encrypted backups are a cornerstone of our data protection strategy, ensuring business continuity."
            },
            {
                id: 26,
                title: "Employee Training and Awareness",
                description: "Ongoing Education: We regularly train our employees in cybersecurity best practices and emergency response protocols."
            },
            {
                id: 27,
                title: "Compliance and Legal Adherence",
                description: "Regulatory Compliance: We strictly adhere to industry standards and legal requirements in cybersecurity."
            },
            {
                id: 28,
                title: "Enforcement and Review",
                description: "Continuous Monitoring and Improvement: Our cybersecurity policies are regularly reviewed and updated to counter emerging threats and technological advancements."
            }
        ]
    }
]