import React from 'react';

// data
import { hireIonicDevData, hireIonicDevFaqs } from '../../../data/hireDev/hireIonicDev';
import ionicDev1 from '../../../assets/pages/hireDev/ionicDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireIonicDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="IONIC DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={ionicDev1}
            data={hireIonicDevData}
            faq={hireIonicDevFaqs}
        />
    )
}
