import React from 'react'

export default function LatestBlogsCard(props) {
    return (
        <div className='tw-col-span-1 tw-mt-[16px] tw-px-[15px] tw-flex tw-flex-col tw-w-full tw-items-center tw-cursor-pointer tw-text-white'>
            <img className='tw-rounded-lg' src={props.src} alt={props.title} />

            <div className='tw-w-[95%] tw-bg-darkPurple tw-rounded-lg tw-relative tw-bottom-8 tw-shadow-lg tw-py-[24px] tw-px-[16px] tw-flex tw-flex-col md:tw-items-start tw-items-center tw-gap-y-2 tw-h-full'>
                <h1 className='tw-font-semibold tw-underline md:tw-text-lg tw-text-sm'>{props.title}</h1>
                <h1 className='md:tw-text-start tw-text-center md:tw-text-lg tw-text-sm'>{props.description}</h1>
                <div className='tw-flex md:tw-flex-row tw-flex-col tw-w-full tw-items-center tw-justify-between'>
                    <h1 className='tw-px-3 tw-py-1 tw-bg-[#FFFFFF1A] tw-rounded-lg md:tw-w-min'>By {props.author}</h1>
                    <h1>{props.date}</h1>
                </div>
            </div>
        </div>
    )
}
