import React from 'react';

// assets
import chatLive from '../../../assets/icons/chatLive.png';

// component
import HeaderText from '../../headerText/HeaderText';
import ArrowButton from '../../ui/arrowButton/ArrowButton';

export default function ApplyNowNoForm() {
    return (
        <div className='md:tw-px-[9.115vw] tw-bg-purple tw-mt-[8.138vw] tw-w-full tw-py-[4vw]'>
            <div className='tw-grid tw-w-full tw-place-items-center'>
                <HeaderText
                    title='Be a part of something great!'
                    description='Interested in working together? Apply now to become a part of our team'
                    className='tw-text-center tw-text-white tw-mb-8 tw-w-[67%]'
                />
                <div className='tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-justify-center tw-gap-[25px] tw-w-full tw-text-white'>
                    <div className='tw-flex tw-items-center tw-gap-x-[25px]'>
                        <img src={chatLive} alt="chat" />
                        <div className='tw-flex tw-flex-col tw-items-start'>
                            <h1 className='tw-text-lg tw-font-semibold'>Mail your resume</h1>
                            <h1>hr@altatechmark.com</h1>
                        </div>
                    </div>
                    <ArrowButton url='/company/career/applyNow' text='Apply Now' className='tw-bg-darkPurple tw-text-white tw-scale-[1.2 ]' />
                </div>
            </div>
        </div>
    )
}
