import React from 'react';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';
import CaseStudies from '../../../../components/home/caseStudies/CaseStudies';

// assets
import uiUxDesign2 from '../../../../assets/pages/service/corporateBranding/uiUxDesign2.png';
import { uiUxDesignDevData, uiApproachData } from '../../../../data/services/corporateBranding/uiUxDesignDevData';

export default function UiUxDesign() {
    return (
        <ServicesWrapper
            title="USER INTERFACE & USER EXPERIENCE DESIGN"
            descriptionCont="Developing a user interface and user experience design that is data-driven and user-centered in order to provide the highest possible level of user satisfaction."
            img={uiUxDesign2}
        >
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={uiUxDesignDevData} />

                <h1 className='tw-text-center tw-font-semibold md:tw-text-4xl md:tw-mt-[11.458vw]'>Redifine Experiences Through </h1>
                <h1 className='tw-text-center tw-font-semibold md:tw-text-4xl tw-text-purple tw-mb-8 '>User-Centered & Data-Driven UI/UX</h1>

                <div className='tw-flex tw-flex-col tw-gap-y-4 tw-w-full'>
                    {uiApproachData.map(approach =>
                        <div key={approach.id} className='tw-grid tw-grid-cols-4 tw-w-full tw-justify-items-center tw-items-center'>
                            <div className={`tw-col-span-1 ${approach.id % 2 === 0 ? 'md:tw-order-2' : "md:tw-order-1"} md:tw-w-[15vw]`}>
                                <img src={approach.src} alt="pic" />
                            </div>
                            <div className={`tw-col-span-3 tw-flex tw-flex-col tw-items-start tw-w-full ${approach.id % 2 !== 0 ? 'md:tw-order-2 tw-pl-[10%]' : "md:tw-order-1 tw-pl-[10%]"}`}>
                                <h1 className='md:tw-text-xl tw-font-semibold'>{approach.title}</h1>
                                <ul className='tw-list-disc tw-ml-4'>
                                    {approach.bullets.map((item, index) => <li key={index} className='tw-text-purple max-md:tw-text-xs'>{item}</li>)}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <CaseStudies colorTitleStart="PORTFOLIO" title=" " />
        </ServicesWrapper>
    )
}
