import React from 'react';

// components
import ArrowButton from '../../ui/arrowButton/ArrowButton';

export default function Login() {
    return (
        <div className='tw-py-[20vw] tw-px-[10vw] tw-flex tw-flex-col tw-items-center tw-justify-around tw-h-[60vh]'>
            <h1 className='tw-text-5xl tw-font-semibold tw-text-darkPurple'>Login</h1>

            <div className='tw-flex tw-flex-col tw-items-center tw-gap-y-4 tw-w-full'>
                <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-1 tw-w-full'>
                    <h1 className='tw-text-lg'>Username</h1>
                    <input className='tw-px-2 tw-py-1 tw-rounded-lg tw-border-[1px] tw-border-slate-600 tw-w-full' type="text" placeholder='Enter your username' />
                </div>
                <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-1 tw-w-full'>
                    <h1 className='tw-text-lg'>Password</h1>
                    <input className='tw-px-2 tw-py-1 tw-rounded-lg tw-border-[1px] tw-border-slate-600 tw-w-full' type="password" placeholder='Enter Password' />
                </div>
                <div className='tw-flex tw-items-center tw-justify-between tw-w-full'>
                    <div className='tw-flex tw-items-center tw-gap-x-2'>
                        <input type="checkbox" name="" id="" />
                        <h1 className='tw-text-xs'>Remember me</h1>
                    </div>
                    <h1 className='tw-text-blue-600 tw-cursor-pointer tw-text-xs'>Forgot password</h1>
                </div>
                <ArrowButton text='Login' className='tw-bg-darkPurple tw-w-full tw-text-white' />
            </div>
        </div>
    )
}
