export const aboutUsData = [
    {
        id: 1,
        title: 'About Company',
        url: '/company/aboutUs'
    },
    {
        id: 2,
        title: 'Our Team',
        url: '/company/ourTeam'
    },
    {
        id: 3,
        title: 'Our Expertise',
        url: '/company/ourExpertise'
    },
    {
        id: 4,
        title: 'Clients',
        url: '/company/ourClients'
    },
];