export const hireYiiDevData = [
    {
        id: 1,
        title: "Why YII ?",
        descriptions: [
            "With its MVC framework, Yii is a comprehensive open-source web application development tool that provides the greatest platform for developers. Additionally, Yii, a safe PHP framework, allows you to construct interactive web applications.",
            "Developers now favor Yii as their development framework of choice. Robust, secure, and highly scalable web applications may be developed using it in the least amount of time. Furthermore, several of PHP's more sophisticated features—like SPL classes and interfaces, late static binding, and hidden functions—benefit a basic OOP framework."
        ],
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Performance Optimization",
                description: "Yii is known for its performance. With the lazy loading technique, Yii has an edge over other frameworks, making it faster in comparison."
            },
            {
                id: 2,
                title: "Highly Extensible",
                description: "Yii is designed to be extensible. Almost every component of the framework can be extended or replaced to fit the specific needs of a project, making customization a breeze."
            },
            {
                id: 3,
                title: "Security Features",
                description: "Yii comes packed with tools to help write secure code. Cross-site scripting (XSS), cross-site request forgery (CSRF), and SQL injection attacks are prevented with Yii’s core security features."
            },
            {
                id: 4,
                title: "Gii Tool (Code Generator)",
                description: "One of Yii's most notable features is Gii, a web-based code scaffolding tool. This allows developers to automatically generate the required code for models, controllers, form inputs, and more."
            },
            {
                id: 5,
                title: "MVC Architecture",
                description: "Adhering to the MVC pattern, Yii ensures a clear separation of logic and presentation. This architectural pattern is beneficial for web apps as it promotes clean code, making maintenance and scalability more manageable."
            },
            {
                id: 6,
                title: "AJAX-Enabled Widgets",
                description: "Yii comes with AJAX-enabled widgets like auto-complete input fields, data grids, and more. These widgets aid in writing highly efficient and responsive UI elements with minimal code."
            },
            {
                id: 7,
                title: "Layered Data Caching Scheme",
                description: "Yii supports data caching, fragment caching, page caching, and dynamic content. The framework also supports multiple cache storage like APC, XCache, Redis, and others."
            },
            {
                id: 8,
                title: "Error Handling and Logging",
                description: "Yii provides efficient error handling, and logging features which can be categorized, filtered, and routed to different destinations like files, browsers, or emails."
            },
            {
                id: 9,
                title: "Active Record ORM",
                description: "Yii provides an Active Record ORM, which allows developers to work with databases using object-oriented syntax. This helps in abstracting the database access and makes data access more secure and efficient."
            },
            {
                id: 10,
                title: "Integration with Third-party Code",
                description: "Yii is friendly when it comes to integration. You can integrate other frameworks' code into Yii applications smoothly, ensuring developers can use tools they are already familiar with."
            },
            {
                id: 11,
                title: "Role-Based Access Control (RBAC)",
                description: "With its built-in authentication and role-based access control, Yii makes managing user permissions straightforward and efficient."
            }
        ]
    },
]

export const hireYiiDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Yii developers?",
        answer: "The dedicated Yii developers will work only for you to deliver outstanding results with the highest optimization. The team of Yii developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Yii developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Yii developers. Just provide project details and the technical expertise you need. The team of Yii developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Yii developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Yii developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Yii developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Yii developer, then you can hire dedicated Yii developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Yii developers?",
        answer: "The cost estimation for dedicated Yii developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]