export const salesforceDevData3 = [
    {
        id: 1,
        title: "",
        description: "Salesforce offers its clients cutting-edge software-as-a-service options that are hosted in the cloud, making the program very flexible and accessible from any location. Salesforce can be configured to function exactly as a company wants it to since it is completely customisable. Salesforce improves employee productivity, fosters better customer relations, and ultimately raises customer happiness. These days, a lot of companies use Salesforce since it's a reliable cloud-based CRM that helps connect management, employees, and customers.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Understand The Advantages of Implementing Salesforce:",
        description: "",
        onlyBullets: true,
        data: [
            "Accurate and Complete Information",
            "Sales Forecasting",
            "Saves Time",
            "Lead and Sales Team Management",
            "Improved Internal Processes",
            "Customer-Centric Approach",
            "Formation of Business Strategy"
        ]
    },
    {
        id: 3,
        title: "",
        descriptions: [
            "An organization with expertise in Salesforce implementation is Alta Tech Mark. We have a knowledgeable group of installation leaders and consultants with extensive expertise that will walk you through every stage of your Salesforce journey. Simplified business procedures, the discovery of applicable Salesforce capabilities, and much more will be provided by us and will almost immediately have a favorable impact on your return on investment.",
            "We assist you in defining Salesforce's system function as an application inside your corporate technology and business ecosystem as the first step in our Salesforce deployment services. We create a strategy for Salesforce Implementation and Integration, establish the Salesforce Solution Architecture, and define the feature list for your Salesforce Implementation project all with that function in mind.",
            "We at Alta Tech Mark are aware that every company is different. We describe ourselves as a progressive supplier of CRM systems, providing creative business solutions that provide reliable outcomes. Working together, we can actively deploy new CRM solutions that will increase productivity and assure long-term success, giving you a competitive edge over your rivals."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Alta Tech Mark Provides Salesforce Implementation Services:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Discovery and Project Envisioning",
                description: "Businesses must thoroughly examine their present business procedures prior to using Salesforce. To help a firm achieve its needs, our trained Salesforce consultants map these processes to Salesforce capability and offer an implementation approach."
            },
            {
                id: 2,
                title: "Salesforce Setup & Configuration",
                description: "With the help of our knowledgeable Salesforce experts, business process automation will be completed with specially created email templates, unique workflows, security access configuration, and support procedures. You may get more information from Alta Tech Mark on how we can set up personalized dashboards and reports to guarantee that your company has improved data visibility."
            },
            {
                id: 3,
                title: "Salesforce Customization",
                description: "Alta Tech Mark assists in modifying Salesforce applications to meet specific business requirements. Whether you are a startup or an established company, our trained Salesforce experts can customize Salesforce to meet your unique business needs."
            },
            {
                id: 4,
                title: "Salesforce Integration",
                description: "Alta Tech Mark's Salesforce integration solution helps companies maintain an accurate flow of data."
            },
            {
                id: 5,
                title: "Data transfer to Salesforce",
                description: "Within a predetermined timeline, our skilled staff can handle complex data transfer difficulties. We provide data extraction, cleaning, deduplication, standardization, validation, and import into Salesforce as part of our data migration services."
            },
            {
                id: 6,
                title: "Bolt-On Integration Requirements",
                description: "The Salesforce consultants at Alta Tech Mark, both onshore and offshore, have extensive experience identifying problems and integrating bolt-on connectors with both traditional and cloud-based business-critical systems."
            },
            {
                id: 7,
                title: "Salesforce Support, Training, and Documentation",
                description: "Alta Tech Mark provides the essential continuous maintenance and enhancement support services for Salesforce with a team of qualified and experienced developers, testers, administrators, and specialists."
            }
        ]
    },
    {
        id: 5,
        title: "Implementing Transformational Salesforce for Your Expanding Company:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Implementations of Sales Cloud",
                description: "With our sales cloud service, you have the freedom to mirror your sales methodology and develop a well-informed perspective of your company's expansion that is specific to your requirements."
            },
            {
                id: 2,
                title: "Analytics Cloud Implementations",
                description: "You may connect your sales, service, and community cloud data with our Analytics Cloud service to easily monitor the state of your company."
            },
            {
                id: 3,
                title: "Community Cloud Implementation",
                description: "Using branded portals that may be customized, our Salesforce community cloud solution encourages interaction with partners, customers, sources of referrals, and other organizations."
            },
            {
                id: 4,
                title: "Service Cloud",
                description: "Service Cloud drastically lowers expenses by continually monitoring and replying to consumers on various social media channels. Draw a complete picture of your client by combining this data with the information from your Sales Cloud."
            }
        ]
    },
    {
        id: 6,
        title: "When it comes to Salesforce integration, why use Alta Tech Mark?",
        descriptions: [
            "With almost every member of the team holding a Salesforce certification, Alta Tech Mark is an expert in Salesforce. We use an iterative approach and embrace the Agile Methodology. We have a start-up mentality and an experienced team that is always willing to go above and beyond to provide solutions that are perfectly suited for the company.",
            "Our experts will work closely with you to understand your goals and business model, outline your business procedures, and help you choose the best solution for your company, whether you're integrating Salesforce for the first time or want to improve your current configuration."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 7,
        title: "The Alta Tech Mark's Principal Elements:",
        description: "",
        onlyBullets: true,
        data: [
            "Dependable Shipping",
            "Salesforce installation services provided by experts",
            "Adaptability",
            "Seamless change of the business",
            "Exceptional Experience",
            "Project Management"
        ]
    }
]