export const hireFrontendDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Front-end developers are responsible for the layout, typeface, color, content, and any other element you see on a website when you access it. With front-end development, developers may work on the website's external design while doing backend work. Since they oversee and manage every aspect of the website and web applications, the front-end developers play a crucial role in the team. Front-end developers are essential if you want to create an amazing and engaging mobile and online application.",
            "Front-end developers may work as freelancers, part-timers, or full-timers. The work you anticipate and your company requirements must be taken into consideration when hiring developers. By using front-end development services, you may differentiate yourself from the competition and connect with your audience. Although implementing front-end development is a difficult undertaking, it is essential for mobile apps and websites. Front-end development uses a variety of technologies, including HTML, CSS, and JavaScript. A contemporary framework is also included into the development, including Angular, Express, ReactJS, VueJS, and others. It works with tablets, smartphones, laptops, and all other devices. Since the layout, user interface, and user experience (UI/UX) are the first things that a user or customer will see, front-end developers need to pay attention to detail."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "",
        description: "Services in Front-End Development We Provide: Architectural Design & Framework: We offer services focused on crafting responsive and long-lasting apps using advanced front-end tools and strategies to enhance the quality of code. User Interface & Experience Creation: Essential for any online platform, our UI / UX services ensure intuitive, data - driven applications that captivate users.",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Progressive Web App Creation",
                description: "This service, ideal for both websites and mobile platforms, offers a blend of usability and rich interaction, bridging the gap between web and native apps."
            },
            {
                id: 2,
                title: "HTML5/CSS Techniques",
                description: "For enriched web experiences, our HTML/CSS methods offer tools that boost quality and simplify front-end development."
            },
            {
                id: 3,
                title: "Single-Page App Development",
                description: "Many prefer our single-page application services due to the speed and scalability provided, leveraging JavaScript technologies."
            },
            {
                id: 4,
                title: "AMP Application Solutions",
                description: "Targeting optimum web performance, our AMP development ensures swift page loads and superior user engagement."
            },
            {
                id: 5,
                title: "Comprehensive Web Development",
                description: "Our full-stack development is crucial, offering a balance between front-end aesthetics and back-end functionality."
            }
        ]

    },
    {
        id: 3,
        title: "Core Front-End Technologies We Utilize:",
        description: "",
        onlyBullets: true,
        data: [
            "AngularJs",
            "Vue.Js",
            "React Native",
            "React",
            "MEAN",
            "Next",
            "Flutter",
            "HTML",
            "CSS",
            "Ionic"
        ]
    },
    {
        id: 4,
        title: "Why Choose Alta Tech Mark's Front-end Experts?",
        description: "Our front-end specialists are seasoned and adept, capable of managing projects from budding startups to large enterprises. We prioritize quality and are dedicated to using the best of technologies and methodologies. Expertise of Our Team: With a keen understanding of front- end nuances, our adept team ensures delivery that stands out in both function and form.",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "State-of-the-art Tools",
                description: "Alta Tech Mark is recognized for harnessing the latest and most reliable tools in the industry, ensuring top-notch, bug-free outcomes."
            },
            {
                id: 2,
                title: "Transparent Interaction",
                description: "We keep our clients in the loop, ensuring they're updated and involved, aiming for results that match expectations."
            },
            {
                id: 3,
                title: "Flawless User Journeys",
                description: "Our commitment extends beyond delivering the best; we ensure users enjoy a bug-free, smooth experience."
            },
            {
                id: 4,
                title: "Boosted Efficiency",
                description: "Our team stays updated with evolving trends, guaranteeing high-caliber services. We strive for innovation, ensuring each project stands out."
            },
            {
                id: 5,
                title: "Cutting-Edge Ideas",
                description: "Our approach is both unique and forward-thinking, ensuring we deliver unmatched results that set us apart from the competition."
            }
        ]
    }
]

export const hireFrontendDevFaq = [
    {
        id: 1,
        question: "1. How much does it cost to hire dedicated front-end developers?",
        answer: "The cost estimation for dedicated front-end developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    },
    {
        id: 2,
        question: "2. Can I hire dedicated front-end developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated front-end developer, then you can hire dedicated front-end developers on an hourly or project/task base."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated front-end developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the front-end developers before making a final move."
    },
    {
        id: 4,
        question: "4. What steps should I follow to Hire front-end developers?",
        answer: "Alta Tech Mark offers a very simple process to hire front-end developers. Just provide project details and the technical expertise you need. The team of front-end developers will begin working on your project based on your selection."
    },
    {
        id: 5,
        question: "5. Why should I hire dedicated front-end developers?",
        answer: "The dedicated front-end developers will work only for you to deliver outstanding results with the highest optimization. The team of front-end developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    }
]