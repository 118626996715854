import React from 'react';

// assets
import hl1 from '../../../../assets/pages/service/blockchainDev/hl1.jpeg';
import hl2 from '../../../../assets/pages/service/blockchainDev/hl2.png';
import { hyperledgerDevData } from '../../../../data/services/blockchainDevData/hyperledgerDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function HyperledgerDev() {
    return (
        <ServicesWrapper
            title="BLOCKCHAIN DEVELOPMENT HYPERLEDGER"
            descriptionCont="A new need in the corporate sector is hyperledger blockchain technology, and almost all companies are seeking for hyperledger blockchain development. The Linux Foundation created this technology in December 2015."
            img={hl1}
        >
            <DevGridWrapper img={hl2}>
                <DataMapper data={hyperledgerDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
