import React from 'react';

// assets
import sa1 from '../../../../assets/pages/service/aiAndMl/sa1.jpeg';
import sa2 from '../../../../assets/pages/service/aiAndMl/sa2.png';
import { sentimentalAnalysisData } from '../../../../data/services/aiAndMl/sentimentalAnalysisData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function SentimentAnalysis() {
    return (
        <ServicesWrapper
            title="Sentimental Analysis"
            descriptionCont="When a small number of individuals provide feedback, Sentiment Analysis may be used to determine if customers are positive, negative, or neutral about a product. They examine the raw text using machine learning, natural language processing, and data analysis."
            img={sa1}
        >
            <DevGridWrapper img={sa2}>
                <DataMapper data={sentimentalAnalysisData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
