import React, { useState } from 'react';

export default function ChatMessage(props) {
    const [options, setOptions] = useState([
        {
            id: 1,
            text: "Hire For My Project"
        },
        {
            id: 2,
            text: "Want To Know More"
        },
        {
            id: 3,
            text: "Looking For Job"
        },
        {
            id: 4,
            text: "Just Browsing"
        }
    ])
    return (
        <div className='tw-flex tw-flex-col tw-gap-y-1 tw-text-left tw-my-2'>
            <h1 className='tw-text-sm'>Titan</h1>
            <div className='tw-flex tw-justify-start'>
                <div className='tw-bg-slate-100 tw-p-4 tw-w-[90%] tw-text-left'>
                    <h1>{props.message}</h1>
                    <div className='tw-flex tw-items-center tw-flex-wrap tw-gap-4 tw-mt-2'>
                        {options.map(option =>
                            <div key={option.id} className='tw-bg-white tw-p-2 tw-rounded-lg tw-cursor-pointer'>{option.text}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
