import React from 'react';

// data
import { hireWooCommerceDevData, hireWooCommerceDevFaqs } from '../../../data/hireDev/hireWooCommerceDev';
import wcDev1 from '../../../assets/pages/hireDev/wcDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireWooCommerceDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="WOOCOMMERCE DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={wcDev1}
            data={hireWooCommerceDevData}
            faq={hireWooCommerceDevFaqs}
        />
    )
}
