import React from 'react';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import logo from '../../../assets/logo/logo.png';

// components
import ChatMessage from './chatMessage/ChatMessage';

export default function ChatBot() {
    return (
        <div className='tw-animate-slideIn tw-mt-4 tw-rounded-lg md:tw-w-[30vw] tw-w-[98vw] tw-grid tw-grid-cols-1 tw-bg-white'>
            <div className='tw-flex tw-items-center tw-gap-x-4 tw-bg-purple tw-rounded-t-lg tw-text-white md:tw-p-8 tw-p-5'>
                <KeyboardArrowDownIcon className='tw-rotate-90' />
                <div className='tw-bg-white tw-p-2 tw-rounded-full'>
                    <img src={logo} alt="logo" width={50} height={50} />
                </div>
                <div className='tw-flex tw-flex-col tw-w-full'>
                    <h1 className='md:tw-text-xl tw-text-lg tw-font-semibold tw-tracking-wide'>Titan</h1>
                    <h1 className='md:tw-text-lg tw-text-basic'>Your Friendly Assistant Bot</h1>
                </div>
            </div>
            <div className='tw-max-h-[50vh] tw-overflow-y-scroll md:tw-p-4 tw-p-1 tw-flex tw-flex-col tw-w-full tw-rounded-b-lg'>
                <ChatMessage message="Hi, there! I can help you find the services you are looking for, choose an option:" />
            </div>
            <div>
                <h1 className='md:tw-p-4 tw-p-1'>Choose An Option</h1>
            </div>
        </div>
    )
}
