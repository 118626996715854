import React from 'react'

export default function ProjectOverview(props) {
    return (
        <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-4 tw-relative tw-bg-white tw-z-[6]'>
            <h1 className='md:tw-text-[2.635vw] tw-text-red-600 tw-font-bold tw-text-center'>Project Overview</h1>
            <h1 className='tw-text-[1.171vw]'>{props.overview}</h1>
            {props.overviewFullSpan &&
                <img src={props.overviewFullSpan} alt="overview" className='tw-mx-auto tw-w-[50%]' />
            }
            {props.overviewFloatingSpan &&
                <div className='tw-relative'>
                    <h1 className={`tw-absolute tw-w-[40%] tw-top-0 tw-left-0 tw-text-[1.171vw]`}>{props.overviewFloatingText}</h1>
                    <img src={props.overviewFloatingSpan} className='tw-mx-auto tw-w-[90.885vw]' alt="overview" />
                </div>
            }
        </div>
    )
}
