import React from 'react';

// assets
import node1 from '../../../../assets/pages/service/WebAppDev/node1.png';
import node2 from '../../../../assets/pages/service/WebAppDev/node2.png';
import { nodeJsDevData } from '../../../../data/services/webAppDevData/nodeJsDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function NodeJsAppDev() {
    return (
        <ServicesWrapper
            title="NODE JS DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={node1}
        >
            <DevGridWrapper img={node2}>
                <DataMapper data={nodeJsDevData}/>
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
