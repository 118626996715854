import React from 'react';

// assets
import sh1 from '../../../../assets/pages/service/iot/sh1.jpeg';
import sh2 from '../../../../assets/pages/service/iot/sh2.png';
import { smartHomeDevData } from '../../../../data/services/iotDevData/smartHomeDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function SmartHome() {
    return (
        <ServicesWrapper
            title="Smart Home - Home Automation"
            descriptionCont="The most popular component of an IoT system for homes and businesses is the smart home. Virtual assistants that use the Internet for management and operation are known as smart homes. Controlling apps, home security, and other features is one of the greatest ways to utilize home automation."
            img={sh1}
        >
            <DevGridWrapper img={sh2}>
                <DataMapper data={smartHomeDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
