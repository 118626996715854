import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// assets
import careersBg from '../../../assets/background/careersBg.png';
import { reasonsToWorkAtData } from '../../../data/career/reasonsToWorkAt';
import hp5 from '../../../assets/hiringProcess/hp5.png';

// components
import CurrentOpenings from '../../../components/career/currentOpenings/CurrentOpenings';
import HiringProcess from '../../../components/career/hiringProcess/HiringProcess';
import HeaderText from '../../../components/headerText/HeaderText';
import ApplyNowNoForm from '../../../components/career/applyNow/ApplyNowNoForm';
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';

export default function Career() {
    // initializations
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {
                outlet
                ||
                <div className=''>
                    <HeroTitleImage
                        title='We provide for you the'
                        coloredTitle='ideal launch pad'
                        bg={careersBg}
                    />

                    <div className='tw-grid tw-gap-y-4'>
                        <h1 className='tw-px-[9.115vw] tw-text-purple md:tw-text-5xl tw-text-3xl tw-font-semibold tw-mt-[4vw] md:tw-text-left tw-text-center'>JOIN US, <br /><span className='tw-text-darkPurple'>TO GROW TOGETHER</span></h1>

                        <h1 className='tw-px-[9.115vw]'>
                            We rank among the top workplaces. Alta Tech Mark fosters an environment where you may develop yourself, showcase your talents, and make the most of who you are. We support the development of new procedures that make your job easier. Flexible working hours are available at Alta Tech Mark. We're seeking for some new talent that can offer cultural values to Alta Tech Mark; someone who shares our vision but can also introduce diversity.
                        </h1>

                        <h1 className='tw-px-[9.115vw] tw-text-purple md:tw-text-4xl tw-text-xl tw-font-semibold tw-text-center tw-mt-[4vw]'><span className='tw-text-darkPurple tw-inline tw-whitespace-nowrap'>TOP REASONS TO WORK AT</span> <br /> <span className='tw-inline tw-whitespace-nowrap'>ALTA TECH MARK</span></h1>

                        {screenWidth < 768 ?
                            <Swiper
                                style={{
                                    "--swiper-pagination-color": "#9065FD",
                                    "--swiper-pagination-bullet-inactive-color": "#999999",
                                    "--swiper-pagination-bullet-inactive-opacity": "1",
                                    "--swiper-pagination-bullet-size": "16px",
                                    width: `${screenWidth / 1.2}px`
                                }}
                                modules={[Autoplay, Pagination]}
                                spaceBetween={100}
                                slidesPerView={1}
                                loop={true}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 3000,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                            >
                                {reasonsToWorkAtData.map(reason =>
                                    <SwiperSlide>
                                        <div className='tw-h-[450px]'>
                                            <div key={reason.id} className='tw-text-center tw-col-span-1 tw-grid tw-gap-4 tw-h-[400px] tw-items-center tw-justify-items-center'>
                                                <img src={reason.src} alt={reason.title} />
                                                <h1 className='tw-text-lg tw-font-semibold -tw-mb-2'>{reason.title}</h1>
                                                <h1>{reason.description}</h1>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            :
                            <div className='tw-px-[9.115vw] tw-grid tw-grid-cols-3 tw-gap-6'>
                                {reasonsToWorkAtData.map(reason =>
                                    <div key={reason.id} className='tw-text-center tw-col-span-1 tw-grid tw-gap-4'>
                                        <img src={reason.src} alt={reason.title} />
                                        <h1 className='tw-text-lg tw-font-semibold -tw-mb-2'>{reason.title}</h1>
                                        <h1>{reason.description}</h1>
                                    </div>
                                )}
                            </div>
                        }

                        <CurrentOpenings />

                        <HiringProcess />

                        <HeaderText
                            colorTitleEnd='GREAT PLACE TO WORK'
                            title='WE ARE A'
                            className='tw-text-center tw-mb-8 tw-mt-[4vw]'
                        />

                        <div className='tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-gap-y-[1.628vw] tw-px-[9.115vw]'>
                            <img src={hp5} alt="hiring" className='tw-col-span-1' />

                            <div className='tw-col-span-2 tw-flex tw-flex-col tw-items-start tw-w-full tw-text-left tw-tracking-wider'>
                                <h1>
                                    At <span className='tw-font-semibold'>Alta Tech Mark</span>, we aren't just a company; we are a community of innovators, thinkers, and creators. Our ethos centers around nurturing individuality, fostering growth, and empowering our employees to be the best versions of themselves. With an environment tailored for creativity and innovation, we are always in search of better processes to optimize our workflow.
                                    <br />
                                    <br />
                                    <span className='tw-font-semibold'>We're Hiring!</span> <br /> We value diversity—it's the bedrock of our innovative spirit. If you're someone who can align with our mission and add to our rich tapestry of cultures, backgrounds, and talents, we want you. <br /> <span className='tw-font-semibold'>Who We Are Looking For:</span><br /> Cultural Contributors: Can you enhance our workplace with your unique background and experiences? We are seeking individuals who can enrich our company culture and add new perspectives. <br /> <span className='tw-underline'>🔗 Apply Now and begin your journey with Alta Tech Mark. 🔗</span>
                                </h1>
                            </div>
                        </div>

                        <ApplyNowNoForm />
                    </div>
                </div>
            }
        </>
    )
}
