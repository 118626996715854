import React from 'react';
import { Link } from 'react-router-dom';

// assets
import { hireDeveloperData } from './hireDeveloperData';
import logo from '../../../assets/logo/logo.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function HireDeveloperMenuItem(props) {
    // functions
    const menuLinkHandler = (event) => {
        props.menuLinkHandler(event)
    }

    return (
        <div className='tw-w-[80vw] tw-grid tw-gap-2 tw-py-[2vw] tw-text-lg tw-font-semibold'>
            <img src={logo} alt="logo" className='tw-w-[20vw] tw-px-2' />
            <div onClick={menuLinkHandler} id='links' className='tw-flex tw-w-full tw-gap-4 tw-p-[2vw]'>
                <KeyboardArrowRightIcon id='links' className='tw-rotate-180' />
                <h1 id='links'>Back</h1>
            </div>
            {hireDeveloperData.map(service => (
                <Link to={service.url} key={service.id} className='tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-text-[#4741A6]'>{service.value}</Link>
            ))
            }
        </div>
    )
}
