import React from 'react';

// assets
import aboutUsBg from '../../../assets/background/aboutUsBg.png';
import au1 from '../../../assets/pages/aboutUs/au1.png';
import au3 from '../../../assets/pages/aboutUs/au3.png';

// components
import CoreValues from '../../../components/aboutUs/coreValues/CoreValues';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import OurJourney from '../../../components/aboutUs/ourJourney/OurJourney';

export default function AboutUs() {
    return (
        <div className='tw-flex tw-flex-col tw-w-full tw-items-center'>
            <HeroTitleImage
                title='Crafting Digital Journeys'
                coloredTitle='Beyond Boundaries'
                bg={aboutUsBg}
            />

            <div className='tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-gap-[2vw] tw-px-[9.115vw] tw-mt-[4vw] tw-w-full'>
                <h1 className='tw-block md:tw-hidden tw-col-span-2 tw-w-full'>Our intramural internal environments of the organization and the developing external IT environment are well and properly coordinated. The promising outcomes of this IT service provider will actually be determined by the increased brand equity and revenue. The extent to which the populace shares in, appreciates, and benefits from the efficacy is one method to gauge a company's success.</h1>
                <div className='tw-flex md:tw-flex-col tw-flex-row-reverse md:tw-items-start tw-items-center tw-justify-between tw-gap-[1vw] tw-col-span-1'>
                    <h1 className='md:tw-text-4xl tw-text-lg tw-font-semibold'>ABOUT <br /><span className='tw-text-purple'>ALTA TECH MARK</span></h1>
                    <img src={au1} alt="about us" className='tw-w-[34.722vw]' />
                </div>

                <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-[1vw] tw-col-span-2 md:tw-order-2 tw-order-1'>
                    <h1 className='md:tw-block tw-hidden'>Our intramural internal environments of the organization and the developing external IT environment are well and properly coordinated. The promising outcomes of this IT service provider will actually be determined by the increased brand equity and revenue. The extent to which the populace shares in, appreciates, and benefits from the efficacy is one method to gauge a company's success.</h1>
                    <h1>Alta Tech Mark recognizes the boundless possibilities stemming from the company's growth, benefiting both organizational objectives and individual aspirations. Celebrating milestones reflects our journey toward progress, always in alignment with our core mission. Our dedication is mirrored by both our relentless efforts and sharp insights, defending the values we've established. Alta Tech Mark stands firm in its decisions, regardless of potential outcomes, driven by an unwavering belief in what's right. Our team embodies passion, always striving for excellence regardless of external challenges. Mutual success is our mantra, forging lasting relationships with clients and partners.</h1>
                    <h1> Every client engagement is treasured by Alta Tech Mark, prioritizing customer needs and ensuring delivery meets expectations. Even as we explore new frontiers, our esteemed market position remains unshaken. Boasting a dynamic team of professionals, Alta Tech Mark ensures impeccable service standards. Our team, brimming with motivation, is wholly devoted to serving our company's primary goals. With pillars of innovation, dedication, and top-tier service, we navigate towards sustainable growth. Our robust infrastructure, coupled with state-of-the-art technological backing and an expansive distribution network, meets the diverse needs of our partners. Above all, client satisfaction remains our ultimate aim. Alta Tech Mark provides tailored solutions to match client expectations, guaranteeing quality products at competitive rates and timely delivery. With a foundation of commitment, competitive edge, and continuous growth, we continually enhance our offerings. Driven by a visionary team and leadership, Alta Tech Mark is poised for unparalleled success.</h1>
                </div>
            </div>

            <OurJourney />

            <img src={au3} alt="about us" className='md:tw-block tw-hidden tw-w-[100%]' />

            <CoreValues bg={au3}/>

            <ContactUsNoForm />
        </div>
    )
}
