import uxr2 from '../../../assets/pages/service/corporateBranding/uxr2.png';

export const uxrDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "At Alta Tech Mark, we recognize the imperative of a seamless user experience (UX) in today's digital age. As more businesses establish a strong online presence, it's not just about having a digital interface, but about ensuring that this interface truly resonates with the end user. This is where our specialized service in UX Audit and Review comes into play.",
            "A UX Audit is a methodical examination of your digital product, be it a website, application, or platform, to identify areas of improvement from a user-centric perspective. Our team delves deep into the user journey, examining all touchpoints to ensure that they are intuitive, efficient, and engaging. By leveraging a mix of heuristic evaluations, user surveys, and usability testing, we provide actionable insights that can bridge the gap between user expectations and your digital reality.",
            "The primary objective of our UX review is to elevate the overall user satisfaction. By highlighting pain points, suggesting enhancements, and recommending design solutions, we ensure that businesses not only retain their current user base but also attract potential customers. After all, a positive user experience is key to increasing conversions, reducing drop-off rates, and building a loyal customer base. With Alta Tech Mark's UX Audit and Review services, you're taking a decisive step towards achieving these outcomes."
        ],
        onlyBullets: false,
        data: [],
        sectionPicRow: true,
        sectionPic: uxr2,
    }
]

export const uxrDevData2 = [
    {
        id: 1,
        title: "Step 1: Understanding business objectives",
        descriptions: [
            "While the auditor themself will be imagining from a user’s perspective what the digital experience is like, the first step must be understanding the business’s goals for the product being audited.",
            "This will help give you the appropriate context of the digital product being audited, and the direction the business is moving towards including goals and key objectives. With this in mind, it helps you to better understand what part this audit plays in the wider picture for future success.",
            "If the company claims they want to ‘increase sales’ you can tailor the audit to focus on areas aligning with this target. Does it involve increasing cross sales across the business, or more of a specific item or driving higher adoption to payment subscription sign ups.",
            "This stage of the audit should be completed collaboratively and agreed upon with your client before you invest in more time consuming in depth investigations."
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 2,
        title: "Step 2: Defining the number of user journeys and platforms",
        descriptions: [
            "This will help you define the scope of your audit and may depend on the time and resources available to complete the audit.",
            "For digital products where more than one type of user might be involved, or multiple journeys with differing routes are possible, it might be best to prioritise the highest value user type.",
            "The same can be said for differing platforms, ideally, you want to look across desktop and mobile as users will likely use both. If prioritisation is needed press for the most important option that receives the most traffic."
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 3,
        title: "Step 3: Analysing data",
        descriptions: [
            "If the business tracks the performance of its digital products using Google Analytics, reviewing any data available will give you valuable insights into many interactions and behaviours taking place in a quantitative format.",
            "If time and the budget allow, you can even go a step further and supplement Google analytics data with tools such as Hotjar, CrazyEgg, or Kissmetrics which show more advanced analytics.",
            "Heatmaps, bounce rates, average time spent per page, scroll rates, and unique visitors are just some examples of data you will get and then be able to dive into building your understanding and establishing trends."
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 4,
        title: "Step 4: Performing heuristic usability evaluation",
        descriptions: [
            "Heuristic evaluation is a widely used approach to identify design flaws in the user interface of a digital experience. Remember the term heuristics themselves indicates these are broad rules of thumb and not specific or rigid rules so you could even develop your own set of heuristics.",
            "If you want a set of well established heuristics to base this process around specific criteria, these 10 usability heuristics for user interface design developed by Jacob Nielsen are well known throughout the industry.",
            "When performing the evaluation, you should put yourself in the end user’s shoes and try to see things from their perspective as you try to accomplish tasks the user would do themselves. Ensure throughout the process you take notes and capture screenshots of problematic areas detailing each and every challenge or pain point encountered."
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 5,
        title: "Step 5: Performing accessibility evaluation",
        descriptions: [
            "Digital accessibility refers to how usable a website, app or digital experience is by all users, regardless of their ability or disability. As electronic devices become evermore integrated into everyday use, it becomes even more critical that digital experiences are available to all and do not segregate users with digital impairments,",
            "Accessible websites usually work better for everyone. They are often faster, easier to use and appear higher in search engine rankings, so it’s important to view digital accessibility as an opportunity, not a burden.",
            "There is a wide range of open-source tools you can use to test accessibility such as Google Lighthouse which offers detailed feedback on site performance, accessibility, and SEO and wraps it all up in a report with solutions. You can also get handy plugins that work directly with design tools like Figma such as the A11y — Color Contrast Checker or Adee Accessibility Colourblind Simulator."
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 6,
        title: "Step 6: Compiling findings and recommending improvements",
        descriptions: [
            "The final step is to summarise the findings and give recommendations to make future improvements. Once all your evaluations have been completed, you can also take a holistic view of the entire service to spot further insights.",
            "How you choose to present findings and recommendations may be dependent on client preference. The key attribute is that it should be able to exist as a stand-alone document once handed over, being accompanied by a verbal presentation when discussing with the client will give great context that stakeholders will appreciate; ultimately it needs to be robust and detailed enough piece of work that it can be picked up and consumed by anyone."
        ],
        onlyBullets: false,
        data: [],
    }
]