import or3 from '../../../assets/pages/service/aiAndMl/or3.png';
import or4 from '../../../assets/pages/service/aiAndMl/or4.png';
import or5 from '../../../assets/pages/service/aiAndMl/or5.png';

export const objectRecognitionData = [
    {
        id: 1,
        title: "Object Recognition: What Is It?",
        descriptions: [
            "Identifying and detecting things in pictures and videos is done using computer vision techniques. It's an approach powered by machine learning and artificial intelligence. Using object recognition, facial habits such as images, videos, and other little items are discovered. Using computer vision methods, analytics may be carried out.",
            "Using object recognition, for example, you may recognize items, images, scenes, individuals, and a lot more information while viewing a movie. The main benefit is that it provides real-time, quick access. It gives you the ability to find, recognize, follow, and ascertain the things in that specific picture or scenario. The algorithm classifies them and offers a comprehensive set of APIs to help you with targeted classification, tagging, and much more."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Improving Object Recognition's Benefits",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Particular Item Recognition",
                description: "It detects all objects, and you can subsequently create new objects. This detection allows for the definition and detection of the visual, providing more specialized and unique uses."
            },
            {
                id: 2,
                title: "Ability to Search Faces",
                description: "The process is simple and elegant thanks to the face search features. The objection restructuring process is now dynamic due to the capability to search based on the look or facial expression."
            },
            {
                id: 3,
                title: "Verification of Users",
                description: "Let's say someone has to verify a few little information. In such a scenario, object identification helps streamline the lengthy procedure, make everything instantaneous, and save time."
            },
            {
                id: 4,
                title: "Individualization",
                description: "Personalization in face procedures such as picture, video, and much more is made possible through Object Recognition. Users and consumers may rapidly search using the visual."
            },
            {
                id: 5,
                title: "Extremely Dependable Source",
                description: "It is a very dependable resource as it allows for the verification of any identified item. You may double-check with the software's assistance and proceed only with authorization. It may be changed instantly if it isn't the same.",
                pic: or3
            },
            {
                id: 6,
                title: "Faster And Easier Procedure",
                description: "Object Recognition is a quick process that yields effective outcomes. It's a quicker, easier procedure that's completed in real time above all else. The service produces results quickly and accurately with minimum effort."
            }
        ]

    },
    {
        id: 3,
        title: "",
        description: "These are the advantages that Object Recognition may provide for you. It is a quick method that greatly simplifies and manages the workflow. Despite the fact that analysis takes time, object recognition helps to shorten the procedure. It is possible to use object recognition in the text as well. The computer vision method affects data collecting and is quite scalable. It will detect and alert the relevant user if the picture or material is improper. Identifying the item in a picture, video, or text is the main function of object recognition. Furthermore, they are able to discover and identify many objects in a single frame.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Technology Employed for Identifying Objects:",
        description: "",
        onlyBullets: true,
        data: [
            "Machine Learning",
            "Image Sensing",
            "LiDar",
            "3D Modelling",
            "3D Scanning",
        ],
        bulletsPic: or4
    },
    {
        id: 5,
        title: "For object recognition, why Alta Tech Mark?",
        description: "",
        onlyBullets: true,
        data: [
            "Excellent App at a Reasonable Price",
            "Flawless sprints",
            "The adaptable and rapid growth feature",
            "Concentrate on achieving the client's objective",
            "Protected"
        ],
        bulletsPic: or5
    },
    {
        id: 6,
        title: "",
        description: "You may quickly recognize and evaluate many photos and movies with Alta Tech Mark's assistance. With real-time object identification capabilities, our skilled staff can improve company operations and has domain understanding. Multiple and repetitive jobs were examined by the AI and ML systems. Our organization improves your business to meet import digital recognition demands by developing a distinctive and powerful object recognition software. The completely managed service that improves your company while scaling up automatically and cutting costs. More computer vision is powered by our skilled developers who build scalable, dependable, secure systems. A member of our staff fully dedicates themselves to your company when you choose to work with us, handling all of your technical needs! Ready to collaborate with our knowledgeable developer for object recognition? To begin, get in touch with the Alta Tech Mark group right now.",
        onlyBullets: false,
        data: []
    }
]