import React from 'react';

// assets
import nft1 from '../../../../assets/pages/service/blockchainDev/nft1.jpeg';
import nft2 from '../../../../assets/pages/service/blockchainDev/nft2.png';
import { nftMarketPlaceDevData } from '../../../../data/services/blockchainDevData/nftMarketPlaceDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function NftMarketplaceDev() {
    return (
        <ServicesWrapper
            title="BLOCKCHAIN DEVELOPMENT NFT MARKETPLACE"
            descriptionCont="The Non-Fundigibal Token is transforming the way we discuss art, music, media, and events. NFTs may be traded using cryptocurrencies. As a result, each NFT is distinct in its own right and very hard to replicate."
            img={nft1}
        >
            <DevGridWrapper img={nft2}>
                <DataMapper data={nftMarketPlaceDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
