import React from 'react';

// assets
import es1 from '../../../../assets/pages/service/iot/es1.jpeg';
import es2 from '../../../../assets/pages/service/iot/es2.png';
import { embeddedSoftwareDevData } from '../../../../data/services/iotDevData/embeddedSoftwareDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function EmbeddedSoftware() {
    return (
        <ServicesWrapper
            title="EMBEDDED SOFTWARE DEVELOPMENT"
            descriptionCont="Computer programs designed for machine and device control are known as embedded programs. The operating system, which has limited memory and is tailored for certain hardware, is what makes it unusual."
            img={es1}
        >
            <DevGridWrapper img={es2}>
                <DataMapper data={embeddedSoftwareDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
