import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';

// redux
import { refreshContactUsSlice } from '../../../store/contactUsSlice/contactUsSlice';

// MUI
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.identifiedAs}</TableCell>
                <TableCell align="right">{row.companyName}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.phoneCountryCode}{row.phoneNumber}</TableCell>
                <TableCell align="right">{row.whatsappCountryCode}{row.whatsappNumber}</TableCell>
                <TableCell align="right">{row.projectBudget}</TableCell>
                <TableCell align="right">{row.subscribedToNewsLetter ? "Yes" : "No"}</TableCell>
                <TableCell align="right">{new Date(row.date).toLocaleDateString()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Message
                            </Typography>
                            <h1>{row.message}</h1>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function Dashboard() {
    // initializations
    const dispatch = useDispatch();
    const socket = io('https://altatechmark.com/server');

    // globals
    const { allQueries } = useSelector(state => state.contactUs);

    // effects
    useEffect(() => {
        console.log('Setting up socket listeners');
        // Listen for new queries in real-time
        socket.on('newQuery', (newQuery) => {
            console.log('New query received:', newQuery);
            dispatch(refreshContactUsSlice())
        });

        // Clean up the effect when the component unmounts
        return () => {
            console.log('Disconnecting socket');
            socket.disconnect();
        };
    }, [dispatch, socket]);

    return (
        <div>
            <TableContainer className='tw-px-[9.115vw] tw-py-[4vw]' component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Identified As</TableCell>
                            <TableCell align="right">Company Name</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Phone</TableCell>
                            <TableCell align="right">Whatsapp</TableCell>
                            <TableCell align="right">Budget</TableCell>
                            <TableCell align="right">Subscribed</TableCell>
                            <TableCell align="right">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allQueries.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
