import React from 'react';

// components
import SimpleButton from '../../../ui/simpleButton/SimpleButton';

export default function PricingCard(props) {
    console.log();
    return (
        <div className={`tw-flex tw-flex-col tw-items-start ${props.inSwiper && "tw-h-[400px]"} tw-col-span-1 tw-w-full ${!props.mini && 'tw-shadow-lg tw-rounded-lg tw-justify-between tw-py-[2.5vw]'}`}>
            <div className='tw-text-[24px] tw-text-white tw-flex tw-items-center'>
                <span className={`tw-pl-[2vw] tw-py-[0.5vw] tw-pr-[2.3vw] tw-bg-${props.bgPolygon}`}>{props.title}</span>
                <div style={{
                    width: '50px',
                    height: '100%',
                    backgroundColor: `${props.bgPolygonCode}`,
                    clipPath: "polygon(100% 50%, 0 0, 0 100%)"
                }}></div>
            </div>
            <h1 className='tw-p-[2vw]'>{props.description}</h1>
            <h1 className='tw-px-[2vw] tw-text-sm'>For Every Project, we sign NDA</h1>

            <h1 className='tw-w-full tw-text-center tw-py-[1vw] tw-mt-[2vw]'><span className='tw-text-purple tw-text-5xl tw-font-semibold'>$ {props.price}</span>/{props.perTime}</h1>

            <div className='tw-w-full tw-px-[2.2vw] tw-mt-[2vw]'>
                <SimpleButton className='tw-w-full' bgColor='purple' text='Hire Now' textColor='white' />
            </div>
        </div>
    )
}
