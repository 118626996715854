import React from 'react';

export default function CurrentOpeningsCard(props) {
    return (
        <div className='tw-grid md:tw-grid-cols-5 tw-grid-cols-3 tw-w-full tw-border-t-[1px] tw-border-slate-300 tw-py-4'>
            <h1 className='tw-text-lg tw-font-semibold tw-col-span-3'>{props.title}</h1>
            <div className='tw-flex md:tw-flex-row tw-flex-col tw-w-full md:tw-items-center tw-gap-y-4 tw-justify-between md:tw-col-span-2 tw-col-span-3'>
                <div className='tw-flex tw-flex-col tw-items-start'>
                    <h1>{props.country}</h1>
                    <h1 className='tw-text-sm'>{props.state}</h1>
                </div>
                <button className='tw-px-4 tw-py-2 tw-bg-purple tw-text-white tw-rounded-full'>Apply Now</button>
            </div>
        </div>
    )
}
