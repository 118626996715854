import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// assets
import game1 from '../../../assets/pages/service/gameDev/game1.png';
import { gameServicesData, gameDevTeamsData, gameProcessData, whyUsForGameDevData } from '../../../data/services/gameDevData/gameData';
import game2 from '../../../assets/pages/service/gameDev/game2.png';
import game3 from '../../../assets/pages/service/gameDev/game3.png';
import game4 from '../../../assets/pages/service/gameDev/game4.png';
import game5 from '../../../assets/pages/service/gameDev/game5.png';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function GameDev() {
    // initializations
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet
                ||
                <div>
                    <HeroTitleImage
                        title="GAME DEVELOPMENT"
                        bg={game1}
                        inWrapper={true}
                        simpleTextBg={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleStart="SPECIALIZE"
                            title="IN SUCH AREAS"
                            className='tw-text-center tw-mb-8'
                        />

                        <h1>
                            We have all had an addiction to a game at some point in our lives, whether it GTA, Candy Crush, or a card game. Thanks to game creation, there are a ton of amazing games that are either out there or will soon be available to amuse players, just like some of these.
                        </h1>
                        <br />
                        <h1>
                            The skilled game developers at Alta Tech Mark may be the ideal option whether you need to create a Ludo game or a metaverse-based game. With their years of experience and skill, Our game development team can provide your game development concept with outstanding visuals to make it more than simply a basic gaming application.
                        </h1>

                        <div className='md:tw-grid md:tw-grid-cols-3 tw-flex tw-items-center tw-gap-[2vw] tw-overflow-x-scroll md:tw-overflow-x-hidden tw-pb-4 tw-h-full tw-w-full'>
                            {gameServicesData.map(service =>
                                <div key={service.id} className={`tw-flex tw-flex-col tw-items-start tw-p-[3.472vw] tw-bg-slate-200 md:tw-justify-start md:tw-gap-y-8 tw-gap-y-4 tw-relative md:tw-w-full md:tw-min-w-0 tw-min-w-[75vw] tw-h-[400px]`}>
                                    <h1 className='tw-w-[50%]'>{service.title}</h1>
                                    <h1 className='tw-text-base'>{service.description}</h1>
                                    <h1 className='tw-absolute md:tw-text-7xl tw-text-4xl tw-tracking-wider tw-font-semibold tw-text-white tw-right-2 tw-top-2'>0{service.id}</h1>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[9.167vw] tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4'
                        style={{
                            backgroundImage: `url(${game2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className='tw-col-span-1 md:tw-w-[95%] tw-grid tw-items-center'>
                            <h1 className='md:tw-text-6xl tw-text-3xl max-md:tw-text-center tw-w-full tw-font-semibold'>The Tech Stacks for Game Development That <span className='tw-text-purple'>ALTA TECH MARK</span> Covers</h1>
                        </div>
                        <div className='tw-col-span-1 md:tw-text-3xl tw-text-xl'>
                            <ol className='tw-list-disc tw-grid md:tw-gap-6 tw-gap-3'>
                                <li>2D/3D Game Development</li>
                                <li>HTML 5 Game Development</li>
                                <li>Unity 3D Game Development</li>
                                <li>Unreal Engine Game Development</li>
                                <li>AR/VR Game Development</li>
                                <li>NFT Game Development</li>
                                <li>Metaverse Game Development</li>
                            </ol>
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-white'
                        style={{
                            backgroundImage: `linear-gradient(${screenWidth < 768 ? '0deg' : '-90deg'}, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${game3})  `,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <HeaderText
                            title="HOW TEAM WORKS"
                            description="What qualifies us as a leading game development firm? Due to the expertise of our specialists, Alta Tech Mark ranks among the top game development companies, adeptly translating the need for game creation into comprehensive gaming solutions. Who are these Alta Tech Mark prodigies?"
                        />
                        <div className='tw-grid tw-gap-y-5 tw-mt-5 md:tw-ml-6 tw-ml-4'>
                            {gameDevTeamsData.map(team =>
                                <div key={team.id} className='tw-grid'>
                                    <h1 className='tw-text-lg tw-font-semibold'>{team.role}</h1>
                                    <h1>{team.description}</h1>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='tw-p-[4vw]'
                        style={{
                            backgroundImage: `url(${game4})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    >
                        <div className='tw-px-[5.115vw] tw-py-[2vw]'
                            style={{
                                backgroundImage: `url(${game5})`
                            }}
                        >
                            <HeaderText
                                colorTitleStart="PROCESS"
                                title="WE FOLLOW"
                                className='tw-text-center tw-mb-8'
                            />
                            {screenWidth < 768 ?
                                <Swiper
                                    style={{
                                        "--swiper-pagination-color": "#9065FD",
                                        "--swiper-pagination-bullet-inactive-color": "#999999",
                                        "--swiper-pagination-bullet-inactive-opacity": "1",
                                        "--swiper-pagination-bullet-size": "16px",
                                    }}
                                    modules={[Autoplay, Pagination]}
                                    spaceBetween={100}
                                    slidesPerView={1}
                                    loop={true}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 3000,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                >
                                    {gameProcessData.map(service =>
                                        <SwiperSlide key={service.id}>
                                            <div className='tw-h-[450px]'>
                                                <div className='tw-h-[400px] tw-flex tw-flex-col tw-items-start tw-p-[3.472vw] tw-bg-slate-200 md:tw-w-[30%] tw-justify-start tw-gap-y-8 tw-relative'>
                                                    <h1 className='tw-w-[50%]'>{service.title}</h1>
                                                    <h1 className='tw-text-base'>{service.description}</h1>
                                                    <h1 className='tw-absolute tw-text-7xl tw-tracking-wider tw-font-semibold tw-text-white tw-right-2 tw-top-2'>0{service.id}</h1>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                                :
                                <div className='tw-flex tw-items-center tw-justify-center tw-flex-wrap tw-gap-[25px] tw-mt-4'>
                                    {gameProcessData.map(service =>
                                        <div key={service.id} className='tw-h-[400px] tw-flex tw-flex-col tw-items-start tw-p-[3.472vw] tw-bg-slate-200 md:tw-w-[30%] tw-justify-start tw-gap-y-8 tw-relative'>
                                            <h1 className='tw-w-[50%]'>{service.title}</h1>
                                            <h1 className='tw-text-base'>{service.description}</h1>
                                            <h1 className='tw-absolute tw-text-7xl tw-tracking-wider tw-font-semibold tw-text-white tw-right-2 tw-top-2'>0{service.id}</h1>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>

                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'>
                        <HeaderText
                            title1="WHY"
                            colorTitleBetween="ALTA TECH MARK"
                            title2="FOR GAME DEVELOPMENT"
                            className='tw-text-center tw-mb-8'
                        />
                        <div className='tw-grid tw-grid-cols-1 tw-gap-y-6'>
                            {whyUsForGameDevData.map(item =>
                                <div key={item.id} className='tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-justify-between tw-w-full tw-flex-col-reverse tw-gap-y-4'>
                                    <div className={`tw-flex tw-flex-col tw-items-start md:tw-w-[50%] tw-w-full`}>
                                        <h1 className='tw-font-semibold'>{item.title}</h1>
                                        <h1>{item.description}</h1>
                                    </div>
                                    <img src={item.src} alt="game pic" className={`${item.id % 2 !== 0 ? 'md:tw-order-first' : 'md:tw-order-last'}`} />
                                </div>
                            )}
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
