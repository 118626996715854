import React from 'react';

// assets
import react1 from '../../../../assets/pages/service/WebAppDev/react1.png';
import react2 from '../../../../assets/pages/service/WebAppDev/react2.png';
import { reactJsDevData } from '../../../../data/services/webAppDevData/reactJsDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function ReactJsAppDev() {
    return (
        <ServicesWrapper
            title="REACT JS DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={react1}
        >
            <DevGridWrapper img={react2}>
                <DataMapper data={reactJsDevData}/>
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
