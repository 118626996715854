export const hireGolangDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "One well-known programming language that has made a name for itself as a pioneer is Golang. At the same time as Google was rapidly growing, the infrastructure management code was growing in size and complexity. Consequently, the large and complex codebase began to slow down certain Google cloud engineers.",
            "Many entrepreneurs want to incorporate it into their work environment, but they can't seem to make it work. The place to go if you're searching for an effective Golang developer is Alta Tech Mark.",
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Understanding the Growth of Golang:",
        description: `Golang, often referred to simply as "Go," is a statically typed and compiled programming language introduced by Google. Designed with simplicity, concurrency, and performance in mind, Golang has rapidly become a go-to choice for various applications, ranging from web servers to data processing.`,
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Concurrency Made Simple",
                description: "One of Go's standout features is its native support for concurrent programming. Go routines and channels simplify the process of running tasks concurrently, allowing developers to build highly efficient applications without the typical complexities of threading."
            },
            {
                id: 2,
                title: "Clean & Efficient Codebase",
                description: "Golang is recognized for its clear syntax, which aids in writing clean and maintainable code, leading to better software quality and reduced technical debt."
            },
            {
                id: 3,
                title: "Powerful Standard Library",
                description: "Go comes with a rich standard library, providing a plethora of built-in functionalities, from web servers to cryptographic operations."
            }
        ]
    },
    {
        id: 3,
        title: "Why Alta Tech Mark for Golang Development?",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Dedicated Golang Experts",
                description: "At Alta Tech Mark, our Golang developers are well-versed with the nuances of the language, ensuring you get the best of what Golang has to offer."
            },
            {
                id: 2,
                title: "Customized Solutions",
                description: "Every business has unique requirements. Our Golang developers prioritize understanding your specific needs to deliver tailor-made solutions that fit seamlessly into your work infrastructure."
            },
            {
                id: 3,
                title: "Competitive Pricing with Quality",
                description: "Alta Tech Mark believes in offering top-notch Golang development services at competitive prices, ensuring high returns on your investment without compromising on quality."
            },
            {
                id: 4,
                title: "End-to-End Development and Support",
                description: "From the inception of your project to post-launch support, our team ensures a smooth journey and ongoing assistance to keep your application up-to-date and efficient."
            },
            {
                id: 5,
                title: "Transparent Communication",
                description: "Open and continuous communication is at the heart of our process. Our team ensures that you're informed about every step of your Golang project's development."
            }
        ]
    },
]