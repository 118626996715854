import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CookieConsent from "react-cookie-consent";

// redux
import { responsiveActions } from './store/responsiveSlice/responsiveSlice';
import { getAllQueries } from './store/contactUsSlice/contactUsSlice';

// icons
import whatsappIcon from './assets/icons/whatsApp.png';
import qaIcon from './assets/icons/qaIcon.svg';

// components
import ContactNav from './components/contactNav/ContactNav';
import Navbar from './components/navbar/Navbar';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Footer from './components/footer/Footer';
import ContactDetails from './components/home/contactDetails/ContactDetails';
import ChatBot from './components/home/chatBot/ChatBot';

// MUI
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

function App() {
	// initializations
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// globals
	const { isAuthenticated, isAdmin } = useSelector(state => state.user);
	const { refreshContactUs } = useSelector(state => state.contactUs);

	// locals
	const [showContactDetails, setShowContactDetails] = useState(false);
	const [showChatBot, setShowChatBot] = useState(false);

	// effects
	// effect for redirecting to home page
	useEffect(() => {
		if (window.location.href.slice(-1) === "/") {
			navigate('/')
		}
	}, [navigate])

	// for resizing
	useEffect(() => {
		// Add the event listener on component mount
		window.addEventListener('resize', handleWindowResize);

		// Clean up the event listener on component unmount
		// return () => {
		//     window.removeEventListener('resize', handleWindowResize);
		// };
	}, []);

	// for scrollbar
	useEffect(() => {
		document.body.classList.add('scrollbar-hide');
		return () => {
			document.body.classList.remove('scrollbar-hide');
		};
	}, []);

	useEffect(() => {
		if (isAuthenticated && isAdmin) {
			dispatch(getAllQueries()).then(response => {
				if (!response.error) {
					navigate('/admin/dashboard')
				}
			})
		}
		else if ((!isAuthenticated || !isAdmin) && window.location.href.includes('/admin/dashboard')) {
			navigate('/')
		}
	}, [isAuthenticated, isAdmin, navigate, dispatch, refreshContactUs])


	// functions
	const whatsappHandler = () => {
		window.open('https://api.whatsapp.com/send/?phone=4477236621464&text&type=phone_number&app_absent=0', '_blank')
	}

	// const toggleDrawer = (newOpen) => () => {
	// 	setShowChatBot(newOpen);
	// };

	const handleWindowResize = () => {
		dispatch(responsiveActions.setScreenWidth({ screenWidth: window.innerWidth }));
	};

	return (
		<div id='mainApp'>
			<ScrollRestoration />
			<ContactNav />
			<Navbar />
			<Outlet />
			<CookieConsent
				location="bottom"
				buttonText="I understand!"
				cookieName="myAwesomeCookieName2"
				style={{ background: "#2B373B" }}
				buttonStyle={{ backgroundColor: "#9065FD", color: "white", fontSize: "13px", borderRadius: '5px' }}
				expires={150}
			>
				We use cookies for the best experience and to analyze site usage. By continuing without changing settings, you consent to our cookie use.
			</CookieConsent>

			<Footer />
			{/* whatsapp icon */}
			<div className='tw-left-[1%] tw-bottom-[25px] tw-z-10 tw-fixed' onClick={whatsappHandler}>
				<img src={whatsappIcon} className='tw-w-[50px]' alt="whatsapp" />
			</div>
			{/* whatsapp icon */}

			{/* phone icon */}
			<div className={`tw-left-0 tw-bottom-[50%] tw-fixed tw-text-white tw-flex tw-items-end tw-cursor-pointer tw-z-10 tw-animate-slideFromLeft tw-transition-colors tw-duration-500 tw-delay-500`} onClick={() => {
				// setShowContactDetails(!showContactDetails)
			}}>
				{showContactDetails && <ContactDetails />}
				<LocalPhoneIcon onClick={() => setShowContactDetails(!showContactDetails)} className='tw-p-3 tw-box-content tw-bg-darkPurple' />
			</div>
			{/* phone icon */}

			{/* chat icon */}
			<div className={`tw-right-[1%] tw-bottom-[25px] tw-fixed tw-flex tw-flex-col tw-justify-end tw-items-end tw-z-10`}>
				<ScrollToTop />
				<img src={qaIcon} className='' alt="qa" onClick={() => setShowChatBot(!showChatBot)} />
				{showChatBot && <ChatBot />}
			</div>
			{/* chat icon */}
		</div>
	);
}

export default App;
