import androidDev4 from "../../assets/pages/hireDev/androidDev4.png";
import androidDev5 from "../../assets/pages/hireDev/androidDev5.png";
import androidDev6 from "../../assets/pages/hireDev/androidDev6.png";
import androidDev7 from "../../assets/pages/hireDev/androidDev7.png";
import androidDev8 from "../../assets/pages/hireDev/androidDev8.png";

export const hireAndroidDevData = [
    {
        id: 1,
        title: "Years of experience enhances our expertise in developing custom Android apps.",
        descriptions: [
            "Alta Tech Mark is a leading app development business that offers much more than just device-specific app development services. From on-demand apps to cutting-edge IoT connections, our Android app developers have built a strong portfolio by developing and releasing a wide variety of mobile app solutions for the Android platform.",
            "We also take great care to make sure that our Android apps are suitable for a variety of gadgets, such as tablets, wearable technology, SmartTVs, and of course smartphones. Our focus on fusing our years of experience in Android development with our customers' original app designs has made Alta Tech Mark a leading option for international companies looking to create Android applications.",
            `With a commitment to the "practice makes perfect" approach, our Android developers have continuously produced mobile apps that are feature-rich, intuitive, and easy to use for the Google Playstore. Many multinational corporations have collaborated with Alta Tech Mark's talented Android development team to achieve their objectives.`
        ],
        sectionPic: androidDev4,
        sectionPicRow: true,
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "",
        description: '',
        onlyBullets: false,
        grid3Display: true,
        data: [
            {
                id: 1,
                title: "Advice on Android Development",
                description: "There is more to Android development than merely publishing apps on the Play Store. It involves designing a mobile application with a particular user base in mind, keeping their preferences at the forefront of the design, all the while guaranteeing you get the best possible app development deal. Consult with experts at Alta Tech Mark who have extensive experience developing Android applications.",
                descPic: androidDev5,
                descPicOrder: 1
            },
            {
                id: 2,
                title: "App UI/UX Design for Android",
                description: "Our Android app designers' only goal is to create cutting-edge apps and provide the best possible user experiences. Our Android app design service ensures that app prototypes reflect your company strategy by using the newest design trends.",
                descPic: androidDev6,
                descPicOrder: 3
            },
            {
                id: 3,
                title: "Develop Android on Demand",
                description: "Employ Alta Tech Mark's technically proficient Kotlin, Java, and other language Android app developers. They are capable of creating feature-rich apps for Android wearables, desktops, and the web. Our Android application developers have worked with a variety of industries, including the gaming and corporate sectors, over their more than ten years of expertise."
            },
            {
                id: 4,
                title: "Assurance of Android App Quality",
                description: "Don't open an Android app that is glitchy or has out-of-date functionality. Delivering an exceptional user experience can help you engage your target audience. With help from Alta Tech Mark, a top Android app development business.",
                descPic: androidDev7,
                descPicOrder: 1
            },
            {
                id: 5,
                title: "Cross-platform Implementation",
                description: "To extend the reach of your app, get in touch with the professionals at Alta Tech Mark. Our experts in Android development can assist you in making sure your app is accessible on all pertinent platforms so it may reach as many users as possible."
            },
            {
                id: 6,
                title: "Testing of Android Software",
                description: "Thorough testing is emphasized by the Alta Tech Mark Android Software Development team as a necessary step in the development process. Take advantage of their proficiency with both automated and manual testing methods to guarantee a feature-rich and error-free product for you and your users.",
                sectionPic: androidDev8,
            },
            {
                id: 7,
                title: "Moving Apps to the Android Platform",
                description: "By releasing your current app on the most popular smartphone platform, you may increase the reach of your app. Make the most of Alta Tech Mark's experience in Android mobile app development to increase your target audience, user base, and return on investment."
            },
            {
                id: 8,
                title: "Employ Android Programmers",
                description: "Hire the best Android app developers from Alta Tech Mark, who are renowned for providing cutting-edge solutions to multinational corporations, to optimize the results of your Android development efforts."
            }
        ]
    },
]

export const hireAndroidDevFaqs = [
    {
        id: 1,
        question: "1. What steps should I follow to Hire Android app developers?",
        answer: "Alta Tech Mark offers a very simple process to hire dedicated Android app developers. Just provide project details and the technical expertise you need. The Android app developer begins working on your project based on your selection."
    },
    {
        id: 2,
        question: "2. Can I check the technical skill set of the dedicated Android app developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Android app developers before making a final move."
    },
    {
        id: 3,
        question: "3. How much does it cost to hire Android app developers?",
        answer: "The cost estimation for dedicated Android app developers varies based on various factors such as team size, hiring model, the complexity of the app development, the type of solution, features and functionalities and many more."
    },
    {
        id: 4,
        question: "4. Why should I hire Android developers?",
        answer: "The dedicated Android app developers will work only for you to deliver outstanding results with the highest optimization. The team of dedicated android developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 5,
        question: "5. Can I hire dedicated Android developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Android app developer, then you can hire a dedicated Android developer on an hourly or project/task base."
    }
]