import cartoonNetwork from '../assets/esteemedClients/cartoonNetwork.png';
import google from '../assets/esteemedClients/google.png';
import disney from '../assets/esteemedClients/disnep.png';
import viacom from '../assets/esteemedClients/viacom.png';
import sbs from '../assets/esteemedClients/sbs.png';
import papaJohns from '../assets/esteemedClients/papaJohns.png';

export const esteemedClientsData = [
    {
        id: 1,
        src: cartoonNetwork,
    },
    {
        id: 2,
        src: google,
    },
    {
        id: 3,
        src: disney,
    },
    {
        id: 4,
        src: viacom,
    },
    {
        id: 5,
        src: sbs,
    },
    {   
        id: 6,
        src: papaJohns,
    },
    {
        id: 7,
        src: viacom,
    },
    {
        id: 8,
        src: sbs,
    },
    {   
        id: 9,
        src: papaJohns,
    },
]