import React from 'react';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

// components
import Divider from '../divider/Divider';

export default function EventsPageCard({ main, sub, title, descriptions, month, year, location }) {
    return (
        <div className={`md:tw-py-[60px] tw-px-[9.115vw] tw-grid tw-grid-cols-2 tw-gap-[50px] tw-relative`}>
            <div className='md:tw-col-span-1 tw-col-span-2'>
                <img src={main} alt="main" />
            </div>

            <div className='md:tw-col-span-1 tw-col-span-2'>
                <h1 className='md:tw-text-2xl tw-text-xl tw-font-semibold'>{title}</h1>
                {descriptions.map((description, index) =>
                    <div key={index}>
                        <h1>{description}</h1>
                        <br />
                    </div>
                )}
            </div>

            <div className='tw-grid tw-grid-cols-2 tw-col-span-2'>
                <div className='md:tw-absolute tw-p-8 tw-bg-white tw-bottom-[0%] tw-left-[25%] tw-col-span-2 md:tw-w-[50%] tw-w-full md:tw-scale-[0.6]'>
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        centeredSlides={true}
                        autoplay={{
                            delay: 3000,
                        }}
                    >
                        {sub.map((card, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img src={card} alt='sub' />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>

                <div className='tw-flex tw-flex-col tw-items-start tw-col-span-2'>
                    <h1 className='md:tw-text-xl tw-text-base tw-font-semibold'>{month}</h1>
                    <h1 className='md:tw-text-xl tw-text-base tw-font-semibold'>{year}</h1>
                    <h1 className='md:tw-text-base tw-text-sm'>{location}</h1>
                </div>
            </div>


            <div className='tw-col-span-2'>
                <Divider />
            </div>
        </div>
    )
}
