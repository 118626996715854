import React from 'react';

// icons
// import pakistan from '../../../assets/flags/pak.png';
// import usa from '../../../assets/flags/usa.png';
// import uk from '../../../assets/flags/uk.png';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import landline from '../../../assets/icons/landline.svg';
import phone from '../../../assets/icons/phone.svg';

import { PopupWidget } from "react-calendly";

export default function ContactDetails() {
    return (
        <div className='tw-grid tw-grid-cols-1 tw-p-4 tw-gap-2 tw-animate-slideFromLeft tw-place-items-center tw-text-sm tw-bg-darkPurple tw-relative tw-top-[100px]'>
            <h1 className='tw-text-center'>Let's hear from you <br /> Dial us now!</h1>
            <div className='tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2'>
                {/* <PhoneAndroidIcon fontSize="small" /> */}
                <img src={phone} alt="indian flag" width={25} height={19} />
                <p className='hover:tw-text-[]'>+44 7723 621464</p>
            </div>
            <div className='tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2'>
                {/* <PhoneEnabledIcon fontSize="small" /> */}
                <img src={landline} alt="indian flag" width={25} height={19} />
                <p className='hover:tw-text-[]'>+92 21 3889 6008</p>
            </div>
            {/* <div className='tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2'>
                <img src={uk} alt="indian flag" width={25} height={19} />
                <p className='hover:tw-text-[]'>+1 309 791 4105</p>
            </div> */}
            <h1 className='tw-flex tw-items-center tw-w-full tw-gap-x-1'>
                <div className='tw-h-[1px] tw-w-full tw-bg-white'></div>
                Or
                <div className='tw-h-[1px] tw-w-full tw-bg-white'></div>
            </h1>

            <PopupWidget
                url="https://calendly.com/abdulmoiez876/schedule-now"
                rootElement={document.getElementById("mainApp")}
                text="Schedule a Call"
                textColor="#ffffff"
                color="#9065FD"
            />
        </div>
    )
}
