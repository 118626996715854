import React from 'react';

// components
import ApplyNowWithForm from '../../../../components/career/applyNow/ApplyNow';

export default function ApplyNow() {
    return (
        <ApplyNowWithForm />
    )
}
