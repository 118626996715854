export const hireUiUxDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Everyone may agree that the only source of knowledge is expertise, and that expertise is the finest of all. Many IT-related firms, particularly software developers, are familiar with UX Design when it comes to user experience. Kind of enterprise or utility: The purpose of an item is to maximize user experience. This is the primary technological background.",
            "To put it simply, designing user experiences (UX) for applications is the process of creating the greatest possible user experience. Although they may have strong programming skills, they may not be well-versed in the design process.",
            "Everyone may get irate as a result of poor customer experiences brought on by this information gap. should work with a company that has a track record of creating websites or applications and maintaining happy, delighted customers."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Take Advantage Of Alta Tech Mark's Creative UI/UX Designers",
        descriptions: [
            "Look no farther than Alta Tech Mark if you're looking for a designer for your program.",
            "Our main goal is to make sure that our customers obtain designs that are unmatched and packed with features that increase ROI and efficiency. Our designers create interface elements with great care, making it possible for users to understand each one's function. They have extensive expertise redesigning or starting software, applications, and portal website designs. Together with their thorough knowledge of cutting-edge design frameworks, we always provide outputs that are focused on the needs of our clients.",
            "The cornerstone of our development methodology continues to be the user viewpoint. We promise that every product we design has the best possible usability. Our UI/UX design services provide our customers a dynamic and impactful user experience, highlighting return on investment and advancing their brand strategy."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 3,
        title: "Explore Our Services for UI/UX Design",
        description: "Within the modern digital environment, which is characterized by a wide range of IT applications, User Experience (UX) design is a crucial factor in determining success. Using the newest design tools and technology is something that our UI/UX Designers are skilled at. A sample of our UI/UX design services is provided here:",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Architecture of Information Helpful UI",
                description: "For a creative UI/UX design, work with Alta Tech Mark's designers. Our skilled designers create a coherent impression on target audiences by matching designs to users' natural behaviors."
            },
            {
                id: 2,
                title: "User Testing",
                description: "Through user testing sessions, our committed UI/UX designers get priceless feedback and insights into UI/UX. This helps implement necessary adjustments and improvements to the design."
            },
            {
                id: 3,
                title: "IA & Wireframes",
                description: "Our UI/UX designers help to conceptualize and illustrate data structures using wireframes, which help to clarify UI qualities by illuminating early processes, data structures, and navigation."
            },
            {
                id: 4,
                title: "Cross-Platform Compatibility",
                description: "Work with the designers of Alta Tech Mark to provide essential features. Platform-agnostic and adaptable designs are manifested by following established UI/UX criteria."
            },
            {
                id: 5,
                title: "Custom Design Solutions",
                description: "Our talented designers can create websites that specifically meet the needs of your business. Using cutting-edge technologies, we modify your product design to fulfill your requirements."
            },
            {
                id: 6,
                title: "Website Design Services",
                description: "Alta Tech Mark prioritizes interactive website solutions and offers UI/UX maestros who carefully choose user-centric website designs to meet your business's requirements."
            }
        ]
    },
    {
        id: 4,
        title: "Our Proficiency:",
        description: "",
        onlyBullets: true,
        data: [
            "Photoshop",
            "Axure",
            "Corel Draw",
            "Dreamweaver",
            "InDesign",
            "InVision",
            "Microsoft Visio",
            "Sketch",
            "Justinmind",
            "Zeplin",
            "Adobe Flash"
        ]
    },
    {
        id: 5,
        title: "Technologies:",
        description: "",
        onlyBullets: true,
        data: [
            "Bootstrap",
            "AngularJS",
            "ReactJS",
            "Sass",
            "Vue JS",
            "Javascript / jQuery",
            "HTML / HTML 5",
            "CSS / CSS 3"
        ]
    },
    {
        id: 6,
        title: "Why Choose Alta Tech Mark as Your UI/UX Design Partner?",
        description: "Combining creativity and technology, our design team stays abreast of worldwide advancements and delivers flawless user interfaces with user-friendly navigation. This is what makes Alta Tech Mark unique in the field of UI/UX design:",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Dedicated Team",
                description: "We are a group of highly qualified UI/UX designers that specialize in providing superior design solutions for a variety of businesses."
            },
            {
                id: 2,
                title: "Flexibility Regarding Time Zones",
                description: "We are set up to match your desired time zone. Work with our talented designers and enjoy smooth communication."
            },
            {
                id: 3,
                title: "On-Time Service",
                description: "We take great satisfaction in finishing 97% of jobs before the deadline by using cutting-edge techniques and technology."
            },
            {
                id: 4,
                title: "Security",
                description: "We at Alta Tech Mark guarantee absolute data security. By implementing strict protocols, we guarantee that our digital solutions are immune to security breaches."
            },
            {
                id: 5,
                title: "Support Available Around-the-Clock",
                description: "Our staff is always available to help with any questions you may have, both during and after the development period."
            }
        ]
    }
]


export const hireUiUxDevFaq = [
    {
        id: 1,
        question: "1. Why should I hire dedicated UI/UX designers?",
        answer: "The dedicated UI/UX designers will work only for you to deliver outstanding results with the highest optimization. The team of UI/UX designers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire UI/UX designers?",
        answer: "Alta Tech Mark offers a very simple process to hire UI/UX designers. Just provide project details and the technical expertise you need. The team of UI/UX designers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated UI/UX designers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the UI/UX Designers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated UI/UX Designers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated UI/UX Designer, then you can hire dedicated UI/UX Designers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated UI/UX Designers?",
        answer: "The cost estimation for dedicated UI/UX Designers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]