export const kotlinDevData = [
    {
        id: 0,
        title: "",
        descriptions: [
            "Mobile app development industry is rising at an immense rate and with the growing demand for apps, a large number of mobile app developers are coming up with extremely scalable apps that can satisfy your business demands. However, when it comes to developing a mobile app, to choose the appropriate programming language is the most importance.",
            "Google has announced a new programming language about Kotlin as an official language for Android app development. Kotlin is a brilliantly designed and developed language that will make Android development quicker and more exciting. Kotlin is the latest open source, statically typed programming language that targets the Java platform. It is a great fit for server-side web applications development, that enables writing safe, concise and fully interoperable code with current Java libraries.",
            "Alta Tech Mark offers Kotlin app development services to develop world-class Android apps. As a Kotlin app development company, we provide Kotlin app developers for both onsite/offshore requirements. Being compatible with Java, Kotlin for Android development can work side-by-side with these languages and it is also easy to get started. So, our developers will simply keep your current code, continue with the Android library and at the same time add Kotlin to your project.",
            "At Alta Tech Mark, we put in our best efforts, to provide the aptest solutions for years of experience and expertise and come up with tailor-made solutions. Our Android app developers have years of experience in producing various kinds of Android applications."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 1,
        title: "Know The Benefits Of Choosing Kotlin To Develop Your Android App",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Open Source",
                description: "Kotlin is an open-source programming language. More apart from being open-source, it also provides a single-click tool for Android developers to convert current Java code."
            },
            {
                id: 2,
                title: "Better Performance",
                description: "Kotlin provides better performance. The operation of call methods in Kotlin is faster as there is no need to know at runtime which method to call."
            },
            {
                id: 3,
                title: "Safe & Reliable",
                description: "Kotlin contains safety features for immutability, which improves Android app development. And Kotlin’s compiler verifies the accuracy of programs at compile time, so less chance for runtime crashes."
            },
            {
                id: 4,
                title: "Functional & Object-Oriented",
                description: "In Kotlin web framework, developers can do code in both object-oriented and functional manner. This way Kotlin helps Android developers to write first-class functions."
            },
            {
                id: 5,
                title: "Data Classes in Kotlin",
                description: "In Kotlin, developers can write the same logic in a very simpler manner and relatively lesser lines."
            },
            {
                id: 6,
                title: "Named parameters",
                description: "In Kotlin web framework, we can pass parameters by name, rather than an index. This way is very helpful when a function has a number of parameters."
            },
            {
                id: 7,
                title: "More Concise",
                description: "Kotlin is more concise, it solves the same problem with relatively fewer lines of code. It increases code readability and maintainability. So, developers can read, write, and change the code efficiently and more effectively."
            }
        ]
    },
    {
        id: 2,
        title: "Globally Renowned Leaders Are Already using Kotlin",
        description: "Various Large Organizations are already started using Kotlin for better and improved Performance. Though Kotlin is new in the market, it has started to give tough competition to Java as the language of choice for Android app development. And so, All the big companies are adopting Kotlin in some way. Let’s have a look at some of the famous name of organizations.",
        onlyBullets: true,
        data: [
            "Pinterest",
            "Uber",
            "Pivotal",
            "Netflix",
            "Coursera",
            "Evernote",
            "Corda",
            "Atlassian"
        ]
    },
    {
        id: 3,
        title: "Alta Tech Mark Develops An Android App With Kotlin For The Following Categories",
        description: "Here are some of the top mobile app development categories, where Kotlin fits perfectly with the Android platform. Apart from these categories, we develop any other type of Android application by using Kotlin according to your business requirements.",
        onlyBullets: true,
        data: [
            "Logistic & Transportation Apps",
            "Grocery Apps",
            "Music & Entertainment Apps",
            "Education & Learning Apps",
            "Project-Management Apps",
            "Food Apps",
            "Photo & Video Apps",
            "Tours & Traveling Apps",
            "Lifestyle Apps",
            "Health & Fitness Apps"
        ]
    },
    {
        id: 4,
        title: "Why Hire Kotlin Developers From Alta Tech Mark?",
        description: "As a leading web and mobile app development company, Alta Tech Mark' professionals are updated with the latest trends and technology frameworks. By using our expertise and experience together, we aim to achieve business objectives through tailored and modernized development approach. It does not matter which kind of business you have, our Kotlin developers can make you the very best apps. We are dedicated to providing customized experience through the Kotlin Android app development services. We have dedicated and experienced app developers who can ideate and execute your app idea very quickly as per your requirements.",
        onlyBullets: false,
        data: []
    },
    {
        id: 5,
        title: "Our Specialties",
        description: "Alta Tech Mark is dedicated to helping you out in building an Android app integrated with Kotlin. Our Kotlin application developers implement their knowledge and expertise to bring forth an effective app design.",
        onlyBullets: true,
        data: [
            "Highly Skilled & Experienced Kotlin",
            "Seamless Communication",
            "Technical Support",
            "Dedicated Kotlin Team",
            "Non-Disclosure Agreement",
            "Attractive Design",
            "Flexible Engagement Models",
            "On-Time Delivery Of The Project"
        ]
    }
]