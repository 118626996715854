import React from 'react';

// data
import { statsCardData } from '../../../data/statsCardData';

// components
import StatsCard from './StatsCard';

// icons
import salesforce from '../../../assets/brands/salesforce.png';
import google from '../../../assets/brands/google.png';
import oracle from '../../../assets/brands/oracle.png';
import aws from '../../../assets/brands/aws.png';
import statsBg from '../../../assets/background/statsBg.png';

export default function Stats() {
    return (
        <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-5 tw-py-[4vw] tw-w-full tw-overflow-x-hidden tw-px-[9.1vw] tw-gap-6 tw-text-white tw-items-start'
            style={{
                backgroundImage: `linear-gradient(-90deg, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${statsBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className='md:tw-col-span-2 tw-col-span-1 tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-3 tw-text-[16px] tw-font-poppins'
                style={{
                    fontWeight: '700px',
                    letterSpacing: '0.5px',
                    lineHeight: '28px',
                    textAlign: 'justify'
                }}
            >
                <h1 className='tw-font-semibold'>
                    <span className='md:tw-text-[1.7vw] tw-text-2xl'>WHY ALTA TECH MARK?</span>
                </h1>

                <h1 className='md:tw-text-base tw-text-sm'>
                    Because excellence is not just a goal but our standard.
                </h1>

                <h1 className='md:tw-text-base tw-text-sm'>
                    Leading the charge in the dynamic landscape of technology, Alta Tech Mark stands ready to become your go-to expert. Specializing in the Internet of Things (IoT) and a wide array of software services, we create custom hardware and software solutions that cater precisely to your business's unique demands.
                    {/* <span className='tw-font-semibold'>Additionally, we have sales offices in Dubai, United Arab Emirates, and California, USA.</span> */}
                </h1>

                <h1 className='md:tw-text-base tw-text-sm'>
                    Choosing <span className='tw-font-semibold'>Alta Tech Mark</span> means selecting a partner who is dedicated to boosting your operational efficiency and opening new avenues for growth. By managing all facets of software and hardware development from the initial blueprint to the final rollout—we deliver a unified and effective system built for longevity.
                </h1>

                <h1 className='md:tw-text-base tw-text-sm'>
                    Collaborate with us to transform your vision into an innovative reality where ingenuity and connectivity forge the path to your success.
                </h1>

                <div className='md:tw-col-span-3 tw-col-span-1 tw-w-full md:tw-hidden tw-py-[48px] tw-items-center tw-overflow-x-scroll tw-gap-x-[25px] tw-flex tw-customScrollbar'>
                    {statsCardData.map((statsCard, index) => <StatsCard
                        key={statsCard.id}
                        icon={statsCard.icon}
                        stats={statsCard.stats}
                        description={statsCard.description}
                    // className={(index === 1 || index === 4) ? "md:tw-mt-[36px] md:tw-relative" : ""}
                    />)}
                </div>

                <h1 className='tw-font-semibold tw-text-2xl'>OFFICIAL PARTNER OF,</h1>

                <div className='tw-grid tw-grid-cols-4 tw-w-full md:tw-gap-4 tw-gap-6'>
                    <img className='tw-col-span-1 tw-w-[90px] tw-bg-slate-200 tw-py-[5px] tw-px-2 tw-box-content tw-rounded-lg' src={google} alt="google" />
                    <img className='tw-col-span-1 tw-w-[90px] tw-bg-slate-200 tw-p-2 tw-box-content tw-rounded-lg' src={aws} alt="aws" />
                    <img className='tw-col-span-1 tw-w-[90px] tw-bg-slate-200 tw-p-2 tw-box-content tw-rounded-lg' src={oracle} alt="oracle" />
                    <img className='tw-col-span-1 tw-w-[90px] tw-bg-slate-200 tw-p-2 tw-box-content tw-rounded-lg' src={salesforce} alt="salesforce" />
                </div>
            </div>

            <div className='md:tw-col-span-3 tw-w-full md:tw-grid-cols-3 md:tw-grid md:tw-gap-8 md:tw-justify-center tw-items-center tw-overflow-x-scroll md:tw-overflow-x-hidden tw-gap-x-[25px] tw-hidden'>
                {statsCardData.map((statsCard, index) => <StatsCard
                    key={statsCard.id}
                    icon={statsCard.icon}
                    stats={statsCard.stats}
                    description={statsCard.description}
                    className={(index === 1 || index === 4) ? "md:tw-mt-[36px] md:tw-relative" : ""}
                />)}
            </div>
        </div>
    )
}
