import React from 'react';

// assets
import laravel1 from '../../../../assets/pages/service/WebAppDev/laravel1.png';
import laravel2 from '../../../../assets/pages/service/WebAppDev/laravel2.png';
import { laravelDevData } from '../../../../data/services/webAppDevData/laravelDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function LaravelAppDev() {
    return (
        <ServicesWrapper
            title="LARAVEL DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={laravel1}
        >
            <DevGridWrapper img={laravel2}>
                <DataMapper data={laravelDevData}/>
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
