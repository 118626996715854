// icons
import servicesHoverBg1 from '../../../assets/background/servicesHoverBg-01.png'
import servicesHoverBg2 from '../../../assets/background/servicesHoverBg-02.png'
import servicesHoverBg3 from '../../../assets/background/servicesHoverBg-03.png'
import servicesHoverBg4 from '../../../assets/background/servicesHoverBg-04.png'
import servicesHoverBg5 from '../../../assets/background/servicesHoverBg-05.png'
import servicesHoverBg6 from '../../../assets/background/servicesHoverBg-06.png'
import servicesHoverBg7 from '../../../assets/background/servicesHoverBg-07.png'
import servicesHoverBg8 from '../../../assets/background/servicesHoverBg-08.png'
import servicesHoverBg9 from '../../../assets/background/servicesHoverBg-09.png'

export const servicesData = [
    {
        id: 1,
        name: 'mobileAppDevelopment',
        value: 'Mobile App Development',
        children: [
            {
                id: 1,
                name: 'androidApp',
                value: 'Android App',
                url: '/services/mobileAppDev/androidAppDev'
            },
            {
                id: 2,
                name: 'iPhoneApp',
                value: 'iPhone App',
                url: '/services/mobileAppDev/iphoneAppDev'
            },
            {
                id: 3,
                name: 'hybridApp',
                value: 'Hybrid App',
                url: '/services/mobileAppDev/hybridAppDev'
            },
            // {
            //     id: 4,
            //     name: 'flutter',
            //     value: 'Flutter',
            //     url: '/services/mobileAppDev/flutterAppDev'
            // },
            // {
            //     id: 5,
            //     name: 'reactNative',
            //     value: 'React Native',
            //     url: '/services/mobileAppDev/reactNativeAppDev'
            // },
            // {
            //     id: 6,
            //     name: 'kotlin',
            //     value: 'Kotlin',
            //     url: '/services/mobileAppDev/kotlinAppDev'
            // },
            // {
            //     id: 7,
            //     name: 'ionic',
            //     value: 'Ionic',
            //     url: '/services/mobileAppDev/ionicAppDev'
            // },
            // {
            //     id: 8,
            //     name: 'swift',
            //     value: 'Swift',
            //     url: '/services/mobileAppDev/swiftAppDev'
            // },
            // {
            //     id: 9,
            //     name: 'xamarin',
            //     value: 'Xamarin',
            //     url: '/services/mobileAppDev/xamarinAppDev'
            // }
        ],
        cover: servicesHoverBg1,
        coverTitle: 'Mobile App Development',
        selected: true
    },
    {
        id: 2,
        name: 'webAndCmsDevelopment',
        value: 'Web And CMS Development',
        children: [
            {
                id: 1,
                name: 'php',
                value: 'PHP',
                url: '/services/webAppDev/phpAppDev'
            },
            {
                id: 2,
                name: 'wordpress',
                value: 'WordPress',
                url: '/services/webAppDev/wordpressAppDev'
            },
            {
                id: 3,
                name: 'drupal',
                value: 'Drupal',
                url: '/services/webAppDev/drupalAppDev'
            },
            {
                id: 4,
                name: 'laravel',
                value: 'Laravel',
                url: '/services/webAppDev/laravelAppDev'
            },
            {
                id: 5,
                name: 'reactJs',
                value: 'ReactJs',
                url: '/services/webAppDev/reactJsAppDev'
            },
            {
                id: 6,
                name: 'angularJs',
                value: 'AngularJs',
                url: '/services/webAppDev/angularJsAppDev'
            },
            {
                id: 7,
                name: 'nodeJs',
                value: 'NodeJs',
                url: '/services/webAppDev/nodeJsAppDev'
            },
            {
                id: 8,
                name: 'codeIgniter',
                value: 'CodeIgniter',
                url: '/services/webAppDev/codeIgniterAppDev'
            },
            {
                id: 9,
                name: 'cakePhp',
                value: 'CakePhp',
                url: '/services/webAppDev/cakePhpAppDev'
            },
            {
                id: 10,
                name: 'python',
                value: 'Python',
                url: '/services/webAppDev/pythonAppDev'
            },
            {
                id: 11,
                name: 'typescript',
                value: 'TypeScript',
                url: '/services/webAppDev/typescriptAppDev'
            }
        ],
        cover: servicesHoverBg2,
        coverTitle: 'Web And CMS Development',
        selected: false
    },
    {
        id: 3,
        name: 'ecommerceDevelopment',
        value: 'Ecommerce Development',
        children: [
            {
                id: 1,
                name: 'magento',
                value: 'Magento',
                url: '/services/ecommerceDev/magentoDev'
            },
            {
                id: 2,
                name: 'bigCommerce',
                value: 'BigCommerce',
                url: '/services/ecommerceDev/bigCommerceDev'
            },
            {
                id: 3,
                name: 'uberCart',
                value: 'UberCart',
                url: '/services/ecommerceDev/uberCartDev'
            },
            {
                id: 4,
                name: 'csCart',
                value: 'CsCart',
                url: '/services/ecommerceDev/csCartDev'
            },
            {
                id: 5,
                name: 'prestashop',
                value: 'Prestashop',
                url: '/services/ecommerceDev/prestashopDev'
            },
            {
                id: 6,
                name: 'virtuemart',
                value: 'Virtuemart',
                url: '/services/ecommerceDev/virtueMartDev'
            },
            {
                id: 7,
                name: 'shopify',
                value: 'Shopify',
                url: '/services/ecommerceDev/shopifyDev'
            },
            {
                id: 8,
                name: 'wooCommerce',
                value: 'WooCommerce',
                url: '/services/ecommerceDev/wooCommerceDev'
            }
        ],
        cover: servicesHoverBg3,
        coverTitle: 'Ecommerce Development',
        selected: false
    },
    {
        id: 4,
        name: 'blockchainDevelopment',
        value: 'Blockchain Development',
        children: [
            {
                id: 1,
                name: 'wallet',
                value: 'Wallet',
                url: '/services/blockchainDev/walletDev'
            },
            {
                id: 2,
                name: 'exchange',
                value: 'Exchange',
                url: '/services/blockchainDev/exchangeDev'
            },
            {
                id: 3,
                name: 'ethereum',
                value: 'Ethereum',
                url: '/services/blockchainDev/ethereumDev'
            },
            {
                id: 4,
                name: 'hyperledger',
                value: 'Hyperledger',
                url: '/services/blockchainDev/hyperledgerDev'
            },
            {
                id: 5,
                name: 'smartContracts',
                value: 'Smart Contracts',
                url: '/services/blockchainDev/smartContractsDev'
            },
            {
                id: 6,
                name: 'privateBlockchain',
                value: 'Private Blockchain',
                url: '/services/blockchainDev/privateBlockchainDev'
            },
            {
                id: 7,
                name: 'nftMarketplace',
                value: 'NFT Marketplace',
                url: '/services/blockchainDev/nftMarketplaceDev'
            },
        ],
        cover: servicesHoverBg4,
        coverTitle: 'Blockchain Development',
        selected: false
    },
    {
        id: 5,
        name: 'gameDevelopment',
        value: 'Game Development',
        children: [
            {
                id: 1,
                name: 'unity3d',
                value: 'Unity 3D',
                url: '/services/gameDev/unity3dDev'
            },
            {
                id: 2,
                name: 'metaverse',
                value: 'Metaverse',
                url: '/services/gameDev/metaverseDev'
            },
            {
                id: 3,
                name: 'unrealEngine',
                value: 'Unreal Engine',
                url: '/services/gameDev/unrealEngineDev'
            },
            {
                id: 4,
                name: 'augmentedReality',
                value: 'Augmented Reality',
                url: '/services/gameDev/augmentedRealityDev'
            },
            {
                id: 5,
                name: 'virtualReality',
                value: 'Virtual Reality',
                url: '/services/gameDev/virtualRealityDev'
            },
            {
                id: 6,
                name: 'casualGames',
                value: 'Casual Games',
                url: '/services/gameDev/casualGamesDev'
            },
        ],
        cover: servicesHoverBg5,
        coverTitle: 'Game Development',
        selected: false
    },
    {
        id: 6,
        name: 'salesforceSolutions',
        value: 'Salesforce Solutions',
        children: [
            {
                id: 1,
                name: 'salesforceDevelopment',
                value: 'Salesforce Development',
                url: '/services/salesforceSolutions/salesforceDev'
            },
            {
                id: 2,
                name: 'salesforceConsulting',
                value: 'Salesforce Consulting',
                url: '/services/salesforceSolutions/salesforceConsulting'
            },
            {
                id: 3,
                name: 'salesforceImplementation',
                value: 'Salesforce Implementation',
                url: '/services/salesforceSolutions/salesforceImplementation'
            },
        ],
        cover: servicesHoverBg6,
        coverTitle: 'Salesforce Solutions',
        selected: false
    },
    {
        id: 7,
        name: 'aiAndMl',
        value: 'AI & ML',
        children: [
            {
                id: 1,
                name: 'objectRecognition',
                value: 'Object Recognition',
                url: '/services/aiAndMl/objectRecognition'
            },
            {
                id: 2,
                name: 'textToSpeech',
                value: 'Text To Speech',
                url: '/services/aiAndMl/textToSpeech'
            },
            {
                id: 3,
                name: 'businessIntelligence',
                value: 'Business Intelligence',
                url: '/services/aiAndMl/businessIntelligence'
            },
            {
                id: 4,
                name: 'dataForecasting',
                value: 'Data Forecasting',
                url: '/services/aiAndMl/dataForecasting'
            },
            {
                id: 5,
                name: 'naturalLanguageProcessing',
                value: 'Natural Language Processing',
                url: '/services/aiAndMl/nlp'
            },
            {
                id: 6,
                name: 'dataAnalytics',
                value: 'Data Analytics',
                url: '/services/aiAndMl/dataAnalytics'
            },
            {
                id: 7,
                name: 'recommendationEngine',
                value: 'Recommendation Engine',
                url: '/services/aiAndMl/recommendationEngine'
            },
            {
                id: 8,
                name: 'sentimentAnalysis',
                value: 'Sentiment Analysis',
                url: '/services/aiAndMl/sentimentAnalysis'
            },
            {
                id: 9,
                name: 'alexaSkillDevelopment',
                value: 'Alexa Skill Development',
                url: '/services/aiAndMl/alexaSkillDev'
            },
        ],
        cover: servicesHoverBg7,
        coverTitle: 'AI & ML',
        selected: false
    },
    {
        id: 8,
        name: 'iotAndEmbedded',
        value: 'IoT & Embedded',
        children: [
            {
                id: 1,
                name: 'iotApp',
                value: 'IoT App',
                url: '/services/iotAndEmbedded/iotApp'
            },
            {
                id: 2,
                name: 'embeddedSoftware',
                value: 'Embedded Software',
                url: '/services/iotAndEmbedded/embeddedSoftware'
            },
            {
                id: 3,
                name: 'iotHardwarePrototyping',
                value: 'IoT Hardware Prototyping',
                url: '/services/iotAndEmbedded/iotHardwarePrototyping'
            },
            {
                id: 4,
                name: 'iotDashboardAndAnalytics',
                value: 'IoT Dashboard and Analytics',
                url: '/services/iotAndEmbedded/iotDashboardAndAnalytics'
            },
            {
                id: 5,
                name: 'smartHome',
                value: 'Smart Home - Home Automation',
                url: '/services/iotAndEmbedded/smartHome'
            },
        ],
        cover: servicesHoverBg8,
        coverTitle: 'IoT & Embedded',
        selected: false
    },
    {
        id: 9,
        name: 'corporateBranding',
        value: 'Corporate Branding',
        children: [
            {
                id: 1,
                name: "uiUxDesign",
                value: "UI/UX Design",
                url: "/services/corporateBranding/uiUxDesign"
            },
            {
                id: 2,
                name: "uxAuditReview",
                value: "UX Audit & Review",
                url: "/services/corporateBranding/uxAuditReview"
            },
            {
                id: 3,
                name: "visualIdentityDesign",
                value: "Visual Identity Design",
                url: "/services/corporateBranding/visualIdentityDesign"
            },
            {
                id: 4,
                name: "digitalBranding",
                value: "Digital Branding",
                url: "/services/corporateBranding/digitalBranding"
            },
            {
                id: 5,
                name: "packagingDesign",
                value: "Packaging Design",
                url: "/services/corporateBranding/packagingDesign"
            }
        ],
        cover: servicesHoverBg2,
        coverTitle: 'UI/UX Design',
        selected: false
    },
    {
        id: 10,
        name: 'devOps',
        value: 'DevOps',
        children: [
            {
                id: 1,
                name: 'devOps',
                value: 'DevOps',
                url: '/services/devOps'
            },
        ],
        cover: servicesHoverBg9,
        coverTitle: 'DevOps',
        selected: false
    }
]