export const hireNodeDevData = [
    {
        id: 1,
        title: "",
        descriptions: ["Node.js is a game-changer in the realm of web development, providing developers with the tools to build highly scalable and efficient web applications. This platform, built on Chrome's JavaScript runtime, is designed for building fast and scalable network applications.","Here's why businesses are choosing Node.js and why partnering with a recognized company like Alta Tech Mark makes all the difference:"],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Advantages of Node.js:",
        description: '',
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Non-Blocking, Asynchronous I/O",
                description: "This allows handling of numerous connections simultaneously, ensuring high performance and scalability."
            },
            {
                id: 2,
                title: "Single Programming Language",
                description: "Node.js enables developers to use JavaScript both for server-side and client-side scripting. This uniformity streamlines the development process."
            },
            {
                id: 3,
                title: "Extensive Module Library",
                description: "The Node Package Manager (NPM) offers a plethora of modules and plugins used by developers to integrate specific functionalities."
            },
            {
                id: 4,
                title: "Cross-Platform",
                description: "Node.js can run on various platforms including Windows, Linux, Unix, and Mac OS X."
            }
        ]
    },
    {
        id: 3,
        title: "Alta Tech Mark's Node.js Development Prowess:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Dedicated Developers",
                description: "Our Node.js developers are not just experienced, but they also have a passion for developing cutting-edge solutions tailored to your business needs."
            },
            {
                id: 2,
                title: "Custom Solutions",
                description: "Every business is unique, and so are its tech requirements. Alta Tech Mark prides itself on crafting custom Node.js solutions that perfectly align with your business objectives."
            },
            {
                id: 3,
                title: "Database Expertise",
                description: "Be it relational databases like MySQL or NoSQL databases like MongoDB, our developers ensure seamless integration, promising data integrity and security."
            },
            {
                id: 4,
                title: "Global Recognition",
                description: "Our expertise in Node.js development is acknowledged globally, ensuring that you are partnering with a trusted and proven entity."
            },
            {
                id: 5,
                title: "Continuous Learning",
                description: "The tech landscape is always evolving. Our developers are perpetual learners, ensuring they are updated with the latest in Node.js and associated technologies."
            }
        ]
    },
    {
        id: 4,
        title: "",
        description: "With Alta Tech Mark by your side, you aren't just getting a service provider; you are getting a partner committed to propelling your business forward using the best in tech. So, if a robust, scalable, and efficient backend is what you seek, hiring our Node.js developers is the way forward.",
        onlyBullets: false,
        data: []
    }
]

export const hireNodeDevFaqs = [
    {
        id: 1,
        question: "1. How Much Does it Cost to Hire NodeJS Developers?",
        answer: "The cost estimation to hire NodeJS developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features, and functionalities, and many more. If we consider the years of experience the junior NodeJS developers with around 0 to 3 years of experience it can cost around $1800 - $2400, mid-level NodeJS developers with 3 to 5 years of experience can cost around $2400 - $3000, and the senior NodeJS developers with more than 5+ years of experience can cost $3000 or more."
    },
    {
        id: 2,
        question: "2. What type of services the dedicated NodeJS developers can offer?",
        answer: "The team of Node.js developers of Alta Tech Mark holds years of experience and expertise to deliver various Node.js services which include but are not limited to the following list: \n- Custom Node.js development \n- Node.js Migration \n- Real-time applications \n- Web application support and maintenance \n- Node.js Enterprise Web application \n- Node.js API development \n- Node.js Plugin development"
    },
    {
        id: 3,
        question: "3. How can I hire NodeJS developers from Alta Tech Mark?",
        answer: "Businesses can hire Dedicated Node.js developers from Alta Tech Mark and get started with the project within 48 hours. All you have to do is contact us with your development requirements. Once you reach out to us with your development requirements, one of our team members will get back to you with the most suitable profile of our Node.js developers based on your development requirements."
    },
    {
        id: 4,
        question: "4. Why should I hire NodeJS developers?",
        answer: "The team of dedicated NodeJS developers will work only for you to deliver outstanding results with the highest optimization. The team of NodeJS developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets, and various others."
    },
    {
        id: 5,
        question: "5. Can I get additional resources and support during the ongoing project?",
        answer: "Yes, if your development requirement changes during the project and you need additional support, you can communicate with the dedicated project manager and get the additional resource as an add-on."
    },
    {
        id: 6,
        question: "6. Can I evaluate the development skills of the dedicated developers before hiring them?",
        answer: "Even though our team will provide the profile of developers after evaluating your development requirements still, you can take an interview to check the technical expertise to hire NodeJS developers."
    }
]