import React from 'react';
import { useSelector } from 'react-redux';

// data
import { ourTeamData } from '../../../data/aboutUs/ourTeamData';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// components
import TeamCard from './teamCard/TeamCard';
import HeaderText from '../../headerText/HeaderText';

export default function MeetOurTeam() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-px-[9.115vw] tw-py-[4vw]'>
            <HeaderText
                title="Our"
                colorTitleEnd="Professionals"
                description="Alta Tech Mark has a team of highly creative and specialized people. With a combination of cutting-edge technological knowledge, we're able to stay on top outlines in every one of our offerings."
                className='tw-text-center tw-mb-8'
            />

            {screenWidth < 768 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {ourTeamData.map(member =>
                        <SwiperSlide>
                            <div className='tw-h-[400px]'>
                                <TeamCard
                                    key={member.id}
                                    name={member.name}
                                    role={member.role}
                                    src={member.src}
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
                :
                <div className='tw-grid tw-grid-cols-3 tw-gap-[25px]'>
                    {ourTeamData.map(member =>
                        <TeamCard
                            key={member.id}
                            name={member.name}
                            role={member.role}
                            src={member.src}
                        />
                    )}
                </div>
            }
        </div>
    )
}
