import React from 'react';

// data
import { hireKotlinDevData, hireKotlinDevFaqs } from '../../../data/hireDev/hireKotlinDev';
import kotlinDev1 from '../../../assets/pages/hireDev/kotlinDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireKotlinDevs() {
    return (
        <HireDevWrapper
            title="Hire"
            colorTitleEnd="KOTLIN DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={kotlinDev1}
            data={hireKotlinDevData}
            faq={hireKotlinDevFaqs}
        />
    )
}
