export const magentoDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Why You Ought to Go With Magento As Your E-commerce Solution When it comes to online retail, Magento provides a number of important benefits. Magento is an open-source solution that is used for applications related to e-commerce. If the retail firm associated with your website is having trouble keeping expenses under control, Magento can help. Indeed, the most prominent online retailers make use of this platform, which was originally owned by the business known as Varien. However, for smaller companies striving to compete against the larger ones, saving every penny matters in the long run. As a consequence of this, even the highly regarded online auction platform eBay acknowledges the benefits of having a relationship with Magento (eBay has already acquired complete control of Magento). Perhaps you need to give it some thought as well.",
            "There is more to the concept of online business than the seemingly overwhelming amount of financial data. E-commerce websites make it their mission to comprehend their clientele, and Magento's Magento Mobile service makes it possible for them to succeed in this endeavor. Using Magento Mobile, your company has the ability to create bespoke applications that are a useful addition to your online retail operations. Your consumers' online shopping experiences may be improved with the help of these native applications, and you can learn more about their product preferences in the process. You are able to design applications for your retail company that are compatible with iPads, iPhones, and Android smartphones. If your goal is to provide more priority to items that are in higher demand, this is a significant benefit.",
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 2,
        title: "When Choosing The Magento, Why Alta Tech Mark?",
        description: "Your newly developed applications might catapult your retail company to the status of a well-known and respected retail brand if you use the Magento Mobile service. In order for shops to thrive in the cutthroat atmosphere of e-commerce, it is vital for them to construct a trustworthy brand. The store that is able to maintain the greatest brand recognition often takes the lead. Get in touch with Alta Tech Mark right away if Magento fits in with your long-term goals. Even if you need to launch your applications on numerous mobile platforms, we will be able to assist you in customizing Magento Mobile to meet your requirements with our guidance. When you have Alta Tech Mark on your side, the move to Magento e-commerce solutions will be much less complicated. In addition, we are always accessible to address any Magento-related questions that, to those who are not well versed in technical particulars, may seem to be complicated. You supply the knowledge of retail, and we'll provide the specialized skills. If we work together, we can make sure that your retail company not only survives but also thrives in an environment where others may struggle.",
        onlyBullets: false,
        data: []
    }
]