export const hireBackendDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Website and web application development is the main emphasis of backend development. It verifies whether or not the website's or web application's functionality is working correctly. Everything that happens on the website is done so in the background. However, it is not a simple task, and to do it, you must employ backend developers. Databases, servers, and application programming interfaces are the typical projects for the developers. It is necessary for backend developers to be proficient in programming languages. The website and mobile application are managed by the backend developers. Structured Query Language is one of the languages used by the backend developers. haskell, PHP, Java, Python, ROR,.NET, and Clojure. They can't grow alone if they don't realize this.",
            "The complicated problems that arise during the creation of a website or mobile application are resolved by backend developers. They are able to be modified from the backend, ensuring that errors are not visible from the outside. They oversee both minor and major aspects of the development process and ensure that it is free of errors. In addition to making sure that the website or app loads more quickly, backend developers are in charge of making applications and the web easy to use. For the website or mobile applications to be improved, the backend developer must change the code. For the development, developers are in charge of altering the current one or making new ones. Depending on the needs of your company, you may engage a backend developer on a full-time, part-time, or freelance basis."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Services Offered by the Backend Developers at Alta Tech Mark:",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Database Programming",
                description: "Microsoft SQL Server, MongoDB, DB2, PostgreSQL, MariaDB, SAP HANA, MySQL, and other databases are just a few of the databases with which our database engineers are proficient."
            },
            {
                id: 2,
                title: "Scripting for services",
                description: "The foundation of each project is its scripting. Our backend programmers guarantee effective scripting that precisely matches company requirements for quick server response."
            },
            {
                id: 3,
                title: "API Integration and Programming",
                description: "Work with our backend developers to create smooth interfaces with other third parties. Any needed application may be skillfully integrated with the current backend system by us."
            },
            {
                id: 4,
                title: "Application Re-Engineering",
                description: "Our skilled backend developers create exceptional customisation by using cutting-edge re-engineering approaches, which enhance the functionality of current application ways."
            },
            {
                id: 5,
                title: "Refactoring Backend",
                description: "Our engineers are skilled at refactoring backends, which enables them to revitalize the current web application backend system without affecting the front end. Error rectification, upcoming modifications, or performance improvements all depend on this."
            },
            {
                id: 6,
                title: "Going cloud-based",
                description: "Are you moving to the cloud? You may rely on our backend engineers for a seamless changeover. Whether you're thinking about a hybrid, private, or public cloud, we can help."
            }
        ]
    },
    {
        id: 3,
        title: "Tools That Our Backend Developers Utilize",
        description: "",
        onlyBullets: true,
        data: [
            "MySQL",
            "Rails for Ruby",
            "Fortran",
            "Js Node",
            "Rack Area",
            "Heroku"
        ]
    },
    {
        id: 4,
        title: "Google Cloud Datasets Utilized:",
        description: "",
        onlyBullets: true,
        data: [
            "Amazon EC2",
            "Azure",
            "IBM Bluemix",
            "Apprenda",
            "MongoDB",
            "Oracle",
            "PostgreSQL",
            "SQLite",
            "MySQL",
            "MSSQL",
            "Databases"
        ]
    },
    {
        id: 5,
        title: "Reasons to Work with the Backend Developers at Alta Tech Mark:",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Effective User Experience",
                description: "Our skilled backend staff provides precise, high-quality web development services, ensuring an excellent user experience."
            },
            {
                id: 2,
                title: "Better Functionality",
                description: "The most important thing is functionality. Our team of backend specialists guarantees flawless operation, giving your website or application a strong base."
            },
            {
                id: 3,
                title: "Effective Solutions",
                description: "At Alta Tech Mark, we provide creative, useful solutions that are adapted to your company's backend development requirements, enhancing its online visibility."
            },
            {
                id: 4,
                title: "Dependable And Safe",
                description: "Your security is our top priority. Our backend developers use state-of-the-art techniques and technology to make sure your apps are always protected against vulnerabilities."
            },
            {
                id: 5,
                title: "Smooth Operations",
                description: "We never compromise on quality. Our backend developers write code that guarantees error-free operation of your apps, resulting in seamless, continuous functioning."
            },
            {
                id: 6,
                title: "Easy interfaces",
                description: "Our backend developers enable seamless interfaces, building high-performance websites and apps, from in-house modules to external systems."
            }
        ]
    },
    {
        id: 6,
        title: "",
        description: "Work with Alta Tech Mark to realize your digital dreams by using the skills of our backend developers.",
        onlyBullets: false,
        data: []
    }
]

export const hireBackendDevFaq = [
    {
        id: 1,
        question: "1. How much does it cost to hire dedicated Back-end developers?",
        answer: "The cost estimation for dedicated Back-end developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    },
    {
        id: 2,
        question: "2. Can I hire dedicated Back-end developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Back-end developer, then you can hire dedicated Back-end developers on an hourly or project/task base."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Back-end developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Back-end developers before making a final move."
    },
    {
        id: 4,
        question: "4. What steps should I follow to Hire Back-end developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Back-end developers. Just provide project details and the technical expertise you need. The team of Back-end developers will begin working on your project based on your selection."
    },
    {
        id: 5,
        question: "5. Why should I hire dedicated Back-end developers?",
        answer: "The dedicated Back-end developers will work only for you to deliver outstanding results with the highest optimization. The team of Back-end developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    }
]