import React from 'react';

// assets
import { processesData } from '../../../data/processesData';

// components
import ProcessesCard from './ProcessesCard';
import HeaderText from '../../headerText/HeaderText';

export default function Processes() {

    return (
        <div className='tw-px-[9.115vw] tw-py-[8vw] tw-bg-[#FCFCFC]'>
            <HeaderText colorTitleStart="PROCESS" title='WE FOLLOW' className='tw-text-center tw-mb-8' />

            <div className='tw-grid md:tw-grid-cols-7 tw-grid-cols-1 md:tw-w-[80.729vw] tw-mx-auto tw-gap-y-6'>
                {processesData.map((process, index) => <ProcessesCard
                    key={process.id}
                    src={process.src}
                    title={process.title}
                    description={process.description}
                    index={index}
                />)}
            </div>
        </div>
    )
}
