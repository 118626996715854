import React from 'react';

// assets
import dp1 from '../../../../assets/pages/service/WebAppDev/dp1.png';
import dp2 from '../../../../assets/pages/service/WebAppDev/dp2.png';
import { drupalDevData } from '../../../../data/services/webAppDevData/drupalDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function DrupalAppDev() {
    return (
        <ServicesWrapper
            title="DRUPAL DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={dp1}
        >
            <DevGridWrapper img={dp2}>
                <DataMapper data={drupalDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
