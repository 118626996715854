import m3 from '../../../assets/pages/service/gameDev/m3.png';
import m4 from '../../../assets/pages/service/gameDev/m4.png';

export const metaverseDevData = [
    {
        id: 1,
        title: "Describe Metaverse.",
        description: "In a nutshell, metaverse is a 3D, AR, and VR recreation of the actual world inside a virtual environment. the virtual environment in which you will own a 3D avatar that can converse with other 3D avatars. You can do whatever you can do in the real world with your 3D avatar, including playing, shopping, and self-entertainment.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "You Consider Your Company Serious. Thus Do We.",
        description: "With the support of metaverse, companies may now grow to unprecedented heights. As a Metaverse Development Company, we use industry best practices and appropriate visuals to highlight your unique selling points. We are able to provide your viewers an incredible three-dimensional experience that rivals can only pretend to offer. Our metaverse developers can create a digital presence for your business that offers customers a distinctive and engaging experience.",
        onlyBullets: false,
        data: []
    },
    {
        id: 3,
        title: "Plan Ahead and Start Laying the Metaverse Foundational Pieces Right Away",
        descriptions: [
            "Our metaverse developers at Alta Tech Mark are always working with a future mindset. We can assist you in creating a virtual network and Metaverse presence online. Our developers has the necessary expertise to create Metaverse products and services that meet your needs.",
        ],
        onlyBullets: false,
        data: [],
        sectionPic: m3
    },
    {
        id: 4,
        title: "Here are a few of our Metaverse development services that may assist companies in offering consumers virtual environments.",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Our Services for Metaverse Development",
                description: "Decentralized Space: We can assist you in creating a decentralized environment for trade, gaming, and socializing. In addition, we provide other services including front-end and back-end development, blockchain services, and UI/UX design that may be tailored to your project's needs."
            },
            {
                id: 2,
                title: "Blockchain-Specific Metaverse apps",
                description: "We can provide our Metaverse developers' services to create user-friendly apps with blockchain-specific characteristics including user authority, security, automation, and transparency."
            },
            {
                id: 3,
                title: "3D Spaces Development",
                description: "By using 3D modeling, 3D visualization, and an interoperability components approach, we can refine the specifications for your Metaverse project. Your use-cases may be expanded to include new ideas with the aid of the 3D design and development services."
            },
            {
                id: 4,
                title: "Third-Party Integration Services",
                description: "You may create an amazing and feature-rich user experience by integrating your Metaverse project with various APIs, data, ecosystem tools, and service-oriented architecture. We provide third-party integration as one of our services."
            },
            {
                id: 5,
                title: "Metaverse gaming",
                description: "More than any other business, the gaming industry is embracing Metaverse technology. With live game streaming services, Alta Tech Mark game developers can assist you in giving your players an amazing 3D virtual world and improved playability. With NFT and cryptocurrency trading services, we can assist you in creating a Metaverse where you may play games and receive rewards."
            },
            {
                id: 6,
                title: "Metaverse Social Media Platform",
                description: "Using Metaverse technology, our web developers can create social media platforms that provide consumers sufficient connection, improved social interaction, and a more grounded experience. Platforms for metaverse social media provide a more advanced 3D virtual environment and an improved virtual experience. Even with simple social media capabilities like connect, interact, and share, you can provide an amazing user experience."
            },
            {
                id: 7,
                title: "Metaverse Marketplaces",
                description: "The future of the retail sector is an e-commerce platform that combines a bitcoin payment gateway with a 3D virtual experience. We provide full-stack end-to-end development services as well as post-purchase assistance."
            },
            {
                id: 8,
                title: "NFT Trading Platform",
                description: "The trading industry has reached new heights because to NFT (Non-Fungible Tokens). The technical and development assistance needed to create a platform that enables NFT trading advantages including value growth, resale, and royalty may be given by our Metaverse developers.",
                pic: m4
            }
        ]
    },
    {
        id: 5,
        title: "Process for Developing a Metaverse Project:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Decentralized Network",
                description: "Using a decentralized network architecture, our engineers will provide high bandwidth and instantaneous connectivity. It will improve user experience by streamlining the data transfer procedure."
            },
            {
                id: 2,
                title: "Interoperable Standards",
                description: "To enable the 3D applications and parts of the Metaverse project, we employ interoperable standards for media, link text, photos, audio, video, 3D scenes, 3D objects, 3D sequences, and vectors."
            },
            {
                id: 3,
                title: "Full-stack Development",
                description: "For full-stack web application development, we use HTML, Java, PHP, Golang, and other languages."
            },
            {
                id: 4,
                title: "Smart Contract",
                description: "The creation and execution of the smart contracts for your Metaverse platform will be handled by our internal staff. Transactions that are safe, effective, transparent, and permissionless may be facilitated by it."
            },
            {
                id: 5,
                title: "Payment Wallets",
                description: "In addition to other widely used e-wallets and payment gateway choices, we are able to provide payment gateways equipped with cryptocurrency technology."
            }
        ]
    },
    {
        id: 6,
        title: "",
        descriptions: [
            "Maintenance, upgrades, and after-sale support: Your Metaverse platform, network, nodes, and smart contracts will all be highly reliable, secure, and easy to use with our maintenance and support services.",
            "Company for Metaverse Development: Experts at Alpha Tech Mark can provide the ideal Metaverse solution for your project's needs. As soon as you provide us the details of your project, we may start working on it. Our team of professionals is capable of creating a Metaverse platform using a variety of technologies, including NFT, Blockchain, Virtual and Augmented Reality, and Virtual Reality."
        ],
        onlyBullets: false,
        data: [],
    }
]