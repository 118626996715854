export const hireSwiftDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "If you plan to develop an app for iPhone, iPad, or Apple Watch, choosing the Swift programming language is the ultimate option. Since the beginning, Alta Tech Mark has been crafting award-winning mobile app solutions with this Object Oriented Programming language. Our team has competently built functional and fully-featured apps using Swift.",
            "Our team develops Swift apps for various Apple platforms like iPhone, macOS, watchOS, and tvOS, ranging from systems programming, mobile, and desktop to cloud services. You can hire the best Swift developers for services: from app strategy, UX/UI design, app development, testing, to end app delivery.",
            "Whether you want to initiate a brand new project or transform your current app into the Swift language, we are available to devise the best app solution for you. Our comprehensive Swift app development service delivers significant business outcomes and value. We assist businesses in adopting Apple's modern programming language, Swift, for advanced apps that bolster their operations.",
            "Alta Tech Mark possesses expertise across various industries and sectors, enabling businesses to connect with a broader audience, enhance engagement, and boost bottom-line results."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Develop iOS Applications Using Swift?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Secure",
                description: "Swift syntax is short and specifically developed for code safety. In addition, the Swift compiler enforces stringent guidelines for objects and data structures in order to detect run-time errors inside the program."
            },
            {
                id: 2,
                title: "Quick",
                description: "High-performance LLVM compiler technology and the standard library contribute to the greatest possible performance in Swift programming. Swift developers can count on producing quick and robust apps."
            },
            {
                id: 3,
                title: "Creative",
                description: "Swift is a contemporary language created from years of expertise with Apple systems and extensive study on programming languages. When creating a mobile application, Swift developers have a wealth of innovative ideas at their disposal to enhance the expressiveness of their code."
            },
            {
                id: 4,
                title: "Easy Syntax",
                description: "Because Swift code mostly uses plain English, developing in this language is easier."
            }
        ]
    },
    {
        id: 3,
        title: "Why Select Alta Tech Mark For Swift App Development",
        description: "Work with Alta Tech Mark to get solutions that are efficient and meet your best company needs. Renowned international businesses and up-and-coming startups have put their confidence in our business, and we have received praise and recognition for our amazing applications and unmatched user experiences.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Excellent Performance History",
                description: "Always rated as one of the best mobile app development companies is Alta Tech Mark. In the app development market, we have received accolades and significant awards for our persistent dedication to excellence."
            },
            {
                id: 2,
                title: "Committed Internal Team",
                description: "Our team of committed Swift developers at Alta Tech Mark creates reliable business solutions using a variety of frameworks and technologies."
            },
            {
                id: 3,
                title: "Experienced & Well-Qualified Developers",
                description: "Our team of experts constantly creates flawless applications for our customers. Their extensive knowledge in a wide range of technology fields guarantees accuracy throughout the stages of development, testing, and implementation."
            },
            {
                id: 4,
                title: "Unique and Divine Experiences",
                description: "Our staff is skilled in developing unique applications that are notable for their robustness, scalability, and dependability. Our ability to provide our partners with the most commercial value is a result of our client-focused approach to app development."
            },
            {
                id: 5,
                title: "On-time Delivery",
                description: "With the use of agile development methods and the newest cutting-edge technology, Alta Tech Mark guarantees on-time delivery for most of its projects."
            }
        ]
    },
]

export const hireSwiftDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Swift developers?",
        answer: "The dedicated Swift developers will work only for you to deliver outstanding results with the highest optimization. The team of Swift developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Swift developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Swift developers. Just provide project details and the technical expertise you need. The team of Swift developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Swift developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Swift developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Swift developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Swift developer, then you can hire dedicated Swift developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Swift developers?",
        answer: "The cost estimation for dedicated Swift developers varies based on various factors such as development team size, hiring model, the complexity of the app development, the type of solution, features and functionalities and many more."
    }
]