export const hireSalesforceDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Given how quickly the corporate world is evolving, your existing CRM system needs a new vision. Businesses have evolved into social media-driven enterprises with everyone in the chain linked via a transparent and quicker platform with the introduction of marketing clouds and cloud-based CRM. This adaptability has increased corporate accountability & efficiency.",
            "A CRM system that connects businesses and consumers is called Salesforce. Because its software is cloud-based, no setup is necessary by IT specialists. Salesforce established the best method for establishing a connection with clients. In addition to helping organizations monitor customer behavior and advertise to customers, software plays a critical role in customer success.",
            "Are you a fresh entrepreneur with a ton of brilliant ideas in your head who is searching for Salesforce development? Then, by lowering risk, training, infrastructure costs, and a host of other elements, using Salesforce tools may assist you in creating the greatest online presence.",
            "Alta Tech Mark is aware that various skill sets and levels of experience are needed for various projects. Our tailored Salesforce CRM solutions are in line with company goals and key performance indicators (KPIs), and we help clients get the most out of their Salesforce investments. Our staff helps you improve your company and has a thorough awareness of your needs.",
            "Our team of Salesforce specialists uses its extensive knowledge in utilizing Apex and Visualforce to enhance and customize Salesforce to provide the best possible solutions for your particular company requirements. We provide a wide variety of services pertaining to Salesforce Development, such as data transfer, customisation, integration, Salesforce lightning, Salesforce consulting, and several more CRM services."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Hire Salesforce Developers From Alta Tech Mark For Following Services:",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Custom App Development",
                description: "Salesforce Custom Application Development At Alta Tech Mark, our expert team offers you a customized Salesforce app development, tailored to your business & market requirements, emphasizing secure and scalable features."
            },
            {
                id: 2,
                title: "Customization",
                description: "We provide you with a tailored Salesforce CRM software according to your demand. Our dedicated Salesforce developers ensure it is optimized for the digital platform."
            },
            {
                id: 3,
                title: "Integration",
                description: "Where integrating Salesforce with other systems is essential, our skilled Salesforce developers provide a robust and secure integration service using app exchange tools or mechanisms."
            },
            {
                id: 4,
                title: "Migration",
                description: "Our seasoned Salesforce developers offer Salesforce Migration solutions to manage the rapidly growing sales and opportunity data. This allows for seamless data migration from one platform to another within Salesforce CRM."
            },
            {
                id: 5,
                title: "Support Services",
                description: "As a leading Salesforce development company, Alta Tech Mark delivers exceptional support services to our clients, ensuring they achieve their desired success."
            },
            {
                id: 6,
                title: "Appexchange",
                description: "Our Salesforce developers' vast experience enables us to offer you a flawless AppExchange development solution, resulting in a highly functional and efficient Salesforce app."
            }
        ]
    },
    {
        id: 3,
        title: "The following are some uses for the Salesforce Platform:",
        description: "",
        onlyBullets: true,
        data: [
            "Sales Cloud",
            "Wave Analytics",
            "Marketing Cloud",
            "IoT Cloud",
            "Service Cloud",
            "Community Cloud",
            "Application Cloud"
        ]
    },
    {
        id: 4,
        title: "Why Work With Us to Hire Salesforce Developers?",
        descriptions: [
            "Reliable Salesforce development services are offered by Alta Tech Mark to improve client relationship management. Employ Salesforce certified developers that are always eager to pick up new skills. Being a top supplier of CRM systems, we guarantee that you get distinctive and personalized services. Our professional services for Salesforce range from a lone worker to a comprehensive staffing solution through outsourcing. Our programming team has the greatest track record of producing complete solutions having worked on a variety of platforms and frameworks.",
            "Thus, Alta Tech Mark is your finest option if you want to finish the greatest project in Salesforce and work with a talented team that has sufficient experience and knowledge."
        ],
        onlyBullets: false,
        data: []
    },
]
export const hireSalesforceDevFaqs = [
    {
        id: 1,
        question: "1. How much does it cost to hire Salesforce developers?",
        answer: "The cost estimation for dedicated Salesforce developers varies based on various factors such as development team size, hiring model, the complexity of the web app development, the type of solution, features and functionalities and many more."
    },
    {
        id: 2,
        question: "2. Can I hire Salesforce developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Salesforce developer, then you can hire dedicated Salesforce developers on an hourly or project/task base."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Salesforce developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Salesforce developers before making a final move."
    },
    {
        id: 4,
        question: "4. What steps should I follow to Hire Salesforce developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Salesforce developers. Just provide project details and the technical expertise you need. The team of Salesforce developers will begin working on your project based on your selection."
    },
    {
        id: 5,
        question: "5. Why should I hire Salesforce developers?",
        answer: "The dedicated Salesforce developers will work only for you to deliver outstanding results with the highest optimization. The team of Salesforce developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    }
];