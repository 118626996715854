import React from 'react';

// data
import { hireDartDevData, hireDartDevFaqs } from '../../../data/hireDev/hireDartDev';
import dartDev1 from '../../../assets/pages/hireDev/dartDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireDartDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="DART DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={dartDev1}
            data={hireDartDevData}
            faq={hireDartDevFaqs}
        />
    )
}
