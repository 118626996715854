import React from 'react';

// assets
import EastIcon from '@mui/icons-material/East';

export default function BrandsHero(props) {
    return (
        <div>
            <div className={`md:tw-h-[55vh] tw-h-[50vh] tw-grid tw-grid-cols-2 tw-text-white tw-px-[9.115vw] tw-py-[5.903vw] tw-w-full tw-gap-x-4`}
                style={{
                    // backgroundImage: `${props.inWrapper ? props.simpleTextBg ? `url(${props.bg})` : `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.bg})` : `url(${props.bg})`}`,
                    backgroundImage: `url(${props.bg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <div className='tw-flex tw-flex-col tw-items-start tw-col-span-1'>
                    <img src={props.logo} alt="logo" className='tw-w-[17.789vw] tw-h-[9.444vw]' />
                    <h1><span className='tw-text-[3vw] tw-font-semibold tw-underline tw-decoration-red-600'>{props.underlineTitle}</span> <span className='tw-text-[1vw]'>{props.simpleTitle}</span></h1>
                    <h1 className='tw-text-[1vw]'>{props.description}</h1>
                </div>
                <div className={`tw-col-span-1 tw-relative tw-bottom-[2.5vw] tw-w-full`}>
                    <img src={props.right} alt="pic" className={`tw-z-[5] tw-ml-auto ${props.rightClasses}`} />
                </div>
            </div>
            <div className='tw-flex tw-items-center tw-justify-between tw-bg-purple tw-text-white tw-py-[1vw] tw-px-[9.115vw] tw-relative tw-z-[6] md:tw-h-[4vw]'>
                <div className='tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer'>
                    <EastIcon className='tw-rotate-180' />
                    <h1>Previous Project</h1>
                </div>
                <h1>All Projects</h1>
                <div className='tw-flex tw-items-center tw-gap-x-2 tw-flex-row-reverse tw-cursor-pointer'>
                    <EastIcon className='' />
                    <h1>Next Project</h1>
                </div>
            </div>
        </div>
    )
}
