export const pythonDevData = [
    {
        id: 1,
        title: "Why Python Stands Out Amongst Other Programming Languages",
        descriptions: [
            "Python is celebrated for its clean syntax and high readability, enabling professionals to express complex concepts with fewer lines of code. Though the technological landscape is populated with myriad programming languages, Python has carved a niche for itself, especially in the domain of general-purpose programming, application crafting, and component creation.",
            "When the objective is to craft agile products with rapid market deployment or seamlessly integrate new tech into your existing ecosystem, Python emerges as a strong contender. But to unlock Python's full potential, what you truly need is a seasoned Python Development Partner—someone who deeply understands Python and can contribute significantly across the product lifecycle.",
            "At Alta Tech Mark, our extensive experience in Python consulting and development positions us as a trusted partner in your tech journey. We boast a talented roster of Python developers and specialists, making us proficient in crafting dynamic web applications, custom applications in the Django framework, and BI & analytics services. We pride ourselves on delivering solutions that efficiently handle vast traffic and address intricate challenges, all thanks to the power of Python frameworks. Partnering with us ensures that you have robust Python-based solutions to drive your business goals."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Choose Python for Your Projects?",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Prototyping Prowess",
                description: "Python is perfect for MVPs and prototypes, given its intuitive data structures and its ability to boost the efficiency of the final application.",
            },
            {
                id: 2,
                title: "Expansive Libraries",
                description: "Python's rich library set obviates the need for writing code from scratch, covering diverse areas like databases, web browsers, and more.",
            },
            {
                id: 3,
                title: "Embeddability",
                description: "Python code can seamlessly be integrated into source codes of languages like C++.",
            },
            {
                id: 4,
                title: "User-Friendly",
                description: "Python is not just developer-friendly but also beginner-friendly. Those who master Python often find transitioning to verbose languages challenging.",
            },
            {
                id: 5,
                title: "Vibrant Community",
                description: "The Python community is renowned for its passion and vastness.",
            },
            {
                id: 6,
                title: "Versatility",
                description: "Python seamlessly supports both procedural and object-oriented programming paradigms, blending the best of both worlds.",
            },
        ]
    },
    {
        id: 3,
        title: "",
        description: "Services Offered by Alta Tech Mark's Python Experts Drawing upon our vast experience, our Python experts, driven by a relentless pursuit of quality, offer a plethora of services:",
        onlyBullets: true,
        data: [
            "Python Dynamic Website Development",
            "UI Design & Development via Python",
            "Python Web Crawler Creation",
            "Desktop Application Development using Python",
            "Customized CMS Development in Python",
            "Python's Integration with Active Directory, Java, PHP, ASP .NET, and Perl",
            "Python Web Services Crafting",
            "Python Data Extraction Techniques",
            "Migration services to Python from Java, ColdFusion, and PHP",
            "Upgrades to the latest Python version",
            "Python-driven Machine Learning"
        ]
    },
    {
        id: 4,
        title: "",
        description: "Frameworks Empowering Our Python Solutions We utilize the finest Python frameworks to offer our esteemed clients rapid application development, structured solutions, and cost-effective services:",
        onlyBullets: true,
        data: [
            "Django",
            "Cubic Web",
            "Google API Engine",
            "Flask",
            "Cherrypy",
            "Pylons",
            "Topsite Templating System",
            "Web2py",
            "Pyramid",
            "TurboGears",
            "Zope",
            "Bottle"
        ]
    },
    {
        id: 5,
        title: "",
        description: "Why Alta Tech Mark is Your Best Bet for Python Development Having collaborated with a diverse clientele on a broad range of projects, Alta Tech Mark's vast experience breeds the kind of confidence essential for achieving tangible outcomes. Whether you need dedicated Python developers on-site or offshore, we offer flexibility tailored to your requirements.",
        onlyBullets: false,
        data: []
    },
    {
        id: 6,
        title: "Why Partner with Alta Tech Mark, the Premier Python Development Entity?",
        description: "",
        onlyBullets: true,
        data: [
            "High-Speed & Robust Apps",
            "Rapid Product Deployment",
            "Proficient & Agile Team",
            "Quality-driven Solutions",
            "Comprehensive Development Approach",
            "Meticulous Requirement Analysis",
            "SEO-ready Web Applications",
            "Advanced Security Mechanisms & Scalability",
            "Open Channels of Communication",
            "Strict Confidentiality with NDA",
            "Innovative Solutions",
            "Adaptable Engagement Models",
            "Cost-effective Services",
            "Smooth Rollout of Apps & Web Solutions",
            "Extended Tech Support"
        ]
    }
]