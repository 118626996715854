export const wordpressDevData = [
    {
        id: 1,
        title: "Reasons to Think About Creating a WordPress Website",
        descriptions: [
            "The ideal alternative for you if you want to start a blog but are not familiar with coding languages or even know what a code is, could be to create a WordPress website. Because WordPress is used by so many bloggers worldwide, it's possible that some people think it's the most widely used blogging program available online. If you are just beginning started, it's easy to sign up for a free WordPress blog account. However, after you get the hang of it, you may want to get further into blogging by eventually obtaining a WordPress blog account that costs money.",
            "Since WordPress is so user-friendly, newbies won't have any trouble setting up a new blog account. It just requires you to provide accurate information about yourself and choose the blog theme from the choices WordPress provides for your website. Interestingly, you can hire a skilled developer like Alta Tech Mark to adjust the theme's HTML, CSS, and PHP code until it meets your needs. This is useful if you discover themes that you would want to update. It should be noted that several websites on the Internet provide bespoke and editable WordPress themes.",
            "A lot of entrepreneurs understand the advantages of obtaining a WordPress account for search engine optimization. You may contact Alta Tech Mark, a Premier WordPress Web Development Company, for assistance in locating the appropriate plugins in the WordPress database if this describes you. Using plugins, you may improve the SEO friendliness of your blog site by altering its layout or content. Another reason why WordPress blog sites are so popular with corporate users is that they are very search engine friendly.",
            "Throughout the years, WordPress has garnered several accolades, in part due to its capabilities and in part to the overwhelming adoration it has from bloggers. You may still depend on security plugins to make your WordPress more safe, even if some people have issues with its security features. To safeguard yourself from fresh online threats, you should also update any plugins, themes, or installation codes. Avoid downloading themes and plugins from websites with questionable security. It is also recommended that you modify the admin account that is set as default. Access file itself to ensure that your blog site remains safe from any form of SQL injection attack or if hackers attempt to take advantage of your private data."
        ],
        onlyBullets: false,
        data: []
    }
]