import sc3 from '../../../assets/pages/service/salesforceDev/sc3.png';
import sc4 from '../../../assets/pages/service/salesforceDev/sc4.png';

export const salesforceDevData2 = [
    {
        id: 1,
        title: "",
        description: "One of the best CRM platforms available is Salesforce, which gives businesses the ability to improve employee collaboration, streamline business processes, track sales, service, and marketing initiatives, and create apps tailored to specific needs. This best cloud-based CRM software can revolutionize the customer and staff experiences while completely changing the way your company runs. Because Salesforce CRM has so many linked applications, it requires a great deal of knowledge and skill to fully realize its potential to help your organization succeed.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Advantages of Salesforce",
        description: "",
        onlyBullets: true,
        data: [
            "Information on Customers Account Scheduling",
            "Time Administration",
            "Cooperation among Teams Accessibility"
        ]
    },
    {
        id: 3,
        title: "Concerning Alta Tech Mark:",
        descriptions: [
            "Experience and understanding are key factors in obtaining the finest Salesforce consulting services. Years of expertise in a variety of fields and businesses have allowed Alta Tech Mark to create and provide customers all over the world with end-to-end Salesforce solutions! We assist businesses in achieving their objectives by offering Salesforce consulting services at every stage of the digital transformation.",
            "Our primary passion is serving people with our work, even if we are enthusiastic about forging new territory. Our innovative and flexible approach, in particular, may assist you in realizing advantages promptly while maintaining awareness of the demands of your clients and staff.",
            "With years of experience and knowledge, the Salesforce certified specialists at Alta Tech Mark provide a range of Salesforce solutions to companies in a variety of sectors. We are one of the top Salesforce consulting partners, offering our customers the finest Salesforce consulting services in addition to round-the-clock technical support. In addition, we provide a range of Salesforce-related services that may be advantageous for your company's expansion in the proper directions."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "View Our Consulting Services for Salesforce:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Salesforce Consulting",
                description: "With a skilled group of Salesforce consultants, we help our valued clients adopt Salesforce in an easy and economical way."
            },
            {
                id: 2,
                title: "Salesforce Development",
                description: "To satisfy your needs, Alta Tech Mark combines the strength of bespoke apps with straightforward Salesforce solutions."
            },
            {
                id: 3,
                title: "Sales, Service, and Community Cloud",
                description: "Setting up and customizing sales and service procedures is our team's area of expertise. With bespoke development, we give your ideas wings! We will help you bring your business's goals to life. Just envision where you want it to be."
            },
            {
                id: 4,
                title: "Salesforce Configure, Price, Quote (CPQ)",
                description: "Our CPQ specialists at Alta Tech Mark will collaborate closely with you to automate your CPQ procedures."
            },
            {
                id: 5,
                title: "Salesforce Billing",
                description: "Alta Tech Mark assists you in centralizing your quotations, orders, contracts, and bills into a unified platform."
            },
            {
                id: 6,
                title: "Salesforce Integration",
                description: "You can connect and manage information assets, save costs, and optimize workflow with our Salesforce data integration services."
            }
        ]
    },
    {
        id: 5,
        title: "Specializations of Our Salesforce Developers Include:",
        description: "",
        onlyBullets: true,
        data: [
            "Sales Cloud",
            "Community Cloud",
            "Marketing Cloud",
            "Commerce Cloud",
            "Services Cloud",
            "Pardot",
            "Salesforce IQ",
            "Force.com",
        ],
        bulletsPic: sc3
    },
    {
        id: 6,
        title: "Our Proficiency in Business:",
        description: "Numerous industry verticals have been the focus of our Salesforce team's expertise. As far as our Salesforce Consulting staff working across many sectors is concerned, there's no wait. Let's examine a few of the sectors:",
        onlyBullets: true,
        data: [
            "Healthcare",
            "Education",
            "Startups",
            "NPO",
            "Transportation",
            "Banking",
            "Insurance",
            "Real - Estate",
            "Advertising",
        ],
        bulletsPic: sc4
    },
    {
        id: 7,
        title: "Why Selecting Alta Tech Mark for Salesforce Consulting Services is a Wise Choice?",
        description: "The project is developed and successfully led by our team of business and system analysts, data architects, tech consultants, and business intelligence specialists. As a group of Salesforce specialists, strategists, designers, and developers at Alta Tech Mark, we think that the most effective solutions are the ones that are maintained as simple as possible. Engaging with Alta Tech Mark will turn you into a partner. Our joint effort will be guided by your priorities, and our shared goals will inform each choice we make. Your long-term success is our primary goal as a company.",
        onlyBullets: true,
        data: [
            "Free Consultation",
            "24X7 Support and Maintenace",
            "Easy Hiring Process",
            "Use of Latest Technology",
            "Experts Across the Globe",
            "On - Time Delivery & Cost - Effective Solution",
        ]
    }
]