import React from 'react';

// data
import { hireNodeDevData, hireNodeDevFaqs } from '../../../data/hireDev/hireNodeDev';
import nodeDev1 from '../../../assets/pages/hireDev/nodeDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireNodeJsDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="NODE JS DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={nodeDev1}
            data={hireNodeDevData}
            faq={hireNodeDevFaqs}
        />
    )
}
