// import ux from '../assets/icons/ux.png';
import apps from '../assets/icons/apps.png';
import websites from '../assets/icons/websites.png';
// import ratings from '../assets/icons/ratings.png';
import nativeApps from '../assets/icons/nativeApps.png';
import developers from '../assets/icons/developers.png';
import enterprise from '../assets/icons/enterprise.svg';
import iot from '../assets/icons/iot.svg';

export const statsCardData = [
    {
        id: 1,
        icon: iot,
        stats: "550+",
        description: "Custom IoT Solutions Delivered"
    },
    {
        id: 2,
        icon: nativeApps,
        stats: '110',
        description: "Million App Downloads on App Stores (iOS + Android)"
    },
    {
        id: 3,
        icon: developers,
        stats: '1000+',
        description: "Dedicated Developers & Designers"
    },
    {
        id: 4,
        icon: apps,
        stats: '500+',
        description: "Mobile Apps Developed"
    },
    {
        id: 5,
        icon: websites,
        stats: '2200+',
        description: "Websites Developed"
    },
    {
        id: 6,
        icon: enterprise,
        stats: '200+',
        description: "Enterprise Clients Supported"
    },
]