import h3 from '../../../assets/pages/service/mobileAppDev/hybridAppDev/h3.png';
import h4 from '../../../assets/pages/service/mobileAppDev/hybridAppDev/h4.png';

export const hybridDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "A hybrid application is a piece of software that combines native and web application functionality. The fact that developers just need to create code once and then apply it to several platforms is the most treasured aspect of hybrid applications in application development. Cross-platform programs like HTML, JavaScript, and other computer languages are used in the writing of this code. It becomes more economical and time-efficient as a result of not having to build separate programs for numerous platforms. Rather, it helps company owners by using the same code on many platforms. This method combines the greatest features of both approaches: low development costs and simple data accessibility. Additionally, hybrid applications include offline functionality. Among the newest frameworks for developing hybrid apps are PhoneGap and Sencha. These frameworks support mobile apps in providing seamless functioning and peak performance.",
            "Leading hybrid application development business Alta Tech Mark provides adaptable and unmatched mobile solutions. Our professionals help you choose the ideal cross-platform framework for your company's requirements. You may quickly and affordably reach your target audience with us. For cross-platform app development, our engineers use technologies like Phone Gap, Xamarin, Ionic, React Native, and Flutter. With our cross-platform software, we guarantee an enhanced user experience across a variety of platforms, including Windows, iOS, Android, and more. Acknowledging the critical role that mobile applications play in today's business landscape, we have established ourselves as a leading mobile application development firm, offering our services to companies of all sizes, from startups to multinational corporations. Our mission is to consistently deliver powerful hybrid apps that enable companies without sacrificing any quality."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Industries We Provide Hybrid App Development Services To",
        description: "",
        onlyBullets: true,
        data: [
            "Finance & Banking",
            "Retail Online Shopping",
            "Healthcare Automation Logistics",
            "Entertainment and Media"
        ],
        bulletsPic: h3
    },
    {
        id: 3,
        title: "Services for Tourism and Travel In terms of hybrid app development, we offer",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Adaptive Native Programming",
                description: "The foundation of hybrid app development is React Native. Strong React Native Development services are what we provide; quick implementation and great app performance are guaranteed. React Native enables organizations to achieve previously unheard-of levels of expansion and success.",
            },
            {
                id: 2,
                title: "Designing Hybrid Applications",
                description: "Our staff carefully considers end users while designing. We always work to create attractive and user-engaging designs as part of our hybrid app development process since we know how much an excellent design can increase app downloads.",
            },
            {
                id: 3,
                title: "Flutter Application Creation",
                description: "We provide state-of-the-art Flutter app development services as part of our Hybrid app development package. Alta Tech Mark promises the best possible performance and quality in its solutions.",
            },
            {
                id: 4,
                title: "Design of Progressive Web Applications",
                description: "Our progressive web app development service is designed to provide a single codebase that works well on a variety of platforms. Using this service, our knowledgeable staff guarantees the best installation and connection.",
            },
            {
                id: 5,
                title: "High-Tech Application Quality Control",
                description: "We put our apps through a thorough testing process to make sure there are no bugs or other difficulties. To produce applications that are perfect, our Hybrid Application Quality Assurance services are essential.",
            },
            {
                id: 6,
                title: "Updating Hybrid Applications",
                description: "We provide ongoing oversight and assistance for our apps. Our staff is accessible around-the-clock to address issues and keep the applications operating at peak efficiency. We make sure that our apps run well and give priority to our clients' business goals.",
                pic: h4
            },
        ]
    },
    {
        id: 4,
        title: "Why Select Alta Tech Mark for the Development of Hybrid Apps?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Professional Fix",
                description: "Our committed group of experts specializes in hybrid app development and produces excellent, scalable outcomes. Our goal is to satisfy every client's demand while promoting substantial business development and success.",
            },
            {
                id: 2,
                title: "Safe and Secure Technology",
                description: "We take great satisfaction in using cutting-edge, safe technology in the creation of our hybrid apps. As a reputable business, we consistently maintain our good name by using the newest and most cutting-edge equipment.",
            },
            {
                id: 3,
                title: "Agile Approach",
                description: "Our agile approaches are a reflection of our dedication to excellence. Our cutting-edge methods distinguish us and make Alta Tech Mark a leading mobile app development business.",
            },
            {
                id: 4,
                title: "Economical",
                description: "Our dedication is in delivering solutions that are both economical and timely. Alta Tech Mark strives to strengthen companies by providing unmatched outcomes.",
            },
        ]
    }
]