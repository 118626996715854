export const swiftDevData = [
    {
        id: 0,
        title: "",
        description: "Swift is a famous programming language. It is a quick, highly efficient, and secure programming language. Swift language is preferably suitable for iOS app development. Moreover, Apple provides a suite feature for the iOS app development process. Swift language is used in mobile applications and websites. It is a game-changing advantage if we use it right and properly with innovative technology. It is an emerging language that is easy to use and interactive. The Swift code runs along with Objective C, and it is added into iOS projects for web and mobile apps. The business gains benefits in the process. The C and Object C code will help you to make your process and development smooth. Developing applications through Swift language will help you to obtain benefits. Several other features will add value to your applications. Alta Tech Mark will help you to run the application 100% bug-free with a seamless experience. The application will help you in several ways, but it is only possible with a Swift developer or an expert.",
        onlyBullets: false,
        data: []
    },
    {
        id: 1,
        title: "Service We Provide:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Swift iOS Consultation",
                description: "Alta Tech Mark provides informative and opts Swift iOS App Consultation. Swift language release by Apple, hence iOS plays a key major role. Our Swift developers will guide you at every step and through every process."
            },
            {
                id: 2,
                title: "Custom Swift Development",
                description: "We know customization makes business better that is why we provide custom Swift development. Our company delivers custom Swift development according to your business needs and targets, the custom Swift development is different from traditional development, and if you are looking for long-term objectives, go for custom development."
            },
            {
                id: 3,
                title: "Swift App Designing",
                description: "Our company built stunning, innovative, adequate designs with Swift development. We ensure that it is different than the usual and help you to stand out among others. The design will help you be interactive, highly engaged to the users, and match client needs."
            },
            {
                id: 4,
                title: "Enterprise Swift App",
                description: "With the use of enterprise apps, we can apply to build striking apps with Swift. It can also be compatible with other iOS devices like iPhone, Mac, and iPad. Our Swift developer engaged in every small process to make the app more compelling with the Swift language."
            },
            {
                id: 5,
                title: "Swift Testing Development",
                description: "We go through testing development with well-equipped testing tools for Swift Development. Our Swift developers and QA team follow the best practice to enhance app performance and solve all the issues. In addition, our team ensures that after testing development, the application is bug-free and flawless."
            },
            {
                id: 6,
                title: "Swift Porting Development",
                description: "Our Swift development provides swift porting development, which is helpful to run applications flawless on different Apple devices. The language can operate only based on Swift to their desired platforms. The application is useful for several other reasons."
            },
            {
                id: 7,
                title: "Swift Migration Development",
                description: "The Swift development provides swift migration, which is profitable for the application to migrate one device into another. Moreover, our company ensures that your data or information is secured and safe. Therefore, Swift Migration development is helpful for businesses."
            },
            {
                id: 8,
                title: "Swift App Support & Maintenance",
                description: "We support Swift development with support and maintenance. It helps to enhance availability, decrease downtime, solve all issues, and others. In addition, we ensure that our developed team will solve any glitches or bugs."
            }
        ]
    },
    {
        id: 2,
        title: "Why Choose Alta Tech Mark For Swift Development?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Agile Procedure",
                description: "Our company provides a prompt and agile procedure to make your task effortless. We optimize the practice and method. It helps to deliver faster and bring the finest outcome. Even though the procedure is agile, we maintain the high quality and keep it constant throughout the process."
            },
            {
                id: 2,
                title: "Unique Approach & Dedicated Team",
                description: "With years of experience, we have a unique approach to the Swift development process. The method comes with a large variety of talent, skills, ability, and much more; the developers, designers, and all work hard to stand out on the top. The unique approach delivers high-end business solutions over technologies in Swift development and its usage."
            },
            {
                id: 3,
                title: "Interactive Process",
                description: "A client should know and be aware of their project, and hence we provide an interactive process where the client can raise any issue regarding the project. With the interaction, both the client and us will be clear on what to do and acknowledge that we both are on the same page for the Swift development. Swift development is an easy language, so we maintain it by providing the easiest method and process."
            },
            {
                id: 4,
                title: "24/7 Support And Maintenance",
                description: "We provide 24/7 support and maintenance during the process and after the development. We are a reliable and trustworthy company that supports and provides multiple tools, techniques, and technology. If there is any mobile application or web issue with Swift development, our expert will solve it instantly. We provide 24/7 support and maintenance so any client can solve the issue at their respective time and be left without disappointment."
            }
        ]
    }
]