import ar3 from '../../../assets/pages/service/gameDev/ar3.png';
import ar4 from '../../../assets/pages/service/gameDev/ar4.png';
import ar5 from '../../../assets/pages/service/gameDev/ar5.png';

export const augmentedRealityDevData = [
    {
        id: 1,
        title: "",
        description: "The application market is seeing one of the biggest trends of the future: augmented reality. The world around us seems to be in its most natural state; augmented reality, on the other hand, is not like that. It opens the door to a new dimension by fusing the digital and physical worlds. AR improves several elements, such as motion, sound, picture & image, and GPS data, to provide an engaging and educational experience. It resides in the space between virtual reality and reality, strengthening users' sensations and obfuscating the line between computer-generated and real. Augmented reality, in general, provides an enhanced perspective of reality by including contextual details. Users may fully immerse themselves in an immersive digital world that seamlessly blends into reality using AR technology.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Select Alta Tech Mark as Your Development Partner for AR Applications?",
        descriptions: [
            "Alta Tech Mark has several years of experience at the forefront of developing cross-platform augmented reality apps. Alta Tech Mark, a renowned leader in AR application development, uses modern methods to complete projects more quickly.",
            "We follow industry norms to provide best practices solutions that are in line with customer needs and corporate goals. Clients at Alta Tech Mark have access to a committed staff with experience in development approaches and an open and honest work environment that helps to polish your idea. You may anticipate receiving top-notch AR app development services on schedule and at affordable costs."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 3,
        title: "The foundation of application development is technology.",
        description: "",
        onlyBullets: true,
        data: [
            "Advanced Level Programming",
            "2D & 3D Game and App Developers",
            "Cross platform support(with unity)",
            "C# language based",
            "Emulator or device to test the game",
        ],
        bulletsPic: ar3
    },
    {
        id: 4,
        title: "Offerings From Alta Tech Mark For The Creation Of High-End AR Apps:",
        description: "",
        onlyBullets: true,
        data: [
            "A Team of expert, skilled and experienced developers.",
            "Client oriented engagement model.",
            "Exceptional UI / UX Design.",
            "Vast Technical Expertise.",
            "Follows best QA Standards.",
            "Provides Robust Solutions."
        ],
        bulletsPic: ar4
    },
    {
        id: 5,
        title: "How Can Alta Tech Mark's Augmented Reality Application Development Help You Advance?",
        description: "In addition to having a strong and committed technical staff, working with Alta Tech Mark has the following benefits:",
        onlyBullets: true,
        data: [
            "Productive AR apps development that boosts your brand recognition.",
            "Access to Latest tools and technologies.",
            "High - tech and Secured Infrastructure.",
            "Elegant and Interesting Application Development Ideas.",
            "User - friendly as well functional apps."
        ],
        bulletsPic: ar5
    },
    {
        id: 6,
        title: "",
        description: "The importance of graphics in attracting users cannot be overstated. As a result, apps need to have excellent graphics that captivate users and take them to a new world. Join us for growth as we take you on a path to unmatched achievement!",
        onlyBullets: false,
        data: []
    }
]