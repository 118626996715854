import React from 'react';

// assets
import img1 from '../../../../assets/pages/service/mobileAppDev/swiftAppDev/1.png';
import { swiftDevData } from '../../../../data/services/mobileAppDevData/swiftDevData';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';

export default function SwiftAppDev() {
    return (
        <ServicesWrapper
            title="Swift Development"
            description="Develop The Best Application With Our Swift Development"
            img={img1}
        >
            <DevGridWrapper>
                <DataMapper data={swiftDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
