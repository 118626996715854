export const hireDrupalDevData = [
    {
        id: 1,
        title: "Why The Drupal Content Management System Is So Dependent on Organizations",
        descriptions: [
            "You are essentially following in the footsteps of several well-known websites, including those operated by the US government and numerous organizations, when you choose to use the Drupal open-source CMS (content management system). However, you may get this free CMS under a GNU General Public License even if all you desire is for your own personal blog site.",
            "The main features of Drupal may have some similarities with other content management systems (CMS) if you have used them before. The creation of user accounts, maintaining them, RSS feeds, managing menus, adjusting page layouts, and even system administration are a few instances. This implies that regardless of how basic or sophisticated your demands are, you could find it straightforward to modify Drupal to meet them. Creating a multi-user blog site using Drupal is a straightforward application, however creating a community discussion forum is a more complicated one. This might be the reason why significant organizations like the US government find this CMS beneficial.",
            "Since the CMS is free, the open-source community itself makes improvements to it. Consequently, in the event that you have questions or would want to make changes to any part of the CMS, you may seek assistance from the user community. Installing and maintaining it on your website should be rather simple, but in the event that you run into issues, it could be wise to have Alta Tech Mark handle the CMS installation and administration. By working with Alta Tech Mark, you can be sure that your CMS will run more efficiently and you won't have any downtime because of technical issues."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Choose Drupal?",
        description: "",
        noList: true,
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Flexibility",
                description: "Whether you want to launch a personal blog, a multi-user platform, an e-commerce site, or a government website, Drupal's extensibility ensures it fits any requirement."
            },
            {
                id: 2,
                title: "Modular Architecture",
                description: "Drupal's architecture is based on modules, allowing users to extend its functionalities by adding or removing modules as per their requirements."
            },
            {
                id: 3,
                title: "Scalability",
                description: "From start-ups to large enterprises, Drupal scales with your needs and handles high traffic loads efficiently."
            },
            {
                id: 4,
                title: "Security",
                description: "Drupal's core has robust security features, and the community actively works on addressing vulnerabilities, making it a preferred choice for businesses that prioritize security."
            },
            {
                id: 5,
                title: "Community Support",
                description: "Being open-source means a vast community of developers continually contributes to its growth, offering a plethora of modules, themes, distributions, and resources."
            }
        ]
    }
]

export const hireDrupalDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Drupal developers?",
        answer: "The dedicated Drupal developers will work only for you to deliver outstanding results with the highest optimization. The team of dedicated Drupal developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire dedicated Drupal developers?",
        answer: "Alta Tech Mark offers a very simple process to hire dedicated Drupal developers. Just provide project details and the technical expertise you need. The Drupal developer begins working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Drupal developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Drupal developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Drupal developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Drupal developer, then you can hire the team of dedicated Drupal developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Drupal developers?",
        answer: "The cost estimation for dedicated Drupal developers varies based on various factors such as team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]