import React from 'react'

// assets
import img1 from '../../../../assets/pages/service/mobileAppDev/reactNativeAppDev/1.png';
import { reactNativeDevData } from '../../../../data/services/mobileAppDevData/reactNativeDevData';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// components
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function ReactNativeAppDev() {
    return (
        <ServicesWrapper
            title="React Native App Development Company"
            description="Develop Powerful Mobile Cross-Platform Apps With React Native"
            img={img1}
        >
            <DevGridWrapper>
                <DataMapper data={reactNativeDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
