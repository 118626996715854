import React from 'react';

// data
import { hireReactDevData, hireReactDevFaqs } from '../../../data/hireDev/hireReactDev';
import reactDev1 from '../../../assets/pages/hireDev/reactDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireReactJsDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="REACT JS DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={reactDev1}
            data={hireReactDevData}
            faq={hireReactDevFaqs}
        />
    )
}
