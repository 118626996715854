import iad3 from '../../../assets/pages/service/iot/iad3.png';

export const iotAppDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "One new technology is the Internet of Things. IoT has completely changed and evolved business. And because of IoT applications, the future of IoT is bright. An avenue for connecting devices and networks through the Internet is the Internet of Things (IoT). IoT apps are designed specifically for almost every sector and work on IoT devices. Artificial Intelligence and Machine Learning are being used by IoT applications to include smart devices. Internet of Things applications include wearable technology and smart automation.",
            "The Internet of Things apps facilitate the process and expedite and simplify your work. Several technologies are needed for Internet of Things applications since almost all of them can provide relevant information about how they work. The way an activity or circumstance is carried out is not the same as how the Internet of Things is controlled and observed. IoT applications have several uses and are applicable to many areas.",
            "IoT applications boost company value and change whole sectors. New digitization improves consumer experiences and corporate processes. Applications provide robots and networks the intelligence to reduce human labor. Relevant information may be shared across linked devices with the use of cloud-based apps. IoT apps utilize a variety of techniques to assist organizations in automating, streamlining, enhancing, and more. IoT applications improve income sources and stimulate new business models. Real-time business improvements and service and product development are facilitated by IoT applications."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Various IoT Applications Types:-",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Business Objective",
                description: "The Internet of Things offers several commercial uses for multiple devices. The services, even if they notify about changes and actions that need to be made, utilize the IoT software on their device to get notifications about the service. While using IoT technologies for business may improve services and save development expenses. IoT offers sensors that improve productivity and makes it simple to handle several data sets at once. Automation enhances the goal of the company, and IoT applications benefit the organizations."
            },
            {
                id: 2,
                title: "Intelligent City",
                description: "The smart city of the future is something that we will all soon experience. Since IoT is used to run smart cities, there is an increasing need for these IoT applications. Smart city applications include trash management, traffic control, water distribution, and urban security, among others. A lot of individuals and companies want to employ it due to its superior offerings. A smart city reduces pollution and improves a city by offering intelligent solutions for everyday problems."
            },
            {
                id: 3,
                title: "The Internet of Industry",
                description: "Industrial Internet is one of the most popular Internet of Things applications. Although it may not be well recognized, it will provide wonderful chances in the future. Smart sensors and actuators are used by the industrial Internet of things (IIoT) to enhance industrial and manufacturing operations. The notion of the industrial Internet is broader and has potential to be lucrative.",
                pic: iad3
            },
            {
                id: 4,
                title: "Smart Home",
                description: `Smart Homes are the most popular and widely utilized IoT applications. Through the use of gadgets and the Internet, homeowners may operate and maintain their homes with the help of an application known as a "smart home." Tablets, smartphones, or any other device with an Internet connection may be used to control the system. Homeowners find the Smart Home to be very handy and cost-effective. Instructions are sent to the IoT application; voice control is also possible. The owners' burden is lessened, and it facilitates quicker, more efficient labor.`
            },
            {
                id: 5,
                title: "Medical Care",
                description: "Applications for IoT in healthcare are a blessing in disguise. Applications for the Internet of Things improve machine performance and accuracy in medical reporting. The IoT HealthCare applications have led to an increase in accuracy rates. Though an expert is needed for the application, it has increased the efficiency of medical research. Real-time data or information is provided, and many IoT applications have a medical focus. It is quite beneficial to examine and play with. It makes the gadgets better and increases the level of wellbeing. The IoT helps you enhance the present system and promotes the use of power-hungry gadgets."
            }
        ]
    },
    {
        id: 3,
        title: "",
        description: "Alta Tech Mark offers IoT applications previously described in addition to other ones. We provide top-notch IoT applications that preserve productivity and efficiency.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Why Select Alta Tech Mark For Internet of Things Uses?",
        descriptions: [
            "We are the finest IoT development business because our IoT developers provide the best IoT solutions and services. Using our deep understanding, we support the advancement of technology and provide technology deployment where it is needed. Alta Tech Mark is well-known for offering exceptional quality for Internet of Things applications. We are often credited for making work for companies simple and effective. With the newest tools and technology, as well as an enthusiasm in picking up new upgrades, our IoT stays current. They are distinguished from others by their intense curiosity and outstanding performance.",
            "IoT applications are available from Alta Tech Mark for a variety of industries and uses. The organization emphasizes procedural openness and timely application delivery. In order to meet and satisfy our customers' business requirements, we engage and communicate with them."
        ],
        onlyBullets: false,
        data: []
    }
]