import ed3 from '../../../assets/pages/service/blockchainDev/ed3.png';
import ed4 from '../../../assets/pages/service/blockchainDev/ed4.png';

export const exchangeDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "A big trend in finance is cryptocurrency, and transactions using it are tracked on the Blockchain. It illustrates how value exchange has shifted from centralized to decentralized. Buyers and sellers may trade coins using it. You may now and in the future trade currencies for other coins thanks to the growth of cryptocurrency exchanges. Thus, the growth of cryptocurrency exchanges is dependent on future market developments. Your cryptocurrency exchange includes Bitcoin, Ethereum, and other cryptocurrencies. In this case, the bank's control over transactions is not absolute. Therefore, you will be able to trade quickly and safely if you have access to a cryptocurrency trading platform.",
            "Prominent Blockchain business Alta Tech Mark offers cryptocurrency exchange platforms to facilitate cryptocurrency transactions between buyers and sellers. It will have excellent dependability and financial stability. Our apps are reliable and widely acknowledged. With our software, we will be able to design a cryptocurrency exchange development platform that is reliable, scalable, and simple to maintain. Excellent cryptocurrency exchange development services that are ideal for digital transformation are provided by our team. It will boost your company's income and encourage the expansion of your cryptocurrency venture. With our software, we will create a platform that offers limitless flexibility for international transactions at any time, free from additional fees or third-party participation."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Our Service Offerings for Cryptocurrency Exchange Development:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Development of Decentralized Exchanges",
                description: "Since cryptocurrency exchange promotes decentralization, we provide the establishment of cryptocurrency decentralized exchanges. It makes the transaction more transparent and anonymous. The transactions are carried out instantly and without interference from other parties. Digital transaction is made simple by the Decentralized Exchange Development Services."
            },
            {
                id: 2,
                title: "Centralized Development of Exchanges",
                description: "To assist in maintaining control over the financial body and transactions, we provide centralized exchange development services. Through the use of our centralized exchange development services, we created a reliable and secure platform. The platforms assist in providing the cryptocurrency exchange that facilitates secure transactions with central authorities.",
                pic: ed3
            },
            {
                id: 3,
                title: "White Label Trade Promotion",
                description: "In an effort to promote more involvement in cryptocurrency exchanges, we provide a White Label exchange creation service. It will assist in drawing in additional cryptocurrency-seeking partners. Through the provision of end-to-end solutions and the improvement of user experiences, the service is tailored. Additionally, the likelihood of several transactions rises."
            },
            {
                id: 4,
                title: "Hybrid Development of Exchanges",
                description: "With his hybrid exchange development service, Tech Alta Mark lets users access both decentralized and centralized exchange functionalities. It will assist you in facilitating and using both transactions. It increases the exchange transactions' degree of flexibility. Multiple modalities for cryptocurrency exchanges will be created by it."
            },
            {
                id: 5,
                title: "Binance Exchange's Growth",
                description: "We provide the most skilled services for cryptocurrency exchange trends, specifically for Binance Exchange growth. It is a typical cryptocurrency trading method that facilitates quick and secure trade. Consequently, one of the best cryptocurrency exchanges developed is Binance, which offers exchange development services.",
                pic: ed4
            },
            {
                id: 6,
                title: "P2P Trade Innovation",
                description: "With peer-to-peer exchange development services, we created a dynamic platform. Choose this option if you want to exchange cryptocurrency without the need for middlemen. It is a specialized framework designed to provide a development platform for cryptocurrency exchanges. Transferring cryptocurrency assets is simple with this method."
            },
            {
                id: 7,
                title: "Escrow Exchange Development",
                description: "Our escrow exchange development services provide a secure framework for conducting cryptocurrency transactions. This method ensures that funds are held securely until both parties fulfill their obligations, reducing the risk of fraud or loss. It's a reliable solution for safe cryptocurrency trading."
            }
        ]

    },
    {
        id: 3,
        title: "",
        description: "We provided escrow exchange development services together with safe platforms. With its infinite token support and quick trading engine performance, Alta Tech Mark offers an exchange service. It is advantageous and simple to implement for the growth of cryptocurrency exchanges.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Why Is Alta Tech Mark the Best Option for Developing Cryptocurrency Exchanges?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Modest Development",
                description: "Agile development is offered by Alta Tech Mark for the construction of cryptocurrency exchanges. We provide end-to-end solutions and faster development times with our cryptocurrency exchange software. It also expedites the transaction procedure."
            },
            {
                id: 2,
                title: "Mistake-free",
                description: "Our professionals provide services that are perfect and devoid of any errors. For instance, when it comes to cryptocurrency, we don't allow flaws to prevent exchanges from working, and our experts will take care of any issues right away. We provide bug-free programming services for cryptocurrency exchanges, ensuring smooth transactions."
            },
            {
                id: 3,
                title: "Observance And Safety",
                description: "The process of developing cryptocurrency exchanges adheres to trustworthy and safe sources. We have turned on security features that facilitate the integration of the buyers' and sellers' wallets. To safeguard important transactions, we provide anti-money laundering, KYC compliance, and many more services."
            },
            {
                id: 4,
                title: "Technical Assistance",
                description: "With years of expertise, our technical staff is on hand to assist with any technical issues, day or night. By making technical interactions flawless, we empower them. Our staff responds quickly to any questions or problems. We use cutting-edge instruments and technology in our work."
            },
            {
                id: 5,
                title: "Complete Solutions",
                description: "We provide complete, all-inclusive solutions. It functions similarly to a one-stop shop for all business solutions that enhance your productivity. We provide smooth operations and features for the growth of cryptocurrency exchanges. The realistic and dynamic solutions have shown their ability to effectively improve Blockchain technology services. Our specialists are knowledgeable and provide top-notch solutions for further expansion."
            }
        ]
    }
]