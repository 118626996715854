import React from 'react';
import { useSelector } from 'react-redux';

// data
import { keyFeaturesData } from '../../../data/keyFeaturesData';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// components
import KeyFeaturesCard from './KeyFeaturesCard';
import HeaderText from '../../headerText/HeaderText';

export default function KeyFeatures() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-mt-[8.138vw] tw-w-full'>
            {/* <HeaderText
                title='Our Key'
                colorTitleEnd='Features'
                description='Services That We Deliver Makes Us Dignified As One of The Top App Development Companies.'
                className='tw-mb-8 tw-text-center'
            /> */}
            <HeaderText
                colorTitleStart='OUR'
                title='KEY FEATURES'
                description='Services That We Deliver Makes Us Dignified As One of The Top App Development Companies.'
                className='tw-mb-8 tw-text-center'
            />

            {screenWidth < 768 ?
                <div className='tw-px-[9.115vw]'>
                    <div className='tw-flex tw-w-full tw-gap-x-4 tw-overflow-x-scroll tw-h-[400px]'>
                        {keyFeaturesData.map(card => {
                            return (
                                <SwiperSlide key={card.id}>
                                    <KeyFeaturesCard
                                        src={card.src}
                                        title={card.title}
                                        description={card.description}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </div>
                </div>
                :
                <Swiper
                    modules={[Autoplay, Pagination]}
                    spaceBetween={10}
                    slidesPerView={Math.floor(screenWidth / 350)}
                    loop={true}
                    // centeredSlides={true}
                    autoplay={{
                        delay: 2000,
                    }}
                >
                    {keyFeaturesData.map(card => {
                        return (
                            <SwiperSlide key={card.id}>
                                <KeyFeaturesCard
                                    src={card.src}
                                    title={card.title}
                                    description={card.description}
                                />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            }
        </div>
    )
}
