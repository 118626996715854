import React from 'react';

// assets
import h1 from '../../../../assets/pages/service/mobileAppDev/hybridAppDev/h1.jpeg';
import h2 from '../../../../assets/pages/service/mobileAppDev/hybridAppDev/h2.png';
import h3 from '../../../../assets/pages/service/mobileAppDev/hybridAppDev/h3.png';
import { hybridDevData } from '../../../../data/services/mobileAppDevData/hybridDevData';

// components
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function HybridAppDev() {
    return (
        <ServicesWrapper
            title="HYBRID APP DEVELOPMENT"
            descriptionCont="Our staff knows how to meet targets and adapt concepts to market needs. We don't simply design apps—we create tools to grow your company. We create a masterpiece for you using expertise, innovation, and technology. Alta Tech Mark values learning above milestones."
            img={h1}
        >
            <DevGridWrapper images={[h2]}>
                <DataMapper data={hybridDevData} />
            </DevGridWrapper>

        </ServicesWrapper>
    )
}
