import uc3 from '../../../assets/pages/service/ecommerceDev/uc3.png';

export const uberCartDevData = [
    {
        id: 1,
        title: "Reasons Why Your Company Should Switch to the E-commerce Solution Offered By Ubercart",
        description: "Ubercart emerges as a leading competitor as an option for e-commerce companies that are looking for a software solution that is both strong and efficient. Because it is open-source, Ubercart avoids the need for royalties and license costs, but it does not make any sacrifices in the variety of features that it offers. Alta Tech Mark is prepared to assist companies in making full use of the possibilities offered by Ubercart.",
        onlyBullets: false,
        data: [],
    },
    {
        id: 2,
        title: "What exactly is it about Ubercart that makes it such a great e-commerce option for your online business? This is why:",
        description: "",
        noList: true,
        data: [
            {
                id: 1,
                title: "Catalog Assignment Determined by Size",
                description: "This method simplifies product categorization and ensures that buyers have a clear understanding of the options available within certain size brackets and pricing ranges."
            },
            {
                id: 2,
                title: "Proceeds from the Sale of Virtual Items",
                description: "To meet the demands of the modern market, you should consider selling virtual goods.",
                pic: uc3
            },
            {
                id: 3,
                title: "Downloads of Multimedia Content",
                description: "You may make direct sales of software, films, or music by making these items available as downloadable downloads."
            },
            {
                id: 4,
                title: "Event Registrations",
                description: "Provide the ability to register for events directly via your platform and manage registrations as needed."
            },
            {
                id: 5,
                title: "Access to a Private Location",
                description: "Ubercart makes it easier to sell access permits on platforms that restrict access to members only. This feature comes complete with automatic renewals and the ability to monitor user access till it expires. Additionally, it allows for the selling of products across many sites."
            }
        ]
    },
    {
        id: 3,
        title: "",
        descriptions: [
            "In the environment of e-commerce, which is always shifting and growing, the combination of user-friendliness and functional adaptability may push sales, which in turn ensures the continuation of a firm.",
            "Iterative design changes are automatically accounted for by Ubercart's architecture. The transaction procedures of e-commerce platforms often undergo iterative improvements as a result of trial and error, and Ubercart is a platform that supports this method. It is not enough to just identify the optimal configuration; you must also have the adaptability to accommodate any changes that may occur in the future. Ubercart is built on the principle of adaptability."
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 4,
        title: "Why choose Alta Tech Mark?",
        description: "The XML import/export capability of Ubercart is quite helpful for merchants that are moving their businesses from more antiquated systems to more modern ones. Because of this capability, it is possible to migrate customers, orders, goods, and characteristics across sites without any disruption. In addition, Alta Tech Mark is available to help in the event that assistance is required on the features of Ubercart.",
        onlyBullets: false,
        data: []
    }
]