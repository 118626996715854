import React from 'react';
import { Link } from 'react-router-dom';

// data
import { resourcesData } from './resourcesData';

// icons
import resourcesHoverBg1 from '../../../assets/background/resourcesHoverBg-01.png'
import resourcesHoverBg2 from '../../../assets/background/resourcesHoverBg-02.jpg'
import companyHoverBg2 from '../../../assets/background/companyHoverBg-02.png'

export default function ResourcesHover(props) {
    return (
        <div className='tw-grid tw-grid-cols-4 tw-absolute tw-bg-white tw-border-slate-300 tw-border-[1px] tw-w-full tw-h-[60vh] tw-z-10' onMouseLeave={props.hoverLeave}>
            <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-2'>
                {resourcesData.map(item =>
                    <Link to={item.url} key={item.id} className='tw-cursor-pointer hover:tw-text-[#4741A6] tw-mx-4'>{item.value}</Link>
                )}
            </div>

            <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-4 tw-border-l-[1px] tw-border-slate-300 tw-p-4'>
                <div className='tw-h-full tw-w-full tw-brightness-50 tw-rounded-lg' style={{
                    backgroundImage: `url(${resourcesHoverBg1})`,
                    backgroundSize: 'cover',
                }}>
                </div>
                <h1 className='tw-text-3xl tw-font-semibold tw-absolute tw-top-[70%] tw-text-white tw-ml-4'>
                    How to Develop
                    <br /> a soci...
                </h1>
            </div>

            <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-4 tw-border-l-[1px] tw-border-slate-300 tw-p-4'>
                <div className='tw-h-full tw-w-full tw-brightness-50 tw-rounded-lg' style={{
                    backgroundImage: `url(${resourcesHoverBg2})`,
                    backgroundSize: 'cover',
                }}>
                </div>
                <h1 className='tw-text-3xl tw-font-semibold tw-absolute tw-top-[70%] tw-text-white tw-ml-4'>
                    Why Your
                    <br /> Business Nee...
                </h1>
            </div>

            <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-4 tw-border-l-[1px] tw-border-slate-300 tw-p-4'>
                <div className='tw-h-full tw-w-full tw-brightness-50 tw-rounded-lg' style={{
                    backgroundImage: `url(${companyHoverBg2})`,
                    backgroundSize: 'cover',
                }}>
                </div>
                <h1 className='tw-text-3xl tw-font-semibold tw-absolute tw-top-[70%] tw-text-white tw-ml-4'>
                    Let's discuss your
                    <br /> Requirements!
                </h1>
            </div>
        </div>
    )
}
