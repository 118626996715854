import vi2 from '../../../assets/pages/service/corporateBranding/vi2.png';

export const visualIdentityDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Visual identity design is the cornerstone of a brand's visual communication. It embodies the visual elements that distinguish a brand in the marketplace, serving as a brand's visual fingerprint. Key components like logo design, color palettes, typography, and imagery come together to convey a brand's essence and values. A cohesive visual identity ensures that the brand is immediately recognizable and evokes specific emotions in its audience. Beyond mere aesthetics, it's a strategic tool that, when executed effectively, resonates deeply with a target demographic. As the digital landscape becomes increasingly crowded, a strong and consistent visual identity can be the deciding factor in capturing attention and building trust. In essence, visual identity design is not just about looking good; it's about communicating purpose and values in a visually compelling manner.",
            "Visual identity design is a multidimensional domain that extends beyond mere aesthetics. It is a systematic and strategic approach that crafts the visual elements representing an organization's brand, ensuring that they convey the intended message and evoke the desired emotions in a target audience."
        ],
        sectionPicRow: true,
        sectionPic: vi2,
        onlyBullets: false,
        data: []
    }
]

export const componentsOfVisualIdentityDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Logo Design",
                description: "The logo serves as the central element of any visual identity. It’s a distinctive graphic or typographic solution that provides immediate recognition for a brand. A well-designed logo should be scalable, adaptable, and reflective of the brand’s core values.",
            },
            {
                id: 2,
                title: "Color Palette",
                description: "Colors evoke emotions and associations. The choice of a brand’s color palette is based on psychological and cultural considerations. For instance, blue often represents trust and professionalism, while red can signify passion or urgency.",
            },
            {
                id: 3,
                title: "Typography",
                description: "This encompasses the fonts and typographic treatments used in brand materials. The typography chosen should be legible across different mediums and consistent with the brand’s voice.",
            },
            {
                id: 4,
                title: "Imagery and Graphics",
                description: "This includes photographs, illustrations, icons, and other graphical elements. They should be consistent in style and tone, complementing the brand's message.",
            }
        ]
    }
];

export const principlesOfVisualIdentityDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Consistency",
                description: "For a brand to be recognizable, its visual elements must be consistent across all platforms and touchpoints.",
            },
            {
                id: 2,
                title: "Differentiation",
                description: "In a crowded market, standing out is essential. A unique visual identity helps brands differentiate themselves from competitors.",
            },
            {
                id: 3,
                title: "Flexability",
                description: 'The visual identity should be adaptable across various media and platforms, from print to digital.',
            },
            {
                id: 4,
                title: "Relevance",
                description: "The design should resonate with the target audience and reflect current design trends without being overly trendy.",
            }
        ]
    }
];

export const processOfVisualIdentityDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Research and Discovery",
                description: "This step involves understanding the brand, its values, target audience, competitors, and the market landscape.",
            },
            {
                id: 2,
                title: "Conceptualization",
                description: "Designers brainstorm, sketch, and develop initial concepts for the visual elements.",
            },
            {
                id: 3,
                title: "Design Development",
                description: " Refining the chosen concepts and creating detailed designs.",
            },
            {
                id: 4,
                title: "Implementation",
                description: "Once the designs are finalized, they're applied across all brand materials, from business cards to websites.",
            },
            {
                id: 5,
                title: "Guidelines",
                description: "A visual identity design system or guidelines are created to ensure consistency. This document defines how the visual elements should be used and adapted.",
            }
        ]
    }
];
