import main1 from '../assets/pages/events/main1.png';
import sub11 from '../assets/pages/events/sub11.png';
import sub12 from '../assets/pages/events/sub12.png';
import sub13 from '../assets/pages/events/sub13.png';
import sub14 from '../assets/pages/events/sub14.png';
import sub15 from '../assets/pages/events/sub15.png';

import main2 from '../assets/pages/events/main2.png';
import sub21 from '../assets/pages/events/sub21.png';
import sub22 from '../assets/pages/events/sub22.png';
import sub23 from '../assets/pages/events/sub23.png';
import sub24 from '../assets/pages/events/sub24.png';
import sub25 from '../assets/pages/events/sub25.png';
import sub26 from '../assets/pages/events/sub26.png';
import sub27 from '../assets/pages/events/sub27.png';

import main3 from '../assets/pages/events/main3.png';
import sub31 from '../assets/pages/events/sub31.png';
import sub32 from '../assets/pages/events/sub32.png';
import sub33 from '../assets/pages/events/sub33.png';
import sub34 from '../assets/pages/events/sub34.png';
import sub35 from '../assets/pages/events/sub35.png';

import main4 from '../assets/pages/events/main4.png';
import sub41 from '../assets/pages/events/sub41.png';
import sub42 from '../assets/pages/events/sub42.png';
import sub43 from '../assets/pages/events/sub43.png';
import sub44 from '../assets/pages/events/sub44.png';
import sub45 from '../assets/pages/events/sub45.png';

import main5 from '../assets/pages/events/main5.png';
import sub51 from '../assets/pages/events/sub51.png';
import sub52 from '../assets/pages/events/sub52.png';
import sub53 from '../assets/pages/events/sub53.png';
import sub54 from '../assets/pages/events/sub54.png';
import sub55 from '../assets/pages/events/sub55.png';

import main6 from '../assets/pages/events/main6.png';
import sub61 from '../assets/pages/events/sub61.png';
import sub62 from '../assets/pages/events/sub62.png';
import sub63 from '../assets/pages/events/sub63.png';
import sub64 from '../assets/pages/events/sub64.png';
import sub65 from '../assets/pages/events/sub65.png';

import main7 from '../assets/pages/events/main7.png';
import sub71 from '../assets/pages/events/sub71.png';

import main8 from '../assets/pages/events/main8.png';
import sub81 from '../assets/pages/events/sub81.png';
import sub82 from '../assets/pages/events/sub82.png';
import sub83 from '../assets/pages/events/sub83.png';
import sub84 from '../assets/pages/events/sub84.png';

export const eventsData = [
    {
        id: 1,
        main: main1,
        sub: [sub11, sub12, sub13, sub14, sub15],
        title: 'Game Connection Europe 2022',
        descriptions: ["Hosting three Game Connection Online, the Game Connection Europe was held in Paris for 2 days this year. Game Connection Europe is one of the gaming industry's international events, offering a chance for businesses, developers, and investors to connect and communicate. Being one of the top game development companies, Alta Tech Mark would never miss a chance to connect with 1,500+ senior decision-makers, and 750+ companies from around 60+ countries. That we got with Game Connection 2022.", "The team of Alta Tech Mark presented innovative gaming solutions enriched and enhanced with the latest technologies and trends, such as Metaverse, NFT marketplace and many more with higher. We, as the team of Alta Tech Mark, are grateful to be part of this global technical event where we got a chance to enhance our global network by building new connections with attendees. The game connection 2022 was one of the glorious events of Alta Tech Mark that offered lots of acknowledgement, outstanding responses and incredible attendee visits. We would love to meet you again next year."],
        month: "NOVEMBER",
        year: '2022',
        location: 'Paris, France'
    },
    {
        id: 2,
        main: main2,
        sub: [sub21, sub22, sub23, sub24, sub25, sub26, sub27],
        title: 'GITEX Technology Week 2022',
        descriptions: ["One of the largest tech events of the year GITEX global 2022 came to end on the 14th of October 2022. This 5-day tech event was held at the Dubai World Trade centre. Not breaking the tradition, the team of Alta Tech Mark participated in this tech event again this year. Along with exhibiting the web and app development solutions enriched with Artificial Intelligence, Big Data, Blockchain, Cloud, IoT, and AR/VR; this year we showcased our expertise in NFT marketplace development and Metaverse development as well.", "At the GITEX Global 2022, we got a chance to discuss innovative ideas and business growth using digital innovation with our visitors. The GITEX global 2022 offered the team of Alta Tech Mark a chance to discuss the tech revolution, it's future and potential opportunities for tech innovation for the world tomorrow with global visitors. We are overwhelmed with the response and visits we have gotten through the event. If you have missed a chance to meet us; we will be glad to see you at GITEX Global 2023. Let's meet again."],
        month: "OCTOBER",
        year: '2022',
        location: 'Dubai, UAE'
    },
    {
        id: 3,
        main: main3,
        sub: [sub31, sub32, sub33, sub34, sub35],
        title: 'GITEX Technology Week 2021',
        descriptions: ["The largest information and communication technology trade event, GITEX Global 2021 is over. The event was held in Dubai World Trade Centre between 17th to 21st October. Alta Tech Mark participated in the event where we represented our strength and expertise in the tech industry. We had received marvellous responses from entrepreneurs, visitors, and investors from different fields and countries. We have showcased our efficient services and latest technologies, including web and app development, custom software development, Artificial Intelligence, Big Data, Blockchain, Cloud, IoT, AR/VR, and much more. We thank you all for valuable conversions and businesses and are eagerly excited to be part of the GITEX technology week in 2022. Get a glimpse of our unforgettable experience at GITEX Technology Week 2021."],
        month: "OCTOBER",
        year: '2021',
        location: 'Dubai, UAE'
    },
    {
        id: 4,
        main: main4,
        sub: [sub41, sub42, sub43, sub44, sub45],
        title: 'CES 2020',
        descriptions: ["CES 2020 is over! It was one of the biggest trade shows held in Las Vegas, NV between 7th to 10th Jan 2020 attended by businesses around the world to showcase the latest tech innovations and their business services. For the first time, Alta Tech Mark participated in this tech event and had an amazing response from the visitors, entrepreneurs, and investors from different countries. Our team showcased the latest technologies and services offered by us like Web, Mobile Apps, Artificial Intelligence, IoT, Blockchain, AR/VR and many more. With an overwhelming response from the audience, we're excited to attend CES 2021. Have a Look at the glimpse of CES 2020!"],
        month: "JANUARY",
        year: '2020',
        location: 'Las Vegas, USA'
    },
    {
        id: 5,
        main: main5,
        sub: [sub51, sub52, sub53, sub54, sub55],
        title: '39th GITEX Technology Week 2019',
        descriptions: ["39th GITEX Technology Week 2019 was held between 6th to 10th October 2019 at Dubai World Trade Centre. As a Top Mobile App Development Company, we participated in this biggest tech events for MENA. Our team was present at our stand in Hall 6, Stand D43 and exhibited the services we offer as well as had some great discussions with Entrepreneurs, business owners and Investors about their ideas of solutions they require for their business growth. Team of Alta Tech Mark presented web and mobile app development services and solutions based on the latest technologies like Blockchain, AI, IoT, AR, VR and many more. We have participated for the past 4 years in this event and it always helps us to promote our business in front of the right audience that grows our business well! We thank all our visitors for amazing conversions and partnerships. See some of the Glimpse of GITEX 2019 in pictures."],
        month: "OCTOBER",
        year: '2019',
        location: 'Dubai, UAE'
    },
    {
        id: 6,
        main: main6,
        sub: [sub61, sub62, sub63, sub64, sub65],
        title: '38th GITEX Technology Week 2018',
        descriptions: ["38th GITEX Technology Week was held from 14th to 18th October 2018 at Dubai World Trade Centre. Alta Tech Mark showcase the creative ideas of the latest technologies and most innovative products that may change the future of the mobile industry. In the 3rd consecutive years, Alta Tech Mark booked stall at Zabeel Hall 1, Stand C1-20 at Dubai World Trade Centre to exhibit our services like mobile app development to Blockchain Development, Web Design & Development, AI and many more. Excited to be the part of 39th Gitex Technology Week next year."],
        month: "OCTOBER",
        year: '2018',
        location: 'Dubai, UAE'
    },
    {
        id: 7,
        main: main7,
        sub: [sub71],
        title: "MWC Americas 2018",
        descriptions: ["Mobile World Congress Americas was held on September 12-14, 2018 at the Los Angeles Convention Center. We showcase our services and most innovative products that may change the future of the mobile industry. Alta Tech Mark booked stand W. 320, West Hall and exhibited their services like mobile app development to Blockchain, AI and many more. With exceptional experience ending with some great discussions at #MWCA2018 Looking forward to be at MWC Los Angeles 2019."],
        month: "SEPTEMBER",
        year: '2018',
        location: 'Los Angeles, CA'
    },
    {
        id: 8,
        main: main8,
        sub: [sub81, sub82, sub83, sub84],
        title: "CeBIT 2018",
        descriptions: ["CeBIT is the largest trade show for IT and digitization, happens in Germany every year. This year CeBIT was held from 11th to 15th June 2018. We participated for the first time to showcase an array of technology and the best tailor-made software for every budget offered by us. We had a great experience with our visitors at stand no.: D29, Hall No: 016."],
        month: "JUNE",
        year: '2018',
        location: 'Hannover, Germany'
    }
]