export const angularJsDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Google maintains the open-source AngularJS JavaScript framework. Its objective is to provide MVC functionality to web-based applications. Developers have great faith in this platform to help them overcome the difficulties of creating single-page applications and improve the features of browser-based apps. Its ability to integrate with MongoDB and React Native gives users the freedom to carefully review the functionality of the project. Its strong MVC design guarantees responsiveness and interaction while streamlining backend website maintenance. Full client-side functionality is achieved by AngularJS using normal DOM and two-way data binding. AngularJS's two-way data binding capability makes it easier to create real-time apps.",
            "Being a top AngularJS development firm, Alta Tech Mark enables companies to create dynamic, scalable apps that run on several platforms by using JavaScript's capability. We provide all-inclusive AngularJS development services while maintaining strict respect to privacy and security guidelines. Utilize the benefits of the AngularJS framework's flexibility, readability, and expressiveness by using bespoke applications created by our skilled AngularJS developers.",
            "Our development team stays up to date on the newest technologies and tools. We take pride in having a group of AngularJS developers who are dedicated to excellence and have extensive understanding of the AngularJS framework."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Features Of AngularJS Development",
        description: "",
        onlyBullets: true,
        data: [
            "Reusable HTML components",
            "REST friendly application framework",
            "MVC Design Pattern",
            "Rich library of codes in JavaScript and HTML",
            "Localization",
            "e2e Testing Environment",
            "Easy synchronization between model and DOM",
            "Allows two-way data binding without wrapping model objects",
            "Superior web templates, simply expanding vocabulary HTML",
            "Custom widgets can be built with the help of directives",
            "Presence of Dependency Injection",
        ]
    },
    {
        id: 3,
        title: "Why Alta Tech Mark is a Perfect Angular Js Development Company for You?",
        description: "Your one-stop shop for all things AngularJS web development is Alta Tech Mark. We provide specialized and flexible AngularJS development services, from conception to completion. You will find professionals at Alta Tech Mark that are skilled in both contemporary technologies and AngularJS development layers. We promise feature-rich, maintainable, and fast websites and online applications.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Advantages with Alta Tech Mark",
        description: "",
        onlyBullets: true,
        data: [
            "Outstandingly Skilled Developers",
            "Diverse modes of involvement",
            "Entire IT solutions at one location",
            "profound knowledge of technology",
            "Object-oriented tactics",
            "quick and reasonably priced delivery",
            "Professional AngularJS Programmers",
            "Complete Openness in Procedures"
        ]
    }
]