import React from 'react';

// assets
import php1 from '../../../../assets/pages/service/WebAppDev/php1.png';
import php2 from '../../../../assets/pages/service/WebAppDev/php2.png';
import { phpDevData } from '../../../../data/services/webAppDevData/phpDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function PhpAppDev() {
    return (
        <ServicesWrapper
            title="PHP DEVOLPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={php1}
        >
            <DevGridWrapper img={php2}>
                <DataMapper data={phpDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
