import React from 'react';

// assets
import ci1 from '../../../../assets/pages/service/WebAppDev/ci1.png';
import ci2 from '../../../../assets/pages/service/WebAppDev/ci2.png';
import { codeIgniterDevData } from '../../../../data/services/webAppDevData/codeIgniterDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function CodeIgniterAppDev() {
    return (
        <ServicesWrapper
            title="CODEIGNITER DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={ci1}
        >
            <DevGridWrapper img={ci2}>
                <DataMapper data={codeIgniterDevData}/>
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
