import React from 'react';

// assets
import hp1 from '../../../../assets/pages/service/iot/hp1.jpeg';
import hp2 from '../../../../assets/pages/service/iot/hp2.png';
import { iotHardProtoDevData } from '../../../../data/services/iotDevData/iotHardProtoDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function IotHardwarePrototyping() {
    return (
        <ServicesWrapper
            title="IoT Hardware Prototyping"
            descriptionCont="The process of exploring and putting plan concepts into early iterations of a finished product is known as Internet of Things prototyping. Development of sensor prototypes is aided by hardware prototype management."
            img={hp1}
        >
            <DevGridWrapper img={hp2}>
                <DataMapper data={iotHardProtoDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
