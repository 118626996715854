import sd3 from '../../../assets/pages/service/salesforceDev/sd3.png';

export const salesforceDevData1 = [
    {
        id: 1,
        title: "",
        description: "The largest and most precious asset in your business is unquestionably your customers. Consequently, it is now crucial to build an efficient CRM system in order to meet their needs. Salesforce's tools and services are intended to transform a company into an enterprise, uniting all customers, suppliers, and employees on a single platform that facilitates increased organizational efficiency. An increasing number of businesses are using Salesforce's cutting-edge solutions, including its cloud-based CRM, to optimize their sales operations.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Principal Advantages of Salesforce Development",
        description: "",
        onlyBullets: true,
        data: [
            "Comprehensive Account Planning",
            "Time Management Done Right",
            "Enhanced Group Cooperation",
            "Enhanced Usability",
            "Access to Information Related to Customers"
        ]
    },
    {
        id: 3,
        title: "",
        descriptions: [
            "As a top Salesforce development firm, we assist companies achieve excellent productivity and greater profits by offering optimal Salesforce development services from planning, creating, and implementing whole Salesforce-based business solutions. Our team of knowledgeable Salesforce developers at Alta Tech Mark guarantees that the solutions provided are very practical, dependable, expandable, and maintained on the Salesforce platform.",
            "Our Salesforce development services revolutionize how companies interact with one another and oversee their partners, staff, customers, and merchandise. Strong Salesforce developer tools such as Workbench, Developer Console, Salesforce Lightning, Force, and Lightning Inspector provide a very strong platform for you to expand your adaptability. When it comes to full Salesforce development services, we adhere to the agile process."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Offerings of Salesforce Development Companies",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Salesforce Programming",
                description: "Whether you are a startup or a big enterprise, an Alta Tech Mark team can assist your business in creating and deploying robust Salesforce-based solutions and reap long-term benefits.",
                data: [
                    "Salesforce Implementation Consulting",
                    "Force.com Development",
                    "Force.com Integration and Migration",
                ],
                noList: true
            },
            {
                id: 2,
                title: "Salesforce Implementation Consulting",
                description: "As a top Salesforce development company, Alta Tech Mark can help you to increase Salesforce functionality to mobile devices and use Salesforce to its highest potential.",
                data: [
                    "Force.com Development",
                    "Force.com Integration and Migration",
                    "Salesforce Mobile Development"
                ],
                noList: true
            },
            {
                id: 3,
                title: "Development of Salesforce AppExchange",
                description: "AppExchange may bring new features to Salesforce or just enhance its current capabilities. You may create unique AppExchange applications using the Salesforce development services offered by Alta Tech Mark.",
                data: [
                    "App Consulting",
                    "App Development",
                    "Lightning Apps",
                ],
                noList: true,
            },
            {
                id: 4,
                title: "Lightning Development for Salesforce",
                description: "Being a top provider of Salesforce consulting services, Alta Tech Mark can assist your business in using Salesforce Lightning to its maximum potential so that your Salesforce deployment is more seamless and comfortable.",
                data: [
                    "Salesforce Lightning Consulting",
                    "Salesforce App Development",
                    "Component Development for Salesforce Lightning"
                ],
                noList: true
            },
            {
                id: 5,
                title: "Connectivity With Outside Resources",
                description: "We assist several companies in integrating this third-party program with their Salesforce Solution so they may operate more productively and actively.",
                data: [
                    "Integration Using Tools",
                    "Custom Integration",
                    "API Development",
                    "Integration App Development"
                ],
                noList: true,
                bulletsPic: sd3
            }
        ]
    },
    {
        id: 5,
        title: "Technical Know-How That Alta Tech Mark Will Provide",
        description: "Composing Apex Triggers and Organizing Them in Bulk Overriding Default Behavior Integrating Yahoo UI/ jQuery Components In Visualforce Inbound & Outbound Email Services Future Methods And Batch Apex Record Sharing Through The Apex Webservice Callouts and Scheduled Tasks for Building Web Services",
        onlyBullets: false,
        data: []
    },
    {
        id: 6,
        title: "Take Your Company To New Heights With Alta Tech Mark",
        description: "With several years of expertise in the Salesforce development business, Alta Tech Mark is a top online and app development firm. We provide top-notch Salesforce development services that have the power to transform your company's operations since we think that creativity is the key to success. Our mission is to revolutionize the way companies interact with one another and with their partners, clients, staff, and merchandise. Our goal is to provide you the finest Salesforce services possible.",
        onlyBullets: true,
        data: [
            "Salesforce Development Expertise",
            "Simplify and Automate Your Sales Procedures",
            "Complete Openness, High-Grade Work That Gets Results",
            "Put Client Satisfaction First",
            "Frequent Display of Work Creative Method",
            "Provide Round-the-Clock Customer Support Channels",
            "Reduce Infrastructure and Maintenance Expenses",
            "Increase Revenue and Optimize Your Net Present Value"
        ]
    }
]