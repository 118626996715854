import React from 'react';

// assets
import profile from '../../../assets/pages/services/formIcons/profile.svg';
import email from '../../../assets/pages/services/formIcons/email.svg';
import phone from '../../../assets/pages/services/formIcons/phone.svg';
import whatsapp from '../../../assets/pages/services/formIcons/whatsapp.svg';
import skype from '../../../assets/pages/services/formIcons/skype.svg';
import location from '../../../assets/pages/services/formIcons/location.svg';
import budget from '../../../assets/pages/services/formIcons/budget.svg';
import msg from '../../../assets/pages/services/formIcons/msg.svg';

// components
import ArrowButton from '../../ui/arrowButton/ArrowButton';

export default function ApplyNowForm(props) {
    return (
        <div className={`tw-grid tw-grid-cols-12 tw-shadow-lg tw-rounded-lg tw-p-[3.12vw] tw-gap-[25px] tw-bg-white ${props.className}`}>
            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={profile} alt="profile" />
                <input type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Name*' />
            </div>

            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={email} alt="icon" />
                <input type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='E-Mail*' />
            </div>

            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={phone} alt="icon" />
                <h1 className='tw-ml-[5px]'>+</h1>
                <input type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='+91' />
                <input type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Contact*' />
            </div>

            {!props.mini &&
                <div className='md:tw-col-span-4 tw-col-span-12 tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]'>
                    <img src={whatsapp} alt="icon" />
                    <input type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Whatsapp' />
                </div>
            }

            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={msg} alt="profile" />
                <input type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Your Age*' />
            </div>

            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={msg} alt="profile" />
                <input type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Years of Experience*' />
            </div>

            <div className='tw-col-span-12 tw-flex tw-flex-col tw-items-start tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]'>
                <h1>Upload your resume</h1>
                <input type="file" className='tw-w-full tw-py-[5px] tw-outline-none' placeholder='Upload your resume*' />
            </div>


            <div className='tw-col-span-12 tw-flex tw-w-full tw-justify-end'>
                <ArrowButton
                    text='Submit'
                    className='tw-bg-darkPurple tw-text-white'
                />
            </div>
        </div>
    )
}
