import nlp3 from '../../../assets/pages/service/aiAndMl/nlp3.png';
import nlp4 from '../../../assets/pages/service/aiAndMl/nlp4.png';

export const nlpData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "The methodology and ideas of natural language processing are being applied to many new language technologies, which is why its usage is growing quickly. A collection of artificial intelligence that enables computers to comprehend natural languages is known as this. The words and phrases used in human communication are processed by a process called natural language processing. You can help with machine translation, handwriting recognition, predictive text assistance, and much more with it. Text analytics and solution services constitute the foundation of natural language processing services, which use information to analyze text in order to execute automation and recognition. Words have the ability to give enterprises strength and provide value.",
            "By using natural language processing, robots can understand how people communicate—not only with words, but also with ideas and how they relate to one other to create meaning. The way that NLP's algorithmic libraries are organized has allowed for the creation of building blocks and the development of real-time applications for syntactic and grammatical analysis approaches.",
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Natural language processing advantages include:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Deep Awareness",
                description: "Machines can communicate thanks to natural language, which gives them profound insights and produces useful data. Real-time analytics are developed by the deep understanding."
            },
            {
                id: 2,
                title: "Analytical Remedies",
                description: "It is the answer to lucrative sources and analytics. In addition to producing effective, insightful data, natural language processing facilitates linguistic communication."
            },
            {
                id: 3,
                title: "Complete Analysis",
                description: "Complete solutions are offered by the Natural Language Processing service. Identification, prediction, and much more are all provided by it, and it will assist you expand your company."
            },
            {
                id: 4,
                title: "Enhance Patterns of Behavior and Intent",
                description: "The fact that natural language processing maximizes purpose and behavior patterns is one of its many great qualities. Analytics and prediction are carried out using this approach."
            },
            {
                id: 5,
                title: "Solution for Chatbots",
                description: "A chatbot's primary function is to facilitate communication, and it extracts the essence of performance with the use of natural language processing. It complements one another and offers exceptional ChatBot solutions.",
                pic: nlp3
            }
        ]
    },
    {
        id: 3,
        title: "NLP Solution Available:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Text Classification",
                description: "It's a traditional method for classifying text. With machine learning, the jobs are now very simple, rapid, and provide the greatest results."
            },
            {
                id: 2,
                title: "Sentiment Survey",
                description: "Human approach, viewpoint, perspective, and other characteristics are shown via sentiment analysis. Without a sentiment analysis solution, natural language processing is incomplete and each stage of the process is customized with human behavior."
            },
            {
                id: 3,
                title: "Classification of Intent",
                description: "For the purpose of developing and training chatbots, virtual assistants, and other systems that facilitate human-machine interaction, the Intent Classification service is essential. It is a technique to help companies appropriately understand the objective of the discussion."
            },
            {
                id: 4,
                title: "Entity Identification",
                description: "A method for recognizing and categorizing different textual elements is called entity recognition. Any text, including news articles, online pages, and social media posts, may have data extracted from it. It is now a necessary step for many firms."
            },
            {
                id: 5,
                title: "Advancement",
                description: "The creation of the codebase is required after identification. We build the codebase for data processing and model practice in this stage. A critical stage in the natural language processing process is development.",
                pic: nlp4
            },
            {
                id: 6,
                title: "Data Division",
                description: "Validation, experimentation, and training are all taken into account when splitting data. Test data enhances the model's performance, while validation data gets the model ready for unforeseen tasks. A reliable and sturdy model is produced by this process."
            },
            {
                id: 7,
                title: "Final Assessment",
                description: "Before being deployed, the various models and strategies are reviewed for the ultimate test. Performances are enhanced by the models, and a thorough inspection guarantees everything runs well."
            },
            {
                id: 8,
                title: "Dispatching",
                description: "Deployment is the last stage of the natural language processing process. The project is designed to offer correct data more quickly and to expand and adapt to changing company demands."
            }
        ]
    },
    {
        id: 5,
        title: "When it comes to natural language processing, why use Alta Tech Mark?",
        descriptions: [
            "You may get assistance with any aspect of natural language processing from our knowledgeable staff. Our service is dynamic and comprehensive, which greatly improves our customers' understanding of NLP framework. Our group provides the necessary knowledge to help NLP become more flexible and effective. After that, it will extract the unstructured data and process each piece of information to get top-notch results.",
            "We serve several sectors with our Natural Language Processing services. Our professionals understand the finest practices in the industry and know how to address your company challenge and make your ideas a reality. Alta Tech Mark prioritizes scalability and reliability, therefore we'll provide simple, safe transaction or process records."
        ],
        onlyBullets: false,
        data: []
    }
]