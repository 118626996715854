import gatheringRequirements from '../assets/processes/gatheringRequirements.svg';
import uiUxDesign from '../assets/processes/uiUxDesign.svg';
import prototype from '../assets/processes/prototype.svg';
import development from '../assets/processes/development.svg';
import qualityAssurance from '../assets/processes/qualityAssurance.svg';
import deployment from '../assets/processes/deployment.svg';
import support from '../assets/processes/support.svg';

export const processesData = [
    {
        id: 1,
        src: gatheringRequirements,
        title: "Gathering Requirements",
        description: "Identify. Capture. Categorize"
    },
    {
        id: 2,
        src: uiUxDesign,
        title: "UI/UX Design",
        description: "Understand. Research. Design"
    },
    {
        id: 3,
        src: prototype,
        title: "Prototype",
        description: "Representation of actual Product"
    },
    {
        id: 4,
        src: development,
        title: "Development",
        description: "Transparency, Innovations"
    },
    {
        id: 5,
        src: qualityAssurance,
        title: "Quality Assurance",
        description: "Plan, implement, check, adjust"
    },
    {
        id: 6,
        src: deployment,
        title: "Deployment",
        description: "Understand. Research. Design"
    },
    {
        id: 7,
        src: support,
        title: "Support & Maintenance",
        description: "Understand. Research. Design"
    }
]