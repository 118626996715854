import React from 'react';

// assets
import ur1 from '../../../../assets/pages/service/gameDev/ur1.jpeg';
import ur2 from '../../../../assets/pages/service/gameDev/ur2.png';
import { unrealDevData } from '../../../../data/services/gameDevData/unrealDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function UnrealEngineDev() {
    return (
        <ServicesWrapper
            title="UNREAL ENGINE"
            descriptionCont="Employing game developers from a reputable firm that specializes in game creation may help you and every other player take your gameplay to the next level."
            img={ur1}
        >
            <DevGridWrapper img={ur2}>
                <DataMapper data={unrealDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
