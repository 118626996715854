import React from 'react';
import { useSelector } from 'react-redux';

// components
// import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb';
// import AppDevFront from '../../../../components/services/appDevFront/AppDevFront';
// import Brands from '../../../../components/home/brands/Brands';
// import TechWeWorkOn from '../../../../components/services/techWeWorkOn/TechWeWorkOn';
// import Processes from '../../../../components/home/processes/Processes';
// import LatestBlogs from '../../../../components/home/latestBlogs/LatestBlogs';
// import Podcasts from '../../../../components/home/podcasts/Podcasts';
// import FreeQuoteFormBg from '../../../../components/services/freeQuoteFormBg/FreeQuoteFormBg';
// import Work from '../../../../components/home/work/Work';
// import SocialMedia from '../../../../components/home/socialMedia/SocialMedia';
import HeroTitleImage from './../../../heroTitleImage/HeroTitleImage';
import ContactUs from '../../../contactUs/ContactUs';
import ContactUsNoForm from '../../../contactUs/ContactUsNoForm';

export default function ServicesWrapper(props) {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-tracking-wide tw-flex tw-flex-col'>
            <div style={{
                backgroundImage: `url(${props.img})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <HeroTitleImage
                    title={props.title}
                    descriptionBold={props.descriptionBold}
                    descriptionCont={props.descriptionCont}
                    inWrapper={true}
                />
            </div>

            {/* <Brands /> */}

            {props.children}

            {/* <TechWeWorkOn /> */}

            {/* <Processes /> */}

            {/* <LatestBlogs /> */}

            {/* <Podcasts /> */}

            {/* <FreeQuoteFormBg /> */}

            {/* <Work /> */}

            {/* <SocialMedia /> */}
            {screenWidth < 768 ?
                <ContactUsNoForm /> :
                <ContactUs />
            }
        </div>
    )
}
