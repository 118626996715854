import React from 'react';

// assets
import vm1 from '../../../../assets/pages/service/ecommerceDev/vm1.jpeg';
import vm2 from '../../../../assets/pages/service/ecommerceDev/vm2.png';
import vm3 from '../../../../assets/pages/service/ecommerceDev/vm3.png';
import { virtueMartDevData } from '../../../../data/services/ecommerceDevData/virtueMartDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function VirtueMartDev() {
    return (
        <ServicesWrapper
            title="ECOMMERCE DEVELOPMENT VIRTUEMART"
            descriptionCont="Our E-Commerce website development professionals are experts. Alta Tech Mark developers provide high-quality, fast E-Commerce products. Our skilled staff develops E-Commerce websites utilizing cutting-edge, trustworthy technology. Magento, Shopify, BigCommerce, WooCommerce, and others"
            img={vm1}
        >
            <DevGridWrapper images={[vm2, vm3]}>
                <DataMapper data={virtueMartDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
