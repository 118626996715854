import sh3 from '../../../assets/pages/service/iot/sh3.png';
import sh4 from '../../../assets/pages/service/iot/sh4.png';

export const smartHomeDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Smart Home is the element of IoT solution and one of the most used for the home and businesses. Smart homes are virtual assistants that manage and operate through the Internet. It is the advanced technology that has transformed the home and made every day more convenient. Smart home technology enhances the automation of your home and enables you to control every aspect of the home with the help of the Internet of Things (IoT). Smart home applications include light, door, security, applications, and much more. Smart home applications are often used to command and control household activities.",
            "One of the best methods to use home automation is controlling applications, home security, and more. Home automation is like your personal assistant who reminds you, searches for you, handles, and all. It is one of the biggest markets of the Internet of Things, and the process is less complicated and easy to understand. It saves time and is cost-effective, and features are significant for IoT applications. The new technology is an adequate method for the home and businesses."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "What Is Smart Home - Home Automation?",
        descriptions: [
            "Smart home and home automation enable you to connect smart devices with a remotely controllable network. It coordinates devices to control and handle home applications. It is a source of communication between humans and machines with the help of Internet of Things networks. Home automation manages your smart home with applications like light, security, and all. With the help of WIFI or the Internet, home automation operates the entire house. The smart home integrates all applications into one and makes the network and system easy to function. One command and everything is done; it is that easy. However, the behind the scenes is not that system. To keep the operation seamless, the developers work hard and ensure that functions are controlled and managed.",
            "Home automation and smart home are two different concepts, although they are frequently used reciprocally. It is a 24/7 facility that provides you all services you have opted for in the smart home. The automaton connects through the Internet by applying different communication protocols. Several devices have sensors that observe changes, measurements, and more. The device has relevant information which helps to monitor the system. The network is controlled remotely like Siri or Alex, and many more. It also gains surrounding data and information. The device modifies according to the command preferred and situations. All mechanisms like minoritization, switch on lights, and others."
        ],
        onlyBullets: false,
        data: [],
        sectionPic: sh3
    },
    {
        id: 3,
        title: "Factors of Smart Home and Home Automation",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Energy System",
                description: "Smart home-home automation provides you with splendid energy and lighting systems. The system is quite valuable for the consumer as with one command or guides over the voice assistant light or energy system obtained. The energy and lighting system empowers the IoT devices and network, and IoT helps save energy, time, and cost."
            },
            {
                id: 2,
                title: "Enterprise IoT Systems",
                description: "Enterprise IoT systems have taken resources, capital, and other factors to a different level. The network has services and solutions on a large scale. It reduces the risk in the system and warns the system about threats and cyber attacks. The systems are implemented in such a way that they notify you about the near menace. In the enterprise, IoT is in the design of sensors, tablets, and connected SaaS platforms."
            },
            {
                id: 3,
                title: "FoodTech",
                description: "Many times, we kept food and completely forgot about it. For such situations, Food tech powered by IoT comes to the rescue. The technology helps keep a record of food, and it reminds you about the food and all. It reduces waste management, and record helps to save necessary and unnecessary food items. With a command, users can discover multiple recipes."
            },
            {
                id: 4,
                title: "Healthcare",
                description: "Healthcare and smart automation go hand-in-hand. It helps to remind about the healthy charts to follow; Healthcare is associated with IoT. The system helps to give you accurate results in the form of a report or anything. Healthcare is a vivid concept, and the smart home has a different approach towards it."
            },
            {
                id: 5,
                title: "Smart Warehousing",
                description: "A smart warehouse system is to provide companies real-time visibility. It allows the optimization of processes and the capability to make immediate changes to increase operation speed. Smart warehouses can also eliminate the majority of human error and enhance safety and security with ease. It helps to keep track of all management activities occurring within home automation.",
                pic: sh4
            }
        ]
    },
    {
        id: 4,
        title: "Why Choose Alta Tech Mark For Smart Home - Home Automation?",
        description: "We are the leading Internet of Things company, which provides brilliant smart home and home automation. Our professionals who hold expertise have years of experience in it and are proficient in it. We have the latest and intelligent tools and technology that helps to make home automation and smart homes more efficient. With the pool of experience, our experts have the best solution and service to provide for home automation and smart home. We help to maintain and enhance the smart home and home automation system.",
        onlyBullets: false,
        data: []
    }
]