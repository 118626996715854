import React, { useState, useRef } from 'react';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

// components
import HeaderText from '../../headerText/HeaderText';

// assets
import { mobileData, frontendData, backendData, cmsData, designData } from '../../../data/services/mobileAppDevData/iphoneDevData';
import mobileSwiper from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobileSwiper.svg';
import frontendSwiper from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/frontendSwiper.svg';
import backendSwiper from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backendSwiper.svg';
import cmsSwiper from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cmsSwiper.svg';
import designSwiper from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/designSwiper.svg';

export default function TechWeWorkOn() {
    // locals
    const swiperIcons = [mobileSwiper, frontendSwiper, backendSwiper, cmsSwiper, designSwiper];
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);

    // functions
    const handleSwiper = index => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    }

    return (
        <div className='tw-py-[4vw] tw-px-[9.115vw]'>
            <HeaderText
                colorTitleStart="OUR"
                title="CORE TECHNOLOGIES"
                description="Discover the Core Technologies We Specialize In"
                className='tw-text-center tw-mb-8'
            />

            <div className='tw-grid tw-grid-cols-2'>
                <div className='tw-col-span-2 tw-w-[50%] tw-mx-auto tw-flex tw-items-center tw-justify-center'>
                    <h1 className={`tw-text-purple tw-w-[50%] tw-text-center tw-cursor-pointer md:tw-text-2xl tw-text-[3.376vw] ${activeIndex === 0 ? "tw-font-semibold tw-border-b-[2px]" : "tw-border-t-[1px] tw-border-x-[1px] tw-border-transparent hover:tw-border-slate-200"}`} onClick={() => handleSwiper(0)}>Mobile</h1>
                    <h1 className={`tw-text-purple tw-w-[50%] tw-text-center tw-cursor-pointer md:tw-text-2xl tw-text-[3.376vw] ${activeIndex === 1 ? "tw-font-semibold tw-border-b-[2px]" : "tw-border-t-[1px] tw-border-x-[1px] tw-border-transparent hover:tw-border-slate-200"}`} onClick={() => handleSwiper(1)}>Frontend</h1>
                    <h1 className={`tw-text-purple tw-w-[50%] tw-text-center tw-cursor-pointer md:tw-text-2xl tw-text-[3.376vw] ${activeIndex === 2 ? "tw-font-semibold tw-border-b-[2px]" : "tw-border-t-[1px] tw-border-x-[1px] tw-border-transparent hover:tw-border-slate-200"}`} onClick={() => handleSwiper(2)}>Backend</h1>
                    <h1 className={`tw-text-purple tw-w-[50%] tw-text-center tw-cursor-pointer md:tw-text-2xl tw-text-[3.376vw] ${activeIndex === 3 ? "tw-font-semibold tw-border-b-[2px]" : "tw-border-t-[1px] tw-border-x-[1px] tw-border-transparent hover:tw-border-slate-200"}`} onClick={() => handleSwiper(3)}>CMS</h1>
                    <h1 className={`tw-text-purple tw-w-[50%] tw-text-center tw-cursor-pointer md:tw-text-2xl tw-text-[3.376vw] ${activeIndex === 4 ? "tw-font-semibold tw-border-b-[2px]" : "tw-border-t-[1px] tw-border-x-[1px] tw-border-transparent hover:tw-border-slate-200"}`} onClick={() => handleSwiper(4)}>Design</h1>
                </div>
                <div className='tw-hidden md:tw-col-span-1 tw-w-[50%] md:tw-w-[40%] xl:tw-w-[20%] tw-justify-self-end'>
                    <Swiper
                        ref={swiperRef}
                        style={{
                            "--swiper-pagination-color": "#9065FD",
                            "--swiper-pagination-bullet-inactive-color": "#999999",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "10px",
                        }}
                        modules={[Pagination, Navigation, EffectFade]}
                        effect={'fade'}
                        spaceBetween={100}
                        slidesPerView={1}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        onSlideChange={(swiperData) => {
                            setActiveIndex(swiperData.activeIndex);
                        }}
                    >
                        {swiperIcons.map((swiperIcon, index) =>
                            <SwiperSlide key={index}>
                                <div className="tw-grid tw-place-items-center tw-h-[100px]">
                                    <div className='tw-h-[45%]'>
                                        <img src={swiperIcon} alt="mobile" className='' />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <div className='tw-col-span-3 tw-mt-12'>
                    {activeIndex === 0 &&
                        <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-3 tw-gap-y-[50px] tw-animate-appear'>
                            {mobileData.map(tech =>
                                <div key={tech.id} className='tw-flex tw-flex-col tw-items-center tw-gap-y-[16px]'>
                                    <img src={tech.src} alt="tech" className='tw-w-[53px] tw-h-[53px]' />
                                    <h1 className='tw-text-center'>{tech.title}</h1>
                                </div>
                            )}
                        </div>
                    }
                    {activeIndex === 1 &&
                        <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-3 tw-gap-y-[50px] tw-animate-appear'>
                            {frontendData.map(tech =>
                                <div key={tech.id} className='tw-flex tw-flex-col tw-items-center tw-gap-y-[16px]'>
                                    <img src={tech.src} alt="tech" className='tw-w-[53px] tw-h-[53px]' />
                                    <h1 className='tw-text-center'>{tech.title}</h1>
                                </div>
                            )}
                        </div>
                    }
                    {activeIndex === 2 &&
                        <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-3 tw-gap-y-[50px] tw-animate-appear'>
                            {backendData.map(tech =>
                                <div key={tech.id} className='tw-flex tw-flex-col tw-items-center tw-gap-y-[16px]'>
                                    <img src={tech.src} alt="tech" className='tw-w-[53px] tw-h-[53px]' />
                                    <h1 className='tw-text-center'>{tech.title}</h1>
                                </div>
                            )}
                        </div>
                    }
                    {activeIndex === 3 &&
                        <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-3 tw-gap-y-[50px] tw-animate-appear'>
                            {cmsData.map(tech =>
                                <div key={tech.id} className='tw-flex tw-flex-col tw-items-center tw-gap-y-[16px]'>
                                    <img src={tech.src} alt="tech" className='tw-w-[53px] tw-h-[53px]' />
                                    <h1 className='tw-text-center'>{tech.title}</h1>
                                </div>
                            )}
                        </div>
                    }
                    {activeIndex === 4 &&
                        <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-3 tw-gap-y-[50px] tw-animate-appear'>
                            {designData.map(tech =>
                                <div key={tech.id} className='tw-flex tw-flex-col tw-items-center tw-gap-y-[16px]'>
                                    <img src={tech.src} alt="tech" className='tw-w-[53px] tw-h-[53px]' />
                                    <h1 className='tw-text-center'>{tech.title}</h1>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
