import img2 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/2.png';
import img3 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/3.png';
import img4 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/4.png';
import img5 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/5.png';
import android from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/android.svg';
import ios from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/ios.svg';
import flutter from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/flutter.svg';
import reactNative from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/reactNative.svg';
import kotlin from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/kotlin.svg';
import ionic from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/ionic.svg';
import swift from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/swift.svg';
import xamarin from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/xamarin.svg';
import ipad from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/ipad.svg';
import windows from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/windows.svg';
import phoneGap from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/phoneGap.svg';
import augmentedReality from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/augmentedReality.svg';
import virtualReality from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/virtualReality.svg';
import beacon from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/beacon.svg';
import wearables from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/wearables.svg';
import appceleratorTitanium from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/mobile/appceleratorTitanium.svg';
import senchatouch from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/frontend/senchatouch.svg';
import typescript from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/frontend/typescript.svg';
import angularjs from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/frontend/angularjs.svg';
import golang from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/frontend/golang.svg';
import javascript from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/frontend/javascript.svg';
import reactjs from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/frontend/reactjs.svg';
import php from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/php.svg';
import yii from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/yii.svg';
import cakephp from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/cakephp.svg';
import java from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/java.svg';
import windowsDotnet from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/windowsDotnet.svg';
import csharp from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/csharp.svg';
import rubyOnRails from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/rubyOnRails.svg';
import codeigniter from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/codeigniter.svg';
import python from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/python.svg';
import laravel from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/laravel.svg';
import scala from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/scala.svg';
import symfony from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/symfony.svg';
import zend from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/zend.svg';
import nodejs from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/nodejs.svg';
import django from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/django.svg';
import graphqlApi from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/graphqlApi.svg';
import parse from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/backend/parse.svg';
import wordpress from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/wordpress.svg';
import magento from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/magento.svg';
import prestashop from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/prestashop.svg';
import joomla from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/joomla.svg';
import bigCommerce from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/bigcommerce.svg';
import drupal from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/drupal.svg';
import uberCart from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/ubercart.svg';
import virtueMart from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/virtuemart.svg';
import csCart from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/csCart.svg';
import siteCore from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/cms/sitecore.svg';
import i2 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/i2.png';
import i3 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/i3.png';
import i4 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/i4.png';
import i5 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/i5.png';
import i6 from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/i6.png';
import figma from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/design/figma.svg';
import sketch from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/design/sketch.svg';
import miro from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/design/miro.svg';
import adobeXd from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/design/adobeXd.svg';
import adobeIllustrator from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/design/adobeIllustrator.svg';
import adobePhotoshop from '../../../assets/pages/service/mobileAppDev/iphoneAppDev/techWeWorkOn/design/adobePhotoshop.svg';

export const iphoneDevData1 = [
    {
        id: 1,
        title: "Power Up Your Business Through Service Offering of iOS App Development Company",
        text: "Being a Top iPhone app development company we offer tons of app development services. Browse through some of our iPhone app development services.",
        image: img2,
        children: [
            {
                id: 1,
                title: "Full-Stack iOS App Development",
                description: "Get the experts on your side starting from the app development planning stage to get secure, feature-rich and user-friendly iPhone apps that can work well with iOS smartphones, wearables, Macs or even on TV applications."
            },
            {
                id: 2,
                title: "Enterprise iPhone Application Development",
                description: "We have a team of creative designers who can create stunning UI/UX designs for your iOS apps. We can also help you with the app prototyping and wire framing."
            },
            {
                id: 3,
                title: "iPhone App Migration & Revamp",
                description: "iPhone app developers at Alta Tech Mark have the expertise to migrate your existing mobile application to the iOS operating system and even upgrade your existing iOS mobile application to make them pertinent to the latest market trends."
            },
            {
                id: 4,
                title: "iPhone UI/UX Design",
                description: "Our team of iOS app designers specialize in creating experiences that leave long-lasting impressions on viewers using the latest design trends and technologies to deliver an immersive and memorable user experience."
            },
            {
                id: 5,
                title: "iPhone App Development Consultation",
                description: "Being a leading iPhone app development company, we have industry experts on our team that you can reach out to to get accurate and satisfying guidance for iPhone app development."
            },
            {
                id: 6,
                title: "API Development and Integration",
                description: "Our iOS app development experts will do everything in their power to develop amazing solutions whether it could be the integration of 3rd party APIs or developing customized iPhone API for your complex app development requirements."
            },
        ]
    },
    {
        id: 2,
        title: "Exceptional App Development Process That Makes Us Unique iPhone App Development Company",
        text: "The app development process that we follow to build amazing iOS app solutions for you.",
        image: img3,
        children: [
            {
                id: 1,
                title: "Requirement Gathering",
                description: "We make sure we have every required detail with us before going ahead with the development process. We get in touch with our clients to gather requirements, resources, and information about the app development project."
            },
            {
                id: 2,
                title: "iOS App Design",
                description: "We have in-house iPhone app designers that can create eye-catchy and user-friendly mobile app designs with the latest tools of designing to make it stand out from your competitors. Our designers get in touch with clients to understand their views on app design."
            },
            {
                id: 3,
                title: "iPhone App Development",
                description: "The iPhone app developers will come into the picture over here to develop a working model of your dream project using the latest tools and technologies with transparency."
            },
            {
                id: 4,
                title: "Quality Assurance Testing",
                description: "Even though we trust our iPhone app development team more than anything, our app tester will make sure you get the running mobile app with no bugs or drawbacks that can affect the user experience for your target audience."
            },
            {
                id: 5,
                title: "App Deployment",
                description: "Once our app developers show the green flag, we ask clients to conclude the review process. After making the necessary changes we will help you to launch your mobile app on Apple App Store following all the necessary guidelines."
            },
            {
                id: 6,
                title: "Support & Maintenance",
                description: "Our mobile app developers will be there for you to provide all the support and maintenance you need and make amendments to the app development solution considering the real users' reviews. Get all the support you need."
            },
            {
                id: 7,
                title: "Feature-rich User Experience",
                description: "You and your users matter the most to us. Our iPhone developers make sure that your target audience gets the smoothest user experience keeping your business objective in the center."
            },
            {
                id: 8,
                title: "Confidentiality & Authenticity",
                description: "The privacy of our clients is one of our ethics that we never take for granted. When it comes to custom iOS app development, we never use the same code twice. We make sure to deliver custom app development code to protect the security & privacy of the coding structure."
            },
            {
                id: 9,
                title: "App Maintenance & After-sale Support",
                description: "Our app development process does not end with the deployment, we give app maintenance and after-sale support just the same importance as the development. Our iOS app developers will make sure to enhance your growth and efficiency with our solutions."
            },
            {
                id: 10,
                title: "Understandable Coding Structure",
                description: "Even though the app development coding structure is our work, we make sure it's in a clean and understandable and accessible manner for other app developers who put their hands in the code for further updates and enhancement."
            },
            {
                id: 11,
                title: "Pocket-Friendly iPhone development Solutions",
                description: "App developers at Alta Tech Mark make sure you get the worth of your investments in iPhone app development. We offer competitive and pocket-friendly solutions integrated with the latest technologies and features that can help you boost your ROI."
            },
            {
                id: 12,
                title: "Always Learning Approach",
                description: "We are well-known for delivering solutions integrated and enhanced with the latest trends and technologies as our team of iPhone developers keep learning the new and upcoming iPhone app development tech stacks no matter how experienced they are."
            }
        ]
    }
]

export const iphoneDevData2 = [
    {
        id: 1,
        title: "Technical Expertise Our iPhone App Developers Possess",
        text: "Keeping rooted with traditional methods we master all the latest technologies to deliver the most efficient iPhone app solutions.",
        image: img4,
        language: ['Swift', 'Objective-C'],
        frameworks: ['CoCoa Controls', 'Cocos2D', 'Cocoa Touch', 'Native SDK'],
        platforms: ['MAC OS Maverick', 'macOS Sierra', 'Yosemite'],
        devTools: ['XCODE'],
        database: ['SQLite']
    },
    {
        id: 2,
        title: "Industries Our iOS Developers Covers",
        text: "Just some of the industry verticals our iPhone app developers serve efficiently utilizing their development experience and expertise.",
        image: img5,
        children: ['Retail, Ecommerce', 'Real Estate', 'Education & e-learning', 'Travel & Hospitality', 'Healthcare & Fitness', 'Food & Restaurant', 'Logistics & Distribution', 'On-Demand Solutions', 'Social Networking', 'Gaming']
    }
]

export const mobileData = [
    { id: 1, title: "Android", src: android },
    { id: 2, title: "iOS", src: ios },
    { id: 3, title: "Flutter", src: flutter },
    { id: 4, title: "React Native", src: reactNative },
    { id: 5, title: "Kotlin", src: kotlin },
    { id: 6, title: "Ionic", src: ionic },
    { id: 7, title: "Swift", src: swift },
    { id: 8, title: "Xamarin", src: xamarin },
    { id: 9, title: "iPad", src: ipad },
    { id: 10, title: "Windows", src: windows },
    { id: 11, title: "PhoneGap", src: phoneGap },
    { id: 12, title: "Augmented Reality", src: augmentedReality },
    { id: 13, title: "Virtual Reality", src: virtualReality },
    { id: 14, title: "Beacon", src: beacon },
    { id: 15, title: "Wearables", src: wearables },
    { id: 16, title: "Appcelerator Titanium", src: appceleratorTitanium },
]

export const frontendData = [
    { id: 1, title: "SenchaTouch", src: senchatouch },
    { id: 2, title: "TypeScript", src: typescript },
    { id: 3, title: "AngularJS", src: angularjs },
    { id: 4, title: "Golang", src: golang },
    { id: 5, title: "JavaScript", src: javascript },
    { id: 6, title: "ReactJS", src: reactjs },
]

export const backendData = [
    { id: 1, title: "PHP", src: php },
    { id: 2, title: "Yii", src: yii },
    { id: 3, title: "CakePHP", src: cakephp },
    { id: 4, title: "Java", src: java },
    { id: 5, title: "Windows NET", src: windowsDotnet },
    { id: 6, title: "C Sharp", src: csharp },
    { id: 7, title: "Ruby On Rails", src: rubyOnRails },
    { id: 8, title: "CodeIgniter", src: codeigniter },
    { id: 9, title: "Python", src: python },
    { id: 10, title: "Laravel", src: laravel },
    { id: 11, title: "Scala", src: scala },
    { id: 12, title: "Symfony", src: symfony },
    { id: 13, title: "Zend", src: zend },
    { id: 14, title: "NodeJS", src: nodejs },
    { id: 15, title: "Django", src: django },
    { id: 16, title: "GraphQL API", src: graphqlApi },
    { id: 17, title: "Parse", src: parse },
]

export const cmsData = [
    { id: 1, title: "WordPress", src: wordpress },
    { id: 2, title: "Magento", src: magento },
    { id: 3, title: "PrestaShop", src: prestashop },
    { id: 4, title: "Joomla", src: joomla },
    { id: 5, title: "BigCommerce", src: bigCommerce },
    { id: 6, title: "Drupal", src: drupal },
    { id: 7, title: "UberCart", src: uberCart },
    { id: 8, title: "VirtueMart", src: virtueMart },
    { id: 9, title: "CS Cart", src: csCart },
    { id: 10, title: "SiteCore", src: siteCore },
]

export const designData = [
    { id: 1, title: "Figma", src: figma },
    { id: 2, title: "Sketch", src: sketch },
    { id: 3, title: "Miro", src: miro },
    { id: 4, title: "Adobe XD", src: adobeXd },
    { id: 5, title: "Adobe Illustrator", src: adobeIllustrator },
    { id: 6, title: "Adobe Photoshop", src: adobePhotoshop },
]

export const iphoneDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "iPhone applications are just as popular as the iPhone itself. Although there are a ton of iPhone applications available, creating an app requires much more than just a basic understanding of coding. Top iPhone app development businesses like Alta Tech Mark can provide you with the help you need to create an effective iPhone app that will operate very well for your target audience.",
            "In addition to having experience with app development, Alta Tech Mark's app development team focuses on creating unique iOS mobile apps that fit your specifications and complement your industrial market, setting you apart from the competition. Our team of iPhone app developers is skilled enough to create apps that work flawlessly on the iPad, iPhone, Apple TV, Apple Watch, and other gadgets.",
            "Boost Your Company with the Services Provided by an iOS App Development Firm We provide a wide range of app development services as a leading iPhone app development business. Look over a few of the services we provide for developing iPhone apps."
        ],
        onlyBullets: false,
        sectionPic: i2,
        sectionPicRow: true,
        data: []
    },
    {
        id: 2,
        title: "",
        description: "",
        onlyBullets: false,
        grid3Display: true,
        data: [
            {
                id: 1,
                title: "iOS application development firm",
                description: "Full-Stack iOS App Development: Ensure user-friendly iPhone apps that function flawlessly on iOS devices, wearables, Macs, and TV applications by enlisting the help of specialists from the beginning of the app development planning process.",
                descPic: i3,
                descPicOrder: 1
            },
            {
                id: 2,
                title: "Business iPhone Application Development",
                description: "Do you want to turn your concept for a functioning iPhone mobile app into a digital solution? Speak with us. We have years of experience creating iPhone applications with a business focus.",
                descPic: i4,
                descPicOrder: 3
            },
            {
                id: 3,
                title: "iPhone App Revamp & Migration",
                description: "Alta Tech Mark's iPhone app developers are skilled in updating your present iOS application to reflect current market trends and migrating your application from another platform to the iOS operating system."
            },
            {
                id: 4,
                title: "iPhone UI/UX Design",
                description: "By using the newest design trends and technological advancements, our team of iOS app designers is famous for producing unforgettable experiences."
            },
            {
                id: 5,
                title: "iPhone App Development Consultation",
                description: "As a top iPhone app development business, we have professionals with years of experience in the field on staff who can provide precise and perceptive advice on iPhone app development."
            },
            {
                id: 6,
                title: "API Development and Integration",
                description: "Whether it's integrating third-party APIs or creating a bespoke iPhone API suited to your complex app development requirements, our iOS app development maestros provide flawless solutions.",
                descPic: i5,
                descPicOrder: 3
            }
        ]
    },
    {
        id: 3,
        title: "Process we Follow",
        description: "Outstanding App Development Methodology That Sets Us Apart as an Exclusive iPhone App Development Firm",
        onlyBullets: false,
        grid2Display: true,
        sectionPicGrid: i6,
        data: [
            {
                id: 1,
                title: "Requirement Gathering",
                description: "We make sure that your demands are well understood. We work together with our customers to collect data on the app development project needs."
            },
            {
                id: 2,
                title: "iOS App Design",
                description: "The visually striking and user-focused designs are created by our in-house iPhone app designers. To guarantee conformity with their goal, they collaborate closely with clients."
            },
            {
                id: 3,
                title: "iPhone App Development",
                description: "Using the newest tools and technology, our developers bring your vision to life while maintaining transparency all the way through."
            },
            {
                id: 4,
                title: "Quality Assurance Testing",
                description: "We carry out thorough testing to make sure your target audience has a flawless mobile app experience."
            },
            {
                id: 5,
                title: "App Deployment",
                description: "After your app is approved, we help you release it on the Apple App Store while following all the rules."
            },
            {
                id: 6,
                title: "Support & Maintenance",
                description: "We provide post-launch assistance and incremental enhancements grounded on actual user input, standing by our customers."
            },
            {
                id: 7,
                title: "",
                description: "Our Specialties Make Us the Top Choice for iPhone App Development Services"
            },
            {
                id: 8,
                title: "",
                description: "With its creative approach to iPhone app creation, Alta Tech Mark has won praise and revolutionized businesses all over the world. Our iPhone app developers are committed to creating feature-rich, scalable mobile applications for users all around the globe."
            }
        ]
    },
    {
        id: 5,
        title: "",
        description: "",
        onlyBullets: false,
        grid3Display: true,
        data: [
            {
                id: 1,
                title: "Rich in Features User Experience",
                description: "Your company goals and user experience come first."
            },
            {
                id: 2,
                title: "Integrity & Secrecy",
                description: "We respect the privacy of our clients. Our bespoke iOS app development process guarantees the integrity and security of the coding structure by ensuring that every code is distinct."
            },
            {
                id: 3,
                title: "App Upkeep and Post-Sale Support Backing",
                description: "Our dedication goes beyond the act of deployment. Our priorities are efficiency improvement and expansion."
            },
            {
                id: 4,
                title: "Comprehensible Coding Structure",
                description: "We guarantee easy adaptation for next changes with our neat and organized coding."
            },
            {
                id: 5,
                title: "Solutions for Pocket-Friendly iPhone Development",
                description: "We ensure a return on your investment by providing competitive solutions that use cutting-edge technology."
            },
            {
                id: 6,
                title: "Always Learning Approach",
                description: "To keep ahead of market developments, our iPhone developers constantly improve their abilities."
            },
            {
                id: 7,
                title: "Technical Proficiency Our iPhone App Creators Own",
                description: "As much as we like the old-fashioned approach, we also thrive at using cutting edge technology to provide the best iPhone app solutions."
            }
        ]
    },
]