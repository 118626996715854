import React from 'react';

// data
import { hireSpringBootDevData } from '../../../data/hireDev/hireSpringBootDev';
import sbDev1 from '../../../assets/pages/hireDev/sbDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireSpringBootDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="SPRING BOOT DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={sbDev1}
            data={hireSpringBootDevData}
            noFaq={true}
        />
    )
}
