import React from 'react';

// data
import { hirePythonDevData, hirePythonDevFaqs } from '../../../data/hireDev/hirePythonDev';
import pythonDev1 from '../../../assets/pages/hireDev/pythonDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HirePythonDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="PYTHON DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={pythonDev1}
            data={hirePythonDevData}
            faq={hirePythonDevFaqs}
        />
    )
}
