import img1 from '../../../assets/icons/blockchainPage/1.png';
import img2 from '../../../assets/icons/blockchainPage/2.png';
import img3 from '../../../assets/icons/blockchainPage/3.png';
import img4 from '../../../assets/icons/blockchainPage/4.png';
import img5 from '../../../assets/icons/blockchainPage/5.png';
import img6 from '../../../assets/icons/blockchainPage/6.png';

export const blockchainGridBlockData = [
    "Efficiency",
    "Transparency",
    "Trust",
    "Flexibility"
]

export const blockchainServicesData = [
    {
        id: 1,
        title: "Smart Contract Development",
        description: "For the purpose of this service, we either draft a digital contract to safeguard your blockchain transactions or provide a perceptive audit of existing contracts.",
        src: img1,
    },
    {
        id: 2,
        title: "Cryptocurrency Wallet Development",
        description: "We have the technological know-how to create and manage them for your application needs, and it is ready to support any virtual payment methods.",
        src: img2,
    },
    {
        id: 3,
        title: "Crypto Exchange Development",
        description: "By using our development services to set up a sizable cryptocurrency exchange for you, it investigates novel revenue strategies.",
        src: img3,
    },
    {
        id: 4,
        title: "Ethereum App",
        description: "For astute cryptocurrency investors, it is the preferred option. Ethereum applications are integrated into the basis and provide dependable and quick transactions.",
        src: img4,
    },
    {
        id: 5,
        title: "Hyperledger",
        description: "With the help of Hyperledger, stakeholders can be certain that the environment in which they operate will ensure that the process is just as transparent and reliable as the project as a whole.",
        src: img5,
    },
    {
        id: 6,
        title: "Supply Chain Development",
        description: "We can save administrative expenses and increase transparency and traceability by using the supply chain solution. It encompasses every stage of a data block's lifespan.",
        src: img6,
    }
]