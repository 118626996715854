import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// assets
import { onDemandTeamsData } from '../../../data/hireDev/onDemandTeamsData';

// components
import HeaderText from '../../headerText/HeaderText';
import TalentCard from '../talent/talentCard/TalentCard';

export default function OnDemandTeams() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-py-[4vw] tw-px-[9.115vw]'>
            <HeaderText
                title="ON-DEMAND"
                colorTitleEnd="TEAMS"
                description="Create Outstanding Team or Get the Team Member you want for your project."
                className='tw-text-center tw-mb-8'
            />

            {screenWidth < 768 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {onDemandTeamsData.map(team =>
                        <SwiperSlide key={team.id}>
                            <div className='tw-h-[400px]'>
                                <TalentCard
                                    title={team.title}
                                    description={team.description}
                                    src={team.src}
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
                :
                <div className='tw-grid tw-grid-cols-3 tw-gap-[50px]'>
                    {onDemandTeamsData.map(team => <TalentCard
                        key={team.id}
                        title={team.title}
                        description={team.description}
                        src={team.src}
                    />)}
                </div>
            }

        </div>
    )
}
