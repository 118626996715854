import React from 'react';

// data
import { hireUiUxDevData, hireUiUxDevFaq } from '../../../data/hireDev/hireUiUxDev';
import uiUxDev1 from '../../../assets/pages/hireDev/uiUxDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireUiUxDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="UI/UX DESIGNERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={uiUxDev1}
            data={hireUiUxDevData}
            faq={hireUiUxDevFaq}
        />
    )
}
