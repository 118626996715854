import React from 'react';
import { useSelector } from 'react-redux';

// assets
import hiringProcessBg from '../../../assets/background/hiringProcessBg.svg';
import hiringProcessVerticalBg from '../../../assets/background/hiringProcessVerticalBg.svg';
import { hiringProcessData } from '../../../data/career/hiringProcessData';
// import hiringProcessImage from '../../../assets/hiringProcess/hiringProcess.png';

// components
import HeaderText from '../../headerText/HeaderText';
import HiringCard from './HiringCard';

export default function HiringProcess() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-px-[9.115vw] tw-mt-[4vw]'>
            <HeaderText
                colorTitleStart='Hiring'
                title='Process'
                className='tw-text-center tw-mb-8'
            />


            <div className='tw-grid md:tw-grid-cols-4 tw-grid-cols-1 md:tw-justify-items-center tw-mx-auto md:tw-w-[52.639vw] md:tw-h-auto tw-h-[52.778vw]'
                style={{
                    backgroundImage: `url(${screenWidth < 768 ? hiringProcessVerticalBg : hiringProcessBg})`,
                    backgroundSize: `${screenWidth < 768 ? "100% 52.778vw" : '52.639vw 15.278vw'}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            >
                {hiringProcessData.map((process, index) => <HiringCard
                    key={process.id}
                    index={index}
                    src={process.src}
                    title={process.title}
                    description={process.description}
                    hiringCard={true}
                />)}
            </div>

            {/* <img src={hiringProcessImage} alt="hiring Process" className='tw-mx-auto' /> */}
        </div>
    )
}
