export const hireMagentoDevData = [
    {
        id: 1,
        title: "",
        description: "Because it can function on many different operating systems, Magento is regarded as a cross-platform solution. It is essential that you confirm with the development team which operating systems support the e-commerce web apps if you want to hire any Magento developers. Magento is often used for online retail sites, thus the developer should be aware that Magento is really shopping cart software. Thus, you should find out from your applicants how they intend to utilize Magento to improve the utility of your e-commerce operations for your clients and consumers, as well as if they are aware of how Magento may increase revenue for your company. You should think about choosing any of them just after they provide you with appropriate answers.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why is Magento an Excellent Choice for E-Commerce?",
        description: "",
        noList: true,
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Scalability and Flexibility",
                description: "Magento can manage a few products for small businesses or scale to handle millions for large enterprises, making it a suitable solution for businesses of all sizes."
            },
            {
                id: 2,
                title: "Customizable",
                description: "Magento's modular architecture allows businesses to create a unique and powerful shopping experience for their users."
            },
            {
                id: 3,
                title: "Mobile-Friendly",
                description: "With the growth of mobile commerce, Magento ensures that e-commerce sites are mobile-responsive and provide an optimized shopping experience on all devices."
            },
            {
                id: 4,
                title: "Security",
                description: "Magento frequently updates its software to tackle vulnerabilities and has robust security features, ensuring safe transactions."
            },
            {
                id: 5,
                title: "SEO Optimized",
                description: "Magento sites are SEO-friendly, providing tools for URL structure, sitemaps, meta tags, descriptions, and more."
            },
            {
                id: 6,
                title: "Multiple Store Management",
                description: "Magento allows users to manage multiple stores from a single admin panel, making the management process more straightforward."
            },
            {
                id: 7,
                title: "Global Reach",
                description: "With support for multiple languages, currencies, and tax rates, Magento helps businesses cater to a global audience."
            }
        ]
    }
]

export const hireMagentoDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Magento developers?",
        answer: "The dedicated Magento developers will work only for you to deliver outstanding results with the highest optimization. The team of dedicated Magento developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire dedicated Magento developers?",
        answer: "Alta Tech Mark offers a very simple process to hire dedicated Magento developers. Just provide project details and the technical expertise you need. The Magento developer begins working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Magento developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Magento developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Magento developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Magento developer, then you can hire a team of dedicated Magento developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Magento developers?",
        answer: "The cost estimation for dedicated Magento developers varies based on various factors such as team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]