import React from 'react';
import { Link } from 'react-router-dom';

// components
import HeaderText from '../../components/headerText/HeaderText';
import DataMapper from '../../components/services/dataMapper/DataMapper';

// resources
import { cyberSecurityPolicyData } from '../../data/cyberSecurityPolicyData';

export default function CyberSecurityPolicy() {
    return (
        <div className='tw-px-[9.115vw]'>
            <HeaderText
                colorTitleStart="CYBER SECURITY"
                title="POLICY"
                className='tw-text-center tw-my-8'
                description="Welcome to Alta Tech Mark's Cyber Security Policy page. Our commitment to ensuring the safety and security of our data and systems is paramount. This policy outlines the measures we take to protect our network, systems, and the data entrusted to us by our clients and partners."
            />

            <ul className='tw-list-disc tw-gap-y-2 tw-grid'>
                <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-1'>
                    <li className='md:tw-text-lg tw-text-base tw-font-semibold tw-text-darkPurple'>Our Commitment to Cyber Security</li>
                    <h1>At Alta Tech Mark, we understand the importance of robust cyber security in today's digital landscape. We are committed to implementing state-of-the-art security practices to safeguard all information and systems against cyber threats.</h1>
                </div>
                <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-1 -tw-mb-6'>
                    <li className='md:tw-text-lg tw-text-base tw-font-semibold tw-text-darkPurple'>Key Aspects of Our Cyber Security Policy</li>
                    <h1>At Alta Tech Mark, we understand the importance of robust cyber security in today's digital landscape. We are committed to implementing state-of-the-art security practices to safeguard all information and systems against cyber threats.</h1>
                </div>

                <DataMapper data={cyberSecurityPolicyData} />

                <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-1'>
                    <h1 className='md:tw-text-lg tw-text-base tw-font-semibold tw-text-darkPurple'>Your Data, Our Responsibility</h1>
                    <h1>Protecting your data is a responsibility we take seriously. By implementing and continually updating these policies, we aim to maintain the highest level of security and trust.</h1>
                    <h1>For more information about our cyber security practices or if you have any concerns, please <Link className='tw-text-purple tw-underline' to='/contactUs'>contact us.</Link></h1>
                </div>

                <h1 className='tw-text-right tw-my-4'>Last Updated On: 1st November 2023</h1>
            </ul>
        </div>
    )
}
