import React from 'react';

// assets
import bg from '../../../assets/brands/gpca/bg.svg';
import logo from '../../../assets/brands/gpca/logo.svg';
import right from '../../../assets/brands/gpca/right.svg';
import overviewFloatingSpan from '../../../assets/brands/gpca/overviewFloatingSpan.svg';
import bg2 from '../../../assets/brands/gpca/bg2.svg';
import navigation from '../../../assets/brands/gpca/navigation.svg';
import goals from '../../../assets/brands/gpca/goals.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function Gpca() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="GPCA"
                simpleTitle="Gulf Petrochemicals & Chemicals Association"
                description="GPCA aimed for a seamless information flow within the organization, and Alta Tech Mark created a dashboard that enhanced their internal communication and interaction."
                rightClasses="md:tw-w-[30vw] md:tw-h-[20vw]"
            />

            <ProjectOverview
                overview="GPCA, a petrochemical enterprise, Hired Alta Tech Mark to develop a top-tier dashboard management system to integrate business processes and ensure an efficient information flow. The primary goal was to enhance outcomes, alleviate stress, and boost productivity through the use of this dashboard."
                overviewFloatingSpan={overviewFloatingSpan}
                overviewFloatingText="The dashboard is designed with a purpose to represent what’s exactly going on in the business. It consists of different functionalities to measure goals against progress in real time."
            />

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-4'
                style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <h1 className='md:tw-text-[2.635vw] tw-text-white tw-font-bold tw-text-center'>User-Friendly Navigation</h1>
                <h1 className='md:tw-text-[1.171vw] tw-text-center tw-text-white'>The dashboard is engineered to offer intuitive navigation, enabling users to swiftly access vital information and automatically directing them to the data they require. Furthermore, it is developed to be fully compatible with all mobile devices, ensuring that accurate information is accessible to everyone on the go.</h1>
                <img className='tw-mx-auto' src={navigation} alt="navigation" />
            </div>

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-4'>
                <h1 className='md:tw-text-[2.635vw] tw-text-red-600 tw-font-bold tw-text-center'>Aligning Strategies and Organizational Goals</h1>
                <h1 className='md:tw-text-[1.171vw] tw-text-center'>We delivered a digital dashboard which has the ability to align key performance indicators with business goals in order to improve decision making.</h1>
                <img className='tw-mx-auto' src={goals} alt="navigation" />
            </div>

            <ContactUsNoForm />
        </div>
    )
}
