import React from 'react';

// assets
import uc1 from '../../../../assets/pages/service/ecommerceDev/uc1.jpeg';
import uc2 from '../../../../assets/pages/service/ecommerceDev/uc2.png';
import { uberCartDevData } from '../../../../data/services/ecommerceDevData/uberCartDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function UberCartDev() {
    return (
        <ServicesWrapper
            title="ECOMMERCE DEVELOPMENT UBERCART"
            descriptionCont="Our E-Commerce website development professionals are experts. Alta Tech Mark developers provide high-quality, fast E-Commerce products. Our skilled staff develops E-Commerce websites utilizing cutting-edge, trustworthy technology. Magento, Shopify, BigCommerce, WooCommerce, and others"
            img={uc1}
        >
            <DevGridWrapper img={uc2}>
                <DataMapper data={uberCartDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
