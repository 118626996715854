export const hireDataScientistData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "A number of sectors have embraced data science as an emerging technology to improve their operations. Hiring a data scientist is necessary in order to use data science. From both organized and unstructured data, they aid in the extraction of insights. Scientists use systems, procedures, algorithms, and scientific techniques to extract data. Data scientists assist in transforming massive databases into useful business insights. Our team of Data Scientists will assist you in transforming your company and improving procedures across all sectors. Your ability to forecast the future and make precise decisions will be aided by the data. Thus, employing data scientists is essential for any company looking to grow.",
            "You may get in-depth information and analyses from our data scientist, who will assist you in identifying industry trends. You may discover more about contemporary business and the market curve with the assistance of the data scientist. We have an expert in data mining and analytics. Experts in data science carry out exacting tasks using mathematical methods and algorithms built for analysis. Several difficult challenges will be helped to solve by its insight data. Our data scientist helps you with any question you have and maintains open communication throughout the exchange. We use the data to try to improve companies and fit the demands of our clients."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Service Our Data Scientist Offer:",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Data Science Approach",
                description: "Our data scientists provide a customized data science plan to boost your company. They create a precise plan by using statistics and sophisticated machine learning methods. Innovative techniques and tools are used to harness data in order to meet particular needs. The cornerstone of our Data Science approach is multifaceted insights."
            },
            {
                id: 2,
                title: "Data Science Advice",
                description: "Our data scientists at Alta Tech Mark provide excellent consulting services that are highlighted by using designs and optimal data techniques. Understanding the critical role that data science plays in today's corporate environment, our specialists give insightful advice. Our guidance extends to enterprises from the initial ideation stage through the ultimate execution. Our Data Scientists are here to assist you with any questions or difficulties at any time."
            },
            {
                id: 3,
                title: "Services for Data Visualization",
                description: "Our Data Scientists are skilled in using Data Visualization services to enable effective communication. Clients can easily understand operations, data trends, and more thanks to clear visual aids. Making wise and beneficial judgments is facilitated by having real-time contact with customers."
            },
            {
                id: 4,
                title: "Development of Data Science",
                description: "Our data scientists at Alta Tech Mark are experts in data science development services. They create plans with great care, refine them, and carry them out flawlessly. Clients are openly informed about this thorough procedure to make sure their objectives are fulfilled."
            },
            {
                id: 5,
                title: "Encrypted Data Creation",
                description: "The security of data is critical. Because of this, our data scientists provide secure data creation services that safeguard your priceless data. Through strengthening data security, we reduce risks and increase reliability. We also assist customers in understanding the criticality of data by demystifying its analytical subtleties."
            }
        ]
    },
    {
        id: 3,
        title: "Sectors We Cover:",
        description: "",
        onlyBullets: true,
        data: [
            "Banking and Finance",
            "Insurance Company",
            "E-Commerce",
            "Travel and Tourism",
            "Security",
            "Health & Wellness",
            "Logistics",
            "Education",
            "Event Management",
            "Media & Entertainment"
        ]       
    },
    {
        id: 4,
        title: "Utilized Media & Entertainment Technology:",
        description: "",
        onlyBullets: true,
        data: [
            "MySQL",
            "MEAN",
            "Pandas",
            "Tidyverse",
            "SparkDask",
            "SQLKafka",
            "MongoDB",
            "CassandraElastic",
            "TrelloSlack",
            "PHP",
            "Python",
            "Laravel",
            "Postgre",
            "StackDevOps",
            "MERN stack",
            "GolangFlutter",
            "BasecampJira",
            "AsanaAzure"
        ]
    },
    {
        id: 5,
        title: "",
        description: "Proficiency in Data Structures and Modeling: comprehension of both organized and unstructured data is essential to data science. Our staff guarantees insights that may spur the expansion of your organization thanks to its extensive experience with data formats and modeling.",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Obtaining Improved Tools",
                description: "Keeping up with the newest technological advancements, our Data Scientists are proficient in both conventional approaches and state-of-the-art instruments. This flexibility guarantees better results on every assignment."
            },
            {
                id: 2,
                title: "Dedication to Excellence",
                description: "Alta Tech Mark is a symbol of excellence. Our data scientists provide revolutionary solutions while upholding the highest standards of quality. These excellent solutions transform your data into insights that can be put to use, accelerating company expansion and improving efficiency."
            },
            {
                id: 3,
                title: "Turn of the Clock Support and upkeep",
                description: "We're not done with our commitments after the project is finished. We take pleasure in building enduring connections and offering round-the-clock assistance with all of your questions and problems. And as soon as your project launches, we're there to support you with our first-rate maintenance services."
            }
        ]
    }
]


export const hireDataScientistFaq = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Data scientists?",
        answer: "The dedicated Data scientists will work only for you to deliver outstanding results with the highest optimization. The team of Data scientists at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Data scientists?",
        answer: "Alta Tech Mark offers a very simple process to hire Data scientists. Just provide project details and the technical expertise you need. The team of Data scientists will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Data scientist?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Data scientists before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Data scientists for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Data scientist, then you can hire dedicated scientists on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Data scientists?",
        answer: "The cost estimation for dedicated Data scientists varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]