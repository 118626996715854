export const hireAppDevData = [
    {
        id: 1,
        title: '',
        descriptions: [
            "The need for mobile app development has surged across all businesses! In the past, computers and desktops were the go-to sources for information. However, technological advancements have now steered users towards mobile devices for everything – from browsing websites and ordering food to booking taxis in their daily routine. This shift not only represents an opportunity but also places a responsibility on businesses to deliver optimal experiences to their most valued customers, predominantly on their smartphones. Recognizing the increasing demand for mobile apps, many entrepreneurs are on the hunt for reliable and skilled mobile app developers.",
            "Alta Tech Mark offers a stellar opportunity to hire adept and seasoned mobile app developers. Our certified and professional app developers craft feature-packed and functional mobile applications tailored to diverse business needs, catering to both established enterprises and startups. With Alta Tech Mark, you can avail of cost-effective mobile app development services. We proudly boast a portfolio of over 4500 successfully developed mobile apps spanning various industry sectors.",
            "Our dedicated app developers are enthusiastic about embracing innovative concepts that enhance user engagement during the app's development process. Engage with developers from Alta Tech Mark and embark on an exciting journey towards endless possibilities with distinctive mobile apps, bolstering your business's competitive edge and customer-centric approach."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Benefits of Hiring App Developers From Alta Tech Mark",
        description: "Advantages of Engaging App Developers From Alta Tech Mark Our proficient mobile app developers are seasoned in offering comprehensive solutions. Here's what you stand to gain when you collaborate with mobile app developers from Alta Tech Mark:",
        onlyBullets: true,
        data: [
            "Committed Developer Teams",
            "Detailed Progress Updates",
            "Assured Confidentiality via NDAs",
            "Round-the-Clock Support",
            "Robust Operating Conditions",
            "Assured Data & Intellectual Property Safety",
            "Rapid Team Expansion Possibility",
            "Quality Assured, Error-Free Outcomes",
            "Complete Ownership of Source Code",
            "Transparent Pricing",
            "Tech Assistance",
            "Punctual Project Completion"
        ]
    },
    {
        id: 3,
        title: "Diverse Expertise at Alta Tech Mark: ",
        description: "",
        onlyBullets: true,
        data: [
            "iPhone App Development Experts",
            "Unity3D Application Specialists",
            "Android App Development Professionals",
            "Game Development Masters",
            "Flutter Development Gurus",
            "AR/VR Application Innovators"
        ]
    },
    {
        id: 4,
        title: "",
        description: "Showcase of Applications Crafted by Alta Tech Mark: To date, our developers at Alta Tech Mark have crafted a range of customized mobile apps spanning various sectors:",
        onlyBullets: true,
        data: [
            "Social Media Platforms",
            "Online Music Platforms",
            "Health & Fitness Monitors",
            "Beauty & Wellness Applications",
            "Financial Management Apps",
            "Live Broadcasting Apps",
            "Digital Learning Platforms",
            "E-Shopping Apps",
            "Photo Editing Tools",
            "On-Demand Service Apps",
            "Augmented & Virtual Reality Games/Apps",
            "Matchmaking Applications"
        ]
    },
    {
        id: 5,
        title: "",
        description: "Compelling Reasons to Collaborate with Alta Tech Mark Developers: Our squad offers a transparent estimation, detailing every feature, its development, design, and testing phases.",
        onlyBullets: true,
        data: [
            "Agile Methodology Champions",
            "Exceptional Command over English",
            "Comprehensive Unit Testing",
            "Rigorous Code Assessment & White Box Testing",
            "Deep Rooted Object Analysis",
            "Project Blueprint Creation",
            "Expertise in Third-party API Integration",
            "Mastery of Cutting-edge Tools",
            "UX Design Implementation",
            "Capability to Develop SDKs",
            "Regular Client Updates",
            "Highly Qualified and Certified Team Members"
        ]
    },
    {
        id: 6,
        title: "Why Opt for Alta Tech Mark's App Developers?",
        descriptions: ["Realize your vision with our unmatched expertise and dedicated developer services. Alta Tech Mark's adept mobile app developers ensure an unparalleled client journey. We prioritize client satisfaction by innovating and enhancing our project management methodologies."],
        onlyBullets: false,
        data: []
    },
    {
        id: 7,
        title: "Benefits of Engaging with Alta Tech Mark:",
        description: "",
        onlyBullets: true,
        data: [
            "Cost Efficiency",
            "Advanced Infrastructure",
            "Swift Delivery",
            "Expert Developers Across Platforms",
            "Assured Outcomes",
            "Dependable Backing"
        ]
    }
]

export const hireAppDevFaq = [
    {
        id: 1,
        question: "1. How much does it cost to hire mobile app developers?",
        answer: "The cost estimation for mobile app developers to hire varies based on various factors such as app development team size, hiring model, the complexity of the app development, the type of solution, features, and functionalities, and many more. You can reach out to our team with your development requirements to get the exact cost estimation."
    },
    {
        id: 2,
        question: "2. Can I hire mobile app developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the mobile app developer, then you can hire a mobile developer on an hourly or project/task base."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the mobile app developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and app development skills of the mobile app developers before making a final move."
    },
    {
        id: 4,
        question: "4. Why should I hire mobile app developers?",
        answer: "The mobile app developers will work only for you to deliver outstanding results with the highest optimization. The team of mobile app developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets, and various others. "
    },
    {
        id: 5,
        question: "5. How do I Hire mobile app developers?",
        answer: "All you have to do is reach out to the team of Alta Tech Mark. They have a very simple process to hire mobile app developers. Just provide project details and the technical expertise you need. The developer begins working on your project based on your selection."
    }
]