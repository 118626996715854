import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function CaseStudiesCard(props) {
    // initializations
    const navigate = useNavigate();

    return (
        <div className='tw-col-span-1 tw-mt-[16px] tw-px-[15px] tw-flex tw-flex-col tw-w-full tw-items-center tw-cursor-pointer tw-text-white' onClick={() => navigate(props.url)}>
            <img className='tw-rounded-t-lg' src={props.src} alt={props.title} />

            <div className='tw-w-full tw-bg-darkPurple tw-rounded-b-lg tw-relative tw-bottom-0 tw-shadow-lg tw-py-[24px] tw-px-[16px] tw-flex tw-flex-col tw-items-center tw-gap-y-[20px] tw-h-full'>
                <h1 className='tw-font-semibold tw-text-center md:tw-text-lg tw-text-sm'>{props.title}</h1>
                {/* <div className='tw-flex tw-items-center tw-w-full tw-gap-[13px] tw-justify-center tw-flex-wrap'>
                    {props.children.map(child => <h1 key={child.id} className='md:tw-text-[13px] tw-text-[10px] tw-bg-[#FFFFFF1A] tw-py-[3px] tw-px-[8px] tw-rounded-lg'>{child.title}</h1>)}
                </div> */}
            </div>
        </div>
    )
}
