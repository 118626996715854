import React from 'react'

// components
import LogoGrid from '../logoGrid/LogoGrid';

// data
import { esteemedClientsData } from '../../../data/esteemedClientsData';

export default function EsteemedClients(props) {
    return (
        <div className='tw-flex tw-flex-col tw-items-center tw-w-full tw-gap-y-[25px] tw-mb-8'>
            {props.showTitle &&
                <h1 className='tw-text-[28px] tw-font-semibold'>Esteemed Clients</h1>
            }
            <LogoGrid data={esteemedClientsData} />
        </div>
    )
}
