export const hireDjangoDevData = [
    {
        id: 1,
        title: "",
        description: "Alta Tech Mark is at the forefront of Django development, blending expertise and innovation to deliver cutting-edge web solutions. As a leading name in the tech industry, our commitment to excellence is reflected in our diverse portfolio.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Choose Alta Tech Mark for Django Development?",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Experienced Professionals",
                description: "Our team comprises seasoned Django developers well-versed in the nuances of the framework. Their vast experience ensures the development of scalable and robust solutions that cater to evolving business needs."
            },
            {
                id: 2,
                title: "Diverse Industry Experience",
                description: "Alta Tech Mark boasts an impressive track record of projects spanning multiple sectors – from healthcare and legal to finance and e-commerce. This varied experience ensures a holistic understanding of different industry requirements and challenges."
            },
            {
                id: 3,
                title: "Global Outreach",
                description: "Our client base is global, a testament to our reputation and the quality of our deliverables. We've successfully catered to businesses worldwide, tailoring our solutions to accommodate regional preferences and market dynamics."
            },
            {
                id: 4,
                title: "Continuous Upgradation",
                description: "In the rapidly evolving tech landscape, staying updated is crucial. At Alta Tech Mark, continuous learning and adaptation are ingrained in our culture. We consistently upgrade our skills, ensuring our solutions are always aligned with the latest technological trends."
            },
            {
                id: 5,
                title: "Tailored Solutions",
                description: "Whether you're a startup looking for a prototype or an established enterprise aiming for a full-fledged web solution, our team crafts bespoke solutions tailored to your needs. From impeccable UI/UX designs and error-free coding to robust security frameworks, our solutions are comprehensive and future-proof."
            },
            {
                id: 6,
                title: "Client-Centric Approach",
                description: "Our clients are at the heart of everything we do. We prioritize their timelines, technological preferences, and terms of engagement. This client-first approach ensures transparent communication, flexibility in execution, and ultimately, award-winning solutions."
            }
        ]
    },
]

export const hireDjangoDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Django developers?",
        answer: "The dedicated Django developers will work only for you to deliver outstanding results with the highest optimization. The team of Django developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Django developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Django developers. Just provide project details and the technical expertise you need. The team of Django developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Django developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Django developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Django developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Django developer, then you can hire dedicated Django developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Django developers?",
        answer: "The cost estimation for dedicated Django developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]