import React from 'react';

// assets
import bg from '../../../assets/brands/clhu/bg.svg';
import logo from '../../../assets/brands/clhu/logo.svg';
import right from '../../../assets/brands/clhu/right.svg';
import overviewFloatingSpan from '../../../assets/brands/clhu/overviewFloatingSpan.svg';
import bg2 from '../../../assets/brands/clhu/bg2.svg';
import why from '../../../assets/brands/clhu/why.svg';
import pal from '../../../assets/brands/clhu/pal.svg';
import par from '../../../assets/brands/clhu/par.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function Clhu() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="COACH LEFT HAND UP"
                // simpleTitle="Sports"
                description="Coach Left Hand Up sought a mobile application tailored for basketball coaches, staff, and statisticians. We created an app that consolidated all their informational needs onto a single platform."
                rightClasses="md:tw-w-[30vw] md:tw-h-[20vw]"
            />

            <ProjectOverview
                overview="One of our major clients approached us and wanted something unique for the coaches, staff, statistician and fans."
                overviewFloatingSpan={overviewFloatingSpan}
                overviewFloatingText="With the help of this app coaches, players and fans can track different players and teams of the season. This app will give maximum number of solutions to tackle different strategies used by the other teams."
            />

            <div className='tw-pt-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-4 tw-relative'
                style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <h1 className='md:tw-text-[2.635vw] tw-text-white tw-font-bold tw-text-center'>Why coach left hand up is important?</h1>
                <h1 className='md:tw-text-[1.171vw] tw-text-center tw-text-white'>The app is designed to help teams secure more victories by mastering the defense against perimeter shots. It supports coaches by streamlining defensive tactics, allowing a greater focus on offensive play. A key feature is its ability to pinpoint players who require additional coaching support.</h1>
                <img className='tw-mx-auto' src={why} alt="quest" />
            </div>

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-flex tw-justify-end tw-gap-y-4 tw-relative tw-bg-[#a06c1f]'>
                <img src={pal} alt="pa" className='tw-w-[45vw] tw-h-[45vw] tw-absolute tw-left-0 tw-top-0' />
                <div className='tw-flex tw-flex-col tw-items-center tw-gap-y-4 tw-w-[45vw]'>
                    <h1 className='md:tw-text-[1.171vw] tw-text-center tw-text-white'>The best personal assistant for any coach. This app will figure out every possible strategy to tackle the opponents. Not just the coaches but the players can get close feedbacks about their performances and can improve accordingly</h1>
                    <img className='tw-mx-auto tw-w-[48.194vw]' src={par} alt="quest" />
                </div>
            </div>

            <ContactUsNoForm />
        </div>
    )
}
