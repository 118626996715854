import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

// data
import { brandsData } from './brandsData';
import citizen from '../../../assets/brands/citizen.svg';
import dubaiAirports from '../../../assets/brands/dubaiAirports.svg';
import educationUsa from '../../../assets/brands/educationUsa.svg';
import leftHandUp from '../../../assets/brands/leftHandUp.svg';
import lg from '../../../assets/brands/lg.svg';
import porsche from '../../../assets/brands/porsche.svg';
import qatar from '../../../assets/brands/qatar.svg';
import renault from '../../../assets/brands/renault.png';
import towdatos from '../../../assets/brands/towdatos.svg';

export default function Brands() {
    // initializations
    const navigate = useNavigate();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-w-full tw-bg-white tw-py-[3.9vw]'>
            <div className=''>
                <Swiper
                    modules={[Autoplay]}
                    loop={true}
                    spaceBetween={0}
                    autoplay={{
                        delay: 2000,
                    }}
                    slidesPerView={screenWidth / 240}
                    style={{
                        marginLeft: '40px',
                        marginRight: '40px'
                    }}
                >
                    {/* {brandsData.map(card => { */}
                    {/* return ( */}
                    <SwiperSlide onClick={() => navigate('/brands/citizen')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[125px] tw-relative tw-top-2'>
                            <img src={citizen} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide onClick={() => navigate('/brands/dubaiAirports')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-4 tw-top-2'>
                            <img src={dubaiAirports} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide> */}
                    <SwiperSlide onClick={() => navigate('/brands/educationUsa')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={educationUsa} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/clhu')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={leftHandUp} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/lg')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px] tw-relative tw-left-4'>
                            <img src={lg} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/porsche')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[180px] tw-relative tw-right-8'>
                            <img src={porsche} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/qatarTransport')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px]'>
                            <img src={qatar} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/renault')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[115px] tw-relative tw-right-8'>
                            <img src={renault} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-8 tw-top-0'>
                            <img src={towdatos} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/citizen')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[125px] tw-relative tw-top-2'>
                            <img src={citizen} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide onClick={() => navigate('/brands/dubaiAirports')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-4 tw-top-2'>
                            <img src={dubaiAirports} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide> */}
                    <SwiperSlide onClick={() => navigate('/brands/educationUsa')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={educationUsa} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/clhu')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={leftHandUp} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/lg')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px] tw-relative tw-left-4'>
                            <img src={lg} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/porsche')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[180px] tw-relative tw-right-8'>
                            <img src={porsche} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/qatarTransport')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px]'>
                            <img src={qatar} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/renault')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[115px] tw-relative tw-right-8'>
                            <img src={renault} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-8 tw-top-0'>
                            <img src={towdatos} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/citizen')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[125px] tw-relative tw-top-2'>
                            <img src={citizen} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide onClick={() => navigate('/brands/dubaiAirports')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-4 tw-top-2'>
                            <img src={dubaiAirports} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide> */}
                    <SwiperSlide onClick={() => navigate('/brands/educationUsa')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={educationUsa} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/clhu')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={leftHandUp} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/lg')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px] tw-relative tw-left-4'>
                            <img src={lg} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/porsche')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[180px] tw-relative tw-right-8'>
                            <img src={porsche} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/qatarTransport')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px]'>
                            <img src={qatar} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/renault')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[115px] tw-relative tw-right-8'>
                            <img src={renault} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-8 tw-top-0'>
                            <img src={towdatos} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/citizen')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[125px] tw-relative tw-top-2'>
                            <img src={citizen} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide onClick={() => navigate('/brands/dubaiAirports')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-4 tw-top-2'>
                            <img src={dubaiAirports} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide> */}
                    <SwiperSlide onClick={() => navigate('/brands/educationUsa')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={educationUsa} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/clhu')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={leftHandUp} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/lg')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px] tw-relative tw-left-4'>
                            <img src={lg} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/porsche')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[180px] tw-relative tw-right-8'>
                            <img src={porsche} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/qatarTransport')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px]'>
                            <img src={qatar} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/renault')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[115px] tw-relative tw-right-8'>
                            <img src={renault} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-8 tw-top-0'>
                            <img src={towdatos} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/citizen')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[125px] tw-relative tw-top-2'>
                            <img src={citizen} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide onClick={() => navigate('/brands/dubaiAirports')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-4 tw-top-2'>
                            <img src={dubaiAirports} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide> */}
                    <SwiperSlide onClick={() => navigate('/brands/educationUsa')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={educationUsa} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/clhu')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[150px]'>
                            <img src={leftHandUp} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/lg')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px] tw-relative tw-left-4'>
                            <img src={lg} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/porsche')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[180px] tw-relative tw-right-8'>
                            <img src={porsche} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/qatarTransport')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[105px]'>
                            <img src={qatar} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => navigate('/brands/renault')} className='tw-cursor-pointer'>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[115px] tw-relative tw-right-8'>
                            <img src={renault} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='tw-grid tw-place-items-center tw-h-[130px] tw-w-[170px] tw-relative tw-right-8 tw-top-0'>
                            <img src={towdatos} alt="" className='tw-w-full tw-h-full' />
                        </div>
                    </SwiperSlide>
                    {/* ) */}
                    {/* })} */}
                </Swiper>
            </div>
        </div>
    )
}
