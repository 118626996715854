import img1 from '../../../assets/icons/ecommercePage/1.png';
import img2 from '../../../assets/icons/ecommercePage/2.png';
import img3 from '../../../assets/icons/ecommercePage/3.png';
import img4 from '../../../assets/icons/ecommercePage/4.png';
import img5 from '../../../assets/icons/ecommercePage/5.png';
import img6 from '../../../assets/icons/ecommercePage/6.png';

export const ecommerceGridBlockData = [
    "Shopify",
    'Magento',
    'Big Commerce',
    'Ubercart',
    'Woo Commerce',
    'CSCart',
    'Prestashop',
    'Virtuemart'
];

export const ecommerceServicesData = [
    {
        id: 1,
        title: "Custom eCommerce Integration",
        description: "We elevate the e-commerce endeavor with our unique, tailored e-commerce solutions. For the e-commerce company, integrating a bespoke e-commerce system would be advantageous.",
        src: img1
    },
    {
        id: 2,
        title: "B2C Platforms",
        description: "Business-to-business transactions are among the simplest and most in-demand forms of communication. Customers may contact immediately via the online portal, which increases engagement and grows e-commerce company.",
        src: img2
    },
    {
        id: 3,
        title: "Multi Platforms",
        description: "A number of e-commerce platforms function and combine many merchants into one. We provide top-notch technology for those platforms, fostering multi-platform solutions and thriving. We enable platforms that facilitate selling and buying",
        src: img3
    },
    {
        id: 4,
        title: "B2B Commerce Solutions",
        description: "Rich Business to Business Commerce solutions are what we provide. Our businesses are skilled in developing B2B e-commerce solutions with superior performance that boost value and conversions.",
        src: img4
    },
    {
        id: 5,
        title: "Payment Integration",
        description: "Without payment integration, there can be no e-commerce website. Our system gives you access to several integrated payment methods for safe and easy online transactions.",
        src: img5
    },
    {
        id: 6,
        title: "Deploy Maintenance",
        description: "To guarantee faultless and slick online e-commerce experiences, we provide the best support and maintenance services. Implementation maintenance has been handled, and it contributes to the upkeep of error-free e-commerce websites.",
        src: img6
    }
]