import React from 'react'

export default function PartnershipModelsCard(props) {
    return (
        <div className={`tw-p-[15px] tw-flex tw-flex-col tw-items-start tw-mt-[24px] tw-col-span-1 tw-shadow-xl tw-rounded-lg tw-gap-y-[20px] tw-text-white`}
            style={{
                background: "linear-gradient(180deg, #372A60 0%, #0A0095 100%)"
            }}
        >
            <img className='tw-w-[64px] tw-h-[64px]' src={props.src} alt={props.title} />
            <h1 className='md:tw-text-[20px] tw-text-[16px] tw-font-semibold tw-text-start tw-break-words'>{props.title}</h1>
            <h1 className='tw-text-start md:tw-text-[16px] tw-text-[12px] tw-tracking-wider tw-w-auto'>{props.description}</h1>
        </div>
    )
}