export const typescriptDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "A business called Alta Tech offers scalable TypeScript development services. They have a group of skilled and knowledgeable developers that are knowledgeable with TypeScript. With cutting-edge instruments and technology, they provide a fast speed and dependability.",
            "Retail, e-commerce, banking and finance, healthcare, education, logistics, travel and tourism, and education are just a few of the areas that Alta Tech provides services to."
        ],
        onlyBullets: false,
        data: [],
    },
    {
        id: 2,
        title: "Alta Tech Mark provide several different TypeScript services, such as: ",
        description: "",
        onlyBullets: true,
        data: [
            "Development of TypeScript web apps",
            "Business application development",
            "bespoke TypeScript layout",
            "Building TypeScript applications",
            "Migration from JavaScript to TypeScript",
            "TypeScript guidance",
            "Upkeep and support for TypeScript"
        ]
    },
    {
        id: 3,
        title: "",
        description: "For companies searching for top-notch TypeScript development services, Alta Tech is a fantastic option. They have a group of skilled and committed developers with a track record of accomplishment.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "The following are some advantages of developing TypeScript using Alta Tech:",
        description: "",
        onlyBullets: true,
        data: [
            "Our methodical and thorough approach to the development process is shown in their holistic approach to TypeScript development.",
            "We produce high-quality goods and applications by using state-of-the-art equipment and technology.",
            "We have a group of skilled and knowledgeable developers that are knowledgeable with TypeScript.",
            "To accommodate the demands of companies of various sizes, We provide a broad variety of TypeScript services.",
            "Our development services are reliable and delivered at a fast speed."
        ]
    },
    {
        id: 5,
        title: "",
        description: "Alta Tech might be a terrific option if you're searching for a trustworthy and knowledgeable TypeScript development firm. They can assist you with creating TypeScript apps of the highest quality to suit your company's requirements.",
        onlyBullets: false,
        data: []
    }
]