import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function SimpleButton(props) {
    // initializations
    const navigate = useNavigate();

    return (
        <div className={`tw-bg-${props.bgColor} tw-py-[10px] tw-px-[30px] tw-text-${props.textColor} tw-grid tw-place-items-center tw-cursor-pointer hover:tw-bg-${props.bgHover} tw-transition-colors tw-duration-200 tw-border-[2px] tw-border-purple tw-rounded-xl ${props.noHover ? "" : "hover:tw-bg-purple hover:tw-text-white"} ${props.className}`} onClick={() => {
            props.clickFunction && props.onClick();
            !props.noUrl && navigate(props.to)
        }}>
            {props.text}
        </div>
    )
}
