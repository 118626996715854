import React from 'react';

// data
import { hireSalesforceDevData, hireSalesforceDevFaqs } from '../../../data/hireDev/hireSalesforceDev';
import sd1 from '../../../assets/pages/hireDev/sd1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireSalesforceDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="SALESFORCE DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={sd1}
            data={hireSalesforceDevData}
            faq={hireSalesforceDevFaqs}
        />
    )
}
