export const prestaShopDevData = [
    {
        id: 1,
        title: "Develop Your Online Store With Prestashop",
        descriptions: [
            "The main reason PrestaShop is a desirable e-commerce solution is because it is open-source. Cutting costs is essential for internet business owners, and using an affordable solution like PrestaShop may result in big savings. Alta Tech Mark is an expert in PrestaShop, and we can make a big difference in your online business.",
            "The transfer procedure is simple for those who are now using another e-commerce platform but are interested in PrestaShop. Many online retailers have switched to PrestaShop so that all of their vital information—including clientele, product descriptions, payments, shipping information, orders, and company analytics—is preserved. No matter what platform you were using before, PrestaShop can connect your company data with ease.",
            "PrestaShop's user-friendly back-office solutions and plenty of cutting-edge features make it a noticeable advantage to switch. Any e-commerce company must handle front- and back-office operations effectively. If back-office management isn't getting what they want out of your present system, PrestaShop could be the answer.",
            "The user-friendly features of PrestaShop might help you save hours every day that you would have otherwise spent managing different e-commerce tasks. You may use that time to focus on other important aspects of your company, like planning strategies with your sales staff or evaluating inventories for your best-selling goods. These additional hours might also lead to creative ideas for new products or services, which would increase the profitability and competitiveness of your internet company.",
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "How Alta Tech Mark, can help you simplify your journey with PrestaShop?",
        description: "PrestaShop is open-source software supported by a rapidly expanding community of users willing to contribute their e-commerce knowledge and unique PrestaShop experiences. By using this pooled expertise and the professional assistance of Alta Tech Mark, you can streamline your PrestaShop experience and increase the likelihood that your e-commerce goals will be met.",
        onlyBullets: false,
        data: []
    }
]