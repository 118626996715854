import React from 'react';

// assets
import or1 from '../../../../assets/pages/service/aiAndMl/or1.png';
import or2 from '../../../../assets/pages/service/aiAndMl/or2.png';
import { objectRecognitionData } from '../../../../data/services/aiAndMl/objectRecognitionData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function ObjectRecognition() {
    return (
        <ServicesWrapper
            title="Object Recognition"
            descriptionCont="Identifying and detecting things in pictures and videos is done using computer vision techniques. It's an approach powered by machine learning and artificial intelligence."
            img={or1}
        >
            <DevGridWrapper img={or2}>
                <DataMapper data={objectRecognitionData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
