import React from 'react';

// data
import { hireAppDevData, hireAppDevFaq } from '../../../data/hireDev/hireAppDev';
import appDev1 from '../../../assets/pages/hireDev/appDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireAppDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="APP DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={appDev1}
            data={hireAppDevData}
            faq={hireAppDevFaq}
        />
    )
}
