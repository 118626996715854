import {
    createBrowserRouter,
} from 'react-router-dom';

import App from './App';

// pages
import Home from './pages/home/Home';

// auth
import Auth from './components/auth/Auth';
import Login from './components/auth/login/Login';

// company
import Company from './pages/company/Company';
import AboutUs from './pages/company/aboutUs/AboutUs';
import Services from './pages/company/services/Services';
import Career from './pages/company/career/Career';
import Events from './pages/company/events/Events';
import ApplyNow from './pages/company/career/applyNow/ApplyNow';
import OurClients from './pages/company/ourClients/OurClients';
import OurExpertise from './pages/company/ourExpertise/OurExpertise';
import OurTeam from './pages/company/ourTeam/OurTeam';

// services
import ServicesMain from './pages/services/Services';

import MobileAppDev from './pages/services/mobileAppDev/MobileAppDev';
import AndroidAppDev from './pages/services/mobileAppDev/androidAppDev/AndroidAppDev';
import IphoneAppDev from './pages/services/mobileAppDev/iphoneAppDev/IphoneAppDev';
import HybridAppDev from './pages/services/mobileAppDev/hybridAppDev/HybridAppDev';
import FlutterAppDev from './pages/services/mobileAppDev/flutterAppDev/FlutterAppDev';
import ReactNativeAppDev from './pages/services/mobileAppDev/reactNativeAppDev/ReactNativeAppDev';
import KotlinAppDev from './pages/services/mobileAppDev/kotlinAppDev/KotlinAppDev';
import IonicAppDev from './pages/services/mobileAppDev/ionicAppDev/IonicAppDev';
import SwiftAppDev from './pages/services/mobileAppDev/swiftAppDev/SwiftAppDev';
import XamarinAppDev from './pages/services/mobileAppDev/xamarinAppDev/XamarinAppDev';

import WebAppDev from './pages/services/webAppDev/WebAppDev';
import PhpAppDev from './pages/services/webAppDev/phpAppDev/PhpAppDev';
import WordpressAppDev from './pages/services/webAppDev/wordpressAppDev/WordpressAppDev';
import DrupalAppDev from './pages/services/webAppDev/drupalAppDev/DrupalAppDev';
import LaravelAppDev from './pages/services/webAppDev/laravelAppDev/LaravelAppDev';
import ReactJsAppDev from './pages/services/webAppDev/reactJsAppDev/ReactJsAppDev';
import AngularJsAppDev from './pages/services/webAppDev/angularJsAppDev/AngularJsAppDev';
import NodeJsAppDev from './pages/services/webAppDev/nodeJsAppDev/NodeJsAppDev';
import CodeIgniterAppDev from './pages/services/webAppDev/codeIgniterAppDev/CodeIgniterAppDev';
import CakePhpAppDev from './pages/services/webAppDev/cakePhpAppDev/CakePhpAppDev';
import PythonAppDev from './pages/services/webAppDev/pythonAppDev/PythonAppDev';
import TypescriptAppDev from './pages/services/webAppDev/typescriptAppDev/TypescriptAppDev';

import EcommerceDev from './pages/services/ecommerceDev/EcommerceDev';
import MagentoDev from './pages/services/ecommerceDev/magentoDev/MagentoDev';
import BigCommerceDev from './pages/services/ecommerceDev/bigCommerceDev/BigCommerceDev';
import UberCartDev from './pages/services/ecommerceDev/uberCartDev/UberCartDev';
import CsCartDev from './pages/services/ecommerceDev/csCartDev/CsCartDev';
import PrestashopDev from './pages/services/ecommerceDev/prestashopDev/PrestashopDev';
import VirtueMartDev from './pages/services/ecommerceDev/virtueMartDev/VirtueMartDev';
import ShopifyDev from './pages/services/ecommerceDev/shopifyDev/ShopifyDev';
import WooCommerceDev from './pages/services/ecommerceDev/wooCommerceDev/WooCommerceDev';

import BlockchainDev from './pages/services/blockchainDev/BlockchainDev';
import WalletDev from './pages/services/blockchainDev/walletDev/WalletDev';
import ExchangeDev from './pages/services/blockchainDev/exchangeDev/ExchangeDev';
import EthereumDev from './pages/services/blockchainDev/ethereumDev/EthereumDev';
import HyperledgerDev from './pages/services/blockchainDev/hyperledgerDev/HyperledgerDev';
import SmartContractsDev from './pages/services/blockchainDev/smartContractsDev/SmartContractsDev';
import PrivateBlockchainDev from './pages/services/blockchainDev/privateBlockchainDev/PrivateBlockchainDev';
import NftMarketplaceDev from './pages/services/blockchainDev/nftMarketplaceDev/NftMarketplaceDev';

import GameDev from './pages/services/gameDev/GameDev';
import Unity3dDev from './pages/services/gameDev/unity3dDev/Unity3dDev';
import MetaverseDev from './pages/services/gameDev/metaverseDev/MetaverseDev';
import UnrealEngine from './pages/services/gameDev/unrealEngineDev/UnrealEngineDev';
import AugmentedRealityDev from './pages/services/gameDev/augmentedRealityDev/AugmentedRealityDev';
import VirtualRealityDev from './pages/services/gameDev/virtualRealityDev/VirtualRealityDev';
import CasualGamesDev from './pages/services/gameDev/casualGamesDev/CasualGamesDev';

import SalesforceSolutions from './pages/services/salesforceSolutions/SalesforceSolutions';
import SalesforceDev from './pages/services/salesforceSolutions/salesforceDev/SalesforceDev';
import SalesforceConsulting from './pages/services/salesforceSolutions/salesforceConsulting/SalesforceConsulting';
import SalesforceImplementation from './pages/services/salesforceSolutions/salesforceImplementation/SalesforceImplementation';

import AiAndMl from './pages/services/aiAndMl/AiAndMl';
import ObjectRecognition from './pages/services/aiAndMl/objectRecognition/ObjectRecognition';
import TextToSpeech from './pages/services/aiAndMl/textToSpeech/TextToSpeech';
import BusinessIntelligence from './pages/services/aiAndMl/businessIntelligence/BusinessIntelligence';
import DataForecasting from './pages/services/aiAndMl/dataForecasting/DataForecasting';
import Nlp from './pages/services/aiAndMl/nlp/Nlp';
import DataAnalytics from './pages/services/aiAndMl/dataAnalytics/DataAnalytics';
import RecommendationEngine from './pages/services/aiAndMl/recommendationEngine/RecommendationEngine';
import SentimentAnalysis from './pages/services/aiAndMl/sentimentAnalysis/SentimentAnalysis';
import AlexaSkillDev from './pages/services/aiAndMl/alexaSkillDev/AlexaSkillDev';

import IotAndEmbedded from './pages/services/iotAndEmbedded/IotAndEmbedded';
import IotApp from './pages/services/iotAndEmbedded/iotApp/IotApp';
import EmbeddedSoftware from './pages/services/iotAndEmbedded/embeddedSoftware/EmbeddedSoftware';
import IotHardwarePrototyping from './pages/services/iotAndEmbedded/iotHardwarePrototyping/IotHardwarePrototyping';
import IotDashboardAndAnalytics from './pages/services/iotAndEmbedded/iotDashboardAndAnalytics/IotDashboardAndAnalytics';
import SmartHome from './pages/services/iotAndEmbedded/smartHome/SmartHome';

import Devops from './pages/services/devops/Devops';

import CorporateBranding from './pages/services/corporateBranding/CorporateBranding';
import UiUxDesign from './pages/services/corporateBranding/uiUxDesign/UiUxDesign';
import UxAuditReview from './pages/services/corporateBranding/uxAuditReview/UxAuditReview';
import VisualIdentityDesign from './pages/services/corporateBranding/visualIdentityDesign/VisualIdentityDesign';
import DigitalBranding from './pages/services/corporateBranding/digitalBranding/DigitalBranding';
import PackagingDesign from './pages/services/corporateBranding/packagingDesign/PackagingDesign';

// hire developers
import HireDevs from './pages/hireDevelopers/HireDevs';
import HireAppDevs from './pages/hireDevelopers/hireAppDevs/HireAppDevs';
import HireWebDevs from './pages/hireDevelopers/hireWebDevs/HireWebDevs';
import HireSoftwareDevs from './pages/hireDevelopers/hireSoftwareDevs/HireSoftwareDevs';
import HireFullStackDevs from './pages/hireDevelopers/hireFullStackDevs/HireFullStackDevs';
import HireFrontendDevs from './pages/hireDevelopers/hireFrontendDevs/HireFrontendDevs';
import HireBackendDevs from './pages/hireDevelopers/hireBackendDevs/HireBackendDevs';
import HireUiUxDesigners from './pages/hireDevelopers/hireUiUxDesigners/HireUiUxDesigners';
import HireIotSolutionArchitect from './pages/hireDevelopers/hireIotSolutionArchitect/HireIotSolutionArchitect';
import HireDataScientist from './pages/hireDevelopers/hireDataScientist/HireDataScientist';
import HireSalesforceDevs from './pages/hireDevelopers/hireSalesforceDevs/HireSalesforceDevs';
import HireBlockchainDevs from './pages/hireDevelopers/hireBlockchainDevs/HireBlockchainDevs';
import HireAndroidDevs from './pages/hireDevelopers/hireAndroidDevs/HireAndroidDevs';
import HireIosDevs from './pages/hireDevelopers/hireIosDevs/HireIosDevs';
import HireIphoneDevs from './pages/hireDevelopers/hireIphoneDevs/HireIphoneDevs';
import HireFlutterDevs from './pages/hireDevelopers/hireFlutterDevs/HireFlutterDevs';
import HireReactNativeDevs from './pages/hireDevelopers/hireReactNativeDevs/HireReactNativeDevs';
import HireSwiftDevs from './pages/hireDevelopers/hireSwiftDevs/HireSwiftDevs';
import HireIonicDevs from './pages/hireDevelopers/hireIonicDevs/HireIonicDevs';
import HireKotlinDevs from './pages/hireDevelopers/hireKotlinDevs/HireKotlinDevs';
import HireJavaDevs from './pages/hireDevelopers/hireJavaDevs/HireJavaDevs';
import HireAngularJsDevs from './pages/hireDevelopers/hireAngularJsDevs/HireAngularJsDevs';
import HireReactJsDevs from './pages/hireDevelopers/hireReactJsDevs/HireReactJsDevs';
import HireNodeJsDevs from './pages/hireDevelopers/hireNodeJsDevs/HireNodeJsDevs';
import HireVueJsDevs from './pages/hireDevelopers/hireVueJsDevs/HireVueJsDevs';
import HirePhpDevs from './pages/hireDevelopers/hirePhpDevs/HirePhpDevs';
import HireWordpressDevs from './pages/hireDevelopers/hireWordpressDevs/HireWordpressDevs';
import HireCakePhpDevs from './pages/hireDevelopers/hireCakePhpDevs/HireCakePhpDevs';
import HireCodeIgniterDevs from './pages/hireDevelopers/hireCodeIgniterDevs/HireCodeIgniterDevs';
import HireLaravelDevs from './pages/hireDevelopers/hireLaravelDevs/HireLaravelDevs';
import HireYiiDevs from './pages/hireDevelopers/hireYiiDevs/HireYiiDevs';
import HireMagentoDevs from './pages/hireDevelopers/hireMagentoDevs/HireMagentoDevs';
import HireShopifyDevs from './pages/hireDevelopers/hireShopifyDevs/HireShopifyDevs';
import HireDrupalDevs from './pages/hireDevelopers/hireDrupalDevs/HireDrupalDevs';
import HireWooCommerceDevs from './pages/hireDevelopers/hireWooCommerceDevs/HireWooCommerceDevs';
import HirePythonDevs from './pages/hireDevelopers/hirePythonDevs/HirePythonDevs';
import HireGolangDevs from './pages/hireDevelopers/hireGolangDevs/HireGolangDevs';
import HireSolidityDevs from './pages/hireDevelopers/hireSolidityDevs/HireSolidityDevs';
import HireDartDevs from './pages/hireDevelopers/hireDartDevs/HireDartDevs';
import HireSpringBootDevs from './pages/hireDevelopers/hireSpringBootDevs/HireSpringBootDevs';
import HireDjangoDevs from './pages/hireDevelopers/hireDjangoDevs/HireDjangoDevs';

// resources
import Resources from './pages/resources/Resources';
import Blog from './pages/resources/blog/Blog';
import Research from './pages/resources/research/Research';
import MediaCoverage from './pages/resources/mediaCoverage/MediaCoverage';
import PressRelease from './pages/resources/pressRelease/PressRelease';
import TechNews from './pages/resources/techNews/TechNews';
import WhitePaper from './pages/resources/whitePaper/WhitePaper';
import Podcast from './pages/resources/podcast/Podcast';
import Portfolio from './pages/resources/portfolio/Portfolio';
import DownloadBrochure from './pages/resources/downloadBrochure/DownloadBrochure';
import CaseStudy from './pages/resources/caseStudy/CaseStudy';

// contact us
import ContactUs from './pages/contactUs/ContactUs';

// brands
import Brands from './pages/brands/Brands';
import Porsche from './pages/brands/porsche/Porsche';
import Lg from './pages/brands/lg/Lg';
import Citizen from './pages/brands/citizen/Citizen';
import DubaiAirports from './pages/brands/dubaiAirports/DubaiAirports';
import QatarTransport from './pages/brands/qatarTransport/QatarTransport';
import Renault from './pages/brands/renault/Renault';
import EducationUsa from './pages/brands/educationUsa/EducationUsa';
import Clhu from './pages/brands/clhu/Clhu';
import Gpca from './pages/brands/gpca/Gpca';

// others
import CyberSecurityPolicy from './pages/cyberSecurityPolicy/CyberSecurityPolicy';
import Sitemap from './pages/sitemap/Sitemap';

// admin
import Admin from './pages/admin/Admin';
import Dashboard from './pages/admin/dashboard/Dashboard';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: 'auth',
                element: <Auth />,
                children: [
                    {
                        path: 'login',
                        element: <Login />,
                    }
                ]
            },
            {
                path: 'company',
                element: <Company />,
                children: [
                    {
                        path: 'aboutUs',
                        element: <AboutUs />,
                    },
                    {
                        path: 'services',
                        element: <Services />,
                    },
                    {
                        path: 'career',
                        element: <Career />,
                        children: [
                            {
                                path: 'applyNow',
                                element: <ApplyNow />,
                            }
                        ]
                    },
                    {
                        path: 'events',
                        element: <Events />,
                    },
                    {
                        path: 'ourClients',
                        element: <OurClients />,
                    },
                    {
                        path: 'ourExpertise',
                        element: <OurExpertise />,
                    },
                    {
                        path: 'ourTeam',
                        element: <OurTeam />,
                    }
                ]
            },
            {
                path: 'services',
                element: <ServicesMain />,
                children: [
                    {
                        path: 'mobileAppDev',
                        element: <MobileAppDev />,
                        children: [

                            {
                                path: 'androidAppDev',
                                element: <AndroidAppDev />,
                            },
                            {
                                path: 'iphoneAppDev',
                                element: <IphoneAppDev />
                            },
                            {
                                path: 'hybridAppDev',
                                element: <HybridAppDev />
                            },
                            {
                                path: 'flutterAppDev',
                                element: <FlutterAppDev />
                            },
                            {
                                path: 'reactNativeAppDev',
                                element: <ReactNativeAppDev />
                            },
                            {
                                path: 'kotlinAppDev',
                                element: <KotlinAppDev />
                            },
                            {
                                path: 'ionicAppDev',
                                element: <IonicAppDev />
                            },
                            {
                                path: 'swiftAppDev',
                                element: <SwiftAppDev />
                            },
                            {
                                path: 'xamarinAppDev',
                                element: <XamarinAppDev />
                            }
                        ]
                    },
                    {
                        path: 'webAppDev',
                        element: <WebAppDev />,
                        children: [
                            {
                                path: 'phpAppDev',
                                element: <PhpAppDev />
                            },
                            {
                                path: 'wordpressAppDev',
                                element: <WordpressAppDev />
                            },
                            {
                                path: 'drupalAppDev',
                                element: <DrupalAppDev />
                            },
                            {
                                path: 'laravelAppDev',
                                element: <LaravelAppDev />
                            },
                            {
                                path: 'reactJsAppDev',
                                element: <ReactJsAppDev />
                            },
                            {
                                path: 'angularJsAppDev',
                                element: <AngularJsAppDev />
                            },
                            {
                                path: 'nodeJsAppDev',
                                element: <NodeJsAppDev />
                            },
                            {
                                path: 'codeIgniterAppDev',
                                element: <CodeIgniterAppDev />
                            },
                            {
                                path: 'cakePhpAppDev',
                                element: <CakePhpAppDev />
                            },
                            {
                                path: 'pythonAppDev',
                                element: <PythonAppDev />
                            },
                            {
                                path: 'typescriptAppDev',
                                element: <TypescriptAppDev />
                            }
                        ]
                    },
                    {
                        path: 'ecommerceDev',
                        element: <EcommerceDev />,
                        children: [
                            {
                                path: 'magentoDev',
                                element: <MagentoDev />
                            },
                            {
                                path: 'bigCommerceDev',
                                element: <BigCommerceDev />
                            },
                            {
                                path: 'uberCartDev',
                                element: <UberCartDev />
                            },
                            {
                                path: 'csCartDev',
                                element: <CsCartDev />
                            },
                            {
                                path: 'prestashopDev',
                                element: <PrestashopDev />
                            },
                            {
                                path: 'virtueMartDev',
                                element: <VirtueMartDev />
                            },
                            {
                                path: 'shopifyDev',
                                element: <ShopifyDev />
                            },
                            {
                                path: 'wooCommerceDev',
                                element: <WooCommerceDev />
                            }
                        ]
                    },
                    {
                        path: 'blockchainDev',
                        element: <BlockchainDev />,
                        children: [
                            {
                                path: 'walletDev',
                                element: <WalletDev />
                            },
                            {
                                path: 'exchangeDev',
                                element: <ExchangeDev />
                            },
                            {
                                path: 'ethereumDev',
                                element: <EthereumDev />
                            },
                            {
                                path: 'hyperledgerDev',
                                element: <HyperledgerDev />
                            },
                            {
                                path: 'smartContractsDev',
                                element: <SmartContractsDev />
                            },
                            {
                                path: 'privateBlockchainDev',
                                element: <PrivateBlockchainDev />
                            },
                            {
                                path: 'nftMarketplaceDev',
                                element: <NftMarketplaceDev />
                            }
                        ]
                    },
                    {
                        path: 'gameDev',
                        element: <GameDev />,
                        children: [
                            {
                                path: 'unity3dDev',
                                element: <Unity3dDev />
                            },
                            {
                                path: 'metaverseDev',
                                element: <MetaverseDev />
                            },
                            {
                                path: 'unrealEngineDev',
                                element: <UnrealEngine />
                            },
                            {
                                path: 'augmentedRealityDev',
                                element: <AugmentedRealityDev />
                            },
                            {
                                path: 'virtualRealityDev',
                                element: <VirtualRealityDev />
                            },
                            {
                                path: 'casualGamesDev',
                                element: <CasualGamesDev />
                            }
                        ]
                    },
                    {
                        path: 'salesforceSolutions',
                        element: <SalesforceSolutions />,
                        children: [
                            {
                                path: 'salesforceDev',
                                element: <SalesforceDev />
                            },
                            {
                                path: 'salesforceConsulting',
                                element: <SalesforceConsulting />
                            },
                            {
                                path: 'salesforceImplementation',
                                element: <SalesforceImplementation />
                            }
                        ]
                    },
                    {
                        path: 'aiAndMl',
                        element: <AiAndMl />,
                        children: [
                            {
                                path: 'objectRecognition',
                                element: <ObjectRecognition />
                            },
                            {
                                path: 'textToSpeech',
                                element: <TextToSpeech />
                            },
                            {
                                path: 'businessIntelligence',
                                element: <BusinessIntelligence />
                            },
                            {
                                path: 'dataForecasting',
                                element: <DataForecasting />
                            },
                            {
                                path: 'nlp',
                                element: <Nlp />
                            },
                            {
                                path: 'dataAnalytics',
                                element: <DataAnalytics />
                            },
                            {
                                path: 'recommendationEngine',
                                element: <RecommendationEngine />
                            },
                            {
                                path: 'sentimentAnalysis',
                                element: <SentimentAnalysis />
                            },
                            {
                                path: 'alexaSkillDev',
                                element: <AlexaSkillDev />
                            }
                        ]
                    },
                    {
                        path: 'iotAndEmbedded',
                        element: <IotAndEmbedded />,
                        children: [
                            {
                                path: 'iotApp',
                                element: <IotApp />
                            },
                            {
                                path: 'embeddedSoftware',
                                element: <EmbeddedSoftware />
                            },
                            {
                                path: 'iotHardwarePrototyping',
                                element: <IotHardwarePrototyping />
                            },
                            {
                                path: 'iotDashboardAndAnalytics',
                                element: <IotDashboardAndAnalytics />
                            },
                            {
                                path: 'smartHome',
                                element: <SmartHome />
                            }
                        ]
                    },
                    {
                        path: 'corporateBranding',
                        element: <CorporateBranding />,
                        children: [
                            {
                                path: "uiUxDesign",
                                element: <UiUxDesign />
                            },
                            {
                                path: "uxAuditReview",
                                element: <UxAuditReview />
                            },
                            {
                                path: "visualIdentityDesign",
                                element: <VisualIdentityDesign />
                            },
                            {
                                path: "digitalBranding",
                                element: <DigitalBranding />
                            },
                            {
                                path: "packagingDesign",
                                element: <PackagingDesign />
                            }
                        ]
                    },
                    {
                        path: 'devops',
                        element: <Devops />
                    }
                ]
            },
            {
                path: 'hireDevs',
                element: <HireDevs />,
                children: [
                    {
                        path: 'hireAppDevs',
                        element: <HireAppDevs />
                    },
                    {
                        path: 'hireWebDevs',
                        element: <HireWebDevs />
                    },
                    {
                        path: 'hireSoftwareDevs',
                        element: <HireSoftwareDevs />
                    },
                    {
                        path: 'hireFullStackDevs',
                        element: <HireFullStackDevs />
                    },
                    {
                        path: 'hireFrontendDevs',
                        element: <HireFrontendDevs />
                    },
                    {
                        path: 'hireBackendDevs',
                        element: <HireBackendDevs />
                    },
                    {
                        path: 'hireUiUxDesigners',
                        element: <HireUiUxDesigners />
                    },
                    {
                        path: 'hireIotSolutionArchitects',
                        element: <HireIotSolutionArchitect />
                    },
                    {
                        path: 'hireDataScientists',
                        element: <HireDataScientist />
                    },
                    {
                        path: 'hireSalesforceDevs',
                        element: <HireSalesforceDevs />
                    },
                    {
                        path: 'hireBlockchainDevs',
                        element: <HireBlockchainDevs />
                    },
                    {
                        path: 'hireAndroidDevs',
                        element: <HireAndroidDevs />
                    },
                    {
                        path: 'hireIosDevs',
                        element: <HireIosDevs />
                    },
                    {
                        path: 'hireIphoneDevs',
                        element: <HireIphoneDevs />
                    },
                    {
                        path: 'hireFlutterDevs',
                        element: <HireFlutterDevs />
                    },
                    {
                        path: 'hireReactNativeDevs',
                        element: <HireReactNativeDevs />
                    },
                    {
                        path: 'hireSwiftDevs',
                        element: <HireSwiftDevs />
                    },
                    {
                        path: 'hireIonicDevs',
                        element: <HireIonicDevs />
                    },
                    {
                        path: 'hireKotlinDevs',
                        element: <HireKotlinDevs />
                    },
                    {
                        path: 'hireJavaDevs',
                        element: <HireJavaDevs />
                    },
                    {
                        path: 'hireAngularJsDevs',
                        element: <HireAngularJsDevs />
                    },
                    {
                        path: 'hireReactJsDevs',
                        element: <HireReactJsDevs />
                    },
                    {
                        path: 'hireNodeJsDevs',
                        element: <HireNodeJsDevs />
                    },
                    {
                        path: 'hireVueJsDevs',
                        element: <HireVueJsDevs />
                    },
                    {
                        path: 'hirePhpDevs',
                        element: <HirePhpDevs />
                    },
                    {
                        path: 'hireWordpressDevs',
                        element: <HireWordpressDevs />
                    },
                    {
                        path: 'hireCakePhpDevs',
                        element: <HireCakePhpDevs />
                    },
                    {
                        path: 'hireCodeIgniterDevs',
                        element: <HireCodeIgniterDevs />
                    },
                    {
                        path: 'hireLaravelDevs',
                        element: <HireLaravelDevs />
                    },
                    {
                        path: 'hireYiiDevs',
                        element: <HireYiiDevs />
                    },
                    {
                        path: 'hireMagentoDevs',
                        element: <HireMagentoDevs />
                    },
                    {
                        path: 'hireShopifyDevs',
                        element: <HireShopifyDevs />
                    },
                    {
                        path: 'hireDrupalDevs',
                        element: <HireDrupalDevs />
                    },
                    {
                        path: 'hireWooCommerceDevs',
                        element: <HireWooCommerceDevs />
                    },
                    {
                        path: 'hirePythonDevs',
                        element: <HirePythonDevs />
                    },
                    {
                        path: 'hireGolangDevs',
                        element: <HireGolangDevs />
                    },
                    {
                        path: 'hireSolidityDevs',
                        element: <HireSolidityDevs />
                    },
                    {
                        path: 'hireDartDevs',
                        element: <HireDartDevs />
                    },
                    {
                        path: 'hireSpringBootDevs',
                        element: <HireSpringBootDevs />
                    },
                    {
                        path: 'hireDjangoDevs',
                        element: <HireDjangoDevs />
                    }
                ]
            },
            {
                path: 'resources',
                element: <Resources />,
                children: [
                    {
                        path: 'blog',
                        element: <Blog />
                    },
                    {
                        path: 'research',
                        element: <Research />
                    },
                    {
                        path: 'mediaCoverage',
                        element: <MediaCoverage />
                    },
                    {
                        path: 'pressRelease',
                        element: <PressRelease />
                    },
                    {
                        path: 'techNews',
                        element: <TechNews />
                    },
                    {
                        path: 'whitePaper',
                        element: <WhitePaper />
                    },
                    {
                        path: 'podcast',
                        element: <Podcast />
                    },
                    {
                        path: 'portfolio',
                        element: <Portfolio />
                    },
                    {
                        path: 'downloadBrochure',
                        element: <DownloadBrochure />
                    },
                    {
                        path: 'caseStudy',
                        element: <CaseStudy />
                    }
                ]
            },
            {
                path: "contactUs",
                element: <ContactUs />
            },
            {
                path: "cyberSecurityPolicy",
                element: <CyberSecurityPolicy />
            },
            {
                path: "sitemap",
                element: <Sitemap />
            },
            {
                path: "brands",
                element: <Brands />,
                children: [
                    {
                        path: "porsche",
                        element: <Porsche />
                    },
                    {
                        path: "lg",
                        element: <Lg />
                    },
                    {
                        path: "citizen",
                        element: <Citizen />
                    },
                    // {
                    //     path: "dubaiAirports",
                    //     element: <DubaiAirports />
                    // },
                    {
                        path: "qatarTransport",
                        element: <QatarTransport />
                    },
                    {
                        path: "renault",
                        element: <Renault />
                    },
                    {
                        path: "educationUsa",
                        element: <EducationUsa />
                    },
                    {
                        path: "clhu",
                        element: <Clhu />
                    },
                    {
                        path: "gpca",
                        element: <Gpca />
                    }
                ]
            },
            {
                path: "admin",
                element: <Admin />,
                children: [
                    {
                        path: "dashboard",
                        element: <Dashboard />
                    }
                ]
            }
        ]
    }
])
