import da3 from '../../../assets/pages/service/aiAndMl/da3.png';
import da4 from '../../../assets/pages/service/aiAndMl/da4.png';

export const dataAnalyticsData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "An important component of artificial intelligence is data analytics. Making a data-driven choice improves the company and aids in building brand value. The gathered information helps the business to do insightful analysis. Additionally, data analytics lowers market risks and enables us to make wise selections. Stated differently, it facilitates the conversion of company data into insights that can be put to use. We are able to make quick, data-driven judgments thanks to data analytics.",
            "A realistic leverage model, actionable insights, and a corporate development goal are all provided by data analytics. Data analytics allows for the knowledge of current market patterns. Using the tool, the following modifications and patterns are put into practice. That's a clever way to get useful information and answers. Among the many benefits of artificial intelligence are automation, optimization, and aspect. Businesses may increase customer loyalty to their brands, interact with customers via improved throughput and data-driven decision processes, and monetize their earnings with its assistance."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "several components of data analytics",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: 'Data Retrieval',
                description: 'Data warehousing is a novel approach to gather and derive insightful information. The Data Warehouse represents data optimization, rapid speed and efficiency with increased data quantities, among other things. integrating data warehouses in a manner that balances data usefulness and optimal performance.'
            },
            {
                id: 2,
                title: 'Visualization of Data',
                description: 'For data to be relevant and valuable, clever and insightful explanations are necessary. The professionals in data visualization examine the information using creative diagrams, infographics, and other methods to help you find hidden value, generate original reports, and build your decision support system.'
            },
            {
                id: 3,
                title: 'Data Transfer',
                description: 'Data migrations provide digital transformation, which upgrades and improves the current data. The procedure is automated and contributes to its advantages. The benefit of data migration is that business processes are not hampered in any way. It is very scalable, dependable, safe, and aids in monitoring data migration maintenance.'
            },
            {
                id: 4,
                title: 'Large Data',
                description: 'Big Data solutions provide insight into consumer behavior, operational processes, and other areas while assisting with risk prevention. Achieving the desired company results is achievable with the newest technology and appropriate instruments. Classifying vast amounts of data into a single basis is made easier by big data technologies.'
            },
            {
                id: 5,
                title: 'Management of Data Quality',
                description: 'Data quality management aids in determining whether or not the operation is operating appropriately. Since data management steps in to aid when things go wrong, it\'s also known as rescue management. With skill and expertise, the team took on the challenging tasks of quality control, migration, integration, data governance, deployment, and archiving. Safeguard your information, upgrade security, and get organized, formatted, and sifted data for examination.'
            },
            {
                id: 6,
                title: 'Knowledge Science',
                description: 'One of the components of data analytics that is utilized most commonly is data science. It helps compile vast amounts of data into one and generates and manages them quickly. The element has expertise in arithmetic, advanced statistics, machine learning, programming, predictive modeling, and other areas in addition to data visualization and its fundamental technique.'
            }
        ]
    },
    {
        id: 3,
        title: "Services for the Data Analytics Industry:",
        description: "",
        onlyBullets: true,
        data: [
            "Financial and business research",
            "Medical analysis",
            "Client data analysis",
            "Analytics services for HR",
            "Services for supply chain data analytics"
        ],
        bulletsPic: da3
    },
    {
        id: 4,
        title: "Method We Employ:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Required Data Collection",
                description: "Understanding what information is needed and how to group it into the appropriate categories is crucial. Our professionals identify the company requirements and follow the difficulties to be overcome. We gather pertinent data from many sources as well as the current database. Quantifying existing data and establishing KPIs and goals are helpful. Consistency, cooperation, and other factors go into the formation of the data."
            },
            {
                id: 2,
                title: "Analyzing Data",
                description: "The procedure that follows is an examination of the information and data that Data Analytics has collected. After the data analysis is improved, the future prediction is identified and offers a fresh framework for data study. Finding patterns, establishing comparisons, and developing insights are all beneficial. Additionally, the tool eliminates information that is unneeded, false, and unrelated to the goals. To put it precisely, the data structure is a frame."
            },
            {
                id: 3,
                title: "Optimization of Data",
                description: "To prepare for future repercussions, data analysis is calculated and a predictive approach is used. After the goals are established, it optimizes the data and puts it into action. The tool is expandable to the firm, dependable, and reasonably priced. Data optimization is used to produce a conclusion for further study."
            },
            {
                id: 4,
                title: "Last Deploy Procedure",
                description: "Deployment and maintenance are the last stage of the procedure. Deployed, monitored, and more is the outcome. Our data analyst maintains the goal's upkeep while continuously monitoring obstacles and problems."
            }
        ]
    },
    {
        id: 5,
        title: "Why Should You Use Alta Tech Mark for Analytics in Data?",
        description: "",
        onlyBullets: true,
        data: [
            "Smooth procedure",
            "Newest instruments and technologies",
            "Optimum strategy",
            "Potent techniques"
        ],
        bulletsPic: da4
    },
    {
        id: 6,
        title: "",
        description: "Alta Tech Mark advances by turning data analytics into a significant and customized solution. Our knowledgeable professionals simplify company procedures and successfully complete your project with unwavering commitment. For your business, the data offers precise information that supports the creation of a strong, data-driven choice. Alta Tech Mark's technology and strong data have enabled the firm to grow to unprecedented heights in its operations. Our company's consulting staff is always there to assist. If you have any questions about data analytics, please use the contact information below.",
        onlyBullets: false,
        data: []
    }
]