import React from 'react';

// assets
import iad1 from '../../../../assets/pages/service/iot/iad1.jpeg';
import iad2 from '../../../../assets/pages/service/iot/iad2.png';
import { iotAppDevData } from '../../../../data/services/iotDevData/iotAppDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function IotApp() {
    return (
        <ServicesWrapper
            title="IoT Product Development"
            descriptionBold="Artificial Intelligence & Machine Learning"
            descriptionCont="We boosts your business with advanced image, video, text-to-speech, business intelligence, forecasting, NLP, and data analytics solutions."
            img={iad1}
        >
            <DevGridWrapper img={iad2}>
                <DataMapper data={iotAppDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
