import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// data
import { partnershipModelsData } from '../../../data/partnershipModelsData';
import partnershipModelBg from '../../../assets/background/partnershipModelBg.png';

// components
import PartnershipModelsCard from './PartnershipModelsCard';
import HeaderText from '../../headerText/HeaderText';

export default function PartnershipModels() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-py-[4vw] tw-px-[9.115vw]'
            style={{
                backgroundImage: `linear-gradient(90deg, #9065FD 0.03%, #9065FD 24.31%, #8256F1 44.14%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${partnershipModelBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <HeaderText
                title="OUR THREE PARTNERSHIP MODELS"
                description="Alta Tech Mark, a premier mobile app development firm in both the USA and India, provides bespoke app development solutions to a diverse range of industries and businesses. Learn more about our collaboration models."
                className='tw-text-white tw-mb-8 md:tw-text-left tw-text-center'
            />

            {screenWidth < 768 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {partnershipModelsData.map(partnershipModel =>
                        <SwiperSlide key={partnershipModel.id}>
                            <div className='tw-h-[350px]'>
                                <PartnershipModelsCard
                                    src={partnershipModel.src}
                                    title={partnershipModel.title}
                                    description={partnershipModel.description}
                                    inSwiper={true}
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
                :
                <div className='tw-grid tw-grid-cols-3 tw-w-full tw-gap-[1.628vw]'>
                    {partnershipModelsData.map(partnershipModel => <PartnershipModelsCard
                        key={partnershipModel.id}
                        src={partnershipModel.src}
                        title={partnershipModel.title}
                        description={partnershipModel.description}
                    />)}
                </div>
            }
        </div>
    )
}
