import img1 from '../../../assets/icons/webPage/1.png';
import img2 from '../../../assets/icons/webPage/2.png';
import img3 from '../../../assets/icons/webPage/3.png';
import img4 from '../../../assets/icons/webPage/4.png';
import img5 from '../../../assets/icons/webPage/5.png';
import img6 from '../../../assets/icons/webPage/6.png';

export const webProcessData = [
    {
        id: 1,
        title: "Planning",
        description: "Compile all the data, make a project plan, and create the concept for the CMS and website construction."
    },
    {
        id: 2,
        title: "Web Designing",
        description: "Our front-end designers will work with the wireframe, UI/UX design, and prototype to create an amazing and eye-catching responsive website."
    },
    {
        id: 3,
        title: "Development",
        description: "Once the goals of the website have been determined, the next step is development. The procedure is the website's preliminary phase."
    },
    {
        id: 4,
        title: "Testing",
        description: "To make sure there are no bugs on the website and that you have a flawless, user-friendly website, you must run a test."
    },
    {
        id: 5,
        title: "Deployment",
        description: "When everything has been discussed and covered, including design and deployment, the website may go live."
    },
    {
        id: 6,
        title: "Support & Maintenance",
        description: "Following the website's debut, we will provide complete assistance and respond to any questions following the deployment."
    }
];

export const webServicesData = [
    {
        id: 1,
        title: "Excellent Quality Delivered on Time",
        description: "We provide high-quality work quickly. In addition, we retain our timeliness and discipline while delivering high-quality work on time. We make sure the project is delivered to the highest standard possible.",
        src: img1
    },
    {
        id: 2,
        title: "Effective & Continuous Communication",
        description: "Our professionals are kind and communicate with the customer in an efficient and ongoing manner. We are goal-oriented and maintain open lines of communication so that the customer is aware of the project and all of its stages.",
        src: img2
    },
    {
        id: 3,
        title: "Full Confidentiality",
        description: "Alta Tech Mark respects individual privacy and maintains complete confidentiality of the data and information. When a customer has faith in us, we uphold their confidence by maintaining complete confidentiality.",
        src: img3
    },
    {
        id: 4,
        title: "Friendly & Cordial in Nature",
        description: "Our web & CMS developers are friendly and cordial. Our team is always ready to help and happily solve all the issues of the client. We are passionately always there for our clients and their project.",
        src: img4
    },
    {
        id: 5,
        title: "Experienced Team",
        description: "Years of Web & CMS development expertise are behind our team. They are knowledgeable in both internal and external technologies, methods, and tools. The unconventional approach makes our team stand out at the top.",
        src: img5
    },
    {
        id: 6,
        title: "Support",
        description: "With our deep understanding of CMS and Web development, we provide our customers unwavering assistance. Our professionals address any technical difficulties, bugs, and project-related questions promptly and cheerfully.",
        src: img6
    }
]