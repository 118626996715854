import React from 'react';

// assets
import cs1 from '../../../../assets/pages/service/ecommerceDev/cs1.jpeg';
import cs2 from '../../../../assets/pages/service/ecommerceDev/cs2.png';
import cs3 from '../../../../assets/pages/service/ecommerceDev/cs3.png';
import { csCartDevData } from '../../../../data/services/ecommerceDevData/csCartDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function CsCartDev() {
    return (
        <ServicesWrapper
            title="ECOMMERCE DEVELOPMENT CS CART"
            descriptionCont="Our E-Commerce website development professionals are experts. Alta Tech Mark developers provide high-quality, fast E-Commerce products. Our skilled staff develops E-Commerce websites utilizing cutting-edge, trustworthy technology. Magento, Shopify, BigCommerce, WooCommerce, and others"
            img={cs1}
        >
            <DevGridWrapper images={[cs2, cs3]}>
                <DataMapper data={csCartDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
