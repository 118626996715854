import bi3 from '../../../assets/pages/service/aiAndMl/bi3.png';
import bi4 from '../../../assets/pages/service/aiAndMl/bi4.png';

export const businessIntelData = [
    {
        id: 1,
        title: "",
        description: "One of the key components required to assess corporate operations is business intelligence. The service's objective is to optimally accomplish aims and goals. Excellent business intelligence services are provided by Alta Tech Mark, which provides a comprehensive end-to-end solution for a variety of business intelligence and analytics services. Developing thoughts and forecasting the anticipated expected opportunity are helpful. The performance of the present and the future may be measured with the use of descriptive analytics. The decision-making process is improved and presented with precise data interpretation with the use of business intelligence (BI). With its superior tools and technology, BI makes use of the business's analytical capabilities. Business intelligence is required when one can provide information without providing false or partial information. Because a poorly educated choice might have a negative impact on your company, business intelligence makes ensuring that data is accurate and well-informed. As a top mobile app development firm, we help you identify new trends, make precise decisions, use data-driven strategies, and uncover insightful information.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Major BI Tools That Our BI Experts Are Mastery in:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Excel BI",
                description: "It's a collection of software applications that facilitate connective collaboration to transform data into insightful knowledge. This powerful technology does a better job of transforming corporate structure."
            },
            {
                id: 2,
                title: "Tableau",
                description: "BI utilizes Tableau to make future predictions and comprehend them. Using data as a guide, make decisions based on it. It is among the most often used business intelligence tools."
            },
            {
                id: 3,
                title: "Plotly",
                description: "With the use of this application, managers may more easily execute analytics, statistics, and graphing tasks, as well as make predictions about the future. Analytics-gathered data is used in a variety of industries and business domains.",
                pic: bi3
            }
        ]
    },
    {
        id: 3,
        title: "We Are Able To Help With Our BI Tools Ability To:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Update Current Instruments And Procedures",
                description: "More productive work is produced by the more effective instrument. Keep up with the current technologies and trends with the help of the contemporary tools."
            },
            {
                id: 2,
                title: "Incorporate BI Tools Into Your Current Framework",
                description: "To draw attention to improved outcomes in your existing setup. It facilitates the operation of the current company and improves workflow efficiency."
            },
            {
                id: 3,
                title: "Interpret the Outcomes of BI Tools",
                description: "With the help of our specialist BI tools, we will reinvent the company. The enterprise requirement is prioritized and has an influence on business decisions when using a holistic approach."
            },
            {
                id: 4,
                title: "Choosing BI Tools",
                description: "To build a savvy company plan, clarify words, capacities, and opportunities. We choose the best instruments that have been shown to provide the greatest results."
            },
            {
                id: 5,
                title: "Simplified Implementation of Data",
                description: "It facilitates the seamless and simplified implementation of data. It streamlines the business's procedure and resolution."
            },
            {
                id: 6,
                title: "Data Retrieval",
                description: "The information is kept on file, and the data system offers business intelligence techniques. The data collecting yields insightful information."
            },
            {
                id: 7,
                title: "Visualization of Data",
                description: "With accurate data, it makes your company easier to see. The data visualization is precise and tailored to the business."
            },
            {
                id: 8,
                title: "Utilizing Data",
                description: "Business analysts may discover connections in data, possibilities, research, and much more with the use of data mining. Its primary purpose is to increase revenue growth via the implementation of marketing strategies."
            },
            {
                id: 9,
                title: "Advantages of Business Intelligence",
                description: "Convert company procedures and provide the optimal environment for data intelligence. Give real-time company performance measurement. Freehand summary and outline composition. Collaboration of target and forecast data between enterprises enables faster market response."
            }
        ]
    },
    {
        id: 5,
        title: "Sectors We Cover:",
        description: "",
        onlyBullets: true,
        data: [
            "Production",
            "Purchase",
            "Medical Care",
            "Vitality",
            "In bulk",
            "Electrical Drive",
            "Gas & Oil",
            "Networks",
            "Operations"
        ],
        bulletsPic: bi4
    },
    {
        id: 11,
        title: "For business intelligence, why Alta Tech Mark?",
        descriptions: [
            "You may use our organization's expertise to customize the power of business intelligence to advertise your company. We provide you strategies for the greatest results and assist you in improving your company's internet presence. The Alta Tech Mark team collected important data and information, then deduced insights from it to forecast future data analytics for more adept company, performance, and expansion. Business intelligence helps a firm expand by analyzing and optimizing useful data, identifying market trends, and developing its operations. Future obstacles are met head-on by our Alta Tech Mark specialists, who perform like pros. We take full use of the insights and use the data to create greater chances.",
            "To get a higher-caliber outcome, we think that the process should be followed, and we place an emphasis on quality to help our clients' businesses. We made advantage of the newest tools and technology, and Alta Tech Mark established a reputation for offering the best business intelligence services by offering exceptional service. Our knowledgeable staff assists you in every way and keeps up with the latest developments."
        ],
        onlyBullets: false,
        data: []
    }
]