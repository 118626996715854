import React from 'react';

// assets
import img1 from '../../../assets/pages/services/1.jpg';
import img2 from '../../../assets/pages/services/2.jpg';

// components
import BreadCrumb from '../../../components/breadCrumb/BreadCrumb';
import ServicesWeOffer from '../../../components/home/services/Services';
// import LatestBlogs from '../../../components/home/latestBlogs/LatestBlogs';
// import Podcasts from '../../../components/home/podcasts/Podcasts';
import Work from '../../../components/home/work/Work';
import SocialMedia from '../../../components/home/socialMedia/SocialMedia';
import FreeQuoteFormBg from '../../../components/services/freeQuoteFormBg/FreeQuoteFormBg';

export default function Services() {
    return (
        <div className='tw-py-[24px] tw-tracking-wide'>
            <BreadCrumb main='Home' sub='Services' />

            <div className='tw-grid tw-grid-cols-2 tw-px-[9.1vw] tw-gap-[50px]'>
                <div className='md:tw-col-span-1 tw-col-span-2'>
                    <h1 className='md:tw-h-[96px] tw-h-[76px] tw-font-semibold md:tw-text-4xl tw-text-3xl tw-mb-[10px] tw-flex tw-flex-col tw-justify-end'>Services</h1>
                    <h1>Because We Believe In <span className='tw-text-[#DC2E3D]'>Delivering Excellence!</span></h1>
                    <br />
                    <h1>
                        Various startups, business, and enterprises are targeting versatile platform to outreach maximum user base at all available platforms. Innovations in technology have surpassed the level of humans brainstorming, making life interactive and on the tap. In such competitive era, it's high time to shine your brand with strong online presence having rich, interactive, smart and responsive solutions crafted for end-users. Development of brand needs lots of efforts along with strong & trustworthy development partner that crafts your ideas and thoughts into the exceptional reality that odds out your business against millions of competitors. If you are looking for such type of solution partner then – Alta Tech Mark is the ultimate call off in the deliverance of excellence!
                    </h1>
                </div>
                <div className='md:tw-col-span-1 tw-col-span-2 tw-flex tw-items-end'>
                    <img src={img1} alt="services ref" />
                </div>
            </div>

            <ServicesWeOffer />

            <div className='tw-grid tw-grid-cols-2 tw-px-[9.1vw] tw-gap-[50px]'>
                <div className='md:tw-col-span-1 tw-col-span-2 tw-flex tw-items-end'>
                    <img src={img2} alt="services ref" />
                </div>
                <div className='md:tw-col-span-1 tw-col-span-2'>
                    <h1 className='md:tw-h-[96px] tw-h-[76px] tw-font-semibold md:tw-text-4xl tw-text-3xl tw-mb-[10px] tw-flex tw-flex-col tw-justify-end'>Why Make Us Your Outsourcing Partner?</h1>
                    <br />
                    <h1>
                        Being a reliable mobile app development company, we have the perfect blend of technical expertise and industry experience that help us to create unbeatable & responsive web and mobile apps. We get into the deepest roots of your requirements, scrutinize it thoroughly and frame it into a best competitive mobile app by using latest tools and technologies. We believe in practicing transparent communication channel with our clients and co-ordinate with them at every development stage to reflect the changes accordingly. We have converted dreams of more than 500+ startups, entrepreneurs, and big-labeled businesses by leveraging first-rate app solution at quite a competitive price. Make us your technology partner and experience the change with us!
                    </h1>
                </div>
            </div>

            {/* <LatestBlogs /> */}

            {/* <Podcasts /> */}

            <FreeQuoteFormBg />
            
            <Work />

            <SocialMedia />
        </div>
    )
}
