import lb1 from '../assets/latestBlogs/lb1.png';
import lb2 from '../assets/latestBlogs/lb2.png';
import lb3 from '../assets/latestBlogs/lb3.png';
import lb4 from '../assets/latestBlogs/lb4.png';
import lb5 from '../assets/latestBlogs/lb5.png';
import lb6 from '../assets/latestBlogs/lb6.png';

export const latestBlogsData = [
    {
        id: 1,
        src: lb1,
        title: "Artificial Intelligence (AI)",
        description: "Unveiling the Marvels of AI Development: Building the Future, 1 Algorithm at a Time",
        author: 'Sami Khursheed',
        date: "21 / 07 / 2023"
    },
    {
        id: 2,
        src: lb2,
        title: "Cloud Computing",
        description: "Unveiling the Marvels of AI Development: Building the Future, 1 Algorithm at a Time",
        author: 'Sami Khursheed',
        date: "18 / 07 / 2023"
    },
    {
        id: 3,
        src: lb3,
        title: "Art of Living A Meditation",
        description: "Unveiling the Marvels of AI Development: Building the Future, 1 Algorithm at a Time",
        author: 'Sami Khursheed',
        date: "14 / 07 / 2023"
    },
    {
        id: 4,
        src: lb4,
        title: "UI/UX Design",
        description: "Unveiling the Marvels of AI Development: Building the Future, 1 Algorithm at a Time",
        author: 'Sami Khursheed',
        date: "11 / 07 / 2023"
    },
    {
        id: 5,
        src: lb5,
        title: "Vocal Coach",
        description: "Unveiling the Marvels of AI Development: Building the Future, 1 Algorithm at a Time",
        author: 'Sami Khursheed',
        date: "08 / 07 / 2023"
    },
    {
        id: 6,
        src: lb6,
        title: "Digital Banking Solution",
        description: "Unveiling the Marvels of AI Development: Building the Future, 1 Algorithm at a Time",
        author: 'Sami Khursheed',
        date: "05 / 07 / 2023"
    }
]