import React from 'react';

// assets
import asd1 from '../../../../assets/pages/service/aiAndMl/asd1.jpeg';
import asd2 from '../../../../assets/pages/service/aiAndMl/asd2.png';
import { alexaSkillDevData } from '../../../../data/services/aiAndMl/alexaSkillDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function AlexaSkillDev() {
    return (
        <ServicesWrapper
            title="Alexa Skills Development Company"
            descriptionCont="Amazon's voice-activated technology is called Alexa. You may ask a smart speaker to grant your desires by speaking to it. Start with easy ones, like turning down the lights or turning on some music."
            img={asd1}
        >
            <DevGridWrapper img={asd2}>
                <DataMapper data={alexaSkillDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
