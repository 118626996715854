import React from 'react';

// data
import { hireSolidityDevData, hireSolidityDevFaqs } from '../../../data/hireDev/hireSolidityDev';
import solidityDev1 from '../../../assets/pages/hireDev/solidityDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireSolidityDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="SOLIDITY DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={solidityDev1}
            data={hireSolidityDevData}
            faq={hireSolidityDevFaqs}
        />
    )
}
