import React from 'react';

// assets
import d1 from '../../../assets/pages/service/devops/d1.jpeg';
import d2 from '../../../assets/pages/service/devops/d2.png';
import { devopsDevData } from '../../../data/services/devops/devopsDevData';

// components
import ServicesWrapper from './../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../components/services/dataMapper/DataMapper';

export default function Devops() {
    return (
        <ServicesWrapper
            title="DEVOPS & MAINTANENCE"
            descriptionCont="Ops stands for information technology operations, while Dev represents for software development. Reducing the system's development life cycle and ensuring continuous delivery of high-quality software are the goals of implementing DevOps."
            img={d1}
        >
            <DevGridWrapper img={d2}>
                <DataMapper data={devopsDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
