import React from 'react';

// assets
import ea1 from '../../../../assets/pages/service/blockchainDev/ea1.jpeg';
import ea2 from '../../../../assets/pages/service/blockchainDev/ea2.png'
import { ethereumDevData } from '../../../../data/services/blockchainDevData/ethereumDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function EthereumDev() {
    return (
        <ServicesWrapper
            title="BLOCKCHAIN DEVELOPMENT ETHEREUM"
            descriptionCont="Ethereum, an open Blockchain-based software platform, helps construct Smart Contracts and DApps. Ethereum app development is a new business invention, but it's already powerful for constructing and managing blockchain systems, explicit smart contracts, and clever new company organization concepts."
            img={ea1}
        >
            <DevGridWrapper img={ea2}>
                <DataMapper data={ethereumDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
