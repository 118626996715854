import React from 'react';

// assets
import da1 from '../../../../assets/pages/service/aiAndMl/da1.jpeg';
import da2 from '../../../../assets/pages/service/aiAndMl/da2.png';
import { dataAnalyticsData } from '../../../../data/services/aiAndMl/dataAnalyticsData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function DataAnalytics() {
    return (
        <ServicesWrapper
            title="Data Analytics"
            descriptionCont="A realistic leverage model, actionable insights, and a corporate development goal are all provided by data analytics. Data analytics allows for the knowledge of current market patterns. Using the tool, the following modifications and patterns are put into practice."
            img={da1}
        >
            <DevGridWrapper img={da2}>
                <DataMapper data={dataAnalyticsData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
