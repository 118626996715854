import React from 'react';

// data
import { hireYiiDevData, hireYiiDevFaqs } from '../../../data/hireDev/hireYiiDev';
import yiiDev1 from '../../../assets/pages/hireDev/yiiDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireYiiDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="YII DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={yiiDev1}
            data={hireYiiDevData}
            faq={hireYiiDevFaqs}
        />
    )
}
