import cv1 from '../../assets/pages/aboutUs/coreValues/cv1.png';
import cv2 from '../../assets/pages/aboutUs/coreValues/cv2.png';
import cv3 from '../../assets/pages/aboutUs/coreValues/cv3.png';
import cv4 from '../../assets/pages/aboutUs/coreValues/cv4.png';
import cv5 from '../../assets/pages/aboutUs/coreValues/cv5.png';
import cv6 from '../../assets/pages/aboutUs/coreValues/cv6.png';

export const coreValuesData = [
    {
        id: 1,
        title: "High Integrity",
        description: "Throughout the process, we uphold our high standards of professionalism and morality place a strong value on honesty.",
        src: cv1
    },
    {
        id: 2,
        title: "Give Back",
        description: "We are focused on transforming the businesses we serve into better places through genuine dealings and CSR exercises.",
        src: cv2
    },
    {
        id: 3,
        title: "Be Tenacious",
        description: "Execute, Instead of offering our consumers the quickest or cheapest fixes, we search for the greatest and most real solutions.",
        src: cv3
    },
    {
        id: 4,
        title: "Choose Joy",
        description: "We have faith in preserving an atmosphere of respect and candor. Additionally, our clients adore the manner we operate.",
        src: cv4
    },
    {
        id: 5,
        title: "Dream Big",
        description: "We give our customers the manner of life they've always desired. We offer a path to achievement in addition to solutions.",
        src: cv5
    },
    {
        id: 6,
        title: "Diversity Rules",
        description: "While promoting the open exchange of ideas, we adore working with people from all origins and lifestyles.",
        src: cv6
    }
]