import React from 'react';

// components
// import FreeQuoteForm from '../../freeQuoteForm/FreeQuoteForm';
// import PricingPlan from '../../../hireDev/pricingPlan/PricingPlan';

export default function DevGridWrapper(props) {
    return (
        <div className='tw-py-[3.95vw] tw-px-[9.115vw] tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-gap-[25px]'>
            <div className='tw-col-span-2 tw-flex tw-flex-col tw-gap-y-4'>
                {props.children}
            </div>
            <div className='tw-col-span-1 md:tw-flex tw-hidden md:tw-flex-col tw-items-end tw-justify-between'>
                {props.img && <img src={props.img} alt="img" className='tw-w-[80%] 2xl:tw-w-[50%]' />}
                {props.images && props.images.map((img, index) => <img key={index} src={img} alt="img" className='tw-w-[80%] 2xl:tw-w-[50%]' />)}
            </div>
        </div>
    )
}
