import React from 'react'

export default function TeamCard(props) {
    return (
        <div className='tw-col-span-1 tw-flex tw-flex-col tw-bg-cardsGradient tw-items-center tw-justify-around tw-text-white tw-py-4 tw-rounded-lg tw-pb-8 md:tw-h-[400px] tw-h-[350px]'>
            <img src={props.src} alt="member" className='tw-w-[200px] tw-rounded-full' />
            <div className='tw-flex tw-flex-col tw-items-center'>
                <h1 className='tw-text-xl'>{props.name}</h1>
                <h1 className='tw-text-xs'>{props.role}</h1>
            </div>
        </div>
    )
}
