import React from 'react'

// assets
import img1 from '../../../../assets/pages/service/mobileAppDev/kotlinAppDev/1.png';
import { kotlinDevData } from '../../../../data/services/mobileAppDevData/kotlinDevData';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// components
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function KotlinAppDev() {
    return (
        <ServicesWrapper 
            title="Kotlin Development Company"
            description="Hire Expert Kotlin App Developers for Android App Solution From Alta Tech Mark"
            img={img1}
        >
            <DevGridWrapper>
                <DataMapper data={kotlinDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
