export const hireBlockchainDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Blockchains are data structures that are used to create digital transaction ledgers that are dispersed over several computer networks, ideally with a single supplier. A blockchain is made up of ever expanding data blocks that are connected to one another and safeguarded by cryptography. Every block in a blockchain is linked to every other block through a hash pointer. One of the most cutting-edge and extremely secure methods for exchanging and storing private data is blockchain technology.",
            "The finest element is that a sizable community guards the database kept on the blockchain network. This lowers the total cost of data storage since your data is safely kept in many places. Thus, companies that handle massive volumes of critical data use blockchain engineers to build new, more efficient business procedures. By doing this, they guarantee that every transaction is carried out correctly and inexpensively documented."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Advantages of Using Blockchain",
        description: "",
        onlyBullets: true,
        data: [
            "Decentralization",
            "Transparency",
            "Reduced Transaction Costs",
            "Quality Data",
            "Immutability",
            "Trust"
        ]
    },
    {
        id: 3,
        title: "Hire Alta Tech Mark's Blockchain Developers:",
        descriptions: [
            "We at Alta Tech Mark are proud to have a team of knowledgeable blockchain engineers. They provide a wide variety of possible solutions and are skilled on all main platforms. Our team uses the newest technology to create solutions that are suited for startups and enterprises of all sizes worldwide. Their areas of expertise include data dissemination, storage, and reading; they also integrate security features via block additions and use Blockchain technology to validate transactions.",
            "A group of gifted and knowledgeable mobile developers at Alta Tech Mark are prepared to realize your ideas in an economical way. The following are the main benefits of working with Alta Tech Mark's blockchain developers:"
        ],
        onlyBullets: true,
        data: [
            "Process Automation",
            "Experience",
            "Passion",
            "Expertise",
            "Immutability",
            "Trust"
        ]
    },
    {
        id: 4,
        title: "",
        description: "We are excited to be working on decentralized applications, smart contracts, and blockchain technology. At low costs, Alta Tech Mark provides top-notch Blockchain development services. Our goal is to provide solutions that are unique, economical, and suited to your company's needs. Employ Alta Tech Mark's blockchain app developers to create your decentralized blockchain application.",
        onlyBullets: false,
        data: []
    }
]

export const hireBlockchainDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire Blockchain developers?",
        answer: "The dedicated Blockchain developers will work only for you to deliver outstanding results with the highest optimization. The team of dedicated Blockchain developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire dedicated Blockchain developers?",
        answer: "Alta Tech Mark offers a very simple process to hire dedicated Blockchain developers. Just provide project details and the technical expertise you need. The Blockchain developer begins working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Blockchain developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Blockchain developers before making a final move."
    },
    {
        id: 4,
        question: "4. How much does it cost to hire Blockchain developers?",
        answer: "The cost estimation for dedicated Blockchain developers varies based on various factors such as team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    },
    {
        id: 5,
        question: "5. Can I hire Blockchain developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Blockchain developer, then you can hire a team of dedicated Blockchain developers on an hourly or project/task base."
    }
]