export const reactNativeDevData = [
    {
        id: 0,
        title: "",
        descriptions: [
            "React Native is a new framework - developed by Facebook that helps the hybrid app developers to develop react native apps using the same UI building blocks which are used by Android and iOS. It helps you to develop native applications in JavaScript while still giving a real native user interface that allows reuse of code over the web and mobile. React native is the most preferred language in the industry as it is a single answer to the Enigma of Android and iOS.",
            "React Native framework provides a fast track to completion, performs well on targeted platforms, and is simple to debug. It has gained massive popularity, community support, and market share within the few years since it was introduced. The best part about using this technology over others is that it’s neither a mobile web app nor is it a hybrid app.",
            "As one of the well experienced React Native development company in Pakistan, USA, and UAE, our software solutions have been making classic React Native Apps. A team of Alta Tech Mark not only develop HTML5 or hybrid apps but are also experienced enough to develop real-time mobile applications using objective C or java. With this new technology, the whole mobile app development industry has been transformed and has become more efficient and faster in their development processes.",
            "Our mobile app developers make sure that they can identify client' unique requirements and can deliver the result driven react native apps which can set you apart from the rest of the market. Our focus lies on proper communication, transparency and QA services. Alta Tech Mark has the top mobile app developers, who can easily and cost-effectively develop an application that can adapt to your business goals."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 1,
        title: "Why React Native?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Component-Based",
                description: "React native enables you to develop enclosed components using Javascript which can manage their own state and so it can be assembled together to make complex Uis."
            },
            {
                id: 2,
                title: "The Virtual DOM",
                description: "The virtual DOM allows developers to view changes in a virtual memory rather than on the real screen that gives higher performance and a cleaner user experience all around."
            },
            {
                id: 3,
                title: "Maximum Code Re-usability",
                description: "React Native runs on JavaScript that enables code re-usability for mobile as well as web platforms. With reusable components, it makes possible for a hybrid app to perform natively just by compiling to native. So it reduces enough time of the app development process."
            },
            {
                id: 4,
                title: "Performance Obsessed",
                description: "The React Native framework makes use of the GPU of the mobile device, while other native languages are CPU centric. So it boosts the whole performance."
            },
            {
                id: 5,
                title: "No Dependency",
                description: "It provides a holistic ecosystem to make top notch apps and has no dependency on other technologies."
            },
            {
                id: 6,
                title: "Live Updates",
                description: "React Native framework allows push updates directly to the user's application without waiting for app store approvals, hence decreasing the go-to-market time."
            },
            {
                id: 7,
                title: "Efficiency",
                description: "React Native framework allows rich ecosystem, open source, excellent community support, efficiency in terms of responsiveness, high speed and agility of the overall development process."
            }
        ]
    },
    {
        id: 2,
        title: "Our React Native Development Services",
        description: "At Alta Tech Mark, we offer a complete range of React Native Development Services for different industry verticals like healthcare, e-learning, travel and much more. Our services cover:",
        onlyBullets: true,
        data: [
            "React Native Mobile App (Android & iOS) Development",
            "React Native App Maintenance",
            "React Native UI/UX for App",
            "React Plugin Development",
            "Hybrid Mobile App Development",
            "React Native Team Augmentation",
            "App Migration Services From Other Platform to React Native",
            "React Native Code Development"
        ]
    },
    {
        id: 3,
        title: "Why Alta Tech Mark is an Ultimate Solution for React Native App?",
        description: "Hire React Native developers with vast experience from Alta Tech Mark. Our team of React Native developers provides an outstanding solution to help businesses in marketing their products and services in no time.",
        noList: true,
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Top Notch developers",
                description: "We have skilled developers who do not settle for anything less than perfect and are experienced in developing unique React native mobile apps for different OS like Android and iOS etc."
            },
            {
                id: 2,
                title: "Quality assurance",
                description: "When it comes to quality we make sure that every line of code is quality assured so that we can provide you with suitable react-native services tailored to your business goals."
            },
            {
                id: 3,
                title: "Client satisfaction",
                description: "They have a great ability to make react native apps that can provide 100% client satisfaction through its result-oriented way."
            },
            {
                id: 4,
                title: "Support and maintenance",
                description: "We offer consistent support and maintenance services to complement your business objectives."
            },
            {
                id: 5,
                title: "Competitive prices",
                description: "We offer react native app development services within your budget and as per your business Goals. The project cost depends on your requirements."
            }
        ]
    }
]