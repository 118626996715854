import React from 'react';

// data
import { hireSwiftDevData, hireSwiftDevFaqs } from '../../../data/hireDev/hireSwiftDev';
import hireSwiftDev1 from '../../../assets/pages/hireDev/hireSwiftDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireSwiftDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="SWIFT DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={hireSwiftDev1}
            data={hireSwiftDevData}
            faq={hireSwiftDevFaqs}
        />
    )
}
