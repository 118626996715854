import React from 'react';

// data
import { hireAndroidDevData, hireAndroidDevFaqs } from '../../../data/hireDev/hireAndroidDev';
import androidDev1 from '../../../assets/pages/hireDev/androidDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireAndroidDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="ANDROID DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={androidDev1}
            data={hireAndroidDevData}
            faq={hireAndroidDevFaqs}
        />
    )
}
