export const hireWebDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Web Development encompasses the creation of websites and applications for the Internet or intranet. This can range from crafting a simple webpage to designing complex online platforms and social network services. To realize such intricate projects, it's imperative to onboard a skilled web developer. These professionals possess the necessary expertise to guide and execute web development endeavors efficiently.",
            "In today's digital age, an online footprint is no longer a luxury but a necessity for businesses. A company's website acts as its virtual storefront, providing insights into its mission, offerings, and core values. From emerging startups to established conglomerates, an online presence via a website is fundamental. A well-designed website can act as a bridge, connecting businesses with both potential and existing clientele, fostering growth on a global scale. Moreover, the evolution of web applications has enhanced the online user experience, rendering services more agile and refined.",
            "When contemplating web development, there are various avenues to explore. Depending on the project's requirements, one might engage with freelancers or consider hiring developers on a part-time or full-time basis.",
            "Alta Tech Mark offers exemplary web development services tailored to elevate your online platforms. Our adept web developers bring to the table innovative designs and solutions, ensuring your website stands out in the digital arena. Leveraging their expertise, they craft growth-centric web applications that resonate with users. Our offerings encompass diverse web development needs, including e-commerce platforms, Progressive Web Apps (PWAs), expansive websites, bespoke web applications, and Software as a Service (SaaS) solutions. All tailored to cater to both the end-users and the specific business objectives."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Hire Web Developers For Business?",
        description: "",
        onlyBullets: true,
        listNum: true,
        data: [
            "Boosting Business Credibility: Engaging a web developer can be pivotal in realizing your business objectives, irrespective of its scale – be it small, medium, or large. Web developers enhance your brand's credibility, and firms like Alta Tech Mark further amplify this effect. These professionals meticulously craft your website in alignment with your business requirements.",

            "Adoption of Cutting-edge Technologies: Proficient web developers stay updated with evolving technologies and tools. In a constantly shifting tech landscape, it's challenging for those outside the field to keep pace. Web developers not only possess deep knowledge of contemporary tools but continually upgrade their expertise. When hiring them, it's crucial to ensure their proclivity for staying updated.",

            "Tailored Solutions: Given that every business has its unique attributes, it requires a distinct online approach. Web developers, equipped with the expertise, can tailor your web platform. Their design sensibilities ensure your website not only looks compelling but operates seamlessly, providing a distinct identity and efficient user experience.",

            "Efficiency & Time-saving: Crafting a website is intricate and time-consuming. Experienced web developers, familiar with the nuances, can expedite this process. Their expertise translates to not just time savings but also cost efficiency."
        ]
    },
    {
        id: 3,
        title: "Why Hire Web Developers From Alta Tech Mark?",
        description: "",
        onlyBullets: true,
        listNum: true,
        data: [
            "SEO-Centric Development: At Alta Tech Mark, our developers prioritize SEO in web development. Recognizing the importance of Search Engine Optimization, they meticulously integrate features to bolster your website's search rankings. Leveraging contemporary tools and methodologies, our team ensures your website garners visibility.",

            "Methodical Excellence: Our team boasts profound knowledge in web development, applying it with precision and effectiveness. Alta Tech Mark provides enduring support and maintenance, ensuring your website remains robust and current. Partnering with us promises a distinct, performance-driven approach to web development.",

            "Distinctive Edge: Hiring developers from Alta Tech Mark ensures your business carves a unique niche amidst competition. We commit to the highest standards, leveraging top-tier tools, and never compromising on quality. This dedication ensures your business captures a broad audience spectrum. Moreover, our transparency ensures clients are always in the loop.",

            "Punctual Deliveries: Our commitment extends to timely deliveries. While we prioritize quality, we also ensure that projects are completed within stipulated timelines. At Alta Tech Mark, your business is treasured, and our team works with the dedication as if it's their own enterprise."
        ]
    },
]

export const hireWebDevFaq = [
    {
        id: 1,
        question: "1. How much does it cost to hire web developers?",
        answer: "The cost estimation for dedicated web developers varies based on various factors such as development team size, hiring model, the complexity of the web development solutions, the type of solution, features and functionalities and many more."
    },
    {
        id: 2,
        question: "2. Can I hire dedicated web developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated web developer, then you can hire dedicated web developers on an hourly or project/task base."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated web developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the web developers before making a final move."
    },
    {
        id: 4,
        question: "4. What steps should I follow to Hire web developers?",
        answer: "Alta Tech Mark offers a very simple process to hire web developers. Just provide project details and the technical expertise you need. The team of web developers will begin working on your project based on your selection."
    },
    {
        id: 5,
        question: "5. Why should I hire web developers?",
        answer: "The dedicated web developers will work only for you to deliver outstanding results with the highest optimization. The team of web developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    }
]