import hp3 from '../../../assets/pages/service/iot/hp3.png';
import hp4 from '../../../assets/pages/service/iot/hp4.png';

export const iotHardProtoDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "IoT prototyping is the act of experimenting and executing plan ideas into preliminary accounts of a polished product. Hardware prototype helps to manage and sensor prototypes development. It is the first essential step while creating an IoT product. Likewise, it helps to connect every project with the device. However, the IoT Hardware prototype is challenging because it requires the latest technologies and IoT skilled developers. But the hardware supports sensors that gather insightful data and helps to analyze the product. It helps devices to connect and accomplish their projects. For IoT hardware-based products, the company needs skilled experts and cutting-edge technologies. It is a lengthy process and needs someone who can implement it without any glitches and errors.",
            "IoT hardware prototype is divided into a user interface, backend software, and connectivity of a system. Hence, IoT hardware prototyping is performed in different methods, and all methods have their benefits. Because of it, the insights are obtained, and challenges are solved with astonishing opportunities. The purpose of building an IoT prototype is to test the concept and match it with your target audience. The task of matching expectations becomes easy and makes validation of the business easier."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Tools For IoT Hardware Prototyping",
        description: "",
        onlyBullets: true,
        data: [
            "KNX",
            "Raspberry PI",
            "Beacons",
            "Crestron",
            "Miniature Boards",
            "NodeMCU"
        ],
        bulletsPic: hp4
    },
    {
        id: 3,
        title: "Benefits Of IoT Hardware Prototyping",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Less Money And Times",
                description: "IoT hardware prototyping has different ways and ends up saving money and time. The odds of risk management have diminished, and it is now possible to rule out the possibility of spending additional time and money on post-deployment device concerns. An expensive repair like this would wreak havoc on both your clients' and your team's morale. Not to mention the money you'll lose while the project is offline."
            },
            {
                id: 2,
                title: "Gather Useful Information",
                description: "The design aspect of IoT prototyping is to collect useful information. The connectivity shows the level of latency that is much more powerful than what was required and, consequently, makes the unique design not adequate. Moreover, prototyping a device might reveal insufficient range, is apt to overheat or runs out of battery sooner than expected."
            },
            {
                id: 3,
                title: "Large Range Of Sensors",
                description: "Sensors that gather useful data and evaluate the surroundings are essential components of an IoT device. It uses many sensors to detect humidity, pressure, temperature, smoke, gas, radiation, vibration, water flow, speed, expedition, closeness, GPS position, elevation, and other variable factors."
            },
            {
                id: 4,
                title: "Faster Speed",
                description: "IoT prototyping creates applications that are faster in speed. It is possible with the latest technology and unique techniques of the company. We provide the best IoT hardware prototyping services that are quicker and productive. There are different sensors, and every sensor takes a different time to execute, and the functionality is quite easy and cost-effective."
            },
            {
                id: 5,
                title: "Efficient Functionality",
                description: "This data will show you what changes need to be made during the design phase. Perhaps you should consider a different IoT protocol, or perhaps you should purchase devices from a different manufacturer, or perhaps you should completely restructure the project. In any case, catching these flaws as soon as feasible might help your project in the long run.",
                pic: hp3
            }
        ]
    },
    {
        id: 4,
        title: "Types of IoT Hardware:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Development Kits (DKs)",
                description: "It is easy to use and has user-friendly hardware. For scaling and depending, the Development Kits is perfect and helps to optimize the expandability. The applications are different for every device; hence, the outcome depends on the applications and use case. Use it if you are looking for a short time with limited features."
            },
            {
                id: 2,
                title: "Mass Production Modules (MPMs)",
                description: "If you are looking to deploy a product in mass production, go with the mass production modules. However, it is not for development and contains small spaces. It lasts around 5-10 years, and it is quite optimized and sustainable. The usage space is more and has limitation features."
            },
            {
                id: 3,
                title: "MPM Evaluation Kits (EVK's)",
                description: "The expanded version for Mass production Modules is MPM evaluation kits. It enables debug, develop, and emphasize IoT solutions. The kits are easy to use and quickly with efficiency. Anyone with less knowledge can operate this kit as it is friendly to use. There are different models and methods for it, but the benefits are all significant."
            }
        ]
    },
    {
        id: 5,
        title: "Why Choose Alta Tech Mark For IoT Hardware Prototyping?",
        descriptions: [
            "As an IoT development firm with over a decade of expertise, we provide the greatest solution in the business and the most appropriate for the consumers. Our knowledgeable experts will fulfill all of your demands within the allotted period.",
            "From initial concepts to finished hardware prototypes, we are capable of developing an incredibly creative and inventive IoT hardware development process. Our organization offers production design, high quality, firmware development, and much more. We meet the client's objective of developing IoT hardware for devices, and we work hard to meet other objectives in the road. Our knowledgeable staff responds to all of your questions and addresses any issues that may arise during or after the procedure. We provide flawless, personalized, and excellent service for your items."
        ],
        onlyBullets: false,
        data: []
    }
]