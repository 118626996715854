import r1 from '../../../assets/pages/service/mobileAppDev/androidAppDev/r1.png';
import p1 from '../../../assets/pages/service/mobileAppDev/androidAppDev/p1.png';
import p2 from '../../../assets/pages/service/mobileAppDev/androidAppDev/p2.png';
import p3 from '../../../assets/pages/service/mobileAppDev/androidAppDev/p3.png';
import p4 from '../../../assets/pages/service/mobileAppDev/androidAppDev/p4.png';
import p5 from '../../../assets/pages/service/mobileAppDev/androidAppDev/p5.png';
import p6 from '../../../assets/pages/service/mobileAppDev/androidAppDev/p6.png';
import p7 from '../../../assets/pages/service/mobileAppDev/androidAppDev/p7.png';
import android6 from '../../../assets/pages/service/mobileAppDev/androidAppDev/android6.png';
import android2 from '../../../assets/pages/service/mobileAppDev/androidAppDev/android2.png';
import android4 from '../../../assets/pages/service/mobileAppDev/androidAppDev/android4.png';
import android3 from '../../../assets/pages/service/mobileAppDev/androidAppDev/android3.png';
import android5 from '../../../assets/pages/service/mobileAppDev/androidAppDev/android5.png';

export const androidDevData = [
    {
        id: 1,
        title: "Years of experience enhances our expertise in developing custom Android apps.",
        descriptions: [
            "Alta Tech Mark is a leading app development business that offers much more than just device-specific app development services. From on-demand apps to cutting-edge IoT connections, our Android app developers have built a strong portfolio by developing and releasing a wide variety of mobile app solutions for the Android platform.",
            "We also take great care to make sure that our Android apps are suitable for a variety of gadgets, such as tablets, wearable technology, SmartTVs, and of course smartphones. Our focus on fusing our years of experience in Android development with our customers' original app designs has made Alta Tech Mark a leading option for international companies looking to create Android applications.",
            `With a commitment to the "practice makes perfect" approach, our Android developers have continuously produced mobile apps that are feature-rich, intuitive, and easy to use for the Google Playstore. Many multinational corporations have collaborated with Alta Tech Mark's talented Android development team to achieve their objectives.`
        ],
        onlyBullets: false,
        data: [],
        sectionPicRow: true,
        sectionPic: android2
    },
    {
        id: 2,
        title: "",
        description: "",
        onlyBullets: false,
        grid3Display: true,
        data: [
            {
                id: 1,
                title: "Advice on Android Development",
                description: "There is more to Android development than merely publishing apps on the Play Store. It involves designing a mobile application with a particular user base in mind, keeping their preferences at the forefront of the design, all the while guaranteeing you get the best possible app development deal. Consult with experts at Alta Tech Mark who have extensive experience developing Android applications.",
                descPic: android4,
                descPicOrder: 1
            },
            {
                id: 2,
                title: "App UI/UX Design for Android",
                description: "Our Android app designers' only goal is to create cutting-edge apps and provide the best possible user experiences. Our Android app design service ensures that app prototypes reflect your company strategy by using the newest design trends.",
                descPic: android3,
                descPicOrder: 3
            },
            {
                id: 3,
                title: "Develop Android on Demand",
                description: "Employ Alta Tech Mark's technically proficient Kotlin, Java, and other language Android app developers. They are capable of creating feature-rich apps for Android wearables, desktops, and the web. Our Android application developers have worked with a variety of industries, including the gaming and corporate sectors, over their more than ten years of expertise."
            },
            {
                id: 4,
                title: "Assurance of Android App Quality",
                description: "Don't open an Android app that is glitchy or has out-of-date functionality. Delivering an exceptional user experience can help you engage your target audience. With help from Alta Tech Mark, a top Android app development business.",
                descPic: android5,
                descPicOrder: 1
            },
            {
                id: 5,
                title: "Cross-platform Implementation",
                description: "To extend the reach of your app, get in touch with the professionals at Alta Tech Mark. Our experts in Android development can assist you in making sure your app is accessible on all pertinent platforms so it may reach as many users as possible."
            },
            {
                id: 6,
                title: "Testing of Android Software",
                description: "Thorough testing is emphasized by the Alta Tech Mark Android Software Development team as a necessary step in the development process. Take advantage of their proficiency with both automated and manual testing methods to guarantee a feature-rich and error-free product for you and your users.",
                sectionPic: android6
            },
            {
                id: 7,
                title: "IoT App Creation",
                description: "Get an IoT application customized for your industry by our Android app developers, who have both the necessary technical expertise and real-world experience. Take advantage of Internet of Things technologies that provide unmatched device accessibility through mobile applications, even from far-off places."
            },
            {
                id: 8,
                title: "Moving Apps to the Android Platform",
                description: "By releasing your current app on the most popular smartphone platform, you may increase the reach of your app. Make the most of Alta Tech Mark's experience in Android mobile app development to increase your target audience, user base, and return on investment."
            },
            {
                id: 9,
                title: "Employ Android Programmers",
                description: "Hire the best Android app developers from Alta Tech Mark, who are renowned for providing cutting-edge solutions to multinational corporations, to optimize the results of your Android development efforts."
            }
        ]
    }
]

export const androidDevTechnologies = [
    {
        id: 1,
        title: 'Front-end Development',
        technologies: ['Native (Java/Kotlin)', "Xamarin Native C", "Xamarin Forms for Android", "Cordova/PhoneGap", "Ionic", "React Native"]
    },
    {
        id: 2,
        title: 'Operating Systems',
        technologies: ['Android OS']
    },
    {
        id: 3,
        title: 'SDKs/Tools/Framework',
        technologies: ['Android Studio', 'Google Fit SDK', 'Google Assistant SDK', 'OpenGL', 'ARCore', "Android Auto"]
    },
    {
        id: 4,
        title: 'Cloud Platforms',
        technologies: ['Amazon Web', 'Services(AWS)', 'Microsoft Azure', 'Google Cloud', 'Platform (GCP)', 'Firebase', 'AppCenter for CI/CD', 'Kubernetes']
    },
    {
        id: 5,
        title: 'Others',
        technologies: ['Google Assistant', 'Google VR', 'Machine Learning (MK Kit)', 'Firebase', 'Google Cloud', 'Google Analytics']
    }
]

export const industriesWeServeData = ['Retail, Ecommerce', 'Real Estate', 'Education & e-learning', 'Travel & Hospitality', 'Healthcare & Fitness', 'Food & Restaurant', 'Logistics & Distribution', 'On-Demand Solutions', 'Social Networking', 'Gaming']

export const industriesWeServeImages = [r1];

export const androidDevProcess = [
    {
        id: 1,
        title: '1. Requirement Gathering',
        description: "Make your app idea perfect covering almost every affecting factor starting from recognizing the target audience to finalizing the tech stacks and features with the help of experts.",
        src: p1
    },
    {
        id: 2,
        title: "2. UI/UX Design",
        description: "Make your mobile application enriched with the latest UI design trends to own an eye-pleasing and amazing mobile application to make app browsing more convenient for the app users.",
        src: p2
    },
    {
        id: 3,
        title: "3. Prototype",
        description: "Finalize the UI/UX design of the mobile application to turn the visualized look and feel of the mobile application into a working solution to make sure all your development requirements have been covered perfectly.",
        src: p3
    },
    {
        id: 4,
        title: "4. App Development",
        description: "The team of app development programmers will make sure to choose the best possible app development technologies, programming languages, and more to develop the application based on finalized app design, features, and functionalities.",
        src: p4
    },
    {
        id: 5,
        title: "5. Quality Assurance",
        description: "Pakistann app developers cover all aspects of the app development process to enhance the user experience testing all the features, and offerings of the application while performing a series of automated and manual testing to find and resolve bugs before deployment.",
        src: p5
    },
    {
        id: 6,
        title: "6. App Deployment",
        description: "Make your application accessible to your target audience deploying them on different platforms following their app publishing guideline and criteria.",
        src: p6
    },
    {
        id: 7,
        title: "7. Support & Maintenance",
        description: "Keep your application always exceptional for your target audience getting all the much-needed post-deployment support and maintenance.",
        src: p7
    }
]

export const whyUsData = [
    '12+ years of App Development Experience',
    '1000+ App Developers',
    '97% Success Ratio',
    '4500+ Developed Mobile Applications',
    '110 Million App Downloads',
    'Enhanced Android App Development Expertise',
    'Average 7+ Android App Development Experience of Our In-house Developers',
    'Multiple Hiring Models',
];

export const faqData = [
    {
        id: 1,
        question: "1. How can I find the best Android app development team for my development project requirements?",
        answer: "You can reach out to one of the top Android app development companies like Alta Tech Mark and share your development requirements with them. Finalize the best one, based on their Android app development expertise, development knowledge, years of experience, and advanced skills to develop digital solutions based on your project requirements."
    },
    {
        id: 2,
        question: "2. What tools and technologies do you use for development?",
        answer: "There are various tools and technologies we use for Android development. Some of them include Android Studio, AVD Manager, Eclipse, XML, SQLite, Android NDK, Realm, Android SDK, Fabric, Genymotion, GameMaker: Studio, and various others. Along with this, our Android app development team keeps themselves knowledgeable about almost every latest trend which can help us to choose the most suitable tech stacks based on your development requirements."
    },
    {
        id: 3,
        question: "3. Can I Hire dedicated developers from your company?",
        answer: "As our Android app developers hold the experience and expertise to deliver outstanding results with the highest optimization. We allow businesses to hire developers from our Android development team that can take advantage of our technical expertise to get much-anticipated development outcomes."
    },
    {
        id: 4,
        question: "4. How much can Android app development cost?",
        answer: "Android App development cost estimation does not depend on just one factor. The cost estimation considers various factors such as your development project requirements, type of app development solution, the complexity of the solution, features and functionalities to include in the mobile application and many more."
    },
    {
        id: 5,
        question: "5. How much time app development can take?",
        answer: "Just as the cost estimation, defining the time frame of the Android app development solution depends on various factors such as project requirements, app development time frame, the complexity of the app development, the type of solution, features, and functionalities of the mobile application, the required app development team, and many more."
    },
    {
        id: 6,
        question: "6. How do you assure the confidentiality of my app idea?",
        answer: "Your app idea matters to us as much as it does to you. So before moving on with anything else we make sure to sign an NDA to keep your app idea always safe and secure."
    },
    {
        id: 7,
        question: "7. Who will have complete control over the application after the deployment?",
        answer: "Once our development team successfully deploys the application on the Play Store, we make sure to transfer the app ownership rights and source code of the application to our clients while handing over the project."
    }
]