import React from 'react';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

// assets
import android1 from '../../../../assets/pages/service/mobileAppDev/androidAppDev/android1.jpeg';
import { androidDevData } from '../../../../data/services/mobileAppDevData/androidDevData';

export default function AndroidAppDev() {
    return (
        <ServicesWrapper
            title="ANDROID APP DEVELOPMENT"
            descriptionCont="Our staff knows how to meet targets and adapt concepts to market needs. We don't simply design apps—we create tools to grow your company. We create a masterpiece for you using expertise, innovation, and technology. Alta Tech Mark values learning above milestones."
            img={android1}
        >
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={androidDevData} />
            </div>
        </ServicesWrapper>
    )
}
