import React from 'react';

// assets
import ourClientsBg from '../../../assets/background/ourClientsBg.svg';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import EsteemedClients from '../../../components/home/esteemedClients/EsteemedClients';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function OurClients() {
    return (
        <div className=''>
            <HeroTitleImage
                bg={ourClientsBg}
                title='Our Clients'
                simpleTextDesc={true}
                titleDesc="Striving to work with the best in the world"
            />

            <div className='tw-px-[9.115vw]'>
                <h1 className='tw-text-center tw-w-full tw-text-3xl tw-font-semibold md:tw-my-8 tw-my-4'>Loved and trusted by the industry leaders</h1>

                <EsteemedClients />
            </div>

            <ContactUsNoForm />
        </div>
    )
}
