import React from 'react'

export default function WorkWithCard(props) {
    return (
        <div className='tw-col-span-1 tw-mt-[1.042vw] tw-px-[15px] tw-py-[35px] tw-flex tw-flex-col tw-items-center tw-gap-y-[25px] tw-shadow-xl tw-rounded-lg tw-text-white'
            style={{
                background: "linear-gradient(180deg, #372A60 0%, #0A0095 100%)"
            }}
        >
            <div className='tw-w-[50px] tw-h-[50px]'>
                {props.src}
            </div>
            <h1 className='md:tw-text-[16px] tw-text-[12px] tw-font-semibold tw-text-center'>{props.title}</h1>
        </div>
    )
}
