import React from 'react';

// assets
import magento1 from '../../../../assets/pages/service/ecommerceDev/magento1.jpeg';
import magento2 from '../../../../assets/pages/service/ecommerceDev/magento2.png';
import magento3 from '../../../../assets/pages/service/ecommerceDev/magento3.png';
import { magentoDevData } from '../../../../data/services/ecommerceDevData/magentoDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function MagentoDev() {
    return (
        <ServicesWrapper
            title="ECOMMERCE DEVELOPMENT MAGENTO"
            descriptionCont="Our E-Commerce website development professionals are experts. Alta Tech Mark developers provide high-quality, fast E-Commerce products. Our skilled staff develops E-Commerce websites utilizing cutting-edge, trustworthy technology. Magento, Shopify, BigCommerce, WooCommerce, and others"
            img={magento1}
        >
            <DevGridWrapper images={[magento2, magento3]}>
                <DataMapper data={magentoDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
