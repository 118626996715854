import nft3 from '../../../assets/pages/service/blockchainDev/nft3.png';
import nft4 from '../../../assets/pages/service/blockchainDev/nft4.png';

export const nftMarketPlaceDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "The Non-Fungible Token is transforming the way we discuss art, music, media, and events. NFTs may be traded using cryptocurrencies. As a result, each NFT is distinct in its own right and very hard to replicate. You may trade whatever digital asset you desire on an NFT marketplace by using the services of Alta Tech Mark, an NFT marketplace creation business.",
            "Select an NFT Marketplace Development Solution Provider That Provides Adaptable NFT Development Services:"
        ],
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "NFT Creation for Electronic Assets",
                description: "With NFT, any digital file or object may have a distinct identity of its own. Employ NFT developers to assist you in creating a marketplace that meets your needs and enables anybody to exchange digital assets, such as photos, memes, digital art, and real estate."
            },
            {
                id: 2,
                title: "NFT Creation for Electronic Arts",
                description: "The use of NFT in digital art is becoming common. The original artwork's originality is verified by NFT. Although the original artwork may be printed or copied, the owner will always own the original. The artist is still eligible to get royalties even after selling their NFT artwork."
            },
            {
                id: 3,
                title: "NFT Video and Music Development",
                description: "Many musicians and producers are reworking their ties with fans in light of the growth of NFT. Leading artists in the music business have sold millions of copies of their NFT-converted tracks and albums. As the original creator, they are entitled to a royalty payment each time the item is sold to a new owner by the former owner. You might provide them a website where they may sign up and exchange their works."
            },
            {
                id: 4,
                title: "NFT Creation for Additional Formats",
                description: "Aside from that, NFTs for GIFs, tweets, memes, and other digital treasures are available for ownership. Every NFT owner who is able to attend virtual events is given a unique identity via the use of NFT integration in tickets for live events. We can assist you in creating a platform that allows them to approve and register their digital valuables."
            },
            {
                id: 5,
                title: "Authorization for a Unique Identification Card",
                description: "When NFT is used, real-world digital resources may provide a remarkable experience. Activities like celebrity fan meets, athlete training videos, and exclusive interviews with well-known individuals may be enhanced by the NFT by giving each member a membership card that is entirely personalized.",
                pic: nft3
            }
        ]
    },
    {
        id: 2,
        title: "NFT Development Sectors We Include",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Sports",
                description: "Hire NFT Developers from Alta Tech Mark to include blockchain technology into your NFT trading platform to stop the problems caused by phony tickets and collectibles that are affecting the sports sector."
            },
            {
                id: 2,
                title: "Online Shopping",
                description: "Alta Tech Mark's NFT engineers are able to create an NFT marketplace platform where makers, merchants, and consumers may exchange their NFT-approved antiques."
            },
            {
                id: 3,
                title: "Artwork",
                description: "Do not take a risk in a world full of imitations; instead, get in touch with Alta Tech Mark, the most reputable NFT marketplace development firm, to create a marketplace platform that gives artists a venue to exchange their unique works of art."
            },
            {
                id: 4,
                title: "Audio",
                description: "In order for musicians, producers, and other industry leaders to exchange their NFT-authorized work, our NFT marketplace developer may provide them NFT authorization services. Each time their music is played or utilized, it allows artists to get royalties."
            },
            {
                id: 5,
                title: "Real Property",
                description: "For your real estate marketplace, our skilled NFT marketplace development team can integrate AR/VR features that enable virtual property tours and accept bitcoin and e-wallet transactions."
            },
            {
                id: 6,
                title: "Photography",
                description: "The owner of the images is authorized and their security is improved by the combination of NFT and Blockchain technology. The staff at Alta Tech Mark, a leading NFT development business, can also assist you in creating an NFT marketplace where photographers can exchange their work and connect with one another."
            },
            {
                id: 7,
                title: "Online Events",
                description: "We are able to assist you in creating a platform for online events. You may even use a membership card or unique identity created by NFT to approve entrance. The only person with access to your platform to attend virtual events is a unique NFT cardholder."
            },
            {
                id: 8,
                title: "Financial Technology",
                description: "Giving a financial marketplace platform that handles money transfers and resales robust security might be challenging. A highly secure NFT license may be created by our NFT development team. We can add blockchain-enabled smart contracts to NFT licenses to improve your financial marketplace's security."
            },
            {
                id: 9,
                title: "Publishing",
                description: "A marketplace based on NFT where publishing companies and writers of ebooks may exchange the rights to their works and split revenues."
            }
        ]
    },
    {
        id: 3,
        title: "Features That Alta Tech Mark Offers for the Development of the NFT Marketplace",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Data protection",
                description: "Alta Tech Mark's NFT marketplace developers adhere to stringent ERC-721 NFT requirements for data protection, meaning that the data collected and retained in NFT for your platform cannot be shared or used by anyone else."
            },
            {
                id: 2,
                title: "Increased Safety",
                description: "Employ NFT marketplace developers who can improve your marketplace's security by using bitcoin technology. It might increase their dependability for your users."
            },
            {
                id: 3,
                title: "Reliability",
                description: "Alta Tech Mark's NFT developers can assist you in creating a safe and dependable NFT marketplace since no NFT can be added, removed, or changed once it is in place.",
                pic: nft4
            }
        ]
    },
    {
        id: 4,
        title: "Our Services for Developing the NFT Marketplace",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "NFT Marketplace Development & Design",
                description: "ERC-721 and ERC-1155 standards, smart contracts, and IPFS protocols are all handled by our NFT marketplace development team throughout the first stages of marketplace design and development."
            },
            {
                id: 2,
                title: "Audit of NFT Smart Contracts",
                description: "With our assistance, you may create precise NFT smart contracts that will guarantee no breaches or defects for a very long period. Prior to providing the final solutions, our NFT marketplace tester team will examine those Smart Contracts."
            },
            {
                id: 3,
                title: "Post-sale Support and Upkeep for the NFT Marketplace",
                description: "Our development team's NFT developers have always held the opinion that a project never really ends with its final submission. For a certain period of time, our NFT marketplace maintenance and support staff will be available to you to provide after-sales services. Therefore, even after we successfully deliver, we are accountable for resolving any questions you may have afterwards."
            }
        ]
    },
    {
        id: 5,
        title: "",
        description: "Alta Tech Mark Full Stack NFT Marketplace Development The NFT marketplace development team can assist you in realizing NFT's boundless commercial possibilities. Your firm may achieve unthinkable success if you own a marketplace where individuals can exchange their NFT-approved digital assets, like memes, to host virtual events that enable NFT legitimate identification. Please stop contacting us with commercial queries so that our NFT marketplace developers can help you.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Join Forces With the Leading NFT Development Firm",
                description: "As one of the best NFT development companies, Alta Tech Mark's team uses its years of experience, knowledge, and development know-how to meet the demands of NFT development projects."
            }
        ]
    },
    {
        id: 6,
        title: "",
        description: "For the NFT developers at Alta Tech Mark, there aren't any development criteria that are sufficiently complicated. The NFT developers at Alta Tech Mark are very aware of the needs of their clients and will provide precisely what you have always dreamed of. Don't settle for anything less than the finest when you hire NFT developers from Alta Tech Mark, the leading NFT marketplace development firm.",
        onlyBullets: false,
        data: []
    }
]