import React from 'react';

// data
import { hireMagentoDevData, hireMagentoDevFaqs } from '../../../data/hireDev/hireMagentoDev';
import magentoDev1 from '../../../assets/pages/hireDev/magentoDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireMagentoDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="MAGENTO DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={magentoDev1}
            data={hireMagentoDevData}
            faq={hireMagentoDevFaqs}
        />
    )
}
