export const bigCommerceDevData = [
    {
        id: 1,
        title: "Why BigCommerce Can Help Your E-commerce Website Survive Competition",
        description:
            "Choosing BigCommerce for your online store comes with a plethora of benefits that can give you an edge in the e-commerce landscape. Firstly, it allows for a visually appealing online store suitable for any demographic. This is because BigCommerce offers a plethora of store themes and an HTML/CSS-based interface for customization. Your e-commerce marketing campaigns are empowered with essential tools like newsletters, coupons, and promotional campaigns. Designed to boost your search engine rankings, transitioning to BigCommerce could likely elevate your online visibility. Plus, the transparency in costs means no hidden fees, despite the myriad features it offers.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Alta Tech Mark for magento store development?",
        description: "Unlike other e-commerce platforms, with BigCommerce, you're never left in the lurch. They provide a dedicated team of experts - the Success Squad - to aid you. Additionally, partnering with Alta Tech Mark can further streamline your transition to BigCommerce. Whether it's pre-launch, post-launch, marketing, or during sales spikes, you have the support of the Success Squad and Alta Tech Mark ensuring uninterrupted business operations.",
        onlyBullets: false,
        data: []
    }
]