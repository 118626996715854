export const casualGamesDevData = [
    {
        id: 1,
        title: "The Advantages Of Developing Casual Games",
        descriptions: [
            `Video games (including software applications) that let casual gamers in are referred to as casual games. A player does not required to follow any stringent regulations while playing a casual game, which is the primary distinction between it and "hardcore" games. On the other side, a "hardcore" gamer could get fixated on beating ever-higher levels of difficulty or score in just one game. A casual gamer may be thought of as less stressed out—in fact, they may be considered so "casual" as to be considered "laid-back" players.`,
            "A casual game is often played on a desktop or laptop computer, while certain titles in this genre may also be played on a mobile device. It's interesting to note that up to 74% of casual gamers are female, according to Wikipedia; this might be because men are more likely to take their video games more seriously or competitively. You could think about employing Alta Tech Mark to help you with your project if you're considering pursuing a career in casual game creation.",
            "A distinguishing feature of developing casual games is that they don't have to be very complex; in fact, the more straightforward the game, the more likely it is to appeal to the female market. It's hardly surprising that older gamers who like casual games also tend to be simpler game enthusiasts. These gamers may, after all, be too preoccupied with their families and jobs and lack the drive, leisure, or stamina to play games that require a lot of skill. This could account for the appeal of a rather straightforward game like Angry Birds. If you decide to create casual games, you must design a more straightforward game that is yet enjoyable to play and aesthetically attractive.",
            `The ability to utilize or download casual games for free is an intriguing feature. You may genuinely profit from advertising without having to charge consumers for playtime since the game includes third-party advertising. Some game producers could give the audience a "free trial" version of their product; if they're happy with it, they can pay to upgrade to a more complex version. Should you find any of these approaches appealing, you could wish to assign Alta Tech Mark the task of developing your game so that it can start producing results straight away.`
        ],
        onlyBullets: false,
        data: []
    }
]