export const hireSpringBootDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Developed in 2002, Spring Boot is a popular Java-based platform for developing mobile and online applications for businesses. The Spring solution offers a plethora of features and functions that satisfy the most advanced business needs. Employ a Spring Boot developer to transform your project into a customized Spring framework with a focus on a certain area. ",
            `Due to its speed and security, Java Spring Boot is used by many fully working applications. Developers choose Spring Boot over other frameworks because it is faster and easier to use. The principles of "code once, run anywhere" are the foundation of Java. Vendor-specific installation descriptors are not needed for Java programs to be transferred across servers.`,
            "Beans may be arranged using XML, annotations, and Java configuration, among other techniques, with the aid of Java Spring Development. The unpredictability has been extended and the process of creating Spring apps has become predictable as the number of varied Java Spring Boot Development Companies has increased."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Spring Boot Stands Out:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Comprehensive",
                description: "Unlike traditional frameworks that might only focus on a specific aspect of application development, Spring Boot offers a comprehensive environment. It combines a variety of features that cater to different parts of an application, each concentrating on a unique function."
            },
            {
                id: 2,
                title: "Rapid Development",
                description: "One of the standout reasons developers gravitate towards Spring Boot is its speed. The framework facilitates faster application development compared to its counterparts. Its opinionated defaults and auto-configuration eliminate boilerplate code, leading to a more streamlined development process."
            },
            {
                id: 3,
                title: "Portability",
                description: "The core philosophy behind Java - 'Write Once, Run Anywhere' is ingrained in Spring Boot. Java applications, inclusive of those developed using Spring Boot, are platform-independent, ensuring ease of transferring applications across servers without relying on vendor-specific configurations."
            },
            {
                id: 4,
                title: "Versatility in Configuration",
                description: "Spring Boot allows developers flexibility in how they configure their beans. Whether through XML, annotations, or Java configurations, Spring Boot accommodates varying preferences, ensuring that developers can structure applications in ways they are most comfortable with."
            },
            {
                id: 5,
                title: "Predictable Development",
                description: "With the expansion of Java Spring Boot Development Companies, the standardization and predictability of developing Spring applications have enhanced. Templates, best practices, and community support have made navigating the intricacies of the framework more manageable."
            }
        ]
    },
]