import React from 'react';

// assets
import wp1 from '../../../../assets/pages/service/WebAppDev/wp1.png';
import wp2 from '../../../../assets/pages/service/WebAppDev/wp2.png';
import { wordpressDevData } from '../../../../data/services/webAppDevData/wordpressDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function WordpressAppDev() {
    return (
        <ServicesWrapper
            title="WORDPRESS DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={wp1}
        >
            <DevGridWrapper img={wp2}>
                <DataMapper data={wordpressDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
