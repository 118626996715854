import React from 'react';
import { Link } from 'react-router-dom';

// data
import { hireDeveloperData } from './hireDeveloperData'

export default function HireDeveloperHover(props) {
    return (
        <div className='tw-absolute tw-bg-purple tw-text-purple tw-border-slate-300 tw-border-[1px] tw-h-[60vh] tw-z-10 tw-grid tw-grid-cols-4' onMouseLeave={props.hoverLeave}>
            <div className='tw-col-span-1 tw-w-[20vw] tw-flex tw-flex-col tw-items-start'
                style={{
                    backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
                }}
            >
                {hireDeveloperData.slice(0, 10).map(item =>
                    <Link to={item.url} key={item.id} className='tw-px-4 tw-py-1 tw-cursor-pointer hover:tw-text-[#4741A6]'>{item.value}</Link>
                )}
            </div>
            <div className='tw-col-span-1 tw-w-[20vw] tw-flex tw-flex-col tw-items-start tw-border-l-[1px] tw-border-slate-300'
                style={{
                    backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
                }}
            >
                {hireDeveloperData.slice(10, 20).map(item =>
                    <Link to={item.url} key={item.id} className='tw-px-4 tw-py-1 tw-cursor-pointer hover:tw-text-[#4741A6]'>{item.value}</Link>
                )}
            </div>
            <div className='tw-col-span-1 tw-w-[20vw] tw-flex tw-flex-col tw-items-start tw-border-l-[1px] tw-border-slate-300'
                style={{
                    backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
                }}
            >
                {hireDeveloperData.slice(20, 30).map(item =>
                    <Link to={item.url} key={item.id} className='tw-px-4 tw-py-1 tw-cursor-pointer hover:tw-text-[#4741A6]'>{item.value}</Link>
                )}
            </div>
            <div className='tw-col-span-1 tw-w-[20vw] tw-flex tw-flex-col tw-items-start tw-border-l-[1px] tw-border-slate-300'
                style={{
                    backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
                }}
            >
                {hireDeveloperData.slice(30, 40).map(item =>
                    <Link to={item.url} key={item.id} className='tw-px-4 tw-py-1 tw-cursor-pointer hover:tw-text-[#4741A6]'>{item.value}</Link>
                )}
            </div>
        </div>
    )
}
