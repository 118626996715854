import React from 'react';

// assets
import img1 from '../../../../assets/pages/service/mobileAppDev/xamarinAppDev/1.png';
import { xamarinDevData } from '../../../../data/services/mobileAppDevData/xamarinDevData';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';

export default function XamarinAppDev() {
    return (
        <ServicesWrapper
            title="Xamarin App Development"
            description="Hire A Skilled Team Of Expert Xamarin Developers For Your Next Project"
            img={img1}
        >
            <DevGridWrapper>
                <DataMapper data={xamarinDevData} />
            </DevGridWrapper>

        </ServicesWrapper>
    )
}
