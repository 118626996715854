import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import ecommerce1 from '../../../assets/pages/service/ecommerceDev/ecommerce1.png';
import ecommerce2 from '../../../assets/pages/service/ecommerceDev/ecommerce2.png';
import ecommerce3 from '../../../assets/pages/service/ecommerceDev/ecommerce3.png';
import { ecommerceGridBlockData, ecommerceServicesData } from '../../../data/services/ecommerceDevData/ecommerceData';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import GridBlock from '../../../components/services/gridBlock/GridBlock';
import KeyFeaturesCard from '../../../components/home/keyFeatures/KeyFeaturesCard';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function EcommerceDev() {
    // initializations
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet
                ||
                <div>
                    <HeroTitleImage
                        title="E-COMMERCE DEVELOPMENT"
                        bg={ecommerce1}
                        inWrapper={true}
                        simpleTextBg={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleEnd="E-COMMERCE"
                            title="ABOUT"
                            className='tw-text-center tw-mb-8'
                        />

                        <h1>
                            It is a straightforward process for making and selling digitally based goods transactions. The e-commerce website is growing in popularity and is now crucial to the company. If you're a budding business owner trying to find the ideal strategy for launching your brand? E-commerce is the ideal solution to meet your promotional objectives. Even though the magnitude varies from person to person, profit is always the end consequence.
                        </h1>
                        <br />
                        <h1>
                            E-commerce websites revolutionize industries and enable companies to flourish for the benefit of the organization. Integrate e-commerce websites with your requirements and tastes. Ecommerce website design and development by Alta Tech Mark include all activities aimed at the establishment, administration, and growth of an online business. Online operations in E-Commerce development may be completed with a single click. The E-Commerce website's UX/UI is crucial, because plenty of alternatives attract visitors and consumers.
                        </h1>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'
                        style={{
                            backgroundImage: `linear-gradient(${screenWidth < 768 ? '0deg' : '-90deg'}, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${ecommerce2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className='tw-text-white'>
                            <HeaderText
                                title="Our Services For The Development Of E-Commerce:"
                                description="A number of e-commerce platforms function and combine many merchants into one. We provide top-notch technology for those platforms, fostering multi-platform solutions and thriving. We make e-commerce systems functional so that they may support purchasing and selling on e-commerce websites."
                                className='tw-text-white tw-mb-[2vw] md:tw-text-left tw-text-center'
                                descriptionClassName='tw-text-left'
                            />
                        </div>
                        <div className='tw-flex md:tw-flex-row tw-flex-col md:tw-items-start tw-justify-between tw-w-full'>
                            <img src={ecommerce3} alt="pic" />
                            <div className='tw-col-span-2 tw-grid tw-grid-cols-4 tw-gap-[25px] tw-justify-items-center'>
                                {ecommerceGridBlockData.map((data, index) =>
                                    <GridBlock
                                        key={index}
                                        text={data}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple'>
                        <HeaderText
                            colorTitleEnd="SOLUTIONS"
                            title="E-COMMERCE DEVELOPMENT"
                            className='tw-text-center tw-mb-8'
                            description="A range of services are available from Alta Tech Mark to enhance the development process."
                        />
                        <div className='md:tw-grid md:tw-grid-cols-3 tw-flex tw-items-center tw-gap-[2vw] tw-overflow-x-scroll md:tw-overflow-x-hidden tw-pb-4'>
                            {ecommerceServicesData.map(service =>
                                <KeyFeaturesCard
                                    key={service.id}
                                    title={service.title}
                                    description={service.description}
                                    src={service.src}
                                    height="400"
                                />
                            )}
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleEnd="ALTA TECH MARK?"
                            title="WHY E-COMMERCE WITH"
                            className='tw-text-center tw-mb-8'
                        />
                        <h1>
                            We have industry professionals on staff who are quite knowledgeable in developing e-commerce websites. E-commerce projects are completed quickly and to the highest standard by Alta Tech Mark developers. The highly skilled group uses unique, robust, and dependable technology to decide on the building of e-commerce websites. Captivating tools like BigCommerce, WooCommerce, Shopify, Magento, and more are used in e-commerce development.
                        </h1>
                        <br />
                        <h1>
                            Our highly qualified staff provides a satisfactory user interface and a pleasant experience. Using the newest technology and providing high-quality work is how we aim to provide our customers the finest possible service. The creation of an e-commerce website incorporates data, reviews, ratings, and other relevant elements. There is an extreme need for E-Commerce website creation, and Alta Tech Mark will provide the greatest E-Commerce websites available.
                        </h1>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
