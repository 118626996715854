import fixedPriceModel from '../assets/partnershipModels/fixedPriceModel.svg'
import hireDedicatedModel from '../assets/partnershipModels/hireDedicatedModel.svg'
import onSiteDevelopmentModel from '../assets/partnershipModels/onSiteDevelopmentModel.svg'

export const partnershipModelsData = [
    {
        id: 1,
        src: fixedPriceModel,
        title: 'Set Cost Model',
        description: "In this approach, the project's scope, associated costs, and timeline are predetermined prior to the commencement of development. It's an ideal model for extended engagements, offering clients the reassurance of a consistent budget. It's best suited for clients with a clear vision of their needs."
    },
    {
        id: 2,
        src: hireDedicatedModel,
        title: 'Engage Dedicated Professionals',
        description: "A straightforward engagement mode where clients are billed based on the actual hours invested by the developer. It's beneficial for clients without a detailed project plan, allowing for flexibility in adding new features. The transparency in billing fosters better trust and open communication."
    },
    {
        id: 3,
        src: onSiteDevelopmentModel,
        title: "On-Premises Development Approach",
        description: "Chosen when clients require temporary on-site resources. It ensures cost-effectiveness and direct interactions with developers. Continuous communication in this model ensures timely project completion and heightened alignment with client goals."
    },
]