import React from 'react';

// assets
import arrowDown from '../../assets/icons/arrowDown.png';

export default function HeroTitleImage(props) {
    return (
        <div className={`md:tw-h-[55vh] tw-h-[50vh] tw-flex tw-flex-col tw-items-start tw-justify-end tw-text-white tw-px-[9.115vw] tw-pb-4 tw-w-full ${props.className}`}
            style={{
                backgroundImage: `${props.inWrapper ? props.simpleTextBg ? `url(${props.bg})` : `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.bg})` : `url(${props.bg})`}`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <h1 className={`tw-font-semibold md:tw-text-[4.167vw] md:tw-leading-[3.581vw] tw-leading-[6.667vw] tw-w-[53vw] tw-text-[6.4vw] md:tw-text-left ${!props.simpleTextBg ? "tw-text-left" : "tw-text-center tw-mb-[5vw]"}`}>{props.title}</h1>
            {props.simpleTextDesc &&
                <h2 className='md:tw-mt-4 tw-py-0 md:tw-w-[40vw] md:tw-text-lg tw-text-sm'>
                    {props.titleDesc}
                </h2>
            }
            {!props.inWrapper ?
                <>
                    {!props.titleDesc &&
                        <span className='tw-text-purple tw-font-semibold md:tw-text-[4.167vw] tw-text-[6.4vw] md:tw-text-left tw-text-center tw-w-full'>
                            {props.coloredTitle} <span className='tw-text-white'>.</span>
                        </span>
                    }
                </>
                :
                <div className='tw-flex tw-flex-col tw-items-start tw-mt-5 tw-w-full'>
                    {!props.simpleTextBg &&
                        <div className='tw-h-[5px] tw-w-[150px] tw-bg-purple'></div>
                    }
                    <h2 className='tw-my-0 tw-py-0 md:tw-w-[40vw] md:tw-text-base tw-text-sm'>
                        <span className='tw-font-semibold'>{props.descriptionBold} </span> {props.descriptionCont}
                    </h2>
                    {/* {!props.simpleTextBg &&
                        <div className='tw-w-full tw-grid tw-justify-center max-md:tw-hidden'>
                            <img src={arrowDown} alt="arrow" className='tw-scale-[0.8]' />
                        </div>
                    } */}
                </div>
            }
        </div>
    )
}
