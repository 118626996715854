import { configureStore } from "@reduxjs/toolkit";

// slices
import responsiveSlice from "./responsiveSlice/responsiveSlice";
import contactUsSlice from "./contactUsSlice/contactUsSlice";
import { userSlice } from "./userSlice/userSlice";

export default configureStore({
    reducer: {
        responsive: responsiveSlice.reducer,
        contactUs: contactUsSlice.reducer,
        user: userSlice.reducer,
    }
})