import tts3 from '../../../assets/pages/service/aiAndMl/tts3.png';

export const textToSpeechData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Use a custom AI and ML engine to turn your text into voice overs for a more effective application. It makes it possible for your application to speak and to build a whole product around voice commands. You may use text-to-speech to communicate your company. to increase the reader's accuracy, fluency, and reading comprehension while providing additional motivation to interact with the intended audience. A few minutes of speech may be created from a lengthy text with a single tap of the finger or click of the button. Text to speech simplifies everything, from basic audio notifications to customized voice messages. The user may access discussions in several languages and the service is utilized in numerous languages.",
            "For companies looking to grow both their clientele and their operations, it is advantageous. The approach is quite beneficial for businesses of all sizes and sectors. The program is well-known for its read-aloud capabilities since it facilitates audible and loud reading of digital material with assistive technology. For individuals who can read the complete essay or who don't have much time to read, the technology is helpful. They will be better able to compose, revise, and concentrate on the piece's most important sections. In only a few seconds, the text-to-speech summarizes the whole text or set of words and becomes useful for the users.",
            "Text to voice is crucial for business since it enables organizations to operate more productively and quickly. You may personalize the text to speech by ensuring that it follows a different format wherever. The greatest text-to-speech services are offered by Alta Tech Mark, which can ease your workload."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "The advantages of text to speech:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Tailor Your Speech",
                description: "For organizations, the engine may tailor speech and scenario, making it simple to operate in accordance with that personalized speech. There are several variations and styles available for text-to-speech."
            },
            {
                id: 2,
                title: "Utilizing Several Languages",
                description: "Since there are more than 70 languages spoken and written in the globe, doing business will never be hampered by a language barrier. The user-friendly interface and many languages provide you with a customized experience."
            },
            {
                id: 3,
                title: "Make a Greater Impact",
                description: "Text-to-speech enables marketers to reach a wider audience since it can translate any language. Additionally, it may assist you in expanding your audience so that your company can run smoothly."
            },
            {
                id: 4,
                title: "Elevate the Brand's Value",
                description: "More facilities always translate into a higher brand value. Therefore, text-to-speech is the ideal illustration of how to link a wider audience and favorable results to increase brand value."
            },
            {
                id: 5,
                title: "Cost-effective and Effective",
                description: "The advantage is that it is affordable while yet reaching a large audience. The job position reduces burden and promotes efficiency. It is quite lucrative for all sizes of businesses, small and big alike."
            },
            {
                id: 6,
                title: "Boost the Business's Performance",
                description: "Speech-to-text improves business results. Reaching a larger and more affordable audience contributes to achieving the best possible result for the company. Both the experience and the results produced by the engine are intriguing."
            }
        ]
    },
    {
        id: 3,
        title: "Advanced Text-to-Speech Technologies:",
        description: "",
        onlyBullets: true,
        data: [
            "Selection Synthesis of Units",
            "Even if it provides human-like voice without modifying signals, it is still regarded as artificial.",
            "The Articulatory and Formant Synthesis",
            "The technology offers recorded speech, speech with specified quality, and much more.",
            "Synaptic Neurons",
            "Voice recordings and written scripts were used as the voice source in the early days of technology."
        ]
    },
    {
        id: 4,
        title: "The Uses Of Text-To-Speech:",
        description: "",
        onlyBullets: true,
        data: [
            "Navigational Aids",
            "Audio Recording",
            "Online instruction",
            "Engaging Voice Dialogue",
            "Wired Home Appliances"
        ],
        bulletsPic: tts3
    },
    {
        id: 5,
        title: "",
        description: "It works on all mobile devices, laptops, PCs, and much more. As a result, you won't need to spend extra time applying codes to various devices. The computer controls it so that the audio may be played at different speeds. Approximately 250 voices and over 70 languages are available for usage by audiences worldwide. A scenario where text readers and voice-activated virtual assistants can express voices like real beings may be created by the user. It also includes a variety of speaking styles, which is advantageous for international companies.",
        onlyBullets: false,
        data: []
    },
    {
        id: 6,
        title: "Why Opt for Alta Tech Mark's Text-to-Speech Software?",
        descriptions: [
            "Our organization offers cloud-based APIs, so users don't have to be concerned about misplacing crucial papers. We provide cutting-edge technology that is cross-platform compatible. The greatest service is provided to clients and users by Alta Tech Mark specialists who focus on specifics. The business is renowned for its brilliance and has provided a number of use cases. Our engine developers possess profound and perceptive understanding of text-to-speech.",
            "Experts at Alta Tech Mark pinpoint demands, trends, market awareness, and much more. We guarantee that the voice that people hear is synthetic, yet it still sounds human. We also provide transparency. Numerous companies are aware of Alta Tech Mark's ability to customize and customize offerings. The engine is a great way to grow the company, and because we think you should take advantage of every chance you have, we provide the most dependable services."
        ],
        onlyBullets: false,
        data: []
    }
]