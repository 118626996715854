import React from 'react';
import { useNavigate } from 'react-router-dom';

// components
import HeaderText from '../../components/headerText/HeaderText';
import ContactUsNoForm from '../../components/contactUs/ContactUsNoForm';

// assets
import { companyHoverData } from '../../data/companyHoverData';
import { aboutUsData } from '../../data/aboutUs/aboutUsData';
import { servicesData } from '../../components/navbar/servicesHover/servicesData';
import { hireDeveloperData } from '../../components/navbar/hireDeveloperHover/hireDeveloperData';

export default function Sitemap() {
    // initializations
    const navigate = useNavigate();

    return (
        <div className=''>
            <HeaderText
                colorTitleStart="SITEMAP"
                description="Explore All Pages Of Our Website"
                className='tw-text-center tw-my-8 tw-px-[9.115vw]'
            />

            <div className='tw-grid tw-grid-cols-3 tw-w-full tw-gap-4 tw-px-[9.115vw] tw-mb-2'>
                {/* company */}
                <div className='md:tw-col-span-1 tw-col-span-3 tw-list-disc tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-y-2'>
                    <h1 className='tw-text-lg tw-font-semibold'>Alta Tech Mark</h1>
                    <div className='tw-w-full tw-h-[1px] tw-bg-slate-300 -tw-mt-1'></div>
                    {companyHoverData.map(data => <li key={data.id} className='tw-text-base tw-cursor-pointer tw-text-purple' onClick={() => navigate(data.url)}>{data.title}</li>)}
                </div>

                {/* about us */}
                <div className='md:tw-col-span-1 tw-col-span-3 tw-list-disc tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-y-2'>
                    <h1 className='tw-text-lg tw-font-semibold'>About Us</h1>
                    <div className='tw-w-full tw-h-[1px] tw-bg-slate-300 -tw-mt-1'></div>
                    {aboutUsData.map(data => <li key={data.id} className='tw-text-base tw-cursor-pointer tw-text-purple' onClick={() => navigate(data.url)}>{data.title}</li>)}
                </div>

                {/* contact us */}
                <div className='md:tw-col-span-1 tw-col-span-3 tw-list-disc tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-y-2'>
                    <h1 className='tw-text-lg tw-font-semibold'>Contact Us</h1>
                    <div className='tw-w-full tw-h-[1px] tw-bg-slate-300 -tw-mt-1'></div>
                    <li className='tw-text-base tw-cursor-pointer tw-text-purple' onClick={() => navigate('/contactUs')}>Contact Us</li>
                    {/* {aboutUsData.map(data => <li key={data.id} className='tw-text-base tw-cursor-pointer tw-text-purple' onClick={() => navigate(data.url)}>{data.title}</li>)} */}
                </div>

                {/* services */}
                <div className='tw-col-span-3 tw-list-disc tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-y-2'>
                    <h1 className='tw-text-lg tw-font-semibold'>Services</h1>
                    <div className='tw-w-full tw-h-[1px] tw-bg-slate-300 -tw-mt-1'></div>
                    <div className='tw-grid tw-grid-cols-3 tw-w-full tw-gap-2'>
                        {servicesData.map(data => data.children.map(data => <li key={data.id} className='tw-col-span-3 md:tw-col-span-1 tw-text-base tw-cursor-pointer tw-text-purple' onClick={() => navigate(data.url)}>{data.value}</li>))}
                    </div>
                </div>

                {/* hire developers */}
                <div className='tw-col-span-3 tw-list-disc tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-y-2'>
                    <h1 className='tw-text-lg tw-font-semibold'>Hire Developers</h1>
                    <div className='tw-w-full tw-h-[1px] tw-bg-slate-300 -tw-mt-1'></div>
                    <div className='tw-grid tw-grid-cols-3 tw-w-full tw-gap-2'>
                        {hireDeveloperData.map(data => <li key={data.id} className='tw-col-span-3 md:tw-col-span-1 tw-text-base tw-cursor-pointer tw-text-purple' onClick={() => navigate(data.url)}>{data.value}</li>)}
                    </div>
                </div>
            </div>

            <ContactUsNoForm />
        </div>
    )
}
