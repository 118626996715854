import React from 'react';

// assets
import bg from '../../../assets/brands/porsche/bg.svg';
import logo from '../../../assets/brands/porsche/logo.svg';
import right from '../../../assets/brands/porsche/right.svg';
import overviewFullSpan from '../../../assets/brands/porsche/overviewFullSpan.png';
import ci1 from '../../../assets/brands/porsche/ci1.svg';
import ci2 from '../../../assets/brands/porsche/ci2.svg';
import instructionScreen from '../../../assets/brands/porsche/instructionScreen.svg';
import quizScreen from '../../../assets/brands/porsche/quizScreen.svg';
import innovation from '../../../assets/brands/porsche/innovation.svg';
import inputScreen from '../../../assets/brands/porsche/inputScreen.svg';
import br from '../../../assets/brands/porsche/br.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function Porsche() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="PORSCHE"
                simpleTitle="Automobile"
                description="To boost customer engagement, Porsche sought the creation of a quiz app, and thus Alta Tech Mark stepped in to facilitate this achievement."
                rightClasses="md:tw-w-[35vw] md:tw-h-[25vw]"
            />

            <ProjectOverview
                overview="The Porsche Quiz App initiative was a collaborative project designed to engage customers and enthusiasts by leveraging interactive technology. Our team was tasked with developing a quiz app that would not only entertain users but also educate them about the Porsche brand, its heritage, and its current lineup of vehicles."
                overviewFullSpan={overviewFullSpan}
            />

            {/* customer interaction */}
            <div className='tw-grid tw-relative tw-bg-red-600 tw-grid-cols-2 tw-gap-x-4 tw-px-[9.115vw] tw-py-[4vw] tw-text-white'>
                <div className='tw-col-span-1 tw-w-[40.264vw] tw-h-[28.953vw]'>
                    <img src={ci1} alt="" className='tw-w-[40.264vw] tw-h-[28.953vw]' />
                </div>
                <div className='tw-col-span-1 tw-flex tw-flex-col tw-gap-y-2'>
                    <h1 className='md:tw-text-[1.7vw] tw-font-semibold'>Customer Interaction:</h1>
                    <h1 className='tw-text-[1.171vw]'>The quiz provides a highly interactive experience that elevates user engagement to its peak. Featuring compelling questions and a seamless interface, the Porsche app ensures that each user enjoys the highest standard of interactive activity.</h1>
                </div>
                <div className='tw-absolute tw-bottom-2 tw-left-[50%] tw-w-[47.877vw] tw-h-[27.086vw]'>
                    <img src={ci2} alt="" className='tw-w-[47.877vw] tw-h-[27.086vw]' />
                </div>
            </div>
            {/* customer interaction */}

            {/* style guide */}
            <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-black tw-grid tw-grid-cols-2 tw-gap-x-4'>
                <h1 className='md:tw-text-[4.167vw] tw-text-[#E41E37] tw-font-semibold tw-col-span-2 tw-text-center'>Style Guide</h1>
                <div className='tw-flex tw-flex-col tw-items-center tw-col-span-1'>
                    <h1 className='tw-text-[4.097vw] tw-text-white tw-font-semibold'>Color Palette</h1>
                    <div className='tw-flex tw-items-center tw-justify-around tw-w-[75%]'>
                        <div className='tw-flex tw-flex-col tw-items-center tw-gap-y-2'>
                            <div className='tw-rounded-[25px] tw-w-[7.986vw] tw-h-[10.417vw] tw-bg-[#E41E37]'></div>
                            <h1 className='tw-text-[1.389vw] tw-text-white'>#E41E37</h1>
                        </div>
                        <div className='tw-flex tw-flex-col tw-items-center tw-gap-y-2'>
                            <div className='tw-rounded-[25px] tw-w-[7.986vw] tw-h-[10.417vw] tw-bg-[#000000] tw-border-[1px] tw-border-white'></div>
                            <h1 className='tw-text-[1.389vw] tw-text-white'>#000000</h1>
                        </div>
                        <div className='tw-flex tw-flex-col tw-items-center tw-gap-y-2'>
                            <div className='tw-rounded-[25px] tw-w-[7.986vw] tw-h-[10.417vw] tw-bg-[#ffffff]'></div>
                            <h1 className='tw-text-[1.389vw] tw-text-white'>#ffffff</h1>
                        </div>
                    </div>
                </div>
                <div className='tw-col-span-1 tw-flex tw-flex-col tw-items-start tw-text-white tw-pl-[30%]'>
                    <h1 className='tw-text-[4.167vw] tw-font-semibold'>Typeface</h1>
                    <h1 className='tw-text-[4.167vw]'>Akshar</h1>
                    <div className='tw-flex tw-items-center tw-gap-x-4'>
                        <div className='tw-flex tw-flex-col tw-items-start'>
                            <h1>Regular</h1>
                            <h1 className='tw-text-[4.167vw]'>Aa</h1>
                        </div>
                        <div className='tw-flex tw-flex-col tw-items-start'>
                            <h1>Regular</h1>
                            <h1 className='tw-text-[4.167vw] tw-font-semibold'>Aa</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* style guide */}

            {/* instruction screen */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2 tw-relative tw-bg-white'>
                <h1 className='md:tw-text-[2.635vw] tw-text-red-600 tw-font-bold tw-text-center'>Instructions Screen</h1>
                <img className='tw-mx-auto' src={instructionScreen} alt="instruction screen" />
            </div>
            {/* instruction screen */}

            {/* quiz screen */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2 tw-relative tw-bg-white'>
                <h1 className='md:tw-text-[2.635vw] tw-text-red-600 tw-font-bold tw-text-center'>Quiz Screen</h1>
                <img className='tw-mx-auto' src={quizScreen} alt="quiz screen" />
            </div>
            {/* quiz screen */}

            {/* innovation */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2 tw-relative tw-bg-[#eebb4b]'>
                <h1 className='md:tw-text-[2.635vw] tw-text-red-600 tw-font-bold tw-text-center'>Innovation at its best</h1>
                <h1 className='tw-text-[1.171vw]'>The application features a cutting-edge user interface designed for seamless, effortless interaction. It's intuitive and equipped with all the contemporary functionalities expected in today's world, offering a sophisticated experience that aligns perfectly with the brand's image.</h1>
                <img className='tw-mx-auto' src={innovation} alt="innovation" />
            </div>
            {/* innovation */}

            {/* input screen */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2 tw-relative tw-bg-white'>
                <h1 className='md:tw-text-[2.635vw] tw-text-red-600 tw-font-bold tw-text-center'>Input Screen</h1>
                <img className='tw-mx-auto' src={inputScreen} alt="input screen" />
            </div>
            {/* input screen */}

            {/* brand recognition */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2 tw-relative tw-bg-red-600'>
                <h1 className='md:tw-text-[2.635vw] tw-text-white tw-font-bold tw-text-left'>Brand Recognition</h1>
                <h1 className='tw-text-[1.171vw] tw-text-white tw-w-[50%]'>Despite Porsche's status as a prominent figure in the automotive sector, its vastness can sometimes be intimidating to prospective or new clients. The primary objective of the application was to enhance recognition and knowledge of the brand amongst users, enabling them to feel more knowledgeable and connected with Porsche's offerings.</h1>
                <img className='tw-mx-auto' src={br} alt="brand recognition" />
            </div>
            {/* brand recognition */}

            <ContactUsNoForm />
        </div>
    )
}
