import React from 'react';

// assets
import u1 from '../../../../assets/pages/service/gameDev/u1.jpeg';
import u2 from '../../../../assets/pages/service/gameDev/u2.png';
import u4 from '../../../../assets/pages/service/gameDev/u4.png';
import { unityDevData } from '../../../../data/services/gameDevData/unityDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function Unity3dDev() {
    return (
        <ServicesWrapper
            title="UNITY 3D"
            descriptionCont="Employing game developers from a reputable firm that specializes in game creation may help you and every other player take your gameplay to the next level."
            img={u1}
        >
            <DevGridWrapper images={[u2, u4]}>
                <DataMapper data={unityDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
