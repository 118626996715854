import React from 'react';

// assets
import { currentOpeningsData } from '../../../data/currentOpeningsData';
import CurrentOpeningsCard from './CurrentOpeningsCard';

// components
import HeaderText from '../../headerText/HeaderText';

export default function CurrentOpenings() {
    return (
        <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple tw-mt-[4vw]'>
            <HeaderText
                colorTitleEnd='OPENINGS'
                title='CURRENT'
                className='tw-text-center tw-mb-8'
            />

            <div className='tw-flex tw-flex-col tw-w-full tw-items-start tw-border-t-[4px] tw-border-purple tw-bg-white tw-p-8'>
                <h1 className='tw-text-xl tw-font-semibold'>Differentiated. Delineated. Dedicated</h1>
                <h1 className='tw-text-sm'>Thanks for checking out our job openings. See something that interests you? Apply here.</h1>

                <div className='tw-flex tw-flex-col tw-gap-y-4 tw-w-full tw-mt-[50px]'>
                    {currentOpeningsData.map(opening => <CurrentOpeningsCard
                        key={opening.id}
                        title={opening.title}
                        experience={opening.experience}
                        positions={opening.positions}
                        country={opening.country}
                        state={opening.state}
                    />)}
                </div>
            </div>
        </div>
    )
}
