import sa3 from '../../../assets/pages/service/aiAndMl/sa3.png';

export const sentimentalAnalysisData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "The volume of data often becomes unquantifiable, making it difficult to forecast or make decisions based on it. Sentimental Analysis is the ideal commercial technique at this precise moment. Through the language, it provides you with specifics of the user's behavior or aspect. For example, sentiment analysis may be used to determine if a product is seen as favorable, negative, or neutral if few individuals provide comments. They examine the raw text using machine learning, natural language processing, and data analysis. Social media platforms stand to gain a great deal by creating and implementing new methods.",
            "Understanding the behavioral shifts allows the business to better match customers' requirements with goods and services. As such, it changed to become an essential instrument for keeping an eye on and understanding opinions. Sentimental analysis is a reliable method for making decisions and forecasts more analysis. The use of sentimental analysis has many benefits. In order to generate data questionnaires for analytics, the service analyzes data, customer evaluations, feedback, and other media sources. It improves how well companies execute."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Sentimental Analysis's advantages:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Boost User Engagement",
                description: "Sentimental Analysis assists them in offering a customized experience by illuminating the customer's reaction. Customers begin to show more interest and approach as a result of it improving and increasing user interest."
            },
            {
                id: 2,
                title: "Boost the Experience",
                description: "Customization improves the user and consumer experience. Businesses are able to successfully respond to consumer preferences and sentiments about brands, goods, and services through online communications channels like social networks."
            },
            {
                id: 3,
                title: "More Expandable",
                description: "Higher scalable, Sentimental Analysis delivers excellent service. The information supplied is almost exact, promotes company, and demonstrates how the work structure is expanding."
            },
            {
                id: 4,
                title: "Instantaneous Analysis",
                description: "Because the service gives you real-time analysis, it is fast and efficient and makes the task simpler and more dependable for the data-driven goal."
            },
            {
                id: 5,
                title: "Find Out in-Depth",
                description: "A thorough understanding of the brand and product is offered by the real-time sentiment analysis. The track seems well-organized and has been automatically assessed without human intervention."
            },
            {
                id: 6,
                title: "Advancement Accuracy",
                description: "Sentiment analysis looks for information about consumer views in the text. It will benefit the firm and is dependent on how the client perceives the activity. It may thus advance, and the likelihood of accuracy is increased.",
                pic: sa3
            }
        ]
    },
    {
        id: 5,
        title: "Why Select Alta Tech Mark for Emotional Dissection?",
        descriptions: [
            "Our skilled staff is well-versed in sentiment analysis and has a thorough understanding of its concepts. We provide the most precise and targeted information to enable you to make well-informed decisions. Alta Tech Mark's cutting-edge, powerful solutions will assist you in real-time information analysis. Leveraging human intellect and behavior via the message and gathered data is beneficial. Based on human behavior, preferences, and word prediction, we have developed an algorithm that extracts pertinent keywords and phrases.",
            "With no extra effort, Alta Tech Mark enables the maintenance and improvement of search work's accuracy. We provide domain knowledge and analysis in many languages so that various sectors and languages may anticipate superior outcomes. Using emotive analysis, our staff guarantees both quality and results."
        ],
        onlyBullets: false,
        data: []
    }
]