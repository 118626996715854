import React from 'react';

// assets
import bg from '../../../assets/brands/educationUsa/bg.svg';
import logo from '../../../assets/brands/educationUsa/logo.svg';
import right from '../../../assets/brands/educationUsa/right.svg';
import overviewFullSpan from '../../../assets/brands/educationUsa/overviewFullSpan.svg';
import customDesign from '../../../assets/brands/educationUsa/customDesign.svg';
import cd2 from '../../../assets/brands/educationUsa/cd2.svg';
import bg2 from '../../../assets/brands/educationUsa/bg2.svg';
import ma from '../../../assets/brands/educationUsa/ma.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function EducationUsa() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="EDUCATION USA"
                // simpleTitle="USA Department of State"
                description="Education USA aimed to engage with students and candidates worldwide, and Alta Tech Mark facilitated this connection by developing a native mobile application."
                rightClasses="md:tw-w-[30vw] md:tw-h-[20vw]"
            />

            <ProjectOverview
                overview="One of our prominent clients, Education USA, part of the Department of State network, entrusted us with the task of developing a mobile application designed to connect with students and candidates globally."
                overviewFullSpan={overviewFullSpan}
            />

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2 tw-justify-end'
                style={{
                    backgroundImage: `url(${customDesign})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <div className='tw-flex tw-flex-col tw-items-center'>
                    <h1 className='md:tw-text-[2.635vw] tw-text-center tw-text-white'>Custom and Personalized Design</h1>
                    <img src={cd2} className='tw-w-[17.083vw] tw-h-[42.708vw]' alt="" />
                </div>
            </div>

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2'
                style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <h1 className='md:tw-text-[2.635vw] tw-text-white tw-font-bold tw-text-center'>Mobile Application</h1>
                <h1 className='md:tw-text-[1.171vw] tw-text-center tw-text-white'>We provided user-friendly and simple navigation that can help user to surf throughout the app easily. And Sign in and Sign up with security assurance.</h1>
                <img className='tw-mx-auto' src={ma} alt="quest" />
            </div>

            <ContactUsNoForm />
        </div>
    )
}
