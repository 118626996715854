export const hireFullStackDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "The creation of both the front-end and back-end components of web applications is known as full-stack development. A front-end and back-end developer is known as a full-stack developer. The database, testing, debugging, and other tasks fall to the developer. They also support software development and design. Maintaining software suitable for websites and online apps is the responsibility of a developer. Full-stack developers work on a variety of software components, including database servers, version control systems, and APIs. Because front-end and back-end are combined, the developer is in charge of the whole process of creating a website or web application.",
            "In order to work on front-end development, a developer must be proficient in website presentation. In addition to working with CSS, HTML, and JavaScript, the developer must make sure that the website's content will display differently on mobile devices. The developer handles the website's content, color scheme, fonts, and other crucial aspects. These front-end developers are responsible for everything a user sees on the website. Writing code is what the developer must do in the background while working on back-end projects. The website and web apps are tested and found to be operating correctly by the back-end developers. Another name for it is server-side development. Back-end developers mostly work with databases, APIs, and back-end logic. They use many scripting languages, including PHP, Python, Ruby, and others."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Full-Stack Developer Services Offered by Alta Tech Mark:",
        description: "",
        onlyBullets: true,
        listNum: true,
        data: [
            "Full-stack Web Development: Our full-stack developers craft dynamic, reliable web platforms using top-tier front-end and back-end technologies, infused with bespoke design personalization.",

            "Database Management: Our expert touch ensures precise, real-time database management. Adhering strictly to data flow diagram norms, our team skillfully oversees all database aspects.",

            "Full Stack Front-end Development: Leveraging tools like HTML, JavaScript, the MEAN stack, and CSS, we promise excellence in front-end development. Attention to detail, from fonts to colors, ensures cohesive aesthetics.",

            "Full Stack Back-end Development: Back-end development at Alta Tech Mark utilizes technologies like JavaScript, Laravel, Node.Js, PHP, Ruby, and Python. We prioritize precise code execution, ensuring a secure, dependable back-end infrastructure.",

            "Full Stack Software Development: We pride ourselves on delivering high-caliber, scalable software. Every product undergoes rigorous testing until it offers a flawless user experience, always aligning with client business objectives.",

            "Full Stack Application Development: Prominent among our offerings is full-stack application development. From cross-platform to native and hybrid mobile apps, our experts utilize tools like Flutter, Ionic, and React Native to craft superior applications.",

            "Full-Stack JavaScript Development: JavaScript is second nature to our full-stack team. They construct dynamic web apps, interactive web pages, mobile applications, and software with unparalleled functionality.",

            "API Development And Integration: Our full-stack experts adeptly implement API integration services, connecting devices, third-party payment gateways, B2B integrations, SaaS platforms, and more, always ensuring scalability.",

            "Full Stack PHP Development: At Alta Tech Mark, PHP is utilized for crafting websites and web apps. With a foundation in the Linux operating system, we ensure smooth, error-free operations, even with third-party API integrations."
        ]
    },
    {
        id: 3,
        title: "Why Engage with Alta Tech Mark's Full-Time Stack Developers?",
        description: "",
        onlyBullets: true,
        listNum: true,
        data: [
            "Highly Skilled: Armed with profound knowledge across software, coding, and contemporary technologies, our full-stack developers bring innovation to the table. Acutely aware of modern trends, we guarantee solutions tailored to clients' specific needs.",

            "On-time Delivery: Time is invaluable. Recognizing this, Alta Tech Mark commits to punctual project delivery, fortified by transparent communication with clients.",

            "Defined Approach: Our methodical approach to full-stack development ensures consistent quality and immediate troubleshooting, should any technical hiccups arise.",

            "Cost And Time Effective: Thanks to our team's vast expertise, we offer both cost-effective solutions and efficient turnarounds. Our varied offerings cater to diverse business needs, always prioritizing powerful, efficient results."
        ]
    },
    {
        id: 4,
        title: "",
        description: "Alta Tech Mark stands as a premier full-stack development firm. Our track record is studded with successful projects and satisfied clients. For top-tier full-stack development services, Alta Tech Mark remains the optimal choice.",
        onlyBullets: false,
        data: []
    }
]

export const hireFullStackDevFaq = [
    {
        id: 1,
        question: "1. Why should I hire dedicated full-stack developers?",
        answer: "The dedicated full-stack developers will work only for you to deliver outstanding results with the highest optimization. The team of full-stack developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire full-stack developers?",
        answer: "Alta Tech Mark offers a very simple process to hire full-stack developers. Just provide project details and the technical expertise you need. The team of full-stack developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated full-stack developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the full-stack developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated full-stack developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated full-stack developer, then you can hire dedicated full-stack developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated full-stack developers?",
        answer: "The cost estimation for dedicated full-stack developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]