import React from 'react';

// assets
import db1 from '../../../../assets/pages/service/corporateBranding/db1.png';
import { digitalBrandingDevData, componentsOfDigitalBrandingDevData, principlesOfDigitalBrandingDevData, emergingTechnologiesDigitalBrandingDevData } from '../../../../data/services/corporateBranding/digitalBrandingDevData';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';
import CaseStudies from '../../../../components/home/caseStudies/CaseStudies';
import HeaderText from '../../../../components/headerText/HeaderText';

export default function DigitalBranding() {
    return (
        <ServicesWrapper
            title="DIGITAL BRANDING"
            descriptionCont="Digital branding is the process of establishing a brand identity and presence in the digital realm. Today's online landscape requires companies to consider various facets of the internet when it comes to building a brand."
            img={db1}
        >
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={digitalBrandingDevData} />

                <HeaderText
                    title="Components of"
                    colorTitleEnd="Digital Branding"
                    className="tw-text-center tw-mb-8 md:tw-mt-[11.458vw]"
                />
                <DataMapper data={componentsOfDigitalBrandingDevData} />

                <HeaderText
                    title="Principles of"
                    colorTitleEnd="Digital Branding"
                    className="tw-text-center tw-mb-8 md:tw-mt-16"
                />
                <DataMapper data={principlesOfDigitalBrandingDevData} />

                <HeaderText
                    title="Emerging Technologies in"
                    colorTitleEnd="Digital Branding"
                    className="tw-text-center tw-mb-8 md:tw-mt-16"
                />
                <DataMapper data={emergingTechnologiesDigitalBrandingDevData} />
            </div>

            <CaseStudies colorTitleStart="PORTFOLIO" title=" " />
        </ServicesWrapper>
    )
}
