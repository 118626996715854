// images
// import metaverseDevelopment from '../assets/background/metaverseDevelopment2.jpg'
// import metaverseDevelopment from '../assets/background/metaverseDevelopment.png'
import gameDevelopment from '../assets/background/gameDevelopment.png'
// import mobileAppDevelopment from '../assets/background/mobileAppDevelopment.png'
// import mobileAppDevelopment from '../assets/background/mobileAppDevelopment2.jpg'
import mobileAppDevelopment from '../assets/background/mobileAppDevelopment3.png'
// import webDevelopment from '../assets/background/webDevelopment2.jpg'
import webDevelopment from '../assets/background/webDevelopment3.png'
// import webDevelopment from '../assets/background/webDevelopment.png'
// import blockchainDevelopment from '../assets/background/blockchainDevelopment.png'
// import blockchainDevelopment from '../assets/background/blockchainDevelopment2.jpg'
import blockchainDevelopment from '../assets/background/blockchainDevelopment3.png'
import aiDevelopment from '../assets/background/aiDevelopment3.png'
import iotBg from '../assets/background/iotBg.png';

export const cardsData = [
    {
        id: 1,
        titleInColor: "Mobile App",
        titleCont: 'Development',
        descriptionBold: "Revolutionizing Digital Spaces: Bespoke Mobile App Development.",
        descriptionCont: "Charting the path to innovation, our team crafts cutting-edge mobile apps tailored to your needs, ensuring user-centric design, seamless functionality, and transformative digital experiences for all.",
        img: mobileAppDevelopment,
    },
    {
        id: 2,
        titleInColor: "Web",
        titleCont: 'Development',
        descriptionBold: "Crafting Digital Landscapes: Premier Web Development Services.",
        descriptionCont: "Transforming ideas into reality, our web development team delivers unique, user-friendly, and responsive designs that captivate audiences, elevate brand presence, and drive online success in today’s digital age.",
        img: webDevelopment,
    },
    {
        id: 3,
        titleInColor: "Blockchain",
        titleCont: "Development",
        descriptionBold: "Leading the Charge in Blockchain Development.",
        descriptionCont: "At the forefront of innovation, our team specializes in creating robust blockchain solutions that drive transparency, efficiency, and unparalleled security for businesses around the globe.",
        img: blockchainDevelopment,
    },
    {
        id: 4,
        titleInColor: "Artificial",
        titleCont: "Intelligence (AI)",
        descriptionBold: "Artificial Intelligence & Machine Learning.",
        descriptionCont: "We boosts your business with advanced image, video, text-to-speech, business intelligence, forecasting, NLP, and data analytics solutions.",
        img: aiDevelopment,
    },
    {
        id: 5,
        titleInColor: "Game",
        titleCont: "Development",
        descriptionBold: "Bringing Imaginations to Life: Elite Game Development.",
        descriptionCont: "From concept to console, our game development team blends artistry with technology, creating immersive gaming experiences that captivate, entertain, and set new standards in interactive storytelling.",
        img: gameDevelopment,
    },
    {
        id: 6,
        titleInColor: "IoT",
        titleCont: 'PRODUCT DEVELOPMENT',
        descriptionBold: "From concept to completion:",
        descriptionCont: "We provide a seamless IoT solution encompassing PCB design, software, cloud connectivity, device management, prototyping, testing, and more. Your idea, our execution.",
        img: iotBg,
    }
]