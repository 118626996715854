import React from 'react'

// data
import { workWithData } from '../../../data/workWithData';
import workWithBg from '../../../assets/background/workWithBg.png';

// components
import WorkWithCard from './WorkWithCard';
import HeaderText from '../../headerText/HeaderText';

export default function WorkWith() {
    return (
        <div className='tw-px-[9.115vw] tw-py-[4vw] tw-mt-[8.138vw] tw-grid md:tw-grid-cols-2 tw-gap-[1.628vw] tw-items-center'
            style={{
                backgroundImage: `linear-gradient(90deg, #9065FD 0.03%, #9065FD 24.31%, #8256F1 44.14%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${workWithBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <HeaderText
                title="WHO WE WORK WITH"
                description="As a top mobile app development firm in the USA, we have collaborated with over 2000+ businesses, from startups to enterprises, providing industry-leading solutions. At Alta Tech Mark, we extend a wide array of app development services tailored to business needs."
                className='tw-text-white tw-mb-8 tw-col-span-1 md:tw-text-left tw-text-center'
            />

            <div className='md:tw-col-span-1 tw-col-span-1 tw-grid tw-grid-cols-2 tw-gap-[1.628vw]'>
                {workWithData.map(card => <WorkWithCard
                    key={card.id}
                    src={card.src}
                    title={card.title}
                />)}
            </div>
        </div>
    )
}
