import React from 'react'

export default function KeyFeaturesCard({ src, title, description, height, width }) {
    return (
        <div className={`tw-flex tw-flex-col tw-items-start tw-bg-[#4943AA] tw-p-[20px] tw-rounded-lg ${height ? `tw-h-[${height}px]` : 'tw-h-[350px]'} ${width ? `tw-w-[${width}]` : "tw-w-auto"} tw-w-auto tw-shadow-lg tw-text-white`}
            style={{
                background: "linear-gradient(180deg, #372A60 0%, #0A0095 100%)"
            }}
        >
            <img className='tw-w-[50px] tw-h-[50px]' src={src} alt={title} />
            <h1 className='tw-font-semibold tw-text-[18px] tw-my-[16px]'>{title}</h1>
            <h1 className='tw-text-[16px] tw-mt-[20px] tw-w-[262px]'>{description}</h1>
        </div>
    )
}
