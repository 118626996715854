import React from 'react';

// assets
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function DataMapper(props) {
    return (
        <>
            {props.data.map(data => (
                <div key={data.id} className='tw-flex tw-flex-col tw-items-start tw-mt-[2vw] md:tw-gap-y-3 tw-gap-y-1 tw-tracking-wider tw-w-full'>
                    {data.sectionPicRow ?
                        <div className='tw-grid md:tw-grid-cols-3 tw-w-full tw-justify-items-end'>
                            <div className='md:tw-col-span-2 tw-flex tw-flex-col tw-items-start md:tw-gap-4 tw-gap-2'>
                                <h1 className='md:tw-text-2xl tw-text-base tw-font-semibold tw-text-darkPurple'>{data.title}</h1>
                                {data.description && <h1 className='md:tw-text-base tw-text-sm'>{data.description}</h1>}
                                {data.descriptions?.map((data, index) => <h1 className='md:tw-text-base tw-text-sm' key={index}>{data}</h1>)}
                            </div>
                            <div className='max-md:tw-hidden tw-col-span-1 tw-relative tw-w-[20vw]'>
                                {data.sectionPic && <img src={data.sectionPic} alt={data.title} className='tw-absolute' />}
                            </div>
                        </div>
                        :
                        <div className='tw-flex tw-flex-col tw-w-full tw-items-start md:tw-gap-4 tw-gap-2'>
                            <h1 className='md:tw-text-2xl tw-text-lg tw-font-semibold tw-text-darkPurple'>{data.title}</h1>
                            {data.description && <h1 className='md:tw-text-base tw-text-sm'>{data.description}</h1>}
                            {data.descriptions?.map((data, index) => <h1 className='md:tw-text-base tw-text-sm' key={index}>{data}</h1>)}
                            {data.sectionPic && <img src={data.sectionPic} alt={data.title} className='' />}
                        </div>
                    }
                    {data.onlyBullets ?
                        <div className='tw-flex tw-items-center tw-justify-between md:tw-gap-6 tw-gap-3 tw-w-full'>
                            <ol className={`tw-col-span-1 tw-flex tw-flex-col tw-gap-y-4 ${data.listNum ? "tw-list-decimal" : "tw-list-disc"}`}>
                                {data.data.map((data, index) =>
                                    <li className='tw-ml-5 md:tw-text-base tw-text-sm'>{data}</li>
                                )}
                            </ol>
                            {data.bulletsPic &&
                                <div className='tw-col-span-1 max-md:tw-w-[35%]'>
                                    {data.bulletsPic && <img src={data.bulletsPic} alt="pic" />}
                                </div>
                            }
                        </div>
                        :
                        data.grid3Display ?
                            <>
                                {
                                    data.data.map(data =>
                                        <div className='md:tw-grid md:tw-grid-cols-6 tw-flex tw-flex-col tw-w-full md:tw-gap-4 tw-my-4'>
                                            <div className='tw-col-span-1 tw-justify-self-start tw-relative md:tw-w-[10vw]'>
                                                {data.descPicOrder === 1 && <img src={data.descPic} alt={data.title} className={`md:tw-absolute tw-mx-auto`} />}
                                            </div>
                                            <div className={`tw-flex tw-flex-col tw-items-start tw-gap-4 md:tw-col-span-4 tw-cols-span-6`}>
                                                <h1 className='md:tw-text-xl tw-text-lg tw-font-semibold tw-text-darkPurple'>{data.title}</h1>
                                                {data.description && <h1 className='md:tw-text-base tw-text-sm'>{data.description}</h1>}
                                                {data.descriptions?.map((data, index) => <h1 className='md:tw-text-base tw-text-sm' key={index}>{data}</h1>)}
                                                {data.sectionPic && <img src={data.sectionPic} alt={data.title} className='' />}
                                            </div>
                                            <div className='tw-col-span-1 tw-justify-self-end tw-relative md:tw-w-[10vw]'>
                                                {data.descPicOrder === 3 && <img src={data.descPic} alt={data.title} className={`md:tw-absolute tw-mx-auto`} />}
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                            :
                            data.grid2Display
                                ?
                                <div className='tw-grid md:tw-grid-cols-4 tw-w-full'>
                                    <div className='md:tw-col-span-3 tw-flex tw-flex-col tw-items-start tw-gap-4'>
                                        {data.data.map(data =>
                                            <div className='tw-flex tw-flex-col tw-items-start'>
                                                <h1 className='md:tw-text-2xl tw-text-base tw-font-semibold tw-text-darkPurple'>{data.title}</h1>
                                                {data.description && <h1 className='md:tw-text-base tw-text-sm'>{data.description}</h1>}
                                            </div>
                                        )}
                                    </div>
                                    <div className='md:tw-col-span-1'>
                                        {data.sectionPicGrid && <img src={data.sectionPicGrid} alt={data.title} className='max-md:tw-mx-auto max-md:tw-w-[50%]' />}
                                    </div>
                                </div>
                                :
                                <ol className={`tw-flex tw-flex-col tw-items-start tw-gap-y-4 ${data.noList ? "" : data.listNum ? "tw-list-decimal tw-ml-5" : "tw-list-disc tw-ml-8"}`}>
                                    {data.data.map(data => (
                                        <div key={data.id} className='tw-flex tw-flex-col tw-items-start tw-gap-y-1'>
                                            <li className='md:tw-text-lg tw-text-base tw-font-semibold tw-text-darkPurple'>{data.title}</li>
                                            <h1 className='md:tw-text-base tw-text-sm'>{data.description}</h1>
                                            {data.data &&
                                                <div className='tw-flex tw-items-center tw-justify-between tw-gap-[15px] tw-w-full'>
                                                    <ol className={`tw-col-span-1 ${data.noList ? "" : data.listNum ? 'tw-list-decimal' : 'tw-list-disc'}`}>
                                                        {data.data.map((data, index) =>
                                                            <li className='md:tw-text-base tw-text-sm'>{data}</li>
                                                        )}
                                                    </ol>
                                                    {data.bulletsPic &&
                                                        <img src={data.bulletsPic} alt="pic" className='tw-col-span-1' />
                                                    }
                                                </div>
                                            }
                                            {data.pic &&
                                                <div className='tw-w-full tw-grid tw-justify-end'>
                                                    <img src={data.pic} alt="pic" className='' />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </ol>
                    }
                </div>
            ))}
        </>
    )
}
