import React from 'react';

// assets
import ar1 from '../../../../assets/pages/service/gameDev/ar1.jpeg';
import ar2 from '../../../../assets/pages/service/gameDev/ar2.png';
import { augmentedRealityDevData } from '../../../../data/services/gameDevData/augmentedRealityDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function AugmentedRealityDev() {
    return (
        <ServicesWrapper
            title="AUGMENTED REALITY"
            descriptionCont="Employing game developers from a reputable firm that specializes in game creation may help you and every other player take your gameplay to the next level."
            img={ar1}
        >
            <DevGridWrapper img={ar2}>
                <DataMapper data={augmentedRealityDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
