import React from 'react'
import { Link } from 'react-router-dom';


export default function HelpHover(props) {
    return (
        // <div className='tw-grid tw-grid-cols-3 tw-absolute tw-border-slate-300 tw-border-[1px] tw-h-[30vh] tw-z-10 tw-bg-purple tw-text-purple' onMouseLeave={props.hoverLeave}
        //     style={{
        //         backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
        //     }}
        // >
            <div className='tw-flex tw-flex-col tw-items-start tw-justify-between' onMouseLeave={props.hoverLeave}>
                <div className='tw-flex tw-flex-col tw-items-start tw-w-[100%]'>
                    <Link to='/contactUs' className='tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-white hover:tw-bg-opacity-20'> Contact Us</Link>
                    <Link to='/about/career' className='tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-white hover:tw-bg-opacity-20'>Careers</Link>
                    <Link to='/sitemap' className='tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-white hover:tw-bg-opacity-20'>Sitemap</Link>
                </div>

            </div>
        // </div>
    )
}
