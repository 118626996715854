import React from 'react'

export default function HiringCard(props) {
    return (
        <div className={`tw-col-span-1 tw-relative tw-flex md:tw-flex-col md:tw-h-[25.278vw] tw-h-[10.833vw] md:tw-w-[8.125vw] tw-items-center tw-text-center ${props.index % 2 !== 0 ? 'md:tw-flex-col-reverse md:tw-pb-[5%] md:tw-pr-0 tw-pr-[25%]' : 'tw-flex-row-reverse md:tw-pt-[10%] md:tw-pl-0 tw-pl-[25%]'} ${props.index === 0 ? "md:tw-ml-[20%] md:tw-mt-0 tw-mt-[3%]" : props.index === 1 ? "md:tw-ml-[5%]" : props.index === 2 ? "md:tw-mr-[5%]" : "md:tw-mr-[20%]"}`}>
            <div className='tw-flex tw-flex-col tw-w-full '>
                <h1 className='tw-font-semibold md:tw-text-[1.172vw] tw-text-[4.167vw] md:tw-leading-[1.823vw]'>{props.title}</h1>
                <h1 className='md:tw-text-[1.042vw] tw-text-[2.778vw] md:tw-leading-[1.563vw]'>{props.description}</h1>
            </div>
            <div className={`tw-flex md:tw-flex-col tw-items-center ${props.index % 2 !== 0 ? 'md:tw-flex-col-reverse' : 'tw-flex-row-reverse'} tw-w-full`}>
                <div className='md:tw-w-[0.130vw] tw-h-[0.130vw] md:tw-h-[1.628vw] tw-w-[6.389vw] tw-bg-black'></div>
                <img src={props.src} alt={props.title} className='md:tw-w-[9.097vw] tw-w-[8.056vw] md:tw-h-[7.611vw] tw-h-[7.563vw]' />
            </div>
        </div>
    )
}
