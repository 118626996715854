export const hireWooCommerceDevData = [
    {
        id: 1,
        title: "Introducing WooCommerce: The First Open-Source E-Commerce Platform",
        description: "WooCommerce is an open-source platform used mostly for e-commerce website development. Well-known for being reasonably priced, it provides an excellent management solution that helps with lead production. Do you want complete authority over your website together with integrated ownership? With free and paid plugins for different company requirements, WooCommerce is unmatched. The most adept WooCommerce developers are at navigating the complexities of the platform.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "What Justifies Companies Using WooCommerce Development?",
        description: "WooCommerce has solidified its standing as a model option for online storefronts. It has several features, including order tracking, delivery updates, customer matching, and more. Remarkably, WooCommerce powers a large percentage of WordPress businesses, making it a top choice in the sector. It is regarded by WordPress experts as one of the best e-commerce plugins available. With integrated features like SEO, WooCommerce offers several advantages. It is easy to use and has many benefits too. As a leader in WooCommerce development, Alta Tech Mark stands out for their quality, timeliness, and clarity of delivery.",
        onlyBullets: false,
        data: []
    },
    {
        id: 3,
        title: "Why Hire WooCommerce Developer from Alta Tech Mark?",
        descriptions: [
            "much though WooCommerce is a powerful platform, Alta Tech Mark's skilled group of WooCommerce developers makes it much better. Our developers oversee the whole process, from conception to implementation, guaranteeing engaging user experiences with strong functionality. We are proud to provide 24/7 assistance. Whether your company is small and startup-like or large and corporate, we guarantee the highest quality. WooCommerce is our team's area of expertise, therefore we guarantee that all questions are answered. We promise efficiency, scalability, and cost with our development methodology.",
            "Customer satisfaction is ensured by the flawless execution of our WooCommerce solutions. Using cutting-edge technology, we transform static websites into dynamic e-commerce platforms. You will experience unparalleled security standards and an abundance of features with us. We use cutting-edge tools, technologies, and processes to produce projects that not only meet but also beyond expectations."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Services for WooCommerce Development Provided by Alta Tech Mark:",
        description: "",
        onlyBullets: true,
        data: [
            "Create Plugins for WooCommerce",
            "WooCommerce Website Design",
            "Create WooCommerce Apps",
            "Development of Custom WooCommerce Themes",
            "Migration of WooCommerce Hosting",
            "Gateway for WooCommerce Payments",
            "WordPress Website Layout",
            "Engine Optimization for Search",
            "Management of WooCommerce Inventory",
            "WooCommerce API Connectivity",
            "Photoshop to WooCommerce Store Redesign"
        ]
    },
    {
        id: 5,
        title: "Elevated Technology The Unique Qualities of Mark in WooCommerce Development:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Outstanding Efficiency",
                description: "We put the needs of our users first and strive for operational excellence that boosts business results."
            },
            {
                id: 2,
                title: "Security-First Strategy",
                description: "Our main priority is protecting user privacy. Since we provide a reliable and secure WooCommerce platform, we guarantee data encryption."
            },
            {
                id: 3,
                title: "Affordability",
                description: "Our services are reasonably priced in spite of their excellent quality. All investments offer large profits."
            },
            {
                id: 4,
                title: "Worldwide Experience",
                description: "We have received recognition for our WooCommerce efforts throughout the globe. We serve a global clientele, adapting to various operating standards."
            },
            {
                id: 5,
                title: "Cutting-Edge Tech",
                description: "We use the newest technology available to guarantee prompt delivery without sacrificing quality."
            }
        ]
    },
    {
        id: 6,
        title: "",
        description: "Alta Tech Mark is a giant of the WooCommerce development sector. Our dedication to providing the greatest results is reflected in our customers' ambitions for success. Inquire for more information.",
        onlyBullets: false,
        data: []
    }
]

export const hireWooCommerceDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated WooCommerce developers?",
        answer: "The dedicated WooCommerce developers will work only for you to deliver outstanding results with the highest optimization. The team of WooCommerce developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire WooCommerce developers?",
        answer: "Alta Tech Mark offers a very simple process to hire WooCommerce developers. Just provide project details and the technical expertise you need. The team of WooCommerce developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated WooCommerce developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the WooCommerce developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated WooCommerce developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated WooCommerce developer, then you can hire dedicated WooCommerce developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated WooCommerce developers?",
        answer: "The cost estimation for dedicated WooCommerce developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]