import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import blockchain1 from '../../../assets/pages/service/blockchainDev/blockchain1.png';
import blockchain2 from '../../../assets/pages/service/blockchainDev/blockchain2.png';
import blockchain3 from '../../../assets/pages/service/blockchainDev/blockchain3.png';
import { blockchainGridBlockData, blockchainServicesData } from '../../../data/services/blockchainDevData/blockchainData';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import GridBlock from '../../../components/services/gridBlock/GridBlock';
import KeyFeaturesCard from '../../../components/home/keyFeatures/KeyFeaturesCard';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function BlockchainDev() {
    // initialization
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet ||
                <div>
                    <HeroTitleImage
                        bg={blockchain1}
                        title="BLOCKCHAIN DEVELOPMENT"
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'>
                        <HeaderText
                            colorTitleEnd="BLOCKCHAIN"
                            title="ABOUT"
                            className='tw-mb-[2.083vw] tw-text-center'
                        />
                        <h1 className='tw-text-lg'>
                            The blockchain, to put it simply, is a distinct distributed record that stores data and confirms its accuracy. Blockchain provides stability to transactions by using a distinct collection of cryptography-based technologies to ensure that they continue into the blockchain database.
                        </h1>
                        <br />
                        <h1 className='tw-text-lg'>
                            Blockchain technology controls all money exchanges. However, blockchain extends beyond cash to any sector in which value is exchanged, including contracts, private data, medical records, corporate data, and much more.
                        </h1>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'>
                        <HeaderText
                            colorTitleEnd="DEVELOPMENT"
                            title="BLOCKCHAIN"
                            className='tw-mb-[2.083vw] tw-text-center'
                        />

                        <h1 className='tw-text-lg'>
                            These days, blockchain is the most talked-about issue in many firms, particularly in the IT industry. Thanks to technology, a new, highly secure payment gateway has been created. A blockchain is a great kind of database storage system that stores information or data in records. Through the use of encryption, these records or blocks are automatically duplicated, offering a more secure means of storing data. This lowers the total cost of data storage since your data is safely kept in many locations. The technology that underpins cryptocurrencies and digital money is called blockchain.
                        </h1>
                        <br />
                        <h1 className='tw-text-lg'>
                            Therefore, companies with vast amounts of big data may employ blockchain engineers to build new, more effective business procedures.
                        </h1>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'
                        style={{
                            backgroundImage: `linear-gradient(${screenWidth < 768 ? '0deg' : '-90deg'}, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${blockchain2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className='tw-text-white'>
                            <HeaderText
                                title="ALTA TECH MARK FOR BLOCKCHAIN DEVELOPMENT?"
                                description="As a top online and mobile app development business, we assembled a team of experts to form a board that facilitates the creation of blockchains. Our team of multidisciplinary subject specialists offers a comprehensive and precise customer experience across the blockchain development lifecycle. We created a blockchain service platform that effectively integrates online security, data analysis, artificial intelligence, and the Internet of Things. "
                                className='tw-text-white tw-mb-[2vw] md:tw-text-left tw-text-center'
                                descriptionClassName='tw-text-left'
                            />
                            <h1>
                                We assist you in creating blockchain technology for your company, which will ultimately enable you to easily and securely store transaction data. For Alta Tech Mark, security and transparency are the primary requirements.
                            </h1>
                        </div>
                        <div className='tw-flex md:tw-flex-row tw-flex-col md:tw-items-end tw-justify-between tw-w-full'>
                            <img src={blockchain3} alt="pic" />
                            <div className='tw-grid tw-grid-cols-2 tw-gap-[2vw] tw-justify-items-center'>
                                {blockchainGridBlockData.map((data, index) =>
                                    <GridBlock
                                        key={index}
                                        text={data}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleBetween="ALTA TECH MARK"
                            title="WHAT MAKES"
                            title2="YOUR FIRST CHOICE FOR"
                            colorTitleEnd="BLOCKCHAIN DEVELOPMENT?"
                            className='tw-text-center'
                        />

                        <ol className='tw-list-disc tw-grid tw-gap-2'>
                            <li>Proficiency with Data Structures</li>
                            <li>Proficient in Cryptography</li>
                            <li>knowledge of programming languages and the newest technologies</li>
                            <li>Elevated Availability</li>
                            <li>The whole ideation process, which includes maximizing financial and technical likelihood as well as commercial prospects</li>
                            <li>Flexible, user-friendly models for a range of customer needs</li>
                            <li>timely delivery at a reasonable cost</li>
                            <li>Assures Customer Contentment</li>
                        </ol>
                        <br />
                        <h1>
                            Acquire a comprehensive range of Blockchain development services to use Blockchain technology to grow your organization. Employ ALTA TECH MARK's Blockchain engineers to create your own decentralized blockchain.
                        </h1>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple'>
                        <HeaderText
                            colorTitleStart="SERVICES"
                            title="FOR"
                            colorTitleEnd="BLOCKCHAIN DEVELOPMENT"
                            className='tw-text-center tw-mb-8 tw-w-[110%]'
                            description="A range of services are available from Alta Tech Mark to enhance the blockchain development process."
                        />
                        <div className='md:tw-grid md:tw-grid-cols-3 tw-flex tw-items-center tw-gap-[2vw] tw-overflow-x-scroll md:tw-overflow-x-hidden tw-pb-4'>
                            {blockchainServicesData.map(service =>
                                <KeyFeaturesCard
                                    key={service.id}
                                    title={service.title}
                                    description={service.description}
                                    src={service.src}
                                />
                            )}
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
