import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import corporateBranding from '../../../assets/background/corporateBranding.jpeg';
import cb1 from '../../../assets/pages/service/corporateBranding/cb1.png';
import { corporateBrandingGridBlockData, corporateBrandingServicesData } from '../../../data/services/corporateBranding/corporateBrandingData';
import cb2 from '../../../assets/pages/service/corporateBranding/cb2.png';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import GridBlock from '../../../components/services/gridBlock/GridBlock';
import KeyFeaturesCard from '../../../components/home/keyFeatures/KeyFeaturesCard';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function CorporateBranding() {
    // initializations
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet ||
                <div>
                    <HeroTitleImage
                        bg={corporateBranding}
                        title="Corporate Branding"
                        simpleTextBg={true}
                        inWrapper={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'
                        style={{
                            backgroundImage: `linear-gradient(${screenWidth < 768 ? '0deg' : '-90deg'}, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${cb1})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className='tw-text-white'>
                            <HeaderText
                                title="WHY ALTA TECH MARK FOR CORPORATE BRANDING?"
                                description="Alta Tech Mark for design because we seamlessly blend innovation with aesthetics, crafting designs that resonate and engage."
                                className='tw-text-white tw-mb-[2vw] md:tw-text-left tw-text-center'
                                descriptionClassName='tw-text-left'
                            />
                            <h1>
                                Have you been endlessly searching for the ideal designer, developer, or comprehensive development team? Your search ends here! Our Dedicated Team offering presents a straightforward approach to connect you with professionals who are not just proficient in their domain but also profoundly dedicated to quality and seamlessly collaborating with your current teams or building anew.
                            </h1>
                        </div>
                        <div className='tw-flex md:tw-items-start md:tw-flex-row tw-flex-col tw-justify-between tw-w-full tw-mt-8 tw-gap-y-4'>
                            <div className='tw-grid md:tw-w-[50%] tw-justify-items-center'>
                                <img src={cb2} alt="pic" />
                            </div>
                            <div className='tw-col-span-2 tw-grid tw-grid-cols-3 tw-gap-[25px] tw-justify-items-center md:tw-w-[75%]'>
                                {corporateBrandingGridBlockData.map((data, index) =>
                                    <GridBlock
                                        key={index}
                                        text={data}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] md:tw-text-lg'>
                        <HeaderText
                            colorTitleEnd="ALTA TECH MARK?"
                            title="WHY BRANDING WITH"
                            className='tw-text-center tw-mb-8'
                        />

                        <ol className='tw-list-decimal tw-grid tw-gap-2'>
                            <li>
                                At Alta Tech Mark, we don't just design; we research. Our branding approach begins with a comprehensive study of the market, understanding current trends, competitor positioning, and the unique value proposition your business offers. This ensures that the brand we craft for you is not just aesthetically pleasing but also strategically positioned to stand out in the marketplace.
                            </li>
                            <li>
                                Our branding philosophy revolves around the end-user. We believe in creating brands that resonate deeply with your target audience. Through meticulous user research, persona development, and feedback loops, we ensure that the brand narrative we craft aligns perfectly with your audience's expectations and desires.
                            </li>
                            <li>
                                Brand consistency is paramount to building trust. At Alta Tech Mark, our branding efforts ensure a coherent and consistent brand presence across all platforms and touchpoints, be it digital, print, or environmental. Every element, from color palettes to typography, is chosen with an eye for consistency, ensuring that your brand is immediately recognizable wherever your audience encounters it.
                            </li>
                            <li>
                                We believe in the power of collaboration. Our team works closely with your stakeholders, ensuring that the brand direction aligns perfectly with your business objectives. Through regular check-ins, reviews, and iterative refinements, we ensure that the final branding not only meets but exceeds your expectations, ensuring a brand identity that's truly reflective of your business ethos.
                            </li>
                        </ol>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple'>
                        <HeaderText
                            colorTitleStart="CORPORATE BRANDING"
                            title="SERVICES"
                            className='tw-text-center tw-mb-8'
                            description="Services That We Deliver Makes Us Dignified As One of The Top Designing Companies."
                        />
                        <div className='tw-flex tw-items-center tw-flex-wrap tw-gap-[2vw] tw-justify-center max-md:tw-overflow-x-scroll tw-pb-4'>
                            {corporateBrandingServicesData.map(service =>
                                <KeyFeaturesCard
                                    key={service.id}
                                    title={service.title}
                                    description={service.description}
                                    src={service.src}
                                    height="400"
                                />
                            )}
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
