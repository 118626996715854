import re3 from '../../../assets/pages/service/aiAndMl/re3.png';
import re4 from '../../../assets/pages/service/aiAndMl/re4.png';

export const recommendationEngineData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "These days, recommendation engines are among the most flexible services available for web and mobile development. Companies now need a recommendation search engine to grow their operations and brand recognition. It displays recommendations for goods, services, and websites, all of which are based on data analysis. Data collection is based on user history, clicks, activity, and preferences, among other things. It presents just what the users could find interesting and gives an indication of what they desire. Because the search engine makes their job easier, recommendation engines also contribute to a rise in consumer loyalty. They get more interested in your specific product, company, service, and others the more alternatives they have.",
            "The businesses may provide specialized and individualised information and service solutions in this effective manner. It is, in fact, relevant to the users and contributes to the business's increased income. Recommendation engines improve user experience, increase revenue, and many other important aspects of business operations. The advice and conclusion show that Click-Through Rates have a beneficial impact on consumer happiness and recall. The Recommendation Engine's outstanding features are causing it to grow throughout several sectors and businesses. Using the data, the engine learns about the user's choices, preferences, habits, and much more. Additionally, the data facilitates the generation of analytics and precise decision-making.",
            "The recommendations engine uses data analytics and machine learning to make use of the information. Users may observe, choose, and control the power that best suits them. On the other hand, it is beneficial for consumers to find and get job easily. The deep knowledge of the Recommendation Engine finally included future data into the prediction analysis."
        ],
        onlyBullets: false,
        data: [],
        sectionPic: re3
    },
    {
        id: 2,
        title: "Recommendation engine types:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Content-based Filling",
                description: "These algorithms provide recommendations based on data gathered from the public, with consumer affinity serving as a measure of similarity. Various types of attribute data have been handled by various models that have been created. There is no need for user ratings since the process requires the utilization of market research data. Since there can be no website, service, solution, product, or anything else without content, the content-based filling is necessary. An essential component of the recommendation search engine is a content-based filling."
            },
            {
                id: 2,
                title: "Demographic-based Filling",
                description: "The users provide recommendations based on a range of demographic categories after being categorized according to their attributes. It generates easily applied, simple-to-use demographic recommendation algorithms. User ratings are not required since the strategy requires the complete execution of market research data. It reaches more relevant consumers and aids in focusing on a certain audience. The aim is accomplished more quickly and precisely thanks to the demographic-based filling."
            },
            {
                id: 3,
                title: "Collaborative Filtering",
                description: "By comparing a user's behavior, activities, and preferences with those of other users, collaborative filtering aims to predict what a person would enjoy. Collaboration filtering makes use of a formula in the form of a matrix. One benefit of collaborative filtering is that it chooses which things to propose based on the user's profile, eliminating the need for products, movies, or other information to be examined or comprehended. Every firm is impacted by and becomes lucrative via analysis."
            },
            {
                id: 4,
                title: "Hybrid Engine",
                description: "When generating suggestions, a hybrid recommendation engine takes into account both meta and content-based data. It thus performs better in terms of search than both. In a hybrid recommendation engine, product similarity is determined using vector equations, and natural language processing tags may be created for each product or item. Using a collaborative filtering matrix, users may get recommendations for items based on their preferences, actions, and activities. One great example is a hybrid recommendation engine, like Netflix. It takes into account the interests of the collaborative user as well as the details or features of the content-based film or television program."
            }
        ]
    },
    {
        id: 3,
        title: "What Makes a Recommendation Engine Necessary?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Improve Businesses",
                description: "Businesses may grow and be improved by boosting search. The business flow's structure is enhanced by the search engine."
            },
            {
                id: 2,
                title: "Boost Income",
                description: "The tools aid in earning income more quickly, and the suggestion search engine contributes to this increase."
            },
            {
                id: 3,
                title: "Personalized Experience",
                description: "It gives people a customized experience, making everything relevant to them while they're working on anything."
            },
            {
                id: 4,
                title: "Enhance User Involvement",
                description: "The suggestion search engine contributes to and amplifies user involvement."
            },
            {
                id: 5,
                title: "Comprehensive Analytics Reports",
                description: "The study presents a true picture of the business and offers insightful data in reports with great depth.",
                pic: re4
            }
        ]
    },
    {
        id: 4,
        title: "How Might It Operate?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Collect Information",
                description: "Gathering pertinent information is the first step in operating as a recommendation engine. Information, past events, preferences, and more may all be included. Explicit and Implicit Data are the two methods."
            },
            {
                id: 2,
                title: "Data Storage",
                description: "In order for the recommendation engine to collect data, data storage must be maintained. Because all the data is saved, everything will function in the same way if anything happens in the future."
            },
            {
                id: 3,
                title: "Analyzing Data",
                description: "It's critical to determine whether the information is suitable and relevant to the company. Additionally, data analysis is used to build a recommendation engine."
            },
            {
                id: 4,
                title: "Data Filtering",
                description: "This is the last stage, when the data is categorized using the formula. The Recommendation Engine makes decisions based on demographic, collaborative, hybrid, and content-based data."
            }
        ]
    },
    {
        id: 5,
        title: "For a recommendation engine, why use Alta Tech Mark?",
        descriptions: [
            "An AI-powered recommendation engine increases sales and fosters business growth. Alta Tech Mark guarantees to provide precise search engine suggestion services to improve clients' enterprises and satisfy all of your needs. Our talented team created a superior AI-powered recommendation engine that exceeds the expectations of each and every customer.",
            "For a reasonable charge, Alta Tech Mark offers the greatest recommendation engine, enabling a client to satisfy customers. Every duty is completed by our organization, and the procedure is made easy to follow. For improved user experiences, we provide an engine that is glitch-free and error-free. Our skilled Recommendation Engine staff provides end-to-end support and exceptional software development methodologies."
        ],
        onlyBullets: false,
        data: []
    }
]