import React from 'react';

// assets
import img1 from '../../../../assets/pages/service/mobileAppDev/flutterAppDev/1.png';
import { flutterDevData } from '../../../../data/services/mobileAppDevData/flutterDevData';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// components
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function FlutterAppDev() {
    return (
        <ServicesWrapper
            title="Flutter App Development Company"
            description="An Expressive, Flexible and Revolutionary Framework to Develop The Next-Gen Apps"
            img={img1}
        >

            <DevGridWrapper>
                {/* <h1>
                    Google flutter app development SDK, is the newly launched framework designed to support native development for both iOS & Android mobile apps by using a single code structure. Flutter is robust and reactive with widgets and tools that enable the development and deployment of UI with animations and a single code which works for different platforms.
                </h1>

                <h1>
                    The most lucrative thing about Flutter framework is it provides a bridge to native, so developers can do almost everything that Kotlin/Swift/Java does. The core concepts for Flutter are connecting and drawing. Flutter can be rightly observed in applications that make use of the camera, Geolocation, network, storage, and 3rd party SDKs. All of this attributes to the latest standards in cross-platform developments which can be accomplished using the flutter app development framework.
                </h1>

                <h1>
                    Alta Tech Mark is one of the top flutter application development companies. Despite the latest existence of flutter compared to other frameworks, we have still managed to get accustomed to its styles and methods of functioning by making us one of the best flutter application development companies. Our diverse and skilled team of developers can help sketch you the smartest and quickest solution for your cross-platform development projects and bring to life the expectations you aspire for your projects.
                </h1>

                <h1>
                    We have been known far and wide for our endeavors taken under flutter frameworks. We passionately build beautiful and functionally-rich mobile apps, crafted to meet your specific demands. They help businesses, around the globe, develop next-gen apps that are expressive, flexible, and gives a delightful experience to their users - all in record time.
                </h1> */}

                {/* {flutterDevData.map(data => (
                    <div key={data.id} className='tw-grid tw-gap-2'>
                        <h1 className='tw-font-semibold tw-text-2xl'>{data.title}</h1>
                        {data.description && <h1>{data.description}</h1>}
                        {!data.bullets ? data.data.map(item => (
                            <div key={item.id} className='tw-grid tw-gap-2'>
                                <h1 className='tw-font-semibold tw-text-lg'>{item.title}</h1>
                                <h1>{item.description}</h1>
                            </div>
                        )) :
                            <div className='tw-grid tw-grid-cols-1 tw-gap-x-[25px] tw-gap-y-[10px]'>
                                {data.bulletPoints.map((item, index) => (
                                    <div key={index} className='tw-col-span-1 tw-flex tw-items-start tw-gap-x-2'>
                                        <FiberManualRecordIcon sx={{
                                            scale: '0.5',
                                        }} />
                                        <h1>
                                            {item}
                                        </h1>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                ))} */}
                <DataMapper data={flutterDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
