import React from 'react';

// assets
import formBg from '../../../assets/pages/services/formBg.jpg';

// components
import FreeQuoteForm from '../freeQuoteForm/FreeQuoteForm';
import HeaderText from '../../headerText/HeaderText';

export default function FreeQuoteFormBg() {
    return (
        <>
            <div className='tw-px-[9.115vw] tw-gap-[50px] tw-relative tw-py-[60px]'
                style={{
                    backgroundImage: `url(${formBg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <HeaderText title="Get Free Quote Now !" description="Got An Idea? Leave A Message To Us And We Will Reach Out To You Soon." className='tw-absolute tw-top-[20%] md:tw-top-[5%] md:tw-right-[10%]' />
                <FreeQuoteForm className='tw-relative tw-top-[200px]' />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='md:tw-hidden tw-block'>
                <br />
                <br />
                <br />
                <br />
            </div>
        </>
    )
}
