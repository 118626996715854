import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

// data
import { cardsData } from '../../data/cardsData';

// components
import Template from '../../components/home/template/Template';
import Brands from '../../components/home/brands/Brands';
import KeyFeatures from '../../components/home/keyFeatures/KeyFeatures';
import Services from '../../components/home/services/Services';
import Processes from '../../components/home/processes/Processes';
import PartnershipModels from '../../components/home/partnershipModels/PartnershipModels';
import Industries from '../../components/home/industries/Industries';
import CaseStudies from '../../components/home/caseStudies/CaseStudies';
import WorkWith from '../../components/home/workWith/WorkWith';
// import Podcasts from '../../components/home/podcasts/Podcasts';
// import MediaCoverage from '../../components/home/mediaCoverage/MediaCoverage';
// import EsteemedClients from '../../components/home/esteemedClients/EsteemedClients';
import Reviews from '../../components/home/reviews/Reviews';
import LatestBlogs from '../../components/home/latestBlogs/LatestBlogs';
// import Work from '../../components/home/work/Work';
// import Research from '../../components/home/research/Research';
// import SocialMedia from '../../components/home/socialMedia/SocialMedia';
import Stats from '../../components/home/stats/Stats';
// import Ranking from '../../components/home/ranking/Ranking';
// import FreeQuoteForm from '../../components/services/freeQuoteForm/FreeQuoteForm';
// import HeaderText from '../../components/headerText/HeaderText';
import ContactUs from '../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../components/contactUs/ContactUsNoForm';
import TechWeWorkOn from '../../components/services/techWeWorkOn/TechWeWorkOn';

// assets
import heroBg from '../../assets/background/heroBg.png';

export default function Home() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            <Swiper
                modules={[Autoplay]}
                spaceBetween={150}
                slidesPerView={1}
                loop={true}
                centeredSlides={true}
                autoplay={{
                    delay: 3000,
                }}
                style={{
                    backgroundImage: `url(${heroBg})`,
                    backgroundSize: 'cover',
                }}
            >
                {cardsData.map(card => {
                    return (
                        <SwiperSlide key={card.id}>
                            <Template
                                titleInColor={card.titleInColor}
                                titleCont={card.titleCont}
                                descriptionBold={card.descriptionBold}
                                descriptionCont={card.descriptionCont}
                                img={card.img}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className='tw-bg-white tw-w-full tw-h-full tw-tracking-wide'>
                <Brands />

                {/* stats */}
                <Stats />
                {/* stats */}

                {/* rank */}
                {/* <Ranking /> */}
                {/* rank */}

                {/* key features */}
                <KeyFeatures />
                {/* key features */}

                {/* services */}
                <Services />
                {/* services */}

                {/* processes */}
                <Processes />
                {/* processes */}

                {/* partnership models */}
                <PartnershipModels />
                {/* partnership models */}

                {/* industries */}
                <Industries />
                {/* industries */}

                {/* case studies */}
                <CaseStudies title='STUDIES' description='We shed a light on our work and what goes behind the development.' />
                {/* case studies */}

                {/* work with */}
                <WorkWith />
                {/* work with */}

                {/* podcasts */}
                {/* <Podcasts /> */}
                {/* podcasts */}

                {/* media coverage & esteemed clients */}
                {/* <div className="tw-grid tw-grid-cols-2 tw-py-[60px] tw-px-[140px] tw-gap-[25px]">
                    <MediaCoverage />
                    <EsteemedClients />
                </div> */}
                {/* media coverage & esteemed clients */}

                {/* reviews */}
                <Reviews />
                {/* reviews */}

                {/* latest blogs */}
                {/* <LatestBlogs show={3} /> */}
                {/* latest blogs */}

                {/* tech we work on */}
                <TechWeWorkOn />
                {/* tech we work on */}

                {/* work */}
                {/* <Work /> */}
                {/* work */}

                {/* research */}
                {/* <Research /> */}
                {/* research */}

                {/* contact us */}
                {screenWidth < 768 ?
                    <ContactUsNoForm />
                    : <ContactUs />
                }
                {/* contact us */}

                {/* social media */}
                {/* <SocialMedia /> */}
                {/* social media */}
            </div>
        </>
    )
}
