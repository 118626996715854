import hl3 from '../../../assets/pages/service/blockchainDev/hl3.png';
import hl4 from '../../../assets/pages/service/blockchainDev/hl4.png';

export const hyperledgerDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "In the corporate sector, hyperledger blockchain technology is becoming more and more in demand, and most companies are now searching for hyperledger blockchain development. The Linux Foundation created this technology in December 2015. With a distributed ledger foundation and an open-source design, its goal is to propel the blockchain sector forward. It serves as a go-between for decentralized online communities. Hyperledger blockchain technology makes use of an app called Hyperledger smart contracts, which is very advantageous for businesses all around the globe.",
            "Many businesses have moved into Hyperledger Blockchain development in response to the demand; Alta Tech Mark is regarded as one of the best Hyperledger Fabric blockchain development firms. Leading Blockchain platform Hyperledger may be used to create decentralized corporate applications, and Alta Tech Mark's skilled developers can help."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Among The Business Blockchain Frameworks Hyperledger Currently Hosts Are:",
        description: "",
        onlyBullets: true,
        data: [
            "Ethereum Burrow",
            "The Hyperledger Module",
            "Sawtooth Hyperledger",
            "Indy Hyperledger",
            "Iroha Hyperledger",
            "Development of Device Drivers"
        ],
        bulletsPic: hl3
    },
    {
        id: 3,
        title: "Understand the Hyperledger features:",
        description: "You may be certain that Alta Tech Mark can provide the following functionalities with Hyperledger:",
        onlyBullets: true,
        data: [
            "Numerous Network Platforms",
            "Enhanced Accuracy",
            "Accessible Security",
            "Dependable Business Plan",
            "Transparency and Scalability",
            "Safekeeping of Sensitive Data and Digital Keys"
        ]
    },
    {
        id: 4,
        title: "Several Sectors Utilizing Hyperledger:",
        description: "",
        onlyBullets: true,
        data: [
            "Manufacturing Finance",
            "Banking",
            "Shopping",
            "Medical",
            "Auto",
            "Transportation"
        ],
        bulletsPic: hl4
    },
    {
        id: 5,
        title: "Why Select Alta Tech Mark for the Development of Hyperledger Blockchains?",
        descriptions: [
            "You should think about the Alta Tech Mark team for professional Blockchain development when choosing a Hyperledger app for your company growth. Your quest is over if you want to use this technology for the growth of your company. There are several reasons to work with Alta Tech Mark. At the vanguard of blockchain technology is Alta Tech Mark, which is made up of a diverse group of professional, competent, and responsive workers.",
            "Our team will assist you in defining a model by creating a Business network definition that includes all of your model's logic, rules, and functions; launching the network on the Hyperledger platform; and creating and implementing smart contracts to get your business off the ground on a Blockchain network."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 6,
        title: "Principal Benefits of Selecting Alta Tech Mark:",
        description: "",
        onlyBullets: true,
        data: [
            "Award-winning Company with Committed Staff",
            "Constant Support 24-Hour Delivery",
            "Quick Responders Security Experts"
        ]
    }
]