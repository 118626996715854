import React from 'react'

export default function WorkCard(props) {
    return (
        <div className={`tw-col-span-1 tw-flex tw-flex-col tw-w-[120px] tw-py-[30px] tw-pl-[30px] 
        ${(props.id % 2 === 0) ? `${props.id <= 4 ? "tw-bg-[#F4F4FA]" : "tw-bg-[#FDF3F6]"}` : `${props.id <= 4 ? "tw-bg-[#FDF3F6]" : "tw-bg-[#F4F4FA]"}`} 
        `}>
            <h1 className='tw-font-semibold md:tw-text-[30px] tw-text-[20px]'>{props.stat}</h1>
            <h1 className='md:tw-text-[22px] tw-text-[12px] tw-w-max'>{props.title}</h1>
        </div>
    )
}
