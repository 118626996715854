import React, { useState } from 'react'
import { Link } from 'react-router-dom';

// icons
import companyHoverBg2 from '../../../assets/background/companyHoverBg-02.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// data
import { servicesData } from './servicesData'

export default function ServicesHover(props) {
    // locals
    const [services, setServices] = useState(servicesData)

    // functions
    const selectedHandler = (event) => {
        setServices(prevState => {
            return prevState.map(data => {
                if (data.id === +event.target.id) {
                    return {
                        ...data,
                        selected: true
                    }
                } else {
                    return {
                        ...data,
                        selected: false
                    }
                }
            })
        })

    }
    return (
        <div className='tw-grid tw-grid-cols-3 tw-absolute tw-bg-purple tw-text-purple tw-border-slate-300 tw-border-[1px] tw-h-[60vh] tw-z-10' onMouseLeave={props.hoverLeave}>

            <div className='tw-flex tw-flex-col tw-items-starts tw-col-span-1'
                style={{
                    backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
                }}
            >
                {services.map((data, index) => {
                    return (
                        <div key={data.id} id={data.id} className={`tw-flex tw-items-center tw-justify-around tw-w-full tw-border-r-[1px] tw-border-purple ${data.selected ? 'tw-text-black tw-font-semibold tw-border-r-transparent' : 'tw-border-y-transparent'} tw-px-4 tw-py-2 tw-border-y-[1px]`} onMouseEnter={selectedHandler}>
                            <p className={`tw-cursor-pointer ${data.selected && "tw-text-[#4741A6]"} tw-w-full`}>{data.value}</p>
                        </div>
                    )
                })}
            </div>


            <div className='tw-flex tw-flex-col tw-items-start tw-gap-y-2 tw-p-4 tw-col-span-1'
                style={{
                    backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
                }}
            >
                {services.filter(data => data.selected)[0]?.children.map(data =>
                    <Link to={data.url} key={data.id} className='tw-cursor-pointer hover:tw-text-[#4741A6] tw-mx-4'>{data.value}</Link>
                )}
            </div>

            <div className='tw-border-l-[1px] tw-border-slate-300 tw-p-4 tw-col-span-1'
                style={{
                    backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
                }}
            >
                <div className='tw-text-2xl tw-font-semibold tw-text-white tw-rounded-lg tw-h-full tw-p-4 tw-flex tw-flex-col tw-justify-end tw-w-[20vw]'
                    style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${services.filter(data => data.selected)[0]?.cover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <h1 className=''>
                        {services.filter(data => data.selected)[0]?.coverTitle}
                    </h1>
                </div>
            </div>
        </div>
    )
}
