import React from 'react';

// assets
import cg1 from '../../../../assets/pages/service/gameDev/cg1.jpeg';
import cg2 from '../../../../assets/pages/service/gameDev/cg2.png';
import cg3 from '../../../../assets/pages/service/gameDev/cg3.png';
import { casualGamesDevData } from '../../../../data/services/gameDevData/casualGamesDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function CasualGamesDev() {
    return (
        <ServicesWrapper
            title="CASUAL GAMES"
            descriptionCont="Employing game developers from a reputable firm that specializes in game creation may help you and every other player take your gameplay to the next level."
            img={cg1}
        >
            <DevGridWrapper images={[cg2, cg3]}>
                <DataMapper data={casualGamesDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
