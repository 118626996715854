import React from 'react';

// assets
import re1 from '../../../../assets/pages/service/aiAndMl/re1.jpeg';
import re2 from '../../../../assets/pages/service/aiAndMl/re2.png';
import { recommendationEngineData } from '../../../../data/services/aiAndMl/recommendationEngineData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function RecommendationEngine() {
    return (
        <ServicesWrapper
            title="Recommendation Engine"
            descriptionCont="The businesses may provide specialized and individualised information and service solutions in this effective manner. It is, in fact, relevant to the users and contributes to the business's increased income."
            img={re1}
        >
            <DevGridWrapper img={re2}>
                <DataMapper data={recommendationEngineData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
