import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// components
import HeaderText from '../../headerText/HeaderText';
import CoreValuesCard from './CoreValuesCard';

// assets
import { coreValuesData } from '../../../data/aboutUs/coreValuesData';

export default function CoreValues(props) {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-px-[9.115vw] tw-py-[8.138vw]'
            style={{
                backgroundImage: `${screenWidth < 768 && `url(${props.bg})`}`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <HeaderText colorTitleEnd="VALUES" title='OUR CORE' className={`tw-text-center tw-mb-8 ${screenWidth < 768 && "tw-text-white"}`} />

            {screenWidth < 768 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                        width: `${screenWidth / 1.2}px`
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {coreValuesData.map(coreValue =>
                        <SwiperSlide key={coreValue.id}>
                            <div className='tw-h-[400px]'>
                                <CoreValuesCard
                                    key={coreValue.id}
                                    title={coreValue.title}
                                    description={coreValue.description}
                                    src={coreValue.src}
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper> :
                <div className='tw-grid tw-grid-cols-3 tw-gap-[1.628vw]'>
                    {coreValuesData.map(coreValue =>
                        <CoreValuesCard
                            key={coreValue.id}
                            title={coreValue.title}
                            description={coreValue.description}
                            src={coreValue.src}
                        />
                    )}
                </div>
            }
        </div>
    )
}
