import React from 'react';

export default function StatsCard(props) {
    return (
        <div className={`md:tw-py-[45px] tw-py-[35px] tw-gap-y-2 md:tw-px-[25px] tw-px-[15px] tw-flex tw-flex-col tw-items-center tw-shadow-lg tw-rounded-lg tw-w-full max-md:tw-min-w-[80vw] tw-h-full ${props.classNam}`}
            style={{
                background: "linear-gradient(166deg, #FFF 0%, rgba(255, 255, 255, 0.30) 100%)"
            }}
        >
            <img src={props.icon} alt={props.description} />
            <h1 className='md:tw-text-[46px] tw-text-[30px] tw-text-black'>{props.stats}</h1>
            <h1 className='tw-text-[16px] tw-text-center tw-text-black'>{props.description}</h1>
        </div>
    )
}
