import React from 'react';

// assets
import cw1 from '../../../../assets/pages/service/blockchainDev/cw1.jpeg';
import cw2 from '../../../../assets/pages/service/blockchainDev/cw2.png';
import { walletDevData } from '../../../../data/services/blockchainDevData/walletDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function WalletDev() {
    return (
        <ServicesWrapper
            title="BLOCKCHAIN DEVELOPMENT WALLET"
            descriptionCont="The financial industry is booming with cryptocurrency, which is vital to its future. Wallet development is cryptocurrency's core. User may audit, acquire, and conduct digital transactions in multiple currencies using this program."
            img={cw1}
        >
            <DevGridWrapper img={cw2}>
                <DataMapper data={walletDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
