import React from 'react';

// assets
import bg from '../../../assets/brands/lg/bg.svg';
import logo from '../../../assets/brands/lg/logo.svg';
import right from '../../../assets/brands/lg/right.svg';
import overviewFullSpan from '../../../assets/brands/lg/overviewFullSpan.svg';
import conversionRate from '../../../assets/brands/lg/conversionRate.svg';
import explainer from '../../../assets/brands/lg/explainer.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function Lg() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="LG"
                simpleTitle="Electronics"
                description="LG aimed to communicate their message to their customers through an explainer video, and Alta Tech Mark's innovative ideas assisted them in raising awareness."
                rightClasses="md:tw-w-[35vw] md:tw-h-[25vw]"
            />

            <ProjectOverview
                overview="Our client sought to develop a platform that would offer its customers engaging explainer videos and dynamic motion graphics campaigns. To fulfill this goal, we utilized our proficiency at Alta Tech Mark and crafted the LG – Explainer Video, effectively conveying the intended message to the audience in an imaginative manner."
                overviewFullSpan={overviewFullSpan}
            />

            {/* conversion rates */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-2 tw-relative tw-bg-red-600 tw-text-white'>
                <h1 className='md:tw-text-[2.635vw] tw-font-bold tw-text-center'>Increased Conversion rates</h1>
                <h1 className='tw-text-[1.171vw]'>LG Explainer Video was strategically developed to boost conversion rates by enhancing brand engagement and awareness, thereby swaying the audience to prefer LG over its competitors.</h1>
                <img className='tw-mx-auto' src={conversionRate} alt="conversion" />
            </div>
            {/* conversion rates */}

            {/* explainer */}
            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-grid-cols-2 tw-gap-x-4 tw-bg-white tw-items-center'>
                <div className='tw-col-span-1 tw-w-[41.168vw] tw-h-[29.266vw]'>
                    <img src={explainer} alt="explainer" className='tw-w-[41.168vw] tw-h-[29.266vw]' />
                </div>
                <div className='tw-flex tw-flex-col tw-gap-y-2 tw-items-start'>
                    <h1 className='md:tw-text-[1.7vw] tw-font-semibold tw-text-red-600'>Result-Oriented Explainer Video</h1>
                    <h1 className='tw-text-[1.171vw]'>We produced a Result-Oriented explainer video aimed at boosting customer service interactions and capturing the audience's interest by presenting information in both an informative and engaging manner.</h1>
                </div>
            </div>
            {/* explainer */}

            <ContactUsNoForm />
        </div>
    )
}
