import au4 from '../../assets/pages/aboutUs/au4.svg';
import au5 from '../../assets/pages/aboutUs/au5.svg';
import au6 from '../../assets/pages/aboutUs/au6.svg';
import au7 from '../../assets/pages/aboutUs/au7.svg';
import au8 from '../../assets/pages/aboutUs/au8.svg';

export const ourJourneyData = [
    {
        id: 1,
        src: au4,
        title: "Launch",
        description: "Alta Tech Mark was launched on the basis of helping businesses to stay up-to-date with evolving technology by providing desktop applications."
    },
    {
        id: 2,
        src: au5,
        title: "Technological Shift",
        description: "Alta tech mark witnessed and sharply observed the changing technology and helped its clients to adopt it with creative ideas for mobile applications and websites."
    },
    {
        id: 3,
        src: au6,
        title: "Technological Shift",
        description: "After witnessing massive success of Alta Tech Mark's clients it decided to expand technological offerings to the customers"
    },
    {
        id: 4,
        src: au7,
        title: "Marketing",
        description: "After acquiring almost every field related to technology, Alta tech mark decided to help businesses in marketing and developing their brand identity through digital media."
    },
    {
        id: 5,
        src: au8,
        title: "Boost Success",
        description: "Today, Alta tech mark has gained pace by adopting the evolving technology and helping the businesses to do so."
    }
]