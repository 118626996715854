export const workData = [
    {
        id: 1,
        stat: "4500+",
        title: "Apps Developed",
    },
    {
        id: 2,
        stat: "2200+",
        title: "Website Designed",
    },
    {
        id: 3,
        stat: "2700+",
        title: "Happy Clients",
    },
    {
        id: 4,
        stat: "1000+",
        title: "Developers",
    },
    {
        id: 5,
        stat: "120+",
        title: "AI & IoT Solutions",
    },
    {
        id: 6,
        stat: "140+",
        title: "Games Developed",
    },
    {
        id: 7,
        stat: "120+",
        title: "Salesforce Solutions",
    },
    {
        id: 8,
        stat: "40+",
        title: "Data Science",
    },
]