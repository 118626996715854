import appDevelopment from '../assets/services/appDevelopment.png';
import webDevelopment from '../assets/services/webDevelopment.png';
import ecommerceDevelopment from '../assets/services/ecommerceDevelopment.png';
import blockchainDevelopment from '../assets/services/blockchainDevelopment.png';
import gameDevelopment from '../assets/services/gameDevelopment.png';
import salesforceSolutions from '../assets/services/salesforceSolutions.png';
import aiAndMl from '../assets/services/aiAndMl.png';
import iotAndEmbedded from '../assets/services/iotAndEmbedded.png';
import corporateBranding from '../assets/services/corporateBranding.png';
import devops from '../assets/services/devops.png';
import android from '../assets/services/android.png';
import ios from '../assets/services/ios.png';
import flutter from '../assets/services/flutter.png';
import reactNative from '../assets/services/reactNative.png';
import kotlin from '../assets/services/kotlin.png';
import ionic from '../assets/services/ionic.png';
import php from '../assets/services/php.png';
import wordpress from '../assets/services/wordpress.png';
import drupal from '../assets/services/drupal.png';
import cakephp from '../assets/services/cakephp.png';
import laravel from '../assets/services/laravel.png';
import magento from '../assets/services/magento.png';
import bigcommerce from '../assets/services/bigcommerce.png';
import ubercart from '../assets/services/ubercart.png';
import prestashop from '../assets/services/prestashop.png';
import virtuemart from '../assets/services/virtuemart.png';
import ethereum from '../assets/services/ethereum.png';
import hyperledger from '../assets/services/hyperledger.png';
import smartContracts from '../assets/services/smartContracts.png';
import privateBlockchains from '../assets/services/privateBlockchains.png';
import nftMarketplace from '../assets/services/nftMarketplace.png';
import unity3d from '../assets/services/unity3d.png';
import augmentedReality from '../assets/services/augmentedReality.png';
import virtualReality from '../assets/services/virtualReality.png';
import casualGames from '../assets/services/casualGames.png';
import metaverse from '../assets/services/metaverse.png';
import salesforce from '../assets/services/salesforce.png';
import textToSpeech from '../assets/services/textToSpeech.png';
import businessIntelligence from '../assets/services/businessIntelligence.png';
import dataForecasting from '../assets/services/dataForecasting.png';
import naturalLanguageProcessing from '../assets/services/naturalLanguageProcessing.png';
import dataAnalytics from '../assets/services/dataAnalytics.png';
import alexaSkillsDevelopment from '../assets/services/alexaSkillsDevelopment.png';
import iotApps from '../assets/services/iotApps.png';
import embeddedSoftware from '../assets/services/embeddedSoftware.png';
import iotHardwarePrototyping from '../assets/services/iotHardwarePrototyping.png';
import iotDashboardAndAnalytics from '../assets/services/iotDashboardAndAnalytics.png';
import uiUx from '../assets/services/uiUx.png';
import brandIdentity from '../assets/services/brandIdentity.png';
import logoDesign from '../assets/services/logoDesign.png';
import print from '../assets/services/print.png';
import packagingDesign from '../assets/services/packagingDesign.svg';

export const servicesData = [
    {
        id: 1,
        src: appDevelopment,
        colorTitleStart: "Mobile App",
        title: 'Development',
        url: '/services/mobileAppDev',
        description: "Get Top-notch Mobile App Solution By Us.",
        children: [
            {
                id: 1,
                src: android,
                title: 'Android App',
                url: '/services/mobileAppDev/androidAppDev',
            },
            {
                id: 2,
                src: ios,
                title: 'iOS App',
                url: '/services/mobileAppDev/iphoneAppDev',
            },
            {
                id: 3,
                src: flutter,
                title: 'Flutter',
                url: '/services/mobileAppDev/flutterAppDev',
            },
            {
                id: 4,
                src: reactNative,
                title: 'React Native',
                url: '/services/mobileAppDev/reactNativeAppDev',
            },
            {
                id: 5,
                src: kotlin,
                title: 'Kotlin',
                url: '/services/mobileAppDev/kotlinAppDev',
            },
            {
                id: 6,
                src: ionic,
                title: 'Ionic',
                url: '/services/mobileAppDev/ionicAppDev',
            },
        ]
    },
    {
        id: 2,
        src: webDevelopment,
        colorTitleStart: "Web",
        title: 'Development',
        url: '/services/webAppDev',
        description: "Our highly skilled team have proficient expertise.",
        children: [
            {
                id: 1,
                src: php,
                title: 'PHP',
                url: '/services/webAppDev/phpAppDev',
            },
            {
                id: 2,
                src: wordpress,
                title: 'WordPress',
                url: '/services/webAppDev/wordpressAppDev',
            },
            {
                id: 3,
                src: drupal,
                title: 'Drupal',
                url: '/services/webAppDev/drupalAppDev',
            },
            {
                id: 4,
                src: cakephp,
                title: 'CakePHP',
                url: '/services/webAppDev/cakephpAppDev',
            },
            {
                id: 5,
                src: laravel,
                title: 'Laravel',
                url: '/services/webAppDev/laravelAppDev',
            },
        ]
    },
    {
        id: 3,
        src: ecommerceDevelopment,
        colorTitleStart: "Ecommerce",
        title: 'Development',
        url: '/services/ecommerceDev',
        description: "We have experts with deep insight knowledge.",
        children: [
            {
                id: 1,
                src: magento,
                title: 'Magento',
                url: '/services/ecommerceDev/magentoDev',
            },
            {
                id: 2,
                src: bigcommerce,
                title: 'BigCommerce',
                url: '/services/ecommerceDev/bigcommerceDev',
            },
            {
                id: 3,
                src: ubercart,
                title: 'Ubercart',
                url: '/services/ecommerceDev/uberCartDev',
            },
            {
                id: 4,
                src: prestashop,
                title: 'PrestaShop',
                url: '/services/ecommerceDev/prestashopDev',
            },
            {
                id: 5,
                src: virtuemart,
                title: 'VirtueMart',
                url: '/services/ecommerceDev/virtueMartDev',
            },
        ]
    },
    {
        id: 4,
        src: blockchainDevelopment,
        colorTitleStart: 'Blockchain',
        title: 'Development',
        url: '/services/blockchainDev',
        description: "Hire The Experts of Blockchain Technology!",
        children: [
            {
                id: 1,
                src: ethereum,
                title: 'Ethereum',
                url: '/services/blockchainDev/ethereumDev',
            },
            {
                id: 2,
                src: hyperledger,
                title: 'Hyperledger',
                url: '/services/blockchainDev/hyperledgerDev',
            },
            {
                id: 3,
                src: smartContracts,
                title: 'Smart Contracts',
                url: '/services/blockchainDev/smartContractsDev',
            },
            {
                id: 4,
                src: privateBlockchains,
                title: 'Private Blockchains',
                url: '/services/blockchainDev/privateBlockchainDev',
            },
            {
                id: 5,
                src: nftMarketplace,
                title: 'NFT Marketplace',
                url: '/services/blockchainDev/nftMarketplaceDev',
            },
        ]
    },
    {
        id: 5,
        src: gameDevelopment,
        colorTitleStart: "Game",
        title: 'Development',
        url: '/services/gameDev',
        description: "Hire The Expert Development Team.",
        children: [
            {
                id: 1,
                src: unity3d,
                title: 'Unity 3D',
                url: '/services/gameDev/unity3dDev',
            },
            {
                id: 2,
                src: augmentedReality,
                title: 'Augmented Reality',
                url: '/services/gameDev/augmentedRealityDev',
            },
            {
                id: 3,
                src: virtualReality,
                title: 'Virtual Reality',
                url: '/services/gameDev/virtualRealityDev',
            },
            {
                id: 4,
                src: casualGames,
                title: 'Casual Games',
                url: '/services/gameDev/casualGamesDev',
            },
            {
                id: 5,
                src: metaverse,
                title: 'Metaverse',
                url: '/services/gameDev/metaverseDev',
            }
        ]
    },
    {
        id: 6,
        src: salesforceSolutions,
        colorTitleStart: "Salesforce",
        title: 'Solutions',
        url: '/services/salesforceSolutions',
        description: "We Deliver what we commit",
        children: [
            {
                id: 1,
                src: salesforce,
                title: 'Salesforce Development',
                url: '/services/salesforceSolutions/salesforceDev',
            },
            {
                id: 2,
                src: salesforce,
                title: 'Salesforce Consulting',
                url: '/services/salesforceSolutions/salesforceConsulting',
            },
            {
                id: 3,
                src: salesforce,
                title: 'Salesforce Implementation',
                url: '/services/salesforceSolutions/salesforceImplementation',
            }
        ]
    },
    {
        id: 7,
        src: aiAndMl,
        colorTitleStart: "AI",
        title: '& ML',
        url: '/services/aiAndMl',
        description: "Prominent AI and ML high-end solutions.",
        children: [
            {
                id: 1,
                src: textToSpeech,
                title: 'Text to Speech',
                url: '/services/aiAndMl/textToSpeech',
            },
            {
                id: 2,
                src: businessIntelligence,
                title: 'Business Intelligence',
                url: '/services/aiAndMl/businessIntelligence',
            },
            {
                id: 3,
                src: dataForecasting,
                title: 'Data Forecasting',
                url: '/services/aiAndMl/dataForecasting',
            },
            {
                id: 4,
                src: naturalLanguageProcessing,
                title: 'Natural Language Processing',
                url: '/services/aiAndMl/naturalLanguageProcessing',
            },
            {
                id: 5,
                src: dataAnalytics,
                title: 'Data Analytics',
                url: '/services/aiAndMl/dataAnalytics',
            },
            {
                id: 6,
                src: alexaSkillsDevelopment,
                title: 'Alexa Skills Development',
                url: '/services/aiAndMl/alexaSkillDev',
            },
        ]
    },
    {
        id: 8,
        src: iotAndEmbedded,
        colorTitleStart: 'Iot Product',
        title: 'Development',
        url: '/services/iotAndEmbedded',
        description: "Hire The Expert Development Team.",
        children: [
            {
                id: 1,
                src: iotApps,
                title: 'IoT Apps',
                url: '/services/iotAndEmbedded/iotApp',
            },
            {
                id: 2,
                src: embeddedSoftware,
                title: 'Embedded Software',
                url: '/services/iotAndEmbedded/embeddedSoftware',
            },
            {
                id: 3,
                src: iotHardwarePrototyping,
                title: 'IoT Hardware Prototyping',
                url: '/services/iotAndEmbedded/iotHardwarePrototyping',
            },
            {
                id: 4,
                src: iotDashboardAndAnalytics,
                title: 'IoT Dashboard & Analytics',
                url: '/services/iotAndEmbedded/iotDashboardAndAnalytics',
            },
        ]
    },
    {
        id: 9,
        src: corporateBranding,
        title: "Branding",
        colorTitleStart: 'Corporate',
        description: "Offering Cutting-Edge Corporate Branding Services",
        span: 2,
        url: "/services/corporateBranding",
        children: [
            {
                id: 1,
                src: uiUx,
                title: 'UI/UX Design',
                url: '/services/corporateBranding/uiUxDesign'
            },
            {
                id: 2,
                src: brandIdentity,
                title: 'UX Audit & Review',
                url: '/services/corporateBranding/uxAuditReview'
            },
            {
                id: 3,
                src: logoDesign,
                title: 'Visual Identity Design',
                url: '/services/corporateBranding/visualIdentityDesign'
            },
            {
                id: 4,
                src: print,
                title: 'Digital Branding',
                url: '/services/corporateBranding/digitalBranding'
            },
            {
                id: 5,
                src: packagingDesign,
                title: "Packaging Design",
                url: '/services/corporateBranding/packagingDesign'
            }
        ]
    },
    {
        id: 10,
        src: devops,
        colorTitleStart: 'DevOps',
        title: '& Maintenance',
        url: "/services/devops",
        description: "To track the development process for superior digital solutions, we adapt DevOps.",
        span: 2,
        children: [],
    },
]