import React from 'react';

// assets
import blogsBg from '../../../assets/background/blogsBg.png';

// components
import LatestBlogs from './../../../components/home/latestBlogs/LatestBlogs';
import HeaderText from '../../../components/headerText/HeaderText';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';

export default function Blog() {
    const categories = [
        'Technology',
        'Salesforce',
        'Web Design',
        'Blockchain',
        'Metaverse',
        'UI/UX Design',
        'Top Apps',
        'Social Media',
        'App Development'
    ]

    return (
        <div className=''>
            <HeroTitleImage
                title="Latest Blogs on"
                coloredTitle="Trends & Technologies"
                bg={blogsBg}
            />

            <LatestBlogs />

            <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple'>
                <HeaderText
                    colorTitleEnd='CATEGORIES'
                    title='SEARCH BY'
                    className='tw-text-center tw-mb-8'
                />

                <div className='tw-flex tw-items-center tw-overflow-x-scroll tw-gap-4 tw-pb-4'>
                    {categories.map((category, index) =>
                        <h1 key={index} className='tw-p-4 tw-bg-purple tw-rounded-full tw-text-white tw-text-center tw-cursor-pointer tw-whitespace-nowrap'>
                            {category}
                        </h1>
                    )}
                </div>
            </div>

            <LatestBlogs blogsWithoutTitle={true} />

            <ContactUsNoForm />
        </div>
    )
}
