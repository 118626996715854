export const ionicDevData = [
    {
        id: 0,
        title: "",
        descriptions: [
            "Ionic is the best hybrid mobile app development framework which is mainly used to code for powerful hybrid mobile apps in the simplest way. The ionic development enables the use of various frameworks, which helps to add the latest features and functionality to the native or a hybrid mobile app. A mobile app which is developed on this advanced HTML5 hybrid app framework can perform and behave extremely well on all the latest mobile devices.",
            "Ionic app development service drives native mobile elements with attractive designing and smoother animations. Ionic apps have a more native touch which makes UI Layouts and design elements more alluring. So, Ionic Platform proves to be a very responsive framework.",
            "We at Alta Tech Mark, develop higher quality ionic application development which is strong in user experience and user-friendly that can deliver high performance on both web and native platforms. With a team of highly talented Ionic developers and mobile app designers who leverage the potential of this framework to develop apps with unique user engagement and faster time to market. Our Ionic framework developers follow the latest app development processes and methodologies to develop high-performing Ionic app solutions for any kind of businesses. We develop the bug-free app that effectively leads our clients to the top position."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 1,
        title: "Know the Spectacular Benefits of Ionic App Framework",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Easy to Maintain",
                description: "Ionic framework is a comprehensive open-source framework that is used to develop hybrid apps. With plenty of options, Ionic framework the developers develop multi-featured apps with simplicity."
            },
            {
                id: 2,
                title: "Cross-Platform Mobile Development",
                description: "A mobile app that developed on the ionic framework supports on various mobile platforms which decreases the expenses of developing a single app for different platforms."
            },
            {
                id: 3,
                title: "Save Time & Money",
                description: "Developers code for a single time on the ionic framework which saves a massive amount of time and also an extra effort that is spent on codes for different platform."
            },
            {
                id: 4,
                title: "Beautiful UI Design & Multiple Features",
                description: "Ionic framework with its multiple features is comprehensively appreciated by the developers so ionic app development is given priority by the small business entities."
            },
            {
                id: 5,
                title: "Default User Interface",
                description: "The Ionic framework has default JS and CSS components which comprise a majority of the basic and essential things that have to be covered in a mobile application."
            }
        ]
    },
    {
        id: 2,
        title: "Other Key Advantages of Using Ionic Platform",
        description: "",
        onlyBullets: true,
        data: [
            "Fully performance obsessed and native focused",
            "Multi-device solution with great browser compatibility",
            "Reduces the cognitive overhead",
            "Develop once use everywhere",
            "Smoother and faster scrolling views",
            "Sass Enabled",
        ]
    },
    {
        id: 3,
        title: "Our Services – Ionic App Development",
        description: "Our ionic app development services include up-to-date mobile apps that are highly functional and combines powerful features that suit your business.",
        onlyBullets: true,
        data: [
            "Ionic Development Consultancy",
            "Ionic Upgrade to Latest Version",
            "Native to Ionic App Migration",
            "Ionic app with Sass Development",
            "Ionic Hybrid App Development",
            "Ionic AngularJS App Development",
            "Ionic Web Development",
            "Real-Time Hybrid App with Ionic & Firebase",
            "Ionic Game Development",
            "Ionic App Porting",
            "Ionic Framework Development"
        ]
    },
    {
        id: 4,
        title: "Why Choose Alta Tech Mark For Ionic App Development?",
        descriptions: [
            "At Alta Tech Mark, our developers have vast industry experience and have shown impeccable dedication and enthusiasm to contribute the fast-evolving tech world. We provide all customization in web and ionic app development solutions and services with very cost-effective solutions.",
            "We offer amazing Ionic app development service to allow your business to unleash the potential of this advanced platform. Our Ionic application development process is based on the unique methodology and clarity. Everything you will get a cross-platform app with your wanted features and functionality.",
            "We know that being average won’t make any difference. Our core values and responsiveness on advanced technologies make us one of the leading IT partners.",
        ],
        onlyBullets: true,
        data: []
    },
    {
        id: 5,
        title: "Strengths of Alta Tech Mark",
        description: "We, simply do not want to be an option but a willing choice of our customers and provided below are some of the reasons which justify, why you should hire us for ionic app development;",
        onlyBullets: true,
        data: [
            "Process Understanding",
            "Updated with the latest features and skills",
            "24/7 customer support",
            "On-time project delivery",
            "Speedy Set-up",
            "On-site and off-site app development service",
            "Focus on your core business",
            "Dedicated Team of Ionic developers",
            "Creative, unique concepts to handle complex projects",
            "Gulp & Sass expert developers",
            "Highly Skilled Ionic developers",
            "Bug-free Ionic apps",
        ]
    }
]