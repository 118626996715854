import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';

// logo
import logo from '../../assets/logo/logo.png';

// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Hamburger from 'hamburger-react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import facebook from '../../assets/icons/socials/facebookPrimary.svg';
import twitter from '../../assets/icons/socials/twitterPrimary.svg';
import instagram from '../../assets/icons/socials/instagramPrimary.svg';
import linkedIn from '../../assets/icons/socials/linkedinPrimary.svg';

// components
import CompanyHover from './CompanyHover';
import ServicesHover from './servicesHover/ServicesHover';
import HireDeveloperHover from './hireDeveloperHover/HireDeveloperHover';
import ResourcesHover from './resourcesHover/ResourcesHover';
import ServicesMenuItem from './servicesHover/ServicesMenuItem';
import HireDeveloperMenuItem from './hireDeveloperHover/HireDeveloperMenuItem';
import ArrowButton from '../ui/arrowButton/ArrowButton';
import ContactNav from '../contactNav/ContactNav';
import SimpleButton from '../ui/simpleButton/SimpleButton';

// MUI
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

export default function Navbar() {
    // initializations
    const navigate = useNavigate();

    // effects
    useEffect(() => {
        // Add the event listener on component mount
        window.addEventListener('resize', handleWindowResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    // locals
    const [navLinksHoverState, setNavLinksHoverState] = useState({
        company: false,
        services: false,
        hireDevelopers: false,
        caseStudy: false,
        resources: false,
        contactUs: false,
    })
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuLinkHoverState, setMenuLinkHoverState] = useState({
        company: false,
        services: false,
        hireDevelopers: false,
        contactUs: false,
        links: true
    })

    // functions
    // Function to update screenWidth on window resize
    const handleWindowResize = () => {
        setScreenWidth(window.innerWidth);
    };

    const companyHover = () => {
        setNavLinksHoverState({
            company: true,
            services: false,
            hireDevelopers: false,
            caseStudy: false,
            resources: false,
            contactUs: false,
        })
    }

    const hoverLeave = () => {
        setNavLinksHoverState({
            company: false,
            services: false,
            hireDevelopers: false,
            caseStudy: false,
            resources: false,
            contactUs: false,
        })
    }

    const servicesHover = () => {
        setNavLinksHoverState({
            company: false,
            services: true,
            hireDevelopers: false,
            caseStudy: false,
            resources: false,
            contactUs: false,
        })
    }

    const hireDevelopersHover = () => {
        setNavLinksHoverState({
            company: false,
            services: false,
            hireDevelopers: true,
            caseStudy: false,
            resources: false,
            contactUs: false,
        })
    }

    const caseStudyHover = () => {
        setNavLinksHoverState({
            company: false,
            services: false,
            hireDevelopers: false,
            caseStudy: true,
            resources: false,
            contactUs: false,
        })
    }

    // const resourcesHover = () => {
    //     setNavLinksHoverState({
    //         company: false,
    //         services: false,
    //         hireDevelopers: false,
    //         caseStudy: false,
    //         resources: true,
    //         contactUs: false,
    //     })
    // }

    const contactUsHover = () => {
        setNavLinksHoverState({
            company: false,
            services: false,
            hireDevelopers: false,
            caseStudy: false,
            resources: false,
            contactUs: true,
        })
    }

    const menuLinkHandler = (event) => {
        setMenuLinkHoverState(prevState => {
            return {
                company: false,
                services: false,
                hireDevelopers: false,
                contactUs: false,
                links: false,
                [event.target.id]: true,
            }
        })
    }

    return (
        <>
            <div className='tw-flex tw-items-center tw-justify-between tw-bg-white tw-py-4 tw-text-sm tw-px-[9.115vw] md:tw-h-[75px] tw-shadow-md tw-sticky tw-top-[0px] tw-z-10'>
                <img className='tw-cursor-pointer' src={logo} alt="logo" width={115}
                    onClick={() => navigate('/')}
                />

                {screenWidth >= 768 ?
                    <div className='tw-flex tw-items-center tw-gap-x-2'>
                        <div className='tw-cursor-pointer tw-flex tw-items-center hover:tw-text-[#4741A6]' onMouseEnter={companyHover}>
                            <h1>About Us</h1>
                            <ArrowDropDownIcon />
                        </div>
                        <div className='tw-cursor-pointer tw-flex tw-items-center hover:tw-text-[#4741A6]' onMouseEnter={servicesHover}>
                            <h1>Services</h1>
                            <ArrowDropDownIcon />
                        </div>
                        <div className='tw-cursor-pointer tw-flex tw-items-center hover:tw-text-[#4741A6]' onMouseEnter={hireDevelopersHover}>
                            <h1>Hire Developers</h1>
                            <ArrowDropDownIcon />
                        </div>
                        <div className='tw-cursor-pointer tw-flex tw-items-center hover:tw-text-[#4741A6]' onMouseEnter={caseStudyHover}
                            onClick={() => navigate('/resources/caseStudy')}
                        >
                            <h1>Case Study</h1>
                        </div>
                        {/* <div className='tw-cursor-pointer tw-flex tw-items-center hover:tw-text-[#4741A6]' onMouseEnter={resourcesHover}>
                            <h1>Resources</h1>
                            <ArrowDropDownIcon />
                        </div> */}
                        <div className='tw-cursor-pointer tw-flex tw-items-center hover:tw-text-[#4741A6]' onMouseEnter={contactUsHover}>
                            <Link to='/contactUs'>Contact Us</Link>
                        </div>
                        <SimpleButton text='Get A Free Quote' to='/contactUs' />
                    </div>
                    :
                    <Hamburger color='#4842A7' toggled={menuOpen} onToggle={() => setMenuOpen(!menuOpen)} />
                }
            </div>
            <SwipeableDrawer
                open={menuOpen}
                onClose={() => { setMenuOpen(false) }}
                sx={{ width: '60vw', height: '100vh', overflow: 'scroll' }}
            >
                {menuLinkHoverState.company &&
                    <div div className='tw-w-[80vw] tw-grid tw-gap-2 tw-text-lg tw-py-[2vw]'>
                        <img src={logo} alt="logo" className='tw-w-[20vw] tw-px-2' />
                        <div onClick={menuLinkHandler} id='links' className='tw-flex tw-w-full tw-p-[2vw] '>
                            <ArrowRightIcon id='links' className='tw-rotate-180' />
                            <h1 id='links' className='tw-font-semibold'>Back</h1>
                        </div>
                        <Link to='/company/aboutUs' className=' tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-text-[#4741A6] tw-font-semibold'>About Alta Tech Mark</Link>
                        <Link to='/company/services' className=' tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-text-[#4741A6] tw-font-semibold'>Portfolio</Link>
                        <Link to='/resources/blog' className=' tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-text-[#4741A6] tw-font-semibold'>Alta Tech Blogs</Link>
                        <Link to='/company/career' className=' tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-text-[#4741A6] tw-font-semibold'>Careers</Link>
                        <Link to='/company/events' className=' tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-text-[#4741A6] tw-font-semibold'>Download Digital Brochure</Link>
                    </div>
                }
                {menuLinkHoverState.links &&
                    <div className='tw-w-[80vw] tw-grid tw-gap-2 tw-py-[2vw]'>
                        <img src={logo} alt="logo" className='tw-w-[20vw] tw-px-2' />
                        <div onClick={menuLinkHandler} id='company' className='tw-flex tw-w-full tw-justify-between tw-p-[2vw] tw-font-semibold'>
                            <h1 id='company' className='tw-text-lg tw-tracking-wide'>About Us</h1>
                            <ArrowRightIcon id='company' />
                        </div>
                        <div onClick={menuLinkHandler} id='services' className='tw-flex tw-w-full tw-justify-between tw-p-[2vw] tw-font-semibold'>
                            <h1 id='services' className='tw-text-lg tw-tracking-wide'>Services</h1>
                            <ArrowRightIcon id='services' />
                        </div>
                        <div onClick={menuLinkHandler} id='hireDevelopers' className='tw-flex tw-w-full tw-justify-between tw-p-[2vw] tw-font-semibold'>
                            <h1 id='hireDevelopers' className='tw-text-lg tw-tracking-wide'>Hire Developers</h1>
                            <ArrowRightIcon id='hireDevelopers' />
                        </div>
                        <Link to='/contactUs' className='tw-flex tw-w-full tw-justify-between tw-p-[2vw] tw-font-semibold'>
                            <h1 id='contactUs' className='tw-text-lg tw-tracking-wide'>Contact Us</h1>
                            {/* <ArrowRightIcon id='contactUs' /> */}
                        </Link>
                        <Link to='/resources/caseStudy' className='tw-flex tw-w-full tw-justify-between tw-p-[2vw] tw-font-semibold'>
                            <h1 id='contactUs' className='tw-text-lg tw-tracking-wide'>Case Study</h1>
                            {/* <ArrowRightIcon id='contactUs' /> */}
                        </Link>
                        <ArrowButton text='Get A Quote' className='tw-bg-darkPurple tw-p-[2vw] tw-w-[75%] tw-mx-auto tw-text-white tw-my-4' url='/contactUs' />
                        <div className='tw-flex tw-justify-around tw-p-[2vw] tw-w-[60%] tw-mx-auto tw-my-4'>
                            <img className='tw-cursor-pointer' src={facebook} alt="facebook logo" width={20} height={20} />
                            <img className='tw-cursor-pointer' src={twitter} alt="twitter logo" width={20} height={20} />
                            <img className='tw-cursor-pointer' src={instagram} alt="instagram logo" width={20} height={20} />
                            <img className='tw-cursor-pointer' src={linkedIn} alt="linkedin logo" width={20} height={20} />
                        </div>
                        <div className='tw-my-4 tw-p-[2vw]'>
                            <ContactNav show={true} />
                        </div>
                    </div>
                }
                {menuLinkHoverState.services && <ServicesMenuItem menuLinkHandler={menuLinkHandler} />}
                {menuLinkHoverState.hireDevelopers && <HireDeveloperMenuItem menuLinkHandler={menuLinkHandler} />}
            </SwipeableDrawer >
            <div className='tw-sticky tw-top-[92px] tw-z-[11] tw-flex tw-w-full tw-justify-center'>
                {navLinksHoverState.company && <CompanyHover hoverLeave={hoverLeave} />}
                {navLinksHoverState.services && <ServicesHover hoverLeave={hoverLeave} />}
                {navLinksHoverState.hireDevelopers && <HireDeveloperHover hoverLeave={hoverLeave} />}
                {navLinksHoverState.resources && <ResourcesHover hoverLeave={hoverLeave} />}
            </div>
        </>
    )
}
