import React from 'react'

export default function ReviewsCard(props) {
    return (
        <div className='tw-w-auto tw-h-[450px] tw-py-[50px] tw-px-[35px] tw-mb-[80px] tw-flex tw-flex-col tw-items-start tw-justify-between tw-bg-white md:tw-text-[17px] tw-text-[12px]'>
            <img src={props.src} alt="profile" className='tw-w-[80px] tw-rounded-full'/>
            <h1 className='tw-text-sm md:tw-text-base'>{props.review}</h1>
            <h1 className='tw-text-purple tw-text-sm md:tw-text-base'>{props.name}</h1>
        </div>
    )
}
