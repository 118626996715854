export const currentOpeningsData = [
    {
        id: 1,
        title: ".Net Developer",
        experience: 'Required experience - 1 to 3 years',
        positions: 5,
        country: 'United States',
        state: 'California'
    },
    {
        id: 2,
        title: "Node.js Developer",
        experience: 'Required experience - 1 to 2 years',
        positions: 2,
        country: 'United States',
        state: 'California'
    },
    {
        id: 3,
        title: "Business Development Manager",
        experience: 'Required experience - 4+ years',
        positions: 1,
        country: 'United States',
        state: 'California'
    }
]