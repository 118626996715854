import React from 'react'

// components
import ArrowButton from '../../ui/arrowButton/ArrowButton';

export default function Template(props) {
    return (
        <div className="tw-grid md:tw-grid-cols-3 tw-w-full tw-px-[9.115vw] tw-place-items-center tw-justify-center md:tw-h-[80vh] tw-h-auto tw-gap-x-2 tw-py-[4vw]">
            <div className="tw-flex tw-flex-col tw-items-start md:tw-col-span-2 tw-col-span-12 tw-gap-[10px] tw-pr-6">
                <h2 className='tw-my-0 tw-py-0 tw-font-semibold md:tw-text-6xl tw-text-4xl md:tw-text-left tw-text-center md:tw-w-[69%] tw-w-full'>
                    <span className='tw-text-purple'>{props.titleInColor}</span> {props.titleCont}
                </h2>
                <div className="md:tw-hidden tw-block tw-h-[260px]">
                    <img className="" src={props.img} alt="metaverse company" />
                </div>
                <div className='tw-h-[5px] md:tw-w-[100px] tw-w-full tw-bg-purple tw-rounded-lg'></div>
                <h2 className='tw-my-0 tw-py-0 md:tw-text-xl tw-w-full'>
                    <span className='tw-font-semibold'>{props.descriptionBold} </span> {props.descriptionCont}
                </h2>
                <ArrowButton text='Get a Quote' className='tw-bg-darkPurple tw-text-white tw-mx-auto md:tw-mx-0' url='/contactUs' />
            </div>
            <div className="md:tw-col-span-1 tw-hidden md:tw-block tw-col-span-12">
                <img className="tw-img-fluid tw-scale-[1.1]" src={props.img} alt="metaverse company" />
            </div>
        </div>
    )
}
