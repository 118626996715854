import img1 from '../../../assets/icons/aiPage/1.png';
import img2 from '../../../assets/icons/aiPage/2.png';
import img3 from '../../../assets/icons/aiPage/3.png';
import img4 from '../../../assets/icons/aiPage/4.png';
import img5 from '../../../assets/icons/aiPage/5.png';
import img6 from '../../../assets/icons/aiPage/6.png';
import img7 from '../../../assets/icons/aiPage/7.png';

export const aiGridBlockData = [
    "Healthcare",
    'Education',
    'Media & Entertainment',
    'E-Commerce',
    'Logistics',
    'Manufacturing',
    'Sports',
    'Banking & Finance',
    'Food Tech'
]

export const aiServicesData = [
    {
        id: 1,
        title: "Recommendation Engine",
        description: "The recommendation engine is one of the effective products of machine learning and artificial intelligence. The Engine improves user experience.",
        src: img1,
    },
    {
        id: 2,
        title: "Process Automation",
        description: "Repetitive tasks may be automated via process automation. It has many tasks that demonstrate how to be economical, efficient, and resource-conscious.",
        src: img2,
    },
    {
        id: 3,
        title: "Voice-based Artificial Intelligence",
        description: "Voice-based AI has ruled the market and increased the demand by applying it for several purposes. Siri, Alexa, Google Assistant, and others are famous.",
        src: img3,
    },
    {
        id: 4,
        title: "Chatbots",
        description: "Chatbots are another helpful use of AI and machine learning. AI-powered chatbots make it possible for people to converse with machines and increase the level of engagement between them and the machine.",
        src: img4,
    },
    {
        id: 5,
        title: "Inventory Optimization",
        description: "Production efficiency and optimization are aided by inventory optimization. Businesses may use artificial intelligence to calculate product sales and total consumption.",
        src: img5,
    },
    {
        id: 6,
        title: "Image Processing",
        description: "Machine learning and artificial intelligence are used to identify the picture, object, or any other image. Image processing facilitates a variety of tasks.",
        src: img6,
    },
    {
        id: 7,
        title: "Object Recognition",
        description: "Using object recognition, relevant things may be found in photos and useful data can be extracted for later use. As a result, more artificial intelligence and machine learning.",
        src: img7,
    }
]