export const pricingData = [
    {
        id: 1,
        title: "Hourly",
        description: "If you have a project with a shorter time frame or are looking for a more specialized solution, you may engage our experienced app developers on an hourly basis.",
        price: "23.00",
        perTime: "Hour",
        bgPolygon: "mud",
        bgPolygonCode: '#9d689c'
    },
    {
        id: 2,
        title: "Monthly",
        description: "Consider using a source of app developers on a monthly basis if you need something quite specific, have a restricted budget, less time, and a long-term cooperation.",
        price: "2890.00",
        perTime: "Month",
        bgPolygon: 'reddish',
        bgPolygonCode: '#f24535'
    },
    {
        id: 3,
        title: "Quarterly",
        description: "If you anticipate that the needs of both your target audience and the project itself will evolve, you might consider hiring app developers on a quarterly basis.",
        price: "7999.00",
        perTime: "Quarter",
        bgPolygon: 'froozy',
        bgPolygonCode: '#51cbc0'
    }
]