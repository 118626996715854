export const resourcesData = [
    {
        id: 1,
        name: 'blog',
        value: 'Blog',
        url: '/resources/blog'
    },
    {
        id: 2,
        name: 'research',
        value: 'Research',
        url: '/resources/research'
    },
    {
        id: 3,
        name: 'mediaCoverage',
        value: 'Media Coverage',
        url: '/resources/mediaCoverage'
    },
    {
        id: 4,
        name: 'pressRelease',
        value: 'Press Release',
        url: '/resources/pressRelease'
    },
    {
        id: 5,
        name: 'techNews',
        value: 'Tech News',
        url: '/resources/techNews'
    },
    {
        id: 6,
        name: 'whitepaper',
        value: 'Whitepaper',
        url: '/resources/whitepaper'
    },
    {
        id: 7,
        name: 'podcast',
        value: 'Podcast',
        url: '/resources/podcast'
    },
    {
        id: 8,
        name: 'portfolio',
        value: 'Portfolio',
        url: '/resources/portfolio'
    },
    {
        id: 9,
        name: 'downloadBrochure',
        value: 'Download-Brochure',
        url: '/resources/downloadBrochure'       
    },
]