export const virtueMartDevData = [
    {
        id: 1,
        title: "Differentiating VirtueMart Among Open-Source E-Commerce Solutions",
        descriptions: [
            "Not just another open-source e-commerce platform is VirtueMart. Fundamentally, it's designed to work in unison with the Joomla! Content Management System (CMS). Because Joomla! and VirtueMart are both based on PHP, they work well together in a PHP/MySQL context. Interestingly, VirtueMart has a larger user base than many other well-known e-commerce systems, like osCommerce, PrestaShop, and OpenCart. If you're thinking about making the switch to VirtueMart, Alta Tech Mark's expert support can make things go more smoothly.",
            "Make sure you have PHP versions 5.2 or 5.3, MySQL 5, and Joomla! 1.5 or later for a successful conversion to VirtueMart. VirtueMart stands apart due to its more than 500 features that are designed to improve e-commerce operations and maybe boost revenue. Modules, templates, components, and plugins abound on the platform. Additionally, since VirtueMart and Joomla! work well together, customers may take use of Joomla! plugins for a variety of features, including computations, shipping, payment gateways, coupons, and custom product properties.",
            "VirtueMart also invests heavily on SEO. It offers thorough product descriptions that are both concise and informative, including precise product measurements, along with support for meta tags and nested categories. In addition, it provides consumers with an enhanced purchasing experience by showcasing product ratings, user reviews, and related product recommendations. Additionally, VirtueMart puts the needs of its users first with shopper-centric features including customizable price displays, payment and shipping choices depending on shopper groups, and multi-currency payment computations.",
            "Every e-commerce platform has to monitor its product inventories. With capabilities like inventory management, low stock notifications, and insights on virtual stock (items added to carts but not yet bought), VirtueMart is a leader in this field.",
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Alta Tech Mark is better for VirtueMart store development.",
        description: "The many features of VirtueMart are a great advantage, but they may also be intimidating to beginners. In case you feel that you need professional assistance, Alta Tech Mark is available to assist you in navigating and making the most out of the platform.",
        onlyBullets: false,
        data: []
    }
]