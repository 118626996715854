import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import s1 from '../../../assets/pages/service/salesforceDev/s1.png';
import { salesforceData } from '../../../data/services/salesforceDevData/salesforceData';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import DataMapper from '../../../components/services/dataMapper/DataMapper';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function SalesforceSolutions() {
    // initializations
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet
                ||
                <div>
                    <HeroTitleImage
                        title="SALESFOECE DEVELOPMENT"
                        bg={s1}
                        inWrapper={true}
                        simpleTextBg={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleEnd="SALESFORCE DEVELOPMENT"
                            title="ABOUT"
                            className='tw-text-center tw-mb-8'
                        />
                        <h1>
                            Customers are surely the biggest & most valuable assets of your company. And, to their requirements, the establishment of an effective CRM system becomes important now. The tools and services in Salesforce are designed to turn a business into an enterprise where every employee, vendors, and customer are together on a single platform helping an organization to function more efficiently than ever. With the growth of Salesforce’s cloud-based CRM and other advanced products, more and more organizations are adopting them to streamline their sales processes. Important Benefits of Salesforce Development:
                        </h1>
                        <ol className='tw-list-disc tw-ml-4 tw-grid tw-gap-2 tw-my-2'>
                            <li>In-depth Account Planning</li>
                            <li>Effective Time Management</li>
                            <li>Better Team Collaboration</li>
                            <li>Improved Accessibility</li>
                            <li>Access to Customer Related Information</li>
                        </ol>
                        <h1>
                            Being a leading Salesforce development company, we provide optimized Salesforce development services from planning, designing, to executing complete Salesforce-based business solutions to businesses to help them deliver good productivity and higher earnings. At Alta Tech Mark, our team of expert Salesforce developers assures that the solutions given are highly useful, reliable, extensible, and maintainable on the Salesforce platform.
                        </h1>

                        <div className='tw-grid tw-gap-y-5'>
                            <DataMapper data={salesforceData} />
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
