import React from 'react';

// data
import { hireJavaDevData, hireJavaDevFaqs } from '../../../data/hireDev/hireJavaDev';
import javaDev1 from '../../../assets/pages/hireDev/javaDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireJavaDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="JAVA DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={javaDev1}
            data={hireJavaDevData}
            faq={hireJavaDevFaqs}
        />
    )
}
