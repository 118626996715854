import React from 'react';

// assets
import i1 from '../../../../assets/pages/service/mobileAppDev/iphoneAppDev/i1.jpeg';
import i2 from '../../../../assets/pages/service/mobileAppDev/iphoneAppDev/i2.png';
import i3 from '../../../../assets/pages/service/mobileAppDev/iphoneAppDev/i3.png';
import i4 from '../../../../assets/pages/service/mobileAppDev/iphoneAppDev/i4.png';
import i5 from '../../../../assets/pages/service/mobileAppDev/iphoneAppDev/i5.png';
import i6 from '../../../../assets/pages/service/mobileAppDev/iphoneAppDev/i6.png';
import { iphoneDevData } from '../../../../data/services/mobileAppDevData/iphoneDevData';

// components
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function IphoneAppDev() {
    return (
        <ServicesWrapper
            title="I-PHONE APP DEVELOPMENT"
            descriptionCont={`Our staff knows how to meet targets and adapt concepts to market needs. We don' t simply design apps— we create tools to grow your company.We create a masterpiece for you using expertise, innovation, and technology.Alta Tech Mark values learning above milestones.`}
            img={i1}
        >
            {/* <DevGridWrapper images={[i2, i3, i4, i5, i6]}> */}
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={iphoneDevData} />
            </div>
            {/* </DevGridWrapper> */}
        </ServicesWrapper>
    )
}
