import React from 'react';

// assets
import df1 from '../../../../assets/pages/service/aiAndMl/df1.jpeg';
import df2 from '../../../../assets/pages/service/aiAndMl/df2.png';
import { dataForecastingData } from '../../../../data/services/aiAndMl/dataForecastingData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function DataForecasting() {
    return (
        <ServicesWrapper
            title="Data Forecasting"
            descriptionCont="Forecasting and data are the two elements that are now necessary for running a firm. Therefore, by offering Data Forecasting, Artificial Intelligence and Machine Learning facilitate it."
            img={df1}
        >
            <DevGridWrapper img={df2}>
                <DataMapper data={dataForecastingData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
