export const hireSolidityDevData = [
    {
        id: 1,
        title: "",
        description: "At Alta Tech Mark, we're at the forefront of harnessing the power of blockchain technology. Recognized as a leading force in mobile app and blockchain development, our commitment is to deliver cutting-edge Solidity-based blockchain solutions tailored to fit the unique demands of your business.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "What Sets Us Apart:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Deep Domain Expertise",
                description: "Our Solidity developers possess in-depth knowledge of the Ethereum platform, bringing a wealth of experience in crafting robust smart contracts. With a firm grasp on JavaScript technology, our team ensures seamless and efficient execution of Solidity projects."
            },
            {
                id: 2,
                title: "Versatile Blockchain Solutions",
                description: "Whether it's a decentralized crowdfunding platform, a transparent blind auction system, a secure voting mechanism, or any other blockchain initiative, Alta Tech Mark has you covered. We conceptualize, design, and develop solutions that cater to a plethora of industries and applications."
            },
            {
                id: 3,
                title: "Comprehensive Service Suite",
                description: "From ideation and strategy formulation to development and deployment, our services encompass the entire spectrum of blockchain project development. But we don’t stop there. Post-launch, we offer dedicated support to ensure your application runs smoothly and evolves with the ever-changing technological landscape."
            },
            {
                id: 4,
                title: "Client-Centric Approach",
                description: "At the heart of our operations is an unwavering focus on our clients. We delve deep to understand your specific challenges and objectives, ensuring the solutions we craft align perfectly with your business goals."
            },
            {
                id: 5,
                title: "Track Record of Excellence",
                description: "Our portfolio boasts a multitude of successfully managed blockchain projects. This proven track record is a testament to our technical prowess, innovative mindset, and unwavering commitment to excellence."
            }
        ]
    },
    {
        id: 3,
        title: "Future-Proof Your Business with Blockchain:",
        description: "In today’s digital age, blockchain stands as a revolutionary technology, offering unparalleled security, transparency, and decentralization. Partnering with Alta Tech Mark ensures that you're leveraging this technology to its fullest, giving your business a significant competitive edge.",
        onlyBullets: false,
        data: []
    },
]

export const hireSolidityDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Solidity developers?",
        answer: "The dedicated Solidity developers will work only for you to deliver outstanding results with the highest optimization. The team of Solidity developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Solidity developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Solidity developers. Just provide project details and the technical expertise you need. The team of Solidity developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Solidity developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Solidity developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Solidity developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Solidity developer, then you can hire dedicated Solidity developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Solidity developers?",
        answer: "The cost estimation for dedicated Solidity developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
];