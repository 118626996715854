import img1 from '../../assets/icons/hiringComp/1.png';
import img2 from '../../assets/icons/hiringComp/2.png';
import img3 from '../../assets/icons/hiringComp/3.png';
import img4 from '../../assets/icons/hiringComp/4.png';

export const hiringSteps = [
    {
        id: 1,
        title: "Interaction with our Professionals",
        description: "In the beginning, our experts will speak with you to understand your needs and desired outcomes. After that, we will go through tasks and procedures with you.",
        bgColor: 'froozy',
        src: img1
    },
    {
        id: 2,
        title: "Assign to The Team",
        description: "We will assign you to the team or particular person working on the project after we have an understanding of your demands. Our team of professionals will put out great effort to achieve your objective.",
        bgColor: 'yellow',
        src: img2
    },
    {
        id: 3,
        title: "Work and Know",
        description: "We give you team members or staff to assist you in your task. On the other hand, you may let us know if you're not satisfied. At Alta Tech Mark, we provide quality that aligns with the goals of the customer.",
        bgColor: 'mud',
        src: img3
    },
    {
        id: 4,
        title: "Final Selection",
        description: "In our last phase, we will assign you the team and team members you desire for your next tasks.",
        bgColor: 'reddish',
        src: img4
    }
]