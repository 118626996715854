export const ethereumDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Based on Blockchain technology, Ethereum is an open software platform that helps construct Distributed Applications (DApps) and Smart Contracts. Although Ethereum app development is a relatively recent development in the business sector, it is already demonstrating its worth as a potent tool for creating and overseeing blockchain networks, transparent smart contracts, and novel organizational concepts for businesses.",
            "Applications for a variety of businesses and sectors, including financial, semi-financial, and non-financial applications, being developed on the Ethereum platform. In the field of Ethereum app development, Alta Tech Mark combines our knowledge with Ethereum's special capabilities to comprehend your company's needs and provide tailored app development services."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "The Essential Information About Ether",
        description: "With regard to smart contracts, one of the most well-known cryptocurrencies is Ether, a particular kind of cryptographic token. Application developers use ether to pay for services provided by the Ethereum network and transaction fees.",
        onlyBullets: false,
        data: []
    },
    {
        id: 3,
        title: "Elite Ethereum App Development Services Are Offered by Alta Tech Mark",
        description: "The staff at Alta Tech Mark is skilled in using Ethereum's incredible capabilities and works hard to provide the most creative and goal-oriented services.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Ethereum Smart Contract Development",
                description: "By using automation and the advanced capabilities of Ethereum, we create smart contracts for companies to manage their assets."
            },
            {
                id: 2,
                title: "Bespoke Ethereum Wallet App",
                description: "We work with our most talented staff members and resources to provide various companies bespoke Ethereum wallet apps."
            },
            {
                id: 3,
                title: "Decentralized App Development Services",
                description: "We provide top-notch Dapps, or decentralized applications, that seamlessly function on the Ethereum network. We create unique, customized apps by using Ethereum's cutting-edge capabilities and top-tier technologies."
            }
        ]
    },
    {
        id: 4,
        title: "Why Should You Choose Alta Tech Mark As Your Ethereum App Development Partner?",
        description: "With unwavering dedication, Alta Tech Mark is aiming to integrate cutting-edge technology into the revolutionary Ethereum blockchain development to create completely customizable, high-octane Ethereum development solutions that are customized to meet the specific needs of your project. With our cryptocurrency development services, you may create strong decentralized apps and improve your company's operations.",
        onlyBullets: true,
        data: [
            "Professionals with expertise",
            "Customized Decentralized Applications",
            "Adaptable, Interactive, and Scalable Ethereum Apps",
            "Quick Reactions",
            "Experts in Security"
        ]
    },
    {
        id: 5,
        title: "",
        description: "To make a bespoke Ethereum wallet app, get an Ethereum wallet app, or integrate Ethereum to accomplish a complete spectrum of Ethereum applications, hire our skilled Ethereum app developer.",
        onlyBullets: false,
        data: []
    },
]