import React from 'react';

// assets
import nlp1 from '../../../../assets/pages/service/aiAndMl/nlp1.jpeg';
import nlp2 from '../../../../assets/pages/service/aiAndMl/nlp2.png';
import { nlpData } from '../../../../data/services/aiAndMl/nlpData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function Nlp() {
    return (
        <ServicesWrapper
            title="Natural Language Processing"
            descriptionCont="By using natural language processing, robots can understand how people communicate—not only with words, but also with ideas and how they relate to one other to create meaning."
            img={nlp1}
        >
            <DevGridWrapper img={nlp2}>
                <DataMapper data={nlpData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
