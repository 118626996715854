import React from 'react';

// data
import { hireLaravelDevData, hireLaravelDevFaqs } from '../../../data/hireDev/hireLaravelDev';
import laravelDev1 from '../../../assets/pages/hireDev/laravelDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireLaravelDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="LARAVEL DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={laravelDev1}
            data={hireLaravelDevData}
            faq={hireLaravelDevFaqs}
        />
    )
}
