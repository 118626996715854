import React from 'react';

// assets
import bg from '../../../assets/brands/renault/bg.svg';
import logo from '../../../assets/brands/renault/logo.svg';
import right from '../../../assets/brands/renault/right.svg';
import overviewFloatingSpan from '../../../assets/brands/renault/overviewFloatingSpan.svg';
import seamlessUpdate from '../../../assets/brands/renault/seamlessUpdate.svg';
import bg2 from '../../../assets/brands/renault/bg2.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function Renault() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="RENAULT"
                simpleTitle="Automobile"
                description="Renault, one of our esteemed clients, aimed to expand its online presence in Iraq through a dedicated website for its customers. The team at Alta Tech Mark assisted them in realizing this objective."
                rightClasses="md:tw-w-[32vw] md:tw-h-[22vw]"
            />

            <ProjectOverview
                overview="For this project, our task was to overhaul the Renault Iraq website. Our mission was straightforward: to vividly convey Renault's brand message within the digital space, tailored specifically for their targeted customer base in Iraq, thereby aiding in the further growth of their business."
                overviewFloatingSpan={overviewFloatingSpan}
                overviewFloatingText="We embraced minimal design approach for the layout. The idea was to get user focus on the precise value proposition before taking going to the latest Renault offerings."
            />

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-4 tw-relative'
                style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <h1 className='md:tw-text-[2.635vw] tw-text-white tw-font-bold tw-text-center'>Responsive and Seamless Layout Across Devices</h1>
                <h1 className='md:tw-text-[1.171vw] tw-text-center tw-text-white'>Mobile accessibility is important for a website, especially for a brand as prestigious as Renault. Therefore, we coded the website to show pixel perfection across varied devices.</h1>
                <img className='tw-mx-auto' src={seamlessUpdate} alt="seamless update" />
            </div>

            <ContactUsNoForm />
        </div>
    )
}
