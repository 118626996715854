import React from 'react';

// data
import { hireBlockchainDevData, hireBlockchainDevFaqs } from '../../../data/hireDev/hireBlockchainDev';
import blockchainDev1 from '../../../assets/pages/hireDev/blockchainDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireBlockchainDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="BLOCKCHAIN DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={blockchainDev1}
            data={hireBlockchainDevData}
            faq={hireBlockchainDevFaqs}
        />
    )
}
