import uiUxDesign1 from '../../../assets/pages/service/corporateBranding/uiUxDesign1.png';
import userResearch from '../../../assets/icons/corporateBranding/userResearch.svg';
import userFlow from '../../../assets/icons/corporateBranding/userFlow.svg';
import wireframes from '../../../assets/icons/corporateBranding/wireframes.svg';
import mockups from '../../../assets/icons/corporateBranding/mockups.svg';
import prototyping from '../../../assets/icons/corporateBranding/prototyping.svg';
import usabilityTesting from '../../../assets/icons/corporateBranding/usabilityTesting.svg';

export const uiUxDesignDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Developing a user interface and user experience design that is data-driven and user-centered in order to provide the highest possible level of user satisfaction.",
            "Our commitment to excellence is reflected in our strategic design process and our collaborative approach. We work closely with your team to transform your vision into tangible, intuitive user interfaces that effortlessly guide users toward their goals. With every project, we set out not just to meet expectations but to exceed them.",
            "What sets Alta Tech Mark apart is our keen focus on usability. We understand that a beautiful design is only a part of the equation. Through rigorous usability testing, we optimize every touchpoint in your interface to ensure that users enjoy interacting with your product and efficiently achieve their objectives.",
            "Embark on a transformative journey with Alta Tech Mark, where we merge artistry with functionality to design a masterpiece that will elevate your business to new heights."
        ],
        onlyBullets: false,
        sectionPicRow: true,
        sectionPic: uiUxDesign1,
        data: []
    }
]

export const uiApproachData = [
    {
        id: 1,
        title: "1. User Research",
        bullets: [
            "Research the Business, Audience & Market.",
            "Conduct Interviews, Surveys & Competitor Analysis.",
            "Identify Pain Points, Challenges & Opportunities."
        ],
        src: userResearch
    },
    {
        id: 2,
        title: "2. User Flow",
        bullets: [
            "Map Out User Journeys & Interactions.",
            "Define Optimal Path & Guide Users.",
            "Create User Flows."
        ],
        src: userFlow
    },
    {
        id: 3,
        title: "3. Wireframes",
        bullets: [
            "Create User Flows.",
            "Outline Information Architecture & Content Placement.",
            "Iterate & Refine."
        ],
        src: wireframes
    },
    {
        id: 4,
        title: "4. Mockups",
        bullets: [
            "Develop High Fidelity Visual Representations.",
            "Select Color Schemes, Branding Elements, & Visual Hierarchy.",
            "Realistic Preview Of Final Design."
        ],
        src: mockups
    },
    {
        id: 5,
        title: "5. Prototyping",
        bullets: [
            "Build Interactive & Dynamic Prototypes.",
            "Test & Validate The Functionality.",
            "Gather Feedback From Stakeholders & Users."
        ],
        src: prototyping
    },
    {
        id: 6,
        title: "6. Usability Testing",
        bullets: [
            "Conduct Testing Sessions With Representative Users.",
            "Identify Usability Issues, Pain Points & Areas of Improvement.",
            "Implement Necessary Adjustments Based On User Feedback."
        ],
        src: usabilityTesting
    }
]