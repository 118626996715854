import React from 'react';

// assets
import au2Line from '../../../assets/pages/aboutUs/au2Line.svg';
import { ourJourneyData } from '../../../data/aboutUs/ourJourneyData';

// components
import HeaderText from '../../headerText/HeaderText';
import OurJourneyCard from './ourJourneyCard/OurJourneyCard';

export default function OurJourney() {
    return (
        <div className='tw-px-[9.115vw] tw-mt-[8.138vw] tw-flex tw-flex-col tw-items-center tw-w-full'>
            <HeaderText colorTitleEnd='JOURNEY' title='OUR' className='tw-text-center tw-mb-8' />

            <div className='tw-grid tw-grid-cols-5 tw-h-[26.042vw] tw-w-[80.729vw]'
                style={{
                    backgroundImage: `url(${au2Line})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '79% 100%',
                    backgroundPosition: 'left',
                }}
            >
                {ourJourneyData.map(journey => <OurJourneyCard 
                    key={journey.id}
                    id={journey.id}
                    src={journey.src}
                    title={journey.title}
                    description={journey.description}
                />)}

            </div>
            {/* <img src={au2} alt="journey" /> */}
        </div>
    )
}
