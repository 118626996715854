import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import ai1 from '../../../assets/pages/service/aiAndMl/ai1.png';
import ai2 from '../../../assets/pages/service/aiAndMl/ai2.png';
import ai3 from '../../../assets/pages/service/aiAndMl/ai3.png';
import { aiGridBlockData, aiServicesData } from '../../../data/services/aiAndMl/aiData';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import GridBlock from '../../../components/services/gridBlock/GridBlock';
import KeyFeaturesCard from '../../../components/home/keyFeatures/KeyFeaturesCard';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function AiAndMl() {
    // initialization
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet
                ||
                <div>
                    <HeroTitleImage
                        bg={ai1}
                        title="ARTIFICIAL INTELLIGENCE & MACHINE LEARNING"
                        inWrapper={true}
                        simpleTextBg={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-grid tw-gap-4'
                        style={{
                            backgroundImage: `linear-gradient(${screenWidth < 768 ? '0deg' : '-90deg'}, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${ai2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className='tw-text-white'>
                            <HeaderText
                                title="WHY ALTA TECH MARK FOR AI & ML"
                                description="Alta Tech Mark taps into the potential of AI and ML to deliver top-tier solutions."
                                className='tw-text-white tw-mb-[2vw] md:tw-text-left tw-text-center'
                                descriptionClassName='tw-text-left'
                            />
                            <h1>Artificial Intelligence and Machine Learning are two forefronts of technological advancement. We offer smart and evidence-based perspectives that turn your AI aspirations into tangible results. Our firm assists your enterprise in amplifying and offering Image & Video Analysis, Speech Synthesis, Business Intelligence, Data Prediction, Natural Language Processing, and Data Interpretation.</h1>
                        </div>
                        <div className='tw-flex md:tw-flex-row tw-flex-col tw-items-start tw-justify-between tw-w-full tw-gap-6'>
                            <div className='tw-flex md:tw-flex-col tw-flex-row-reverse md:tw-w-[75%] tw-w-full md:tw-items-center tw-items-start tw-gap-y-2 tw-justify-between tw-relative'>
                                <h1 className='md:tw-text-5xl tw-text-xl tw-font-semibold tw-text-white md:tw-text-left tw-text-right tw-w-full md:tw-static tw-absolute tw-top-[15%]'>INDUSTRIES WE SERVE</h1>
                                <img src={ai3} alt="pic" className='md:tw-w-[23.112vw] tw-w-[44vw] tw-mr-auto' />
                            </div>
                            <div className='tw-grid tw-grid-cols-3 tw-gap-[1.628vw] tw-justify-items-center tw-w-full'>
                                {aiGridBlockData.map((data, index) =>
                                    <GridBlock
                                        key={index}
                                        text={data}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'>
                        <HeaderText
                            colorTitleEnd="ALTA TECH MARK"
                            title="WHY AI & ML"
                            className="tw-text-center tw-mb-8"
                        />

                        <ol className='tw-list-decimal'>
                            <li>
                                Advanced Technologies Artificial Intelligence and Machine Learning stand as contemporary innovations driving modern business. At Alta Tech Mark, we harness the most updated and refined technologies to cater to our client's requirements. These tools are proficient, and our developers employ them with precision.
                            </li>
                            <li>
                                Adaptive Solutions With over a decade of experience, Alta Tech Mark delivers premier Artificial Intelligence and Machine Learning solutions. Our data-driven approaches encompass the vast expanse of ML and AI, offering adaptive strategies to bolster businesses.
                            </li>
                            <li>
                                Augmented Efficiency We bring forth top-notch Artificial Intelligence and Machine Learning methodologies that amplify business productivity. Our adept experts devise efficacious plans and strategies tailored to align with client objectives.
                            </li>
                            <li>
                                In-Depth Understanding Our commitment goes beyond just delivering results. When insights emerge through Artificial Intelligence and Machine Learning, we elucidate them for our clients transparently. Our goal is to foster client business growth and momentum. We maintain transparency in our processes, models, and decision-making. By ensuring regular interactions with clients, we provide profound clarity on projects, processes, models, and other vital details. Our team recognizes the prerequisites and strives to address the overarching business aspirations.
                            </li>
                        </ol>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple'>
                        <HeaderText
                            colorTitleStart="AI & ML"
                            title="SERVICES"
                            description="Services That We Deliver Makes Us Dignified As One of The Top App Development Companies."
                            className="tw-text-center tw-mb-8"
                        />
                        <div className='md:tw-grid md:tw-grid-cols-3 tw-flex tw-items-center tw-w-full tw-gap-[25px] tw-overflow-x-scroll md:tw-overflow-x-hidden tw-pb-4'>
                            {aiServicesData.map(service =>
                                <KeyFeaturesCard
                                    key={service.id}
                                    title={service.title}
                                    description={service.description}
                                    src={service.src}
                                />
                            )}
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
