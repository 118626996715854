import React from 'react';

// components
import BreadCrumb from '../../../components/breadCrumb/BreadCrumb';
import HeaderText from '../../../components/headerText/HeaderText';
import EventsPageCard from '../../../components/events/eventsPageCard/EventsPageCard';
import SocialMedia from '../../../components/home/socialMedia/SocialMedia';

// assets
import { eventsData } from '../../../data/eventsData';

export default function Events() {
    return (
        <div className='tw-py-[24px] tw-tracking-wide tw-grid tw-gap-8'>
            <BreadCrumb main='Home' sub='Events & Conferences' />

            <HeaderText title='Events & Conferences' description='Discover The International Events Where We Showcase Our Business.' className='tw-px-[9.115vw]' />

            {eventsData.map(event => <EventsPageCard
                key={event.id}
                main={event.main}
                sub={event.sub}
                title={event.title}
                descriptions={event.descriptions}
                month={event.month}
                year={event.year}
                location={event.location}
            />
            )}

            <SocialMedia />
        </div>
    )
}
