export const hireLaravelDevData = [
    {
        id: 1,
        title: "",
        description: "One of PHP's most incredible frameworks, Laravel was created primarily with the goal of writing code using an easy-to-understand and affordable syntax. It offers a sophisticated substitute for the Codeigniter framework and adheres to the MVC architectural pattern. Nowadays, Laravel is considered to be the most sought-after PHP framework. With Laravel, designing, developing, and customizing become quick and simple processes. It provides for the creation of top-notch online apps and websites.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Elegant Syntax",
                description: "At its core, Laravel is designed with the goal to make the development process a pleasing one for the developer without sacrificing application functionality. Its expressive and elegant syntax makes the web application development enjoyable and creatively fulfilling."
            },
            {
                id: 2,
                title: "MVC Support",
                description: "Laravel supports the Model-View-Controller (MVC) architectural pattern, ensuring clarity between logic and presentation. This promotes better documentation and results in multiple functionalities like improved performance, better security, and scalability."
            },
            {
                id: 3,
                title: "Eloquent ORM",
                description: "One of the standout features of Laravel is its Eloquent ORM (Object-Relational Mapping), which provides an intuitive way to interact with databases. Using Eloquent, developers can write database queries using PHP rather than SQL, simplifying the process."
            },
            {
                id: 4,
                title: "Artisan",
                description: "Laravel offers a built-in tool named Artisan. This command-line tool helps in performing repetitive and tedious programming tasks, thus streamlining the process and making development faster."
            },
            {
                id: 5,
                title: "Blade Templating Engine",
                description: "The Blade templating engine of Laravel allows developers to use pre-defined templates to write specific code. It doesn't restrict developers from using plain PHP code in the views."
            },
            {
                id: 6,
                title: "Security",
                description: "Laravel takes security seriously and offers built-in features to protect web applications from common vulnerabilities like SQL injection, cross-site request forgery, and cross-site scripting."
            },
            {
                id: 7,
                title: "Migrations and Seeding",
                description: "Laravel’s migrations provide a version control system for your database. This allows teams to easily modify and share the application’s database schema. Seeders in Laravel allow you to populate your databases with sample data."
            },
            {
                id: 8,
                title: "Task Scheduling",
                description: "The scheduler, introduced in Laravel 5.0, offers a consolidated API over a variety of job scheduling services. It allows you to fluently and expressively define your command schedule."
            },
            {
                id: 9,
                title: "Dependency Injection",
                description: "Laravel supports dependency injection, making it easy for developers to manage class dependencies and conduct unit testing."
            },
            {
                id: 10,
                title: "Library & Modular",
                description: "Laravel is equipped with many pre-installed libraries. The Authentication library is easy-to-implement and comes with features like checking active users, password reset, Bcrypt hashing, and CSRF (Cross-site Request Forgery) protection."
            }
        ]
    },
]

export const hireLaravelDevFaqs = [
    {
        id: 1,
        question: "1. Can I hire dedicated Laravel developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Laravel developers, then you can hire dedicated Laravel developers on an hourly or project/task base."
    },
    {
        id: 2,
        question: "2. How much does it cost to hire Laravel developers?",
        answer: "The cost estimation for dedicated Laravel developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    },
    {
        id: 3,
        question: "3. Why should I hire Laravel developers?",
        answer: "The dedicated Laravel developers will work only for you to deliver outstanding results with the highest optimization. The team of Laravel developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 4,
        question: "4. Can I check the technical skill set of the dedicated Laravel developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Laravel developers before making a final move."
    },
    {
        id: 5,
        question: "5. What steps should I follow to Hire Laravel developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Laravel developers. Just provide project details and the technical expertise you need. The team of Laravel developers will begin working on your project based on your selection."
    }
]