import es3 from '../../../assets/pages/service/iot/es3.png';

export const embeddedSoftwareDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Computer programs designed for machine and device control are known as embedded programs. The operating system, which has limited memory and is tailored for certain hardware, is what makes it unusual. Support services for the efficient usage of next-generation hardware products are provided by embedded software. With regard to the software, the system has become ubiquitous and is meant for the present world. A lot of businesses like embedded systems and software since they are high-quality software. The computer program is reliable, affordable, easy to use, and much more. The software requires experience to provide a high-quality development process, and Alta Tech Mark is the most qualified to deliver the most effective method.",
            "Many organizations trust embedded software, which is the most widely used and popular kind of software development. because of its scalable experiences and economical approach. Smooth integration with any device is possible thanks to the interoperable software solution. Accurate results and system adaptation are features. In addition to maintaining software development best practices and standards, the software helps improve product performance."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Embedded Software Development Services:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Development of BSP and Embedded OS",
                description: "It is a set of support materials for unique hardware. The OS is simple to use and helpful. We assist in choosing the appropriate development software for the hardware and application and make sure it stays on course. Our professionals are knowledgeable in board support packages and the operating system."
            },
            {
                id: 2,
                title: "Firmware Upgrades",
                description: "When necessary, the software developer keeps an eye on and provides support for hardware systems and products. Firmware creation integrates the physical system with style, valuing each device and product. Nonetheless, a solid grasp of hardware design and development is essential for firmware creation, as is familiarity with both internal and external CPUs. We provide apps the greatest firmware development with smooth, creative user interfaces."
            },
            {
                id: 3,
                title: "API & Middleware Development",
                description: "The middleware layer and standardized API are used to provide portability for application code that is higher level. When it comes to development and flexibility, the computer software is quite trustworthy and scalable. It requires skilled instruction and is simple to use. The hardware is supported by the software, which also aids in tracking. It functions as a mediator between drives to assist link them. It offers various back-end development services and premium software for a variety of uses."
            },
            {
                id: 4,
                title: "Device Driver Development",
                description: "A device driver is used when surface development is required. Networking Hardware, Multimedia devices, wearables, and others are part of the Device Driver Development. Alta Tech Mark developers know when to use and include custom drivers for computer hardware. Linux, Android, Windows, and others are applied for the Device Driver Development in the Embedded system."
            }
        ]
    },
    {
        id: 3,
        title: "Tools for Embedded Software Development:",
        description: "",
        onlyBullets: true,
        data: [
            "Revisionist",
            "NetBeans",
            "Visual Studios",
            "PyCharm",
            "Arduino",
            "Eclipse",
            "Matlab",
            "Debugger"
        ],
        bulletsPic: es3
    },
    {
        id: 4,
        title: "Methods We Employ:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Product Planning",
                description: "It is essential to understand and plan the product, which is created from embedded software development. The planning will give you clarity and usage of the software. As the written computer software is dependable, it needs to plan before implementing it."
            },
            {
                id: 2,
                title: "Analysis",
                description: "There is no point in planning if there is a mistake while executing development. In the analysis step, the process goes through and cross-verify every detail. Later, after approval of analysis, the design is finalized."
            },
            {
                id: 3,
                title: "Testing and Deployment",
                description: "The testing is a pre-final process where before implementing it, it gets tested and checks whether it is appropriate. If there is any glitch or error, our expert team immediately solves it and provides an outstanding outcome."
            },
            {
                id: 4,
                title: "Care and Maintenance",
                description: "After regression testing, the process went ahead with the final system. Our developers observe everything and run the application with care and maintenance. Alta Tech Mark is known for providing a process with efficiency."
            }
        ]
    },
    {
        id: 5,
        title: "Why Choose Alta Tech Mark For Embedded Software Development?",
        descriptions: [
            "Alta Tech Mark has skilled expertise who have domain knowledge as well as depth insight regarding software development. We hold experience working with different industries and providing excellent Embedded Software Development. Our company uses the latest technology and offers specialized architectures and customized designs for the embedded system. The system we provide runs on operating programs as well as non-operating programs. We provide full-stack development crossing from the underlying device drivers to most applications. We provide multiple development models that are customer engaging and applicable to every system.",
            "We offer quality service for embedded software development and continue to offer product life cycles. Our factors and techniques make us stand out from others and hold the embedded software service providers. Our skilled experts' knowledge and services are unique and acknowledged by others. Alta Tech Mark provides several other IoT and Embedded software solutions. For More IoT and Embedded Services, Get in Touch With Us."
        ],
        onlyBullets: true,
        data: [
            "Project and process transparency",
            "Efficient analysis",
            "Seamless services",
            "Full-project documentation",
            "Unique approach",
            "Agile perspective",
            "Augment method"
        ]
    }
]