import img1 from '../../assets/pages/career/reasonsToWorkAt/1.png';
import img2 from '../../assets/pages/career/reasonsToWorkAt/2.png';
import img3 from '../../assets/pages/career/reasonsToWorkAt/3.png';

export const reasonsToWorkAtData = [
    {
        id: 1,
        src: img1,
        title: "Monthly Adventures",
        description: "We work and have fun, that’s how we stay ahead and keep our creative juices flowing!"
    },
    {
        id: 2,
        src: img2,
        title: "Idea Sharing",
        description: "Each teammate contributes in brainstorming and idea-sharing sessions at ALTA TECH."
    },
    {
        id: 3,
        src: img3,
        title: "Constant Learning",
        description: "ALTA TECH organizes monthly sessions by industry qualified coaches for its employees."
    }
]