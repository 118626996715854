import React from 'react';

// data
import { hireReactNativeDevData, hireReactNativeDevFaqs } from '../../../data/hireDev/hireReactNativeDev';
import reactNativeDev1 from '../../../assets/pages/hireDev/reactNativeDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireReactNativeDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="REACT NATIVE DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={reactNativeDev1}
            data={hireReactNativeDevData}
            faq={hireReactNativeDevFaqs}
        />
    )
}
