import pb3 from '../../../assets/pages/service/blockchainDev/pb3.png';

export const privateBlockchainDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "A private blockchain is a decentralized network that is created peer-to-peer by connecting every node to a central hub that oversees the whole system. Banks and other enterprises mostly employ private blockchains to facilitate seamless operations. The network validator typically sets it up, and each member has the last say over who can and cannot conduct transactions and be a part of the network. When a member seeks to join a private blockchain, the center point that appears on the network requests permission. Compared to the Bitcoin blockchain, transactions on private blockchains are simpler and quicker.",
            "An international consulting firm called Alta Tech Mark brings the finest team on board to create and design all services, from basic Blockchain architecture to complex systems.",
            "In order to satisfy your company needs, our experts are prepared to examine and work on your specifications as well as assist you in becoming acquainted with the potential of Blockchain technology."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Recognize Private Blockchain Benefits:",
        description: "",
        onlyBullets: true,
        data: [
            "Auditing Cryptographic Data",
            "Enhanced Productivity",
            "Enhanced Databases",
            "Manage the surroundings",
            "Faster and less expensive exchanges",
            "Enormous volume of data and files that may be exchanged and kept",
            "Transactions with less risk due to KYC authentication",
            "Heightened scalability and stability",
            "How Functions a Private Blockchain?"
        ]
    },
    {
        id: 3,
        title: "",
        description: "The network initiator must provide authority for the private blockchain network to operate. It's not available for everyone to provide control over access or transaction permissions to third parties. The financial, healthcare, and legal sectors employ private blockchains primarily for enterprise-level applications where increased TPS, scalability, and security are critical requirements.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Why Opt for Private Blockchain Development Services from Alta Tech Mark?",
        descriptions: [
            "The world's top blockchain development services are offered by Alta Tech Mark. Given that we provide a highly-secure private blockchain architecture with shared peers, we might be your best option for private blockchain development needs."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 5,
        title: "We pledge to you:",
        description: '',
        onlyBullets: true,
        data: [
            "Fully Decentralized Choices",
            "Get a cryptocurrency of your own",
            "Faster and less expensive exchanges",
            "Automatic creation of public keys",
            "Seek guidance on investments",
            "Make many identity and key value databases",
            "Monitoring accessibility throughout the whole network",
            "Adaptive command over every transaction"
        ],
        bulletsPic: pb3
    }
]