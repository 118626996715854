import React from 'react';

// data
import { hireFrontendDevData, hireFrontendDevFaq } from '../../../data/hireDev/hireFrontendDev';
import frontendDev1 from '../../../assets/pages/hireDev/frontendDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireFrontendDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="FRONT-END DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={frontendDev1}
            data={hireFrontendDevData}
            faq={hireFrontendDevFaq}
        />
    )
}
