import React, { useState, useEffect } from 'react';
import NorthIcon from '@mui/icons-material/North';
// import CircularProgress from '@mui/material/CircularProgress';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ScrollToTop() {
    const [showButton, setShowButton] = useState(false);
    const [borderLength, setBorderLength] = useState(0);

    const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrolled = (window.scrollY / scrollHeight) * 100;
        setBorderLength(scrolled);

        if (window.scrollY > 50) {
            setShowButton(true);
        }
        else {
            setShowButton(false);
        }
    };
    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`tw-w-[45px] tw-h-[45px] tw-cursor-pointer ${!showButton ? "tw-hidden" : ""}  tw-text-red-500 tw-grid tw-place-items-center tw-mr-[6px] tw-bg-white tw-rounded-full tw-mb-2`} onClick={scrollToTop}>
            <CircularProgressbar value={borderLength.toFixed()} className='tw-text-purple tw-relative tw-top-[15px]'
                styles={buildStyles({
                    pathColor: "#9065FD"
                })}
            />
            <NorthIcon sx={{ fontSize: '30px' }} className='tw-text-purple tw-relative tw-bottom-2' />
        </div>
    );
}
