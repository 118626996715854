import React from 'react';

// assets
import vr1 from '../../../../assets/pages/service/gameDev/vr1.jpeg';
import vr2 from '../../../../assets/pages/service/gameDev/vr2.png';
import vr3 from '../../../../assets/pages/service/gameDev/vr3.png';
import vr4 from '../../../../assets/pages/service/gameDev/vr4.png';
import { virtualRealityDevData } from '../../../../data/services/gameDevData/virtualRealityDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function VirtualRealityDev() {
    return (
        <ServicesWrapper
            title="VIRTUAL REALITY"
            descriptionCont="Employing game developers from a reputable firm that specializes in game creation may help you and every other player take your gameplay to the next level."
            img={vr1}
        >
            <DevGridWrapper images={[vr2, vr3, vr4]}>
                <DataMapper data={virtualRealityDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
