import React from 'react';

// data
import { hireIotArchitectData } from '../../../data/hireDev/hireIotArchitect';
import iot1 from '../../../assets/pages/hireDev/iot1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireIotSolutionArchitect() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="IoT DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={iot1}
            data={hireIotArchitectData}
            noFaq={true}
        />
    )
}
