import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// assets
import { hiringSteps } from '../../../data/hireDev/hiringSteps';

// components
import HiringStepCard from './hiringStepCard/HiringStepCard';
import HeaderText from '../../headerText/HeaderText';

export default function HiringSteps() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-py-[4vw] tw-px-[9.115vw]'>
            <HeaderText
                colorTitleEnd="HIRING"
                title='EASIER AND QUICK'
                description="Our group of specialists is well-versed in each of their specialized domains. Their strategy is innovative, cutting edge, and unmatched in the industry."
                className='tw-text-center tw-mb-8'
            />

            {screenWidth < 768 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {hiringSteps.map(hiringStep =>
                        <SwiperSlide key={hiringStep.id}>
                            <div className='tw-h-[450px]'>
                                <HiringStepCard
                                    id={hiringStep.id}
                                    title={hiringStep.title}
                                    description={hiringStep.description}
                                    inSwiper={true}
                                    bgColor={hiringStep.bgColor}
                                    src={hiringStep.src}
                                    className='tw-h-[90%]'
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
                :
                <div className='tw-grid tw-grid-cols-4 tw-gap-x-[1.25vw]'>
                    {hiringSteps.map(hiringStep => <HiringStepCard
                        key={hiringStep.id}
                        id={hiringStep.id}
                        title={hiringStep.title}
                        description={hiringStep.description}
                        bgColor={hiringStep.bgColor}
                        src={hiringStep.src}
                    />)}
                </div>
            }
        </div>
    )
}
