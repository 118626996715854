import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// assets
import { servicesData } from './servicesData';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import logo from '../../../assets/logo/logo.png';

export default function ServicesMenuItem(props) {
    // locals
    const [subLinkSelected, setSubLinkSelected] = useState(false);
    const [subLinkId, setSubLinkId] = useState(1);

    // functions
    const menuLinkHandler = (event) => {
        if (event.target.id === 'links') {
            if (!subLinkSelected) {
                setSubLinkSelected(false);
                props.menuLinkHandler(event)
            }
            else {
                setSubLinkSelected(false)
            }
        }
        else {
            setSubLinkId(event.target.id);
            setSubLinkSelected(true);
        }
    }

    return (
        <div className='tw-w-[80vw] tw-grid tw-gap-2 tw-text-lg tw-font-semibold tw-py-[2vw]'>
            <img src={logo} alt="logo" className='tw-w-[20vw] tw-px-2' />
            <div onClick={menuLinkHandler} id='links' className='tw-flex tw-w-full tw-gap-4 tw-p-[2vw] '>
                <ArrowRightIcon id='links' className='tw-rotate-180' />
                <h1 id='links'>Back</h1>
            </div>
            {!subLinkSelected ? servicesData.map(service => (
                <div key={service.id} onClick={menuLinkHandler} id={service.id} className='tw-flex tw-w-full tw-justify-between tw-p-[2vw] '>
                    <h1 id={service.id}>{service.value}</h1>
                    <ArrowRightIcon id={service.id} />
                </div>
            )) :
                <div div className='tw-w-[80vw] tw-grid tw-gap-2'>
                    {servicesData.filter(service => +service.id === +subLinkId)[0].children.map(service => (
                        <Link to={service.url} key={service.id} className=' tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-text-[#4741A6]'>{service.value}</Link>
                    ))}
                </div>
            }
        </div>
    )
}
