import React from 'react';

// assets
import contactUsBg from '../../assets/background/contactUsBg.png';

// components
import ContactUsComp from '../../components/contactUs/ContactUs';
import HeroTitleImage from '../../components/heroTitleImage/HeroTitleImage';

export default function ContactUs() {
    return (
        <div className=''>
            <HeroTitleImage
                title='Let’s build something'
                coloredTitle='great together'
                bg={contactUsBg}
                className="max-md:tw-hidden"
            />

            <ContactUsComp />
        </div>
    )
}
