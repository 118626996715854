import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// flags
import email from "../../assets/flags/email.png";
import user from "../../assets/flags/user.png";
import emailPrimary from "../../assets/flags/emailPrimary.svg";
import userPrimary from "../../assets/flags/userPrimary.svg";
import CloseIcon from "@mui/icons-material/Close";
import pakistan from "../../assets/flags/pak.png";
import london from "../../assets/flags/london.png";
import uae from "../../assets/flags/uae2.png";

// components
import ArrowButton from "../ui/arrowButton/ArrowButton";
import ForgetPasswordForm from "./forgetPasswordForm/ForgetPasswordForm";
import SimpleButton from "../ui/simpleButton/SimpleButton";

// redux
import { userLogin } from "../../store/userSlice/userSlice";
import { logout } from "../../store/userSlice/userSlice";

export default function ContactNav(props) {
  // globals
  const screenWidth = useSelector((state) => state.responsive.screenWidth);
  const { isAuthenticated } = useSelector((state) => state.user);

  // initializations
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // locals
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");

  // functions
  function isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  const loginHandler = () => {
    if (userData.email === "" || userData.password === "") {
      setMessage("Please fill all the fields");
    } else if (!isValidEmail(userData.email)) {
      setMessage("Invalid Email");
    } else {
      dispatch(userLogin(userData)).then((response) => {
        if (!response.error) {
          setShowLoginForm(false);
          setUserData({
            email: "",
            password: "",
          });
        }
      });
    }
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  // effects
  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }, [message]);

  return (
    <div
      className={`tw-flex ${
        props.show ? "" : "max-md:tw-hidden"
      } md:tw-flex-row tw-flex-col md:tw-items-center tw-items-start tw-justify-between md:tw-bg-darkPurple md:tw-text-white md:tw-py-2 md:tw-text-sm md:tw-px-[9.115vw] tw-relative`}
    >
      <div className="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center tw-items-start md:tw-gap-x-3 tw-gap-y-4">
        <div className="tw-cursor-pointer tw-flex tw-items-center tw-gap-x-1 tw-relative">
          <img
            src={london}
            alt="indian flag"
            width={screenWidth < 768 ? 25 : 35}
          />
          <p className="">+44 77236 621464</p>
        </div>
        <div className="tw-cursor-pointer tw-flex tw-items-center tw-gap-x-1 tw-relative" style={{margin: '0px 15px'}}>
          <img
            src={uae}
            alt="uae flag"
            width={screenWidth < 768 ? 25 : 35}
          />
          <p className="">+971 50 832 1179</p>
        </div>
        <div className="tw-cursor-pointer tw-flex tw-items-center tw-gap-x-1 tw-relative">
          <img
            src={pakistan}
            alt="indian flag"
            width={screenWidth < 768 ? 25 : 35}
          />
          <p className="">+92 21 3889 6008</p>
        </div>
        <div className="tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2" style={{margin: '0px 15px'}}>
          <img
            src={screenWidth < 768 ? emailPrimary : email}
            alt="flag"
            width={screenWidth < 768 ? 25 : 25}
          />
          <p className="">info@altatechmark.com</p>
        </div>
      </div>

      {isAuthenticated ? (
        <SimpleButton
          clickFunction={true}
          onClick={logoutHandler}
          text="Logout"
          noUrl={true}
        />
      ) : (
        <div
          className="tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer max-md:tw-mt-4"
          onClick={() => {
            screenWidth < 768
              ? navigate("/auth/login")
              : setShowLoginForm(!showLoginForm);
          }}
        >
          <img
            src={screenWidth < 768 ? userPrimary : user}
            alt="user"
            width={screenWidth < 768 ? 25 : 15}
          />
          <h1>Log In</h1>
        </div>
      )}

      <div
        className={`${
          showLoginForm ? "tw-block" : "tw-hidden"
        } tw-absolute tw-flex tw-flex-col tw-right-[9.115vw] tw-top-[100%] tw-z-[11] tw-text-darkPurple tw-py-4 tw-px-6 tw-w-[350px] tw-bg-lightPurple tw-gap-y-4`}
        style={{
          backgroundImage:
            "linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)",
        }}
      >
        <CloseIcon
          className="tw-ml-[90%] tw-cursor-pointer"
          onClick={() => setShowLoginForm(false)}
        />
        <h1 className="tw-text-5xl tw-font-semibold tw-text-center">Login</h1>
        <h1 className="tw-text-2xl tw-font-semibold tw-text-center tw-text-red-500">
          {message}
        </h1>
        <div className="tw-my-2"></div>
        <div className="tw-flex tw-flex-col tw-items-start tw-w-full">
          <h1 className="tw-font-semibold">Email</h1>
          <input
            type="text"
            placeholder="Enter your Email"
            className="tw-p-2 tw-rounded-lg tw-w-full tw-bg-transparent tw-border-[2px] tw-border-purple"
            value={userData.email}
            onChange={(event) =>
              setUserData({
                ...userData,
                email: event.target.value,
              })
            }
          />
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-w-full">
          <h1 className="tw-font-semibold">Password</h1>
          <input
            type="password"
            placeholder="Enter Password"
            className="tw-p-2 tw-rounded-lg tw-w-full tw-bg-transparent tw-border-[2px] tw-border-purple"
            value={userData.password}
            onChange={(event) =>
              setUserData({
                ...userData,
                password: event.target.value,
              })
            }
          />
        </div>
        <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center tw-gap-x-1">
            <input type="checkbox" name="" id="" />
            <h1 className="tw-text-xs">Remember me</h1>
          </div>

          <h1
            className="tw-text-blue-600 tw-text-xs tw-cursor-pointer"
            onClick={() => {
              setShowLoginForm(false);
              setShowForgetPasswordForm(true);
            }}
          >
            Forgot Password
          </h1>
        </div>

        <ArrowButton
          className="tw-bg-darkPurple tw-text-white tw-text-center"
          noUrl={true}
          text="Login"
          clickFunction={true}
          onClick={loginHandler}
        />
      </div>
      <div
        className={`${
          showForgetPasswordForm ? "tw-block" : "tw-hidden"
        } tw-absolute tw-flex tw-flex-col tw-right-[9.115vw] tw-top-[100%] tw-z-[11] tw-text-darkPurple tw-py-4 tw-px-6 tw-w-[350px] tw-bg-lightPurple tw-gap-y-4`}
        style={{
          backgroundImage:
            "linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)",
        }}
      >
        <CloseIcon
          className="tw-ml-[90%] tw-cursor-pointer"
          onClick={() => {
            setShowForgetPasswordForm(false);
            setShowLoginForm(false);
          }}
        />
        <ForgetPasswordForm />
      </div>
    </div>
  );
}
