import React from 'react';

// assets
import wc1 from '../../../../assets/pages/service/ecommerceDev/wc1.png';
import wc2 from '../../../../assets/pages/service/ecommerceDev/wc2.png';
import wc3 from '../../../../assets/pages/service/ecommerceDev/wc3.png';
import { wooCommerceDevData } from '../../../../data/services/ecommerceDevData/wooCommerceDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function WooCommerceDev() {
    return (
        <ServicesWrapper
            title="ECOMMERCE DEVELOPMENT WOOCOMMERCE"
            descriptionCont="Our E-Commerce website development professionals are experts. Alta Tech Mark developers provide high-quality, fast E-Commerce products. Our skilled staff develops E-Commerce websites utilizing cutting-edge, trustworthy technology. Magento, Shopify, BigCommerce, WooCommerce, and others"
            img={wc1}
        >
            <DevGridWrapper images={[wc2, wc3]}>
                <DataMapper data={wooCommerceDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
