import React from 'react';

// assets
import sd1 from '../../../../assets/pages/service/salesforceDev/sd1.jpeg';
import sd2 from '../../../../assets/pages/service/salesforceDev/sd2.png';
import { salesforceDevData1 } from '../../../../data/services/salesforceDevData/salesforceDevData1';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function SalesforceDev() {
    return (
        <ServicesWrapper
            title="SALESFORCE DEVELOPMENT"
            descriptionCont="The largest and most precious asset in your business is unquestionably your customers. Consequently, it is now crucial to build an efficient CRM system in order to meet their needs."
            img={sd1}
        >
            <DevGridWrapper img={sd2}>
                <DataMapper data={salesforceDevData1} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
