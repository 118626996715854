import React from 'react';

// data
import { hireIosDevData, hireIosDevFaqs } from '../../../data/hireDev/hireIosDev';
import iosDev1 from '../../../assets/pages/hireDev/iosDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireIosDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="IOS DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={iosDev1}
            data={hireIosDevData}
            faq={hireIosDevFaqs}
        />
    )
}
