export const laravelDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "An open-source PHP framework called Laravel is used to create web applications that adhere to the MVC architectural paradigm. One of the most well-known PHP frameworks, Laravel, was created primarily with the goal of enabling simple, economical coding syntax. These days, Laravel is considered to be the most popular PHP framework.",
            "It has remarkable characteristics and traits that increase production, efficiency, and competitiveness. It is sturdy and dependable. Laravel makes web application and website development, design, and customization quick and easy, enabling the production of high-end online applications.",
            "Our team of skilled Laravel developers at Alta Tech Mark is experienced in working on small, medium, and large-scale projects. We provide a wide range of services, including the construction of Laravel mobile applications and websites. We provide you the freedom to hire knowledgeable and experienced Laravel developers to work on projects that meet customer needs and improve their overall growth trajectory and conversion rate.",
            "With our help, you can quickly and affordably develop everything from simple applications to complex content management systems for eCommerce websites. Our Laravel developers are prepared to create solutions for any size or kind of application or website, all while adhering to the most recent coding standards and requirements. Making the most of the Laravel framework to its fullest, we provide reliable, fast solutions for online applications in a range of sectors. By following rapid business processes, we use Laravel's advantages to create corporate and eCommerce solutions that are unmatched."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Know The Benefits of Using Laravel",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Effective Authentication",
                description: "Laravel has the most powerful authentication process."
            },
            {
                id: 2,
                title: "Abstracts Complex Functionality",
                description: "Laravel extracts complex functionality by using simple commands."
            },
            {
                id: 3,
                title: "URL Routing Configuration",
                description: "User can open the required content with URL routing as all Laravel routes are described in the app/Http/routes.php file in the framework."
            },
            {
                id: 4,
                title: "Fast Caching",
                description: "Laravel makes your web-application very easy with backend caching."
            },
            {
                id: 5,
                title: "Stable",
                description: "One of the most important reasons for Laravel’s fame is that it is very stable and easy to maintain."
            }
        ]
    },
    {
        id: 2,
        title: "Get Our Laravel Development Services",
        description: "",
        onlyBullets: true,
        data: [
            "Personalized Laravel Creation",
            "Laravel Business Edition",
            "Laravel Migration of Data",
            "Develop a Laravel CMS",
            "Development of Web APIs",
            "Development of E-Commerce"
        ]
    },
    {
        id: 3,
        title: "Technical Expertise At Alta Tech Mark",
        description: "",
        onlyBullets: true,
        data: [
            "More than 20 committed Laravel Developers",
            "100% guarantee of product security",
            "using the newest technology",
            "global proficiency",
            "prompt delivery combined with economical options",
            "24 x 7 Upkeep and Assistance",
        ]
    },
    {
        id: 5,
        title: "dependable techniques",
        descriptions: [
            "Our skilled and experienced Laravel developers are excellent at creating feature-rich extensions and correctly mapping them, which guarantees quick performance. We create impactful web apps using Laravel, which is a popular framework among developers. Our team will help you create web applications with smooth user interfaces and striking interactions.",
            "When it comes to any development needs using this cutting-edge technology, we are the place to go. Using Laravel technology, our skilled Laravel developers have created outstanding websites and apps that have won praise from our clients time and time again."
        ],
        onlyBullets: false,
        data: []
    }
]