import React from 'react';

// assets
import pb1 from '../../../../assets/pages/service/blockchainDev/pb1.jpeg';
import pb2 from '../../../../assets/pages/service/blockchainDev/pb2.png';
import { privateBlockchainDevData } from '../../../../data/services/blockchainDevData/privateBlockchainDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function PrivateBlockchainDev() {
    return (
        <ServicesWrapper
            title="BLOCKCHAIN DEVELOPMENT PRIVATE BLOCKCHAIN"
            descriptionCont="A private blockchain is a decentralized network that is created peer-to-peer by connecting every node to a central hub that oversees the whole system. Banks and other enterprises mostly employ private blockchains to facilitate seamless operations."
            img={pb1}
        >
            <DevGridWrapper img={pb2}>
                <DataMapper data={privateBlockchainDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
