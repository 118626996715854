import d3 from '../../../assets/pages/service/devops/d3.png';
import d4 from '../../../assets/pages/service/devops/d4.png';

export const devopsDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Ops stands for information technology operations, while Dev represents for software development. Reducing the system's development life cycle and ensuring continuous delivery of high-quality software are the goals of implementing DevOps. Additionally, it aids in defining company goals and maintaining regular upgrades and feature alignment. It supports many tools and aids in the development of cross-functional activities. Products are improved and evolved at an accelerating rate. With DevOps software, an organization's speed increases and its marketability increases. A compressive tool that enhances the system throughout the development life cycle is the DevOps toolchain. It takes time to modify the program since there are many tools, dynamic changes, technologies, procedures, etc. Compared to other software development, the impression is greater. It offers a dependable setting with quicker cooperation between advances and operations.",
            "The software is used to improve production, logistics, and other aspects of the industry's value chain in order to create operational efficiency. DevOps makes use of cloud computing, automation, monitoring, and teamwork. Reducing expenses and using creativity to get recognition are two of DevOps' well-known traits. Teams are brought together by DevOps to concentrate on improving development efficiency and optimization; it also increases operational dependability. Consumers benefit from frequent communication, higher-quality services, more facilities, and more thanks to software development.",
            "By resolving the problem, it lessens the organization's burden and satisfies consumer demands. Alta Tech Mark offered the finest development and infrastructure lifecycle by using the DevOps paradigm. Our development and quality assurance teams make sure the software follows the production cycle and organizational structure."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Services We Provide for DevOps:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Process Automation",
                description: "In order to simplify the development process for the company, DevOps and Automation are two crucial elements. The whole pipeline process, including test cases, quality assurance, unfold builds, code creation, and many more steps. We provide the newest in process automation for performance and operation."
            },
            {
                id: 2,
                title: "Support Monitoring",
                description: "Keeping an eye on DevOps is essential, particularly when it's operating. Where a monitor is supported and controlling options are offered, we provide the necessary tools. Alta Tech Mark keeps systems operating at 100% while resolving issues before they arise."
            },
            {
                id: 3,
                title: "Cloud Management",
                description: "We provide top-notch cloud management capabilities across various cloud platforms. We deliver stable and safe resource management together with long-term company success via software development. The cloud management components include the Storage Clouding Monitoring, Reporting Cloud Migration services, and Cloud Deployment Network. You will be able to create dependable and speedier apps with our assistance."
            },
            {
                id: 4,
                title: "Upgrade Pipeline Quality",
                description: "It guarantees that the test is conducted at the appropriate stage of the pipeline and helps in improving pipeline automation. We provide pipeline quality that is updated, accurate, and quicker."
            },
            {
                id: 5,
                title: "DevOps Consulting Services",
                description: "Consulting services are one of the DevOps services that are used most often. It uses consultancy services to improve the software's market share. You may get the right advice from the consulting service for a variety of topics, including process, development, management, evaluation, automation, and much more."
            },
            {
                id: 6,
                title: "First Framework Creation",
                description: "We assist you in using the system and integrating your current tools. The extensive ecosystem of open sources and licensed technologies is what creates the framework. The first framework construction guarantees that the step is guided correctly and follows the method."
            },
            {
                id: 7,
                title: "Capacity Management",
                description: "With regard to capacity management, it works similarly to other services in that cloud resources make sure that business workloads are appropriately scaled and reasonably priced. In order to assist regulate and manage the work, we provide capacity management, which is necessary to know before serving in the company process."
            },
            {
                id: 8,
                title: "Environment Management",
                description: "You can operate your server, keep your code up to date, be customer-ready, and automate platforms with ease with the aid of environment management services. The management contributes to the security of the DevOps process and management.",
            }
        ]

    },
    {
        id: 3,
        title: "",
        description: "",
        onlyBullets: true,
        bulletsPic: d3,
        data: [
            "Enhance Productivity",
            "Resolve problems before they arise",
            "Process Automation",
            "Sustain Consistency",
            "Faster Pace of the Development",
            "Improve Operating System",
            "Less Complex",
            "Cost-Effective",
            "Reduce time-consumption",
            "Dynamic Changes"
        ]
    },
    {
        id: 4,
        title: "Why Choose Alta Tech Mark For DevOps?",
        descriptions: [
            "Alta Tech Mark has successfully delivered multiple DevOps solutions and services. Our services contain quality and consistency for the operation and development system. We provide multiple practices, technologies, and unique techniques for DevOps. Alta Tech Mark concentrates on continuous deployment, integration, process improvement, and more. We monitor the work and facilities it to optimize all IT operations. Our service carter robust system that provides results in real-time, faster, and efficiently.",
            "Alta Tech Mark developers gain insightful knowledge of DevOps and software development. Our versatile technologies help companies to save costs and time. Our skilled experts research, practice, and implement new solutions and technologies for transforming your business. We evolve the DevOps with your business and bring the best standard outcomes."
        ],
        onlyBullets: true,
        data: [
            "Transparency in the Process",
            "Excellent Essence",
            "Integrated Approach",
            "High-quality Applications",
            "Diverse Automation"
        ],
        bulletsPic: d4
    }
]