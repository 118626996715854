import ida3 from '../../../assets/pages/service/iot/ida3.png';
import ida4 from '../../../assets/pages/service/iot/ida4.png';

export const iotDashboardAnalyticsDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "IoT Dashboards is an appealing way to analyse data with visualisation. IoT integrated with embedded analytics on the Dashboard. IoT Dashboard and Analytics is quite helpful to visualise data and information with charts, graphs, tables, and maps. IoT analytics allows companies to extract actionable insights from data produced by the connected devices to the IoT cloud. The data is received from IoT devices which will appear on the mobile device or computer. Using Machine Learning, Artificial Intelligence, and the Internet of Things, users can access processes, manage, and monitor. Dashboards enable the control of every aspect of connected devices and perspective through the visualisation of data devices.",
            "Dashboards are easily customised without any disruptions and process device data for further process. Every aspect of connected devices, complexity, everything has been made simpler with IoT Dashboard and Analytics. User interference helps users interact with other connected devices, and IoT dashboards are the primary function of IoT platforms. IoT Analytics has benefits of IoT insights which help to obtain analytics solutions. It is applied with IoT Analytics tools and needs to set up the launch, after-launch, and evolution way."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Benefits Of Using IoT Dashboard And Analytics:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Real-time Data",
                description: "The IoT Dashboard and Analytics solutions are provided in real-time. It helps get quick and efficient data and information, and the analytics helps collect relevant information and insights. The real-time data enables one to react to occurred developments or problems and take real-time decisions."
            },
            {
                id: 2,
                title: "Powerful Monitoring",
                description: "The IoT tools provide a powerful monitor which helps to obtain insight data. Controlling support to monitor and make necessary changes. The technology monitors the inside and outside by using a variety of IoT devices and sensors."
            },
            {
                id: 3,
                title: "Make Complicated Simpler",
                description: "The IoT technology makes things simpler and connected most effectively. With any complex issues, IoT Dashboard and Analytics helps to unsolve the problem and bring the easiest form of the objective. With analysing, track, and report the data to make complicated things more manageable."
            },
            {
                id: 4,
                title: "Unfolds Opportunities",
                description: "The IoT technology and devices uncover several opportunities. With the simplest objective, the IoT Dashboards and Analytics brings the data to many ways. By classifying the data and analysis, the Dashboard shows how the data is used appropriately."
            },
            {
                id: 5,
                title: "Notify Trends",
                description: "The IoT Analytics and Dashboard notify about the latest trends in the market. Moreover, it helps to run and create new trends for the industry. The trends will help the company to sustain itself in the present market and operate the business efficiently."
            }
        ]
    },
    {
        id: 3,
        title: "",
        description: "If you choose the incorrect technology, creating a thorough report/dashboard is a hassle. Similarly, you will not get significant information if you utilise the incorrect chart, graph, report, and map for your unique application or use case. Managing client information and devices placed on customer premises. It's not right for all your consumers to have the same static Dashboard; thus, each of your clients should have their Dashboard, which you can monitor or oversee.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Factors Of IoT Dashboards And Analytics:",
        description: "",
        onlyBullets: true,
        data: [
            "New device equipment",
            "Gather relevant data and information",
            "Visualise data device",
            "Track location and status devices",
            "Control credentials and configuration",
            "Dispatch commands",
            "Analyse data device",
            "Establishment of smart device"
        ],
        bulletsPic: ida3
    },
    {
        id: 5,
        title: "Technologies We Use For IoT Dashboards and Analytics:",
        description: "",
        onlyBullets: true,
        data: [
            "Big Data",
            "Machine Learning",
            "Data Visualization",
            "SQL",
            "AWS",
            "MQTTRoute Dashboard",
            "TCP server Dashboard",
            "Pace DPR Dashboard",
            "IoT Platform Dashboard"
        ],
        bulletsPic: ida4
    },
    {
        id: 6,
        title: "Why Opt for IoT Dashboards and Analytics from Alta Tech Mark?",
        description: "The skilled team of Alta Tech Mark helps to customise dashboards and make them to the top. They ensure that the user can operate it without any flaws or disruption in the IoT processor device. We gather tons of data from the device within a minute and manage the data in IoT Analytics. Meanwhile, allow IoT dashboards to access with a simple URL through any standard browser globally. Our proficient developers with insight knowledge help to visualise the data with efficiency and make the process effortless. Alta Tech Mark is always there to solve queries or complexity and help to accomplish the client's goal.",
        onlyBullets: true,
        data: [
            "Robust Strategies",
            "Standard Browser",
            "Seamless service",
            "Customization and Personalization",
            "Real-time solving",
            "Quick and Efficient Operation",
            "Unsolved Complicate Issues"
        ]
    }
]