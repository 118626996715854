import pd2 from '../../../assets/pages/service/corporateBranding/pd2.png';

export const packagingDesignDevData = [
    {
        id: 1,
        title: "",
        description: "Packaging design is a dynamic blend of form and function, serving as the interface between product and consumer. It encompasses not just the physical container but also the graphics, colors, and typography that adorn it. Beyond mere protection, packaging is a brand's silent ambassador on store shelves, speaking volumes before a product is even accessed. It holds the power to attract, inform, and persuade. Today's packaging design transcends the basics; it embeds stories, sustainability, and user experience. With eco-consciousness on the rise, materials like biodegradable plastics and recycled cardboard are becoming prominent. Simultaneously, designs are being innovated for reusability and minimal waste. QR codes and augmented reality integrations are modern additions, providing a digital bridge from the physical product. In essence, packaging design is not just about encasing but enhancing the product's value, ensuring it resonates both visually and ethically with consumers. It's a craft that marries aesthetics with responsibility and innovation.",
        onlyBullets: false,
        data: [],
        sectionPicRow: true,
        sectionPic: pd2,
    }
];

export const componentsOfPackagingDesignDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Structural Design",
                description: "This pertains to the physical shape and functionality of the packaging. It considers protection of the product, stackability, transport logistics, and user convenience. Materials can range from corrugated cardboard to PET plastics, depending on the product and sustainability requirements."
            },
            {
                id: 2,
                title: 'Graphic Design',
                description: "This includes the visual and tactile elements of the package – colors, typography, imagery, and finishes. This aspect plays a crucial role in brand representation and consumer attraction."
            },
            {
                id: 3,
                title: "Information Design",
                description: "Legally mandated and supplementary information, like ingredients, usage instructions, barcodes, and safety warnings, are included here."
            },
            {
                id: 4,
                title: "Sustainability",
                description: "With growing environmental concerns, the use of eco-friendly materials and processes, as well as designs that minimize waste, have become integral to modern packaging design."
            }
        ]
    }
];

export const principlesOfPackagingDesignDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Protection",
                description: "The primary role of packaging is to safeguard the product from damage, contamination, and tampering."
            },
            {
                id: 2,
                title: "Communication",
                description: "Packaging should effectively communicate the brand's identity, product benefits, and usage instructions."
            },
            {
                id: 3,
                title: "Attraction",
                description: "An appealing package design can differentiate a product on the shelves, capturing consumers' attention."
            },
            {
                id: 4,
                title: "Functionality",
                description: "Beyond aesthetics, the package must be easy to open, reseal, store, and dispose of."
            }
        ]
    }
];

export const processOfPackagingDesignDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Prototyping",
                description: "Before finalizing a design, prototypes are created to test the package's functionality, durability, and visual appeal."
            },
            {
                id: 2,
                title: "Die-Cutting",
                description: "Custom dies are crafted to cut packaging materials into specific shapes, ensuring consistency in production."
            },
            {
                id: 3,
                title: "Printing",
                description: "Methods like offset, digital, or flexographic printing are chosen based on design complexity and production volume. Special finishes such as embossing, foiling, or UV coating can also be added."
            },
            {
                id: 4,
                title: "Mockup & 3D Software",
                description: "Tools like Adobe Dimension, Esko Studio, or Cinema 4D allow designers to create realistic 3D mockups of packaging designs, facilitating client reviews and revisions."
            }
        ]
    }
];