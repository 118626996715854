import React from 'react';

// assets
import vi1 from '../../../../assets/pages/service/corporateBranding/vi1.png';
import { visualIdentityDevData, componentsOfVisualIdentityDevData, principlesOfVisualIdentityDevData, processOfVisualIdentityDevData } from '../../../../data/services/corporateBranding/visualIdentityDevData';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';
import CaseStudies from '../../../../components/home/caseStudies/CaseStudies';
import HeaderText from '../../../../components/headerText/HeaderText';

export default function VisualIdentityDesign() {
    return (
        <ServicesWrapper
            title="VISUAL IDENTITY DESIGN"
            descriptionCont="Some companies use an actual style guide to keep their brand looking consistent. If you're just getting started with design, it's OK to take a more casual approach. The main components of visual identity are logo, color, typography, and images."
            img={vi1}
        >
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={visualIdentityDevData} />

                <HeaderText 
                    title="Components of"
                    colorTitleEnd="Visual Identity"
                    className="tw-text-center tw-mb-8 md:tw-mt-[11.458vw]"
                />
                <DataMapper data={componentsOfVisualIdentityDevData} />

                <HeaderText 
                    title="Principles of"
                    colorTitleEnd="Visual Identity Design"
                    className="tw-text-center tw-mb-8 md:tw-mt-16"
                />
                <DataMapper data={principlesOfVisualIdentityDevData} />

                <HeaderText 
                    title="The"
                    colorTitleEnd="Process"
                    className="tw-text-center tw-mb-8 md:tw-mt-16"
                />
                <DataMapper data={processOfVisualIdentityDevData} />

                <h1 className='md:tw-text-base tw-text-sm tw-mt-[2vw]'>visual identity design is not just about making a brand look good; it's about ensuring that the brand communicates its essence effectively and consistently to its audience. It's a blend of art and science, intuition, and analysis. When executed proficiently, it can elevate a brand, making it both memorable and influential in its market.</h1>
            </div>

            <CaseStudies colorTitleStart="PORTFOLIO" title=" " />
        </ServicesWrapper>
    )
}
