import cw3 from '../../../assets/pages/service/blockchainDev/cw3.png';
import cw4 from '../../../assets/pages/service/blockchainDev/cw4.png';

export const walletDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "The financial world is seeing a boom in cryptocurrency, which is important for the future of finance. The foundation of cryptocurrency is the development of cryptocurrency wallets. It is a feature of software that enables users to purchase, audit, and carry out digital transactions in many currencies. The introduction of cryptocurrency wallets to the market is a result of the growing demand for cryptocurrency. Exchange and tracking of cryptocurrency transactions inside the wallet are aided by it. Wallets for cryptocurrencies operate similarly to other types of wallets. It is thus marketed for financial digital. For someone using cryptocurrency for the first time or as a newbie investor, it is a good option. Wallets for cryptocurrencies are dependable and safe. Stated differently, it is a readily accessible blend of regular wallets and banks across many platforms.",
            "A cryptocurrency development business called Alta Tech Mark makes it possible for wallets to hold your private information and controllable actions. We provide a smooth experience, scalable investment, and an approach that is easy to utilize. Making use of the cryptocurrency wallet's features and UI is beneficial. We provide the simple integration of many payment methods. We help keep a certain quantity of money organized and safe in the wallet. Additionally, it will include a publicly accessible digital code linked to a specific wallet address in the cryptocurrency wallet. We support companies in properly maintaining their cryptocurrency wallets and following professional advice."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Utilizing Technology for Cryptocurrency Wallets:",
        description: "",
        onlyBullets: true,
        data: [
            "NodeJS",
            "MySQL",
            "JavaScript",
            "React Native",
            "Flutter",
            "Ethereum",
        ],
        bulletsPic: cw3
    },
    {
        id: 3,
        title: "Cryptocurrency Wallet Types That We Provide:",
        description: "",
        onlyBullets: true,
        data: [
            "Mobile Wallet",
            "Web Wallet",
            "Hardware Wallet",
            "Desktop Wallet",
        ],
        bulletsPic: cw4
    },
    {
        id: 4,
        title: "Wallet Development Services for Cryptocurrencies That We Offer:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Multi-Crypto Billing System",
                description: "With the use of blockchain technology, we provide a multi-currency wallet service to facilitate currency purchases and sales. The method is distinct and is mostly used for virtual currency purchases and sales. The assistance is really helpful and inventive."
            },
            {
                id: 2,
                title: "Creation of Crypto Coins",
                description: "Blockchain technology is used in the Crypto currency generation service to facilitate coin transactions. It is universally functional and accepted. Because there is just one cryptocurrency and many advantages, buying and trading are thus easier to handle and more convenient."
            },
            {
                id: 3,
                title: "Peer-to-Peer Exchanges for Cryptocurrencies",
                description: "We provide peer-to-peer cryptocurrency transaction services for wallets that hold data. It makes it possible for peers to share assets or data in a decentralized manner. Third parties or mediators are not needed or required to be involved."
            },
            {
                id: 4,
                title: "Building a Bitcoin Wallet",
                description: "One of the most important components of cryptocurrency is Bitcoin, and we provide a Bitcoin wallet creation service for effective support. To store secret transaction keys, our professionals design strong and creative wallets. It is useful for distinct wallet services as well."
            },
            {
                id: 5,
                title: "ICO Administration Services",
                description: "We provide dynamic services for developing cryptocurrency wallets and ICOs (Initial Coin Offerings). It streamlines transactions and is easy to use. Token models for cryptocurrencies also aid in their execution. Its goal is to efficiently enhance the volume of transactions."
            },
            {
                id: 6,
                title: "Crypto Mining of Coins",
                description: "Services for mining cryptocurrency coins assist with centralized token operations. It is renowned for having a secure database and offering assistance in preventing fraud. The currency mining services concentrate on currencies that can trade with other people in an effective manner."
            },
            {
                id: 7,
                title: "Transfers Using Cryptocurrency Wallets",
                description: "We provide dependable and safe services for cryptocurrency wallet transactions. Its easy-to-use interface makes financial transactions easier to complete. Moreover, it uses Blockchain technology to provide precise data value."
            }
        ]
    },
    {
        id: 5,
        title: "Why Should I Use Alta Tech Mark To Develop Cryptocurrency Wallets?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Expert Group",
                description: "Our team comprises proficient individuals with specific knowledge in developing cryptocurrency wallets. In order to produce a cryptocurrency wallet, they have used Blockchain technology and taken advantage of the finest cryptocurrency features. The group is really trustworthy and responsible."
            },
            {
                id: 2,
                title: "Newest Tools And Technology",
                description: "Blockchain technology is going to revolutionize the financial industry, and we at Cryptocurrency Wallet Development are not undervaluing technology for our customers. To make the most of the platform's many uses, we use the newest and greatest tools and technology. Our specialists use cutting-edge equipment and technology to get the greatest results."
            },
            {
                id: 3,
                title: "Novel Method",
                description: "With the help of our specialists' special methodology, we produce cryptocurrency wallet solutions that are both extremely scalable and safe. Its lively and flawless quality makes firms stand out and thrive. Additionally, it offers a flawless user experience while using our services for developing cryptocurrency wallets."
            },
            {
                id: 4,
                title: "24/7 Upkeep and Assistance",
                description: "We guarantee full assistance for the creation of cryptocurrency wallets via our service. Our staff always has answers and can provide prompt assistance. Any question or issue concerning the procedure, the development, or anything else related will be promptly resolved. We also make sure that there are no errors and that the quality is maintained."
            },
            {
                id: 5,
                title: "Economy of scale",
                description: "We maintain our quality throughout the procedure and provide the best pricing on the market. Because of this, Alta Tech Mark does excellent work and ensures that there is no delivery compromise while producing the most lucrative outcome possible for cryptocurrency wallet creation."
            }
        ]
    }
]