import React from 'react';

// assets
import ed1 from '../../../../assets/pages/service/blockchainDev/ed1.jpeg';
import ed2 from '../../../../assets/pages/service/blockchainDev/ed2.png';
import { exchangeDevData } from '../../../../data/services/blockchainDevData/exchangeDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function ExchangeDev() {
    return (
        <ServicesWrapper
            title="BLOCKCHAIN DEVELOPMENT EXCHANGE"
            descriptionCont="The Blockchain records cryptocurrency transactions, a major financial trend. Changing from centralized money to decentralized value exchange. Buyers and sellers may trade coins."
            img={ed1}
        >
            <DevGridWrapper img={ed2}>
                <DataMapper data={exchangeDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
