import db2 from '../../../assets/pages/service/corporateBranding/db2.png';

export const digitalBrandingDevData = [
    {
        id: 1,
        title: "",
        description: "Digital branding, in the contemporary world, represents the convergence of technology and marketing, a melding of traditional branding strategies with the vast, dynamic possibilities of the digital realm. In essence, it's the process of crafting, establishing, and maintaining a brand's presence in the virtual ecosystem where most consumers now spend significant portions of their day. Digital branding goes beyond mere online advertising; it encapsulates everything from the aesthetics and functionality of a company's website, to their social media persona, to the very tone and nature of their email communications. The digital space, with its diverse platforms and interfaces, demands a unique approach to branding that is both consistent with the core identity of a business and flexible enough to adapt to the rapidly changing digital landscape. Yet, the core principles remain timeless: clarity, authenticity, and emotional connection. In the age of the internet, where customers' attention spans are fleeting and competition is but a click away, the significance of effective digital branding cannot be overstated. It's not just about visibility; it's about creating a memorable, meaningful experience for users that engenders trust and loyalty.",
        sectionPicRow: true,
        sectionPic: db2,
        onlyBullets: false,
        data: []
    }
];

export const componentsOfDigitalBrandingDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Logo Design",
                description: "The logo serves as the central element of any visual identity. It’s a distinctive graphic or typographic solution that provides immediate recognition for a brand. A well-designed logo should be scalable, adaptable, and reflective of the brand’s core values.",
            },
            {
                id: 2,
                title: "Color Palette",
                description: "Colors evoke emotions and associations. The choice of a brand’s color palette is based on psychological and cultural considerations. For instance, blue often represents trust and professionalism, while red can signify passion or urgency.",
            },
            {
                id: 3,
                title: "Typography",
                description: "This encompasses the fonts and typographic treatments used in brand materials. The typography chosen should be legible across different mediums and consistent with the brand’s voice.",
            },
            {
                id: 4,
                title: "Imagery and Graphics",
                description: "This includes photographs, illustrations, icons, and other graphical elements. They should be consistent in style and tone, complementing the brand's message.",
            }
        ]
    }
];

export const principlesOfDigitalBrandingDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Website Design & Development",
                description: "The digital storefront for any brand. A mobile-responsive, user-friendly, and visually appealing website fosters brand trust and recognition."
            },
            {
                id: 2,
                title: "SEO (Search Engine Optimization)",
                description: "Optimizing a brand’s digital assets ensures they appear prominently in search engine results, increasing organic visibility and credibility."
            },
            {
                id: 3,
                title: "Content Strategy",
                description: "Curated content delivered via blogs, videos, infographics, podcasts, or webinars. It's the digital voice of the brand, essential for engagement and value delivery."
            },
            {
                id: 4,
                title: "Social Media Presence",
                description: "Platforms like Instagram, Facebook, Twitter, and LinkedIn allow brands to interact with their audience in real-time, fostering community and brand loyalty."
            },
            {
                id: 5,
                title: "Digital Ads",
                description: " PPC (Pay-Per-Click), display ads, and social media ads, target potential customers based on their online behaviors, demographics, and interests."
            }
        ]
    }
];

export const emergingTechnologiesDigitalBrandingDevData = [
    {
        id: 1,
        title: "",
        description: "",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "AI & Chatbots",
                description: "Provide real-time assistance to website visitors, improving user experience and collecting data for personalized marketing strategies."
            },
            {
                id: 2,
                title: "AR (Augmented Reality) and VR (Virtual Reality)",
                description: "These offer immersive brand experiences, particularly useful for industries like real estate, gaming, and e-commerce."
            }
        ]
    }
];