import shomailKazmi from '../../assets/pages/aboutUs/team/shomailKazmi.jpeg';
import sarahHashim from '../../assets/pages/aboutUs/team/sarahHashim.jpeg';
import mohammadNoman from '../../assets/pages/aboutUs/team/mohammadNoman.jpeg';
import rayyanHusain from '../../assets/pages/aboutUs/team/rayyanHussain.jpeg';
import areeba from '../../assets/pages/aboutUs/team/areeba.jpeg';
import asadAhmad from '../../assets/pages/aboutUs/team/asadAhmad.jpeg';

export const ourTeamData = [
    {
        id: 1,
        src: shomailKazmi,
        name: "Shomail Kazmi",
        role: "Co-Founder & CEO"
    },
    {
        id: 2,
        src: sarahHashim,
        name: "Sarah Hashim",
        role:"Sales & Marketing Manager"
    },
    {
        id: 3,
        src: mohammadNoman,
        name: "Muhammad Noman",
        role: "React Native Developer"
    },
    {
        id: 4,
        src: rayyanHusain,
        name: "Rayan Hussain",
        role: "Senior Taxation & Finance Manager"
    },
    {
        id: 5,
        src: areeba,
        name: "Areeb .S",
        role: "Full-Stack Developer"
    },
    {
        id: 6,
        src: asadAhmad,
        name: "Asad Ahmed",
        role: "Senior Engineer"
    }
]