import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import 'intl-tel-input';

// components
import Select from 'react-select';

// assets
import profile from '../../../assets/pages/services/formIcons/profile.svg';
import email from '../../../assets/pages/services/formIcons/email.svg';
import phone from '../../../assets/pages/services/formIcons/phone.svg';
import whatsapp from '../../../assets/pages/services/formIcons/whatsapp.svg';
import budget from '../../../assets/pages/services/formIcons/budget.svg';
import msg from '../../../assets/pages/services/formIcons/msg.svg';

// components
import ArrowButton from '../../ui/arrowButton/ArrowButton';

// redux
import { contactUs } from '../../../store/contactUsSlice/contactUsSlice'

export default function FreeQuoteForm(props) {
    // initializations
    const dispatch = useDispatch();

    // locals
    const options = window.intlTelInputGlobals.getCountryData().map(code => {
        return {
            value: `+${code.dialCode}`,
            label: (
                <div className="tw-flex tw-items-center tw-gap-x-2">
                    <img src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code.iso2.toUpperCase()}.svg`} alt="" style={{ width: '20px', height: '20px' }} />
                    <h1>+{code.dialCode}</h1>
                </div>
            )
        }
    });
    const [contactUsData, setContactUsData] = useState({
        name: '',
        identifiedAs: '',
        companyName: "",
        email: '',
        phoneCountryCode: '',
        phoneNumber: '',
        whatsappCountryCode: '',
        whatsappNumber: '',
        projectBudget: 1000,
        message: '',
        file: null,
        subscribedToNewsLetter: true,
    })
    const [sum, setSum] = useState(0);

    // functions
    const changeHandler = (event) => {
        setContactUsData({
            ...contactUsData,
            [event.target.name]: event.target.value,
        })
    }

    const submitHandler = () => {
        if (+sum === 6) {
            dispatch(contactUs({ contactUsData }));
        } else {
            alert("Please enter correct answer");
        }
    }

    return (
        <div className={`tw-grid tw-grid-cols-12 tw-shadow-lg tw-rounded-lg tw-p-[3.12vw] tw-gap-[25px] tw-bg-white ${props.className}`}>
            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={profile} alt="profile" />
                <input value={contactUsData.name} name="name" onChange={changeHandler} type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Name*' />
            </div>

            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={email} alt="icon" />
                <input value={contactUsData.email} name="email" onChange={changeHandler} type="email" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='E-mail*' />
            </div>

            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw] tw-gap-x-2`}>
                <img src={phone} alt="icon" />
                <Select options={options}
                    onChange={(event) => setContactUsData({
                        ...contactUsData,
                        phoneCountryCode: event.value,
                    })}
                    placeholder="Country Code"
                    styles={{
                        control: (base) => ({
                            ...base,
                            border: '0px !important',
                            outline: '0px !important',
                            boxShadow: 'none !important'
                        })
                    }}
                    className='tw-w-[65%]'
                />
                <input name='phoneNumber' value={contactUsData.phoneNumber} onChange={changeHandler} type="number" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Contact*' />
            </div>

            <div className='md:tw-col-span-6 tw-col-span-12 tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]'>
                <img src={whatsapp} alt="icon" />
                <Select options={options}
                    placeholder="Country Code"
                    onChange={(event) => setContactUsData({
                        ...contactUsData,
                        whatsappCountryCode: event.value,
                    })}
                    styles={{
                        control: (base) => ({
                            ...base,
                            border: '0px !important',
                            outline: '0px !important',
                            boxShadow: 'none !important'
                        })
                    }}
                    className='tw-w-[65%] tw-borer-none'
                />
                <input type="text" name="whatsappNumber" value={contactUsData.whatsappNumber} onChange={changeHandler} className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none tw-border-none' placeholder='Whatsapp' />
            </div>

            <div className='md:tw-col-span-6 tw-col-span-12 tw-flex tw-items-center tw-w-full tw-border-slate-300'>
                <Select placeholder="How do you identify yourself?" options={[
                    { value: "Business Owner", label: "Business Owner" },
                    { value: "Representative of an organization", label: "Representative of an organization" },
                    { value: "Hobbyist", label: "Hobbyist" },
                    { value: "Student", label: "Student" },
                    { value: "Researcher", label: "Researcher" },
                    { value: "Enthusiast", label: "Enthusiast" },
                    { value: "Other", label: 'other' },
                ]}
                    onChange={(event) => setContactUsData({
                        ...contactUsData,
                        identifiedAs: event.value,
                    })}
                    styles={{
                        control: (base) => ({
                            ...base,
                            borderTop: '0px !important',
                            borderLeft: '0px !important',
                            borderRight: '0px !important',
                            outline: '0px !important',
                            boxShadow: 'none !important'
                        })
                    }}
                    className='tw-w-[100%]'
                />
            </div>

            <div className={`${props.mini ? 'tw-col-span-12' : 'md:tw-col-span-6 tw-col-span-12'} tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]`}>
                <img src={profile} alt="profile" />
                <input value={contactUsData.companyName} name="companyName" onChange={changeHandler} type="text" className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Company Name' />
            </div>

            <div className='tw-col-span-12 tw-flex tw-flex-col tw-items-start tw-w-full tw-px-[0.975vw] tw-gap-[15px]'>
                <div className='tw-flex tw-items-center tw-gap-x-[25px]'>
                    <img src={budget} alt="icon" />
                    <h1>Project Budget: $ {contactUsData.projectBudget}</h1>
                </div>
                <input type="range" name="projectBudget" onChange={changeHandler} id="" value={contactUsData.projectBudget} className='tw-w-full range-field' min='1000' max='200000' step='1000' />
            </div>

            <div className='tw-col-span-12 tw-flex tw-items-start tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]'>
                <img src={msg} alt="icon" className='tw-mt-[6px]' />
                <textarea onChange={changeHandler} value={contactUsData.message} name='message' className='tw-w-full tw-py-[5px] tw-px-[0.975vw] tw-outline-none' placeholder='Your Message' />
            </div>

            <div className=' tw-col-span-12 tw-flex tw-items-center tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw]'>
                <input type="file" onChange={(event) => setContactUsData({
                    ...contactUsData,
                    file: event.target.files[0],
                })} />
            </div>

            <div className='tw-col-span-12 tw-flex tw-flex-col tw-items-start tw-w-full tw-border-b-[1px] tw-border-slate-300 tw-px-[0.975vw] tw-gap-y-[16px]'>
                <h1>Please enter sum: </h1>
                <div className='tw-grid tw-grid-cols-12 tw-w-full tw-place-items-center'>
                    <h1 className='tw-col-span-1'>4 + 2 =</h1>
                    <input value={sum} onChange={(event) => setSum(event.target.value)} type="text" className='tw-w-full tw-py-[5px] tw-outline-none' placeholder='Write Answer...' />
                </div>
            </div>

            <h1 className='tw-col-span-12 tw-text-sm'>For every project, we sign NDA</h1>

            {/* <div className=' tw-col-span-12 tw-flex tw-items-center tw-w-full tw-border-slate-300 tw-gap-x-2'>
                <h1>Subscribe to our News Letter</h1>
                <input type="checkbox" checked={contactUsData.subscribedToNewsLetter} onChange={(event) => setContactUsData({
                    ...contactUsData,
                    subscribedToNewsLetter: event.target.checked,
                })} />
            </div> */}

            <div className='tw-col-span-12 tw-flex tw-w-full tw-justify-end'>
                <ArrowButton
                    text='Send Message'
                    className='tw-bg-darkPurple tw-text-white'
                    noUrl={true}
                    clickFunction={true}
                    onClick={submitHandler}
                />
            </div>
        </div>
    )
}
