import React from "react";
import { useSelector } from "react-redux";

// icons
// import EastIcon from '@mui/icons-material/East';

// components
import CaseStudiesCard from "./CaseStudiesCard";
import HeaderText from "../../headerText/HeaderText";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// data
import { caseStudiesData } from "../../../data/caseStudiesData";

export default function CaseStudies({ colorTitleStart, title, description }) {
    // globals
    const screenWidth = useSelector((state) => state.responsive.screenWidth);

    return (
        <div className="tw-mt-[8.138vw] tw-px-[9.115vw]">
            <HeaderText
                colorTitleStart={`${colorTitleStart || "CASE"}`}
                title={`${title || "STUDIES"}`}
                className="tw-text-center tw-mb-8"
                description={`${description || "We illuminate our processes and the intricacies behind the development."}`}
            />

            {screenWidth < 768 ? (
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {caseStudiesData.map((caseStudy) => (
                        <SwiperSlide key={caseStudy.id}>
                            <div className="tw-h-[400px]">
                                <CaseStudiesCard
                                    src={caseStudy.src}
                                    title={caseStudy.title}
                                    children={caseStudy.children}
                                    url={caseStudy.url}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <div className="tw-grid tw-grid-cols-3">
                    {caseStudiesData.map((caseStudy) => (
                        <CaseStudiesCard
                            key={caseStudy.id}
                            src={caseStudy.src}
                            title={caseStudy.title}
                            children={caseStudy.children}
                            url={caseStudy.url}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
