import React from 'react';

// assets
import sc1 from '../../../../assets/pages/service/blockchainDev/sc1.jpeg';
import sc2 from '../../../../assets/pages/service/blockchainDev/sc2.png';
import { smartContractDevData } from '../../../../data/services/blockchainDevData/smartContractDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function SmartContractDev() {
    return (
        <ServicesWrapper
            title="BLOCKCHAIN DEVELOPMENT SMART CONTRACTS"
            descriptionCont={`You may trade anything of value using smart contracts transparently and without the need for an intermediary. Digital computer protocols known as " smart contracts" are supported by the capacity to monitor the conditions of commercial agreements.`}
            img={sc1}
        >
            <DevGridWrapper img={sc2}>
                <DataMapper data={smartContractDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
