export const hireReactDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "ReactJS is essential for modern web development. It's a prominent front-end library that aids developers in creating vibrant and efficient user interfaces. Born from the innovation at Facebook, ReactJS provides a component-based architecture, making it easier to reuse code and manage states across large applications."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Here are the reasons why businesses should consider hiring ReactJS developers:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Component-Based Architecture",
                description: "ReactJS allows developers to break down complex UI into simpler components. This modular structure ensures that changes in one section don't cascade to others, making the application more maintainable and efficient."
            },
            {
                id: 2,
                title: "Performance Boost",
                description: "ReactJS uses a virtual DOM, ensuring that only the components that need to change are re-rendered. This minimizes direct manipulation of the DOM and offers a considerable performance boost."
            },
            {
                id: 3,
                title: "Rich User Interfaces",
                description: "With ReactJS, creating interactive and dynamic user interfaces becomes streamlined. This ensures a smoother user experience."
            },
            {
                id: 4,
                title: "Versatile",
                description: "ReactJS pairs beautifully with various back-end technologies. Whether you're using Node.js, Ruby on Rails, or another back-end framework, ReactJS can easily integrate and elevate your app's front-end."
            },
            {
                id: 5,
                title: "Strong Community Support",
                description: "Since ReactJS is backed by Facebook, it enjoys robust community support. This means there are plenty of resources, from tutorials to third-party tools, available for React developers."
            }
        ]
    },
    {
        id: 3,
        title: "Alta Tech Mark's Expertise in ReactJS Development:",
        description: "",
        listNum: true,
        data: [
            {
                id: 1,
                title: "Skilled Developers",
                description: "At Alta Tech Mark, our ReactJS developers possess deep knowledge not only in React but also in associated technologies like Redux for state management, and other tools in the React ecosystem."
            },
            {
                id: 2,
                title: "Proven Track Record",
                description: "Over the years, we've delivered countless React-based projects, ranging from simple websites to complex single-page applications."
            },
            {
                id: 3,
                title: "End-to-End Solutions",
                description: "Apart from just ReactJS development, our team can handle everything from back-end integration to deploying the application."
            },
            {
                id: 4,
                title: "Tailored Solutions",
                description: "We understand that every business has unique requirements. Our team will closely work with you to tailor solutions that match your specific needs."
            },
            {
                id: 5,
                title: "Continuous Support",
                description: "Development doesn't just end with deployment. We offer continuous support and maintenance to ensure your application stays updated and efficient."
            }
        ]
    },
    {
        id: 4,
        title: "",
        description: "Invest in a technology that's future-proof, versatile, and trusted by giants like Facebook and Instagram. Partner with Alta Tech Mark for your ReactJS development needs.",
        onlyBullets: false,
        data: []
    }
]

export const hireReactDevFaqs = [
    {
        id: 1,
        question: "1. How much does it cost to hire ReactJS developers?",
        answer: "The cost estimation for dedicated ReactJS developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    },
    {
        id: 2,
        question: "2. Can I hire dedicated ReactJS developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated ReactJS developer, then you can hire dedicated ReactJS developers on an hourly or project/task base."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated ReactJS developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the ReactJS developers before making a final move."
    },
    {
        id: 4,
        question: "4. What steps should I follow to Hire ReactJS developers?",
        answer: "Alta Tech Mark offers a very simple process to hire ReactJS developers. Just provide project details and the technical expertise you need. The team of ReactJS developers will begin working on your project based on your selection."
    },
    {
        id: 5,
        question: "5. Why should I hire ReactJS developers?",
        answer: "The dedicated ReactJS developers will work only for you to deliver outstanding results with the highest optimization. The team of ReactJS developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    }
]