export const salesforceData = [
    {
        id: 1,
        title: "Salesforce mobile development",
        description: "Our Salesforce development services transform the way businesses communicate and manage their customers, products, partners, and even employees. The strong Salesforce developer tools like Force, Salesforce Lightning, Salesforce Lightning Inspector, Developer Console, and Workbench build quite a powerful scope for you to grow your versatility. We follow the agile methodology for complete Salesforce development services. Salesforce Development Company Offerings:",
        onlyBullets: true,
        data: [
            "Salesforce Development: A team of Alta Tech Mark can help your organization in designing and implementing strong Salesforce-based solutions and get long-term advantages, whether you are a startup or a large Enterprise.",
            "Salesforce Implementation Consulting",
            "Force.com Development",
            "Force.com Integration and Migration",
            "Salesforce Mobile Development: As a top Salesforce development company, Alta Tech Mark can help you to increase Salesforce functionality to mobile devices and use Salesforce to its highest potential.",
            "Salesforce Mobile App Consulting & Development",
            "Mobile apps for Appexchange",
            "UI/UX Design Services"
        ]
    },
    {
        id: 2,
        title: "Salesforce AppExchange Development",
        description: "Salesforce AppExchange Development AppExchange can improve Salesforce's existing functionality or even implement additional features. With Alta Tech Mark Salesforce development services, you can build custom apps for AppExchange.",
        onlyBullets: true,
        data: [
            "App Consulting",
            "App Development",
            "Lightning Apps"
        ]
    },
    {
        id: 3,
        title: "Salesforce Lightning Development",
        description: "Salesforce Lightning Development As a leading Salesforce consulting company, Alta Tech Mark can help your organization to take full advantage of Salesforce Lightning to make your Salesforce implementation more convenient and natural.",
        onlyBullets: true,
        data: [
            "Salesforce Lightning Consulting",
            "Salesforce App Development",
            "Component Development for Salesforce Lightning"
        ]
    },
    {
        id: 4,
        title: "Integration With Third-party Solutions",
        description: "Integration With Third-party Solutions We help many businesses to connect this third-party software with their Salesforce Solution so that they can work more efficiently and actively.",
        onlyBullets: true,
        data: [
            "Integration Using Tools",
            "Custom Integration",
            "API Development"
        ]
    },
    {
        id: 5,
        title: "Integration App Development Technical Expertise That You Will Get From Alta Tech Mark:",
        description: "",
        onlyBullets: true,
        data: [
            "Writing Apex Triggers And Bulkify Triggers",
            "Overriding Default Behavior",
            "Future Methods And Batch Apex",
            "Record Sharing Through The Apex",
            "Integrating Yahoo UI/jQuery Components In Visualforce",
            "Inbound & Outbound Email Services",
            "Webservice Callouts And Building Web Services"
        ]
    },
    {
        id: 6,
        title: "Scheduled Jobs Elevate Your Business To The Next Level With Alta Tech Mark:",
        description: "Alta Tech Mark is a leading web and app development company with long years of industry experience in Salesforce development. We believe that innovation paves the path for success and provide high-quality Salesforce development services that can change the way you do business. We are here to transform the way businesses communicate and manage their customers, products, partners, and even employees. We are here to provide the best Salesforce services for you.",
        onlyBullets: true,
        data: [
            "Experienced Salesforce Development",
            "Automate And Simplify Your Sales Processes",
            "100% Transparency",
            "Quality Work With Effective Results",
            "Focus On Complete Client Satisfaction",
            "Regular Showcase Of Work",
            "Innovative Approach",
            "Offer 24/7 Customer Support Channels",
            "Cut Down Cost On Infrastructure & Maintenance",
            "Stimulate Sales And Maximize Your Return On Investment"
        ]
    }
]