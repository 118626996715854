import React from 'react';

// assets
import chatLive from '../../assets/icons/chatLive.png';

// component
import HeaderText from '../headerText/HeaderText';
import ArrowButton from '../ui/arrowButton/ArrowButton';

export default function ContactUsNoForm() {
    return (
        <div className='tw-px-[9.115vw] tw-bg-purple tw-w-full tw-py-[4vw]'>
            <div className='tw-grid tw-w-full tw-place-items-center'>
                <HeaderText
                    title='Take the first step towards the right direction'
                    description='Interested in working together? Fill out the form below, our team of professionals will contact you to guide you the way forward.'
                    className='tw-text-center tw-text-white tw-mb-8 md:tw-w-[67%]'
                />
                <div className='tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-justify-center tw-gap-x-[50px] tw-w-full tw-text-white'>
                    <div className='md:tw-flex tw-hidden tw-items-center tw-gap-x-[25px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
                            <g clip-path="url(#clip0_276_100)">
                                <path d="M35.3913 9.94682C34.7784 9.87834 34.1622 10.0431 33.6653 10.4084C33.1685 10.7737 32.8273 11.3126 32.7099 11.918C32.5924 12.5234 32.7071 13.1508 33.0313 13.6754C33.3555 14.2 33.8653 14.5833 34.4593 14.7491C36.3972 15.1234 38.1783 16.0703 39.5724 17.4675C40.9666 18.8647 41.9096 20.6478 42.2797 22.5866C42.3851 23.1459 42.682 23.651 43.1194 24.0151C43.5568 24.3792 44.1074 24.5795 44.6766 24.5816C44.8343 24.5813 44.9916 24.567 45.1468 24.5388C45.7824 24.4094 46.3414 24.0346 46.7026 23.4958C47.0638 22.9569 47.198 22.2975 47.0763 21.6603C46.5234 18.7632 45.1144 16.0984 43.0314 14.0103C40.9484 11.9222 38.287 10.5068 35.3913 9.94682Z" fill="white" />
                                <path d="M35.2146 0.023208C34.8987 -0.0197672 34.5773 0.00257842 34.2705 0.0888603C33.9636 0.175142 33.6777 0.323522 33.4305 0.524808C33.0491 0.826358 32.7656 1.23418 32.6159 1.69675C32.4662 2.15932 32.4569 2.65589 32.5893 3.12372C32.7216 3.59155 32.9897 4.00966 33.3596 4.32523C33.7294 4.6408 34.1845 4.83967 34.6674 4.89671C39.1373 5.38268 43.3064 7.38314 46.4824 10.5658C49.6583 13.7485 51.65 17.9219 52.1265 22.3929C52.1917 22.9907 52.4753 23.5435 52.9228 23.9453C53.3704 24.3471 53.9504 24.5696 54.5518 24.5703C54.6471 24.5705 54.7423 24.5648 54.8368 24.5532C55.1544 24.5188 55.4621 24.4218 55.7419 24.2677C56.0217 24.1136 56.2682 23.9055 56.467 23.6554C56.6678 23.4036 56.8168 23.1146 56.9053 22.805C56.9939 22.4953 57.0202 22.1712 56.9829 21.8514C56.3927 16.2741 53.9111 11.0669 49.9508 7.09571C45.9906 3.12453 40.7902 0.62868 35.2146 0.023208Z" fill="white" />
                                <path d="M44.46 36.1181C42.8267 35.9039 41.1745 36.3376 39.8572 37.3265C38.532 38.096 36.0097 40.1309 34.5676 39.6065C26.8391 36.4016 20.6299 30.3607 17.214 22.7231C16.6839 21.2497 18.7188 18.7331 19.494 17.3908C20.4779 16.0657 20.909 14.4105 20.6967 12.7738C19.0023 9.51451 16.8389 6.52148 14.2756 3.89033C13.437 2.96725 12.2996 2.36976 11.0637 2.20313C6.93401 2.03213 2.32271 7.52123 1.51331 8.83508C-0.515888 11.6338 -0.501638 15.3587 1.54751 19.8731C6.48656 32.0084 25.1655 50.3197 37.392 55.424C39.3108 56.3917 41.4172 56.9304 43.5651 57.0029C45.2903 57.0304 46.9788 56.5029 48.3816 55.4981C49.4247 54.8968 55.1646 50.0831 55.0107 45.8509C54.8452 44.6232 54.2542 43.4926 53.3406 42.656C50.713 40.0577 47.7215 37.8554 44.46 36.1181Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_276_100">
                                    <rect width="57" height="57" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div className='tw-flex tw-flex-col tw-items-start'>
                            <h1 className='tw-text-lg tw-font-semibold'>+92 21 3889 6008</h1>
                            <h1>call us anytime</h1>
                        </div>
                    </div>
                    <ArrowButton url='/contactUs' text='Get a Quote' className='tw-bg-darkPurple tw-text-white tw-scale-[1.2 ]' />
                    <div className='md:tw-flex tw-hidden tw-items-center tw-gap-x-[25px]'>
                        <img src={chatLive} alt="chat" />
                        <div className='tw-flex tw-flex-col tw-items-start'>
                            <h1 className='tw-text-lg tw-font-semibold'>Chat Live</h1>
                            <h1>to discuss your project</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
