import asd3 from '../../../assets/pages/service/aiAndMl/asd3.png';

export const alexaSkillDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Amazon's voice-activated technology is called Alexa. You may talk to a smart speaker and ask it to grant your desires. Basic orders like turning down your lights or turning on some music are the first to be executed. With voice response, users may start tasks with only a few trigger phrases and no button tapping. Voice assistants have completely changed how companies provide their target market a customized user experience.",
            "We at Alta Tech Mark have expanded by mastering the creation of mobile apps for the Internet of Things and developing Alexa skills. Because of our proficiency with these in-demand technologies, we can integrate Alexa-enabled instructions into solutions for our customers. In addition to helping companies voice their operations, our extensive Alexa skills integration expedites procedures and improves customer satisfaction.",
            "Our team of skilled IoT developers follows Alexa's policy requirements and designs in accordance with the client's vision. Our staff ensures that the skills satisfy standard security criteria and provides cost-effective solutions adapted to our client's business demands. Our team at Alta Tech Mark is happy to be at the forefront of Alexa skill development and is very enthused about the prospects that speech technology and smart speakers bring."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Our Skilled Development Services for Alexa:",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Custom Skill Development",
                description: "At HLIS, we create natural and engaging voice experiences with custom-built Alexa interaction models by using our Alexa skills."
            },
            {
                id: 2,
                title: "Alexa For Home Automation",
                description: "Our experienced developers make smart solutions that use conversational Artificial Intelligence. We have a great skill to integrate the current smart home systems with Alexa."
            },
            {
                id: 3,
                title: "Alexa For On-demand solution",
                description: "We assure that your customers can book appointments, place orders, or book cabs with amazing voice commands given to Alexa. Alta Tech Mark team integrate your on-demand business model with Alexa to get the benefits of smart devices."
            },
            {
                id: 4,
                title: "Alexa Strategy",
                description: "With modern techniques of voice-based SEO like geotagging, question-based queries and much more. We efficiently grow your business rankings."
            },
            {
                id: 5,
                title: "Integration With ERP Systems",
                description: "We allow voice-driven management of business operations that draws live data from your ERP system, with the integration of Alexa skills."
            },
            {
                id: 6,
                title: "AWS Lambda",
                description: "The familiarity with AWS Lambda service mandates in order to create robust and dynamic Alexa skills and we are well versed in AWS Lambda service."
            },
            {
                id: 7,
                title: "VUI Designing",
                description: "Voice Designers at our Alexa skill development company make intuitive, intelligent, and frictionless ideas that help your customers to communicate with your device through Alexa hassle-free."
            }
        ]
    },
    {
        id: 3,
        title: "With our Alexa Skills Development Services, Alta Tech Mark provides support to a variety of industrial verticals.",
        description: "",
        onlyBullets: true,
        data: [
            "Medical",
            "Hospitality",
            "Retail",
            "Business",
            "Logistics",
            "Education"
        ],
        bulletsPic: asd3
    },
    {
        id: 6,
        title: "Why Select Alta Tech Mark As A Company That Develops Alexa Skills?",
        description: "",
        onlyBullets: true,
        data: [
            "We have several years of expertise and are among the most reputable suppliers of cloud solutions. You may increase the number of users in your solutions and propel company development by using the newest voice technologies.",
            "We also use the greatest techniques and resources available to guarantee that your Alexa skill is user-friendly and completely functioning.",
            "several years of expertise developing unique Alexa skills.",
            "Agile project management technique that ensures projects are completed on schedule.",
            "Web applications using Alexa skill creation services that are more scalable and quick.",
            "Dedicated group for on-demand maintenance and assistance.",
            "Engaging models that are adaptable to different company needs.",
            "Provide smooth interaction with different codes and APIs.",
            "Our goal is to establish enduring business partnerships with our customers.",
            "greatest value for the money with no extra fees.",
            "Provide clients with published live Alexa skills.",
            "Multifunctional Groups."
        ]
    }
]