import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
// import { useTheme } from '@mui/material/styles';
// import MobileStepper from '@mui/material/MobileStepper';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

// components
import ArrowButton from '../../ui/arrowButton/ArrowButton';
import OtpInput from 'react-otp-input';
import SimpleButton from '../../ui/simpleButton/SimpleButton';

const steps = [
    {
        label: "Recover Password",
        description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
    },
    {
        label: "Recover Password",
        description:
            'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: "Recover Password",
        description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    },
];

export default function ForgetPasswordForm() {
    // const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    // const maxSteps = steps.length;
    // locals
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('')

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % 3);
    };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // functions
    function isValidEmail(email) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }

    // effects
    useEffect(() => {
        setTimeout(() => setMessage(''), 3000)
    }, [message])

    return (
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <h1 className='tw-text-2xl tw-font-semibold tw-text-center tw-whitespace-nowrap'>{steps[activeStep].label}</h1>
            <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
                {activeStep === 0 ?
                    <div className='tw-flex tw-flex-col tw-items-start tw-w-full tw-h-full tw-justify-center tw-gap-y-2'>
                        <h1 className=''>Enter Your Email</h1>
                        <input type="text" placeholder='Enter your Email' className='tw-p-2 tw-rounded-lg tw-w-full tw-bg-transparent tw-border-[2px] tw-border-purple' value={email} onChange={event => setEmail(event.target.value)} />
                        <ArrowButton
                            clickFunction={true}
                            onClick={() => {
                                if (email === '') {
                                    setMessage("Please enter Email!")
                                }
                                else if (!isValidEmail(email)) {
                                    setMessage('Please enter a valid Email!')
                                }
                                else {
                                    setMessage('Unrecognized Email!')
                                    setEmail('')
                                }
                            }}
                            noUrl={true} className='tw-bg-darkPurple tw-text-white tw-text-center tw-w-full' text="Submit"
                        />
                        {message.length > 0 &&
                            <h1 className='tw-text-center tw-font-semibold tw-w-full tw-text-red-500'>{message}</h1>
                        }
                    </div>
                    :
                    activeStep === 1 ?
                        <div className='tw-flex tw-flex-col tw-items-start tw-w-full tw-h-full tw-justify-center tw-gap-y-2'>
                            <h1 className='tw-text-center'>Enter the One Time Password (OTP) that you have received through Email</h1>
                            <div className='tw-w-full'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span className='tw-w-6'></span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{
                                        backgroundColor: 'transparent',
                                        borderBottom: '2px solid #9065FD',
                                        outline: 'none',
                                        width: '100%',
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        // backgroundColor: 'red'
                                    }}
                                />
                            </div>
                            <h1 className='tw-text-center tw-text-xs'>A 6 digit OTP has been sent to your email. This OTP will expire in 10 minutes after which you’ll have to resend the code</h1>
                            <div className='tw-flex tw-items-center tw-gap-x-4'>
                                <SimpleButton noHover={true} text='Resend OTP' className='tw-text-xs tw-whitespace-nowrap' to="/" />
                                <SimpleButton noHover={true} text='Verify OTP' className='tw-bg-darkPurple tw-text-white tw-text-xs tw-whitespace-nowrap' to="/" clickFunction={true} onClick={handleNext} />
                            </div>
                        </div>
                        :
                        <div className='tw-flex tw-flex-col tw-items-start tw-w-full tw-h-full tw-justify-between'>
                            <div className='tw-flex tw-flex-col tw-items-start tw-w-full tw-h-full tw-justify-center tw-gap-y-2'>
                                <h1 className=''>Enter New Password</h1>
                                <input placeholder='Enter Password' type='password' className='tw-p-2 tw-rounded-lg tw-w-full tw-bg-transparent tw-border-[2px] tw-border-purple' />
                            </div>
                            <div className='tw-flex tw-flex-col tw-items-start tw-w-full tw-h-full tw-justify-center tw-gap-y-2'>
                                <h1 className=''>Re-enter New Password</h1>
                                <input placeholder='Re-enter Password' type='password' className='tw-p-2 tw-rounded-lg tw-w-full tw-bg-transparent tw-border-[2px] tw-border-purple' />
                            </div>
                            <SimpleButton noHover={true} text='Reset' className='tw-bg-darkPurple tw-text-white tw-whitespace-nowrap tw-w-full' to="/" clickFunction={true} onClick={handleNext} />
                        </div>
                }
            </Box>
        </Box>
    )
}
