import cs7 from '../assets/caseStudies/cs7.svg';
import cs8 from '../assets/caseStudies/cs8.svg';
import cs9 from '../assets/caseStudies/cs9.svg';
import cs10 from '../assets/caseStudies/cs10.svg';
import cs11 from '../assets/caseStudies/cs11.svg';
import cs12 from '../assets/caseStudies/cs12.svg';
import cs15 from '../assets/caseStudies/cs15.svg';
import cs16 from '../assets/caseStudies/cs16.svg';
import cs17 from '../assets/caseStudies/cs17.svg';

export const caseStudiesData = [
    {
        id: 1,
        src: cs7,
        title: "Porsche - Mobile App Design",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
            {
                id: 3,
                title: "Node Js"
            }
        ],
        url: '/brands/porsche'
    },
    {
        id: 2,
        src: cs8,
        title: "LG Electronics - Explainer Video",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
            {
                id: 3,
                title: "Node Js"
            },
            {
                id: 4,
                title: "My SQL"
            }
        ],
        url: '/brands/lg'
    },
    {
        id: 3,
        src: cs9,
        title: "Citizen - Mobile App Design",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
            {
                id: 3,
                title: "Flutter"
            },
            {
                id: 4,
                title: "Node Js"
            },
        ],
        url: '/brands/citizen'
    },
    // {
    //     id: 4,
    //     src: cs10,
    //     title: "Dubai Airports - Responsive Website",
    //     children: [
    //         {
    //             id: 1,
    //             title: "Android"
    //         },
    //         {
    //             id: 2,
    //             title: "IOS"
    //         },
    //         {
    //             id: 3,
    //             title: "Laravel"
    //         }
    //     ],
    //     url: '/brands/dubaiAirports'
    // },
    {
        id: 5,
        src: cs11,
        title: "Qatar Ministry of Transportation - Mobile Application",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
            {
                id: 3,
                title: "Node Js"
            },
        ],
        url: '/brands/qatarTransport'
    },
    {
        id: 6,
        src: cs12,
        title: "Renault - Website Development",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
        ],
        url: '/brands/renault'
    },
    {
        id: 7,
        src: cs15,
        title: "Education USA - Mobile Application Development",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
        ],
        url: '/brands/educationUsa'
    },
    {
        id: 8,
        src: cs16,
        title: "COACH LEFT HAND UP - Designed & Developed by Alta Tech Mark",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
        ],
        url: '/brands/clhu'
    },
    {
        id: 9,
        src: cs17,
        title: "Gulf Petrochemicals & Chemicals Association - Designed & Developed by Alta Tech Mark",
        children: [
            {
                id: 1,
                title: "Android"
            },
            {
                id: 2,
                title: "IOS"
            },
        ],
        url: '/brands/gpca'
    },
]