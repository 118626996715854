import pk from '../../assets/icons/location/pk.svg';
import uk from '../../assets/icons/location/uk2.svg';
import uae from '../../assets/icons/location/uae.svg';

export const footerLocations = [
   {
        id: 1,
        src: uk,
        country: "United Kingdom",
        address: "2 Frederick St Kings, Cross London, WC1X 0ND",
        contact: "+44 7723 621464"
    },
    {
        id: 2,
        src: uae,
        country: "United Arab Emirates",
        address: "01 Mezz Fl, Suq Al Kabeer, Bur Dubai 1111",
        contact: "+971 50 832 1179"
    },
    {
        id: 3,
        src: pk,
        country: "Pakistan",
        address: "202 13-C Lane-1, Ittehad-Comm, DHA Ph-6 Karachi",
        contact: "+92 21 3889 6008"
    },
]