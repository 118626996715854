import React from 'react';
import { useSelector } from 'react-redux';

// data
import { latestBlogsData } from '../../../data/latestBlogsData';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// components
import LatestBlogsCard from './LatestBlogsCard';
import HeaderText from '../../headerText/HeaderText';

export default function LatestBlogs(props) {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-px-[9.115vw] tw-mt-[8.138vw]'>
            {!props.blogsWithoutTitle &&
                <HeaderText
                    colorTitleEnd="BLOGS"
                    title="LATEST"
                    className='tw-text-center tw-mb-8'
                    description="Find out more about the newest blogs about trends and technology."
                />
            }

            {screenWidth < 768 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {latestBlogsData.slice(0, props.show ? +props.show : latestBlogsData.length).map(blog =>
                        <SwiperSlide key={blog.id}>
                            <div className='tw-h-[450px]'>
                                <LatestBlogsCard
                                    src={blog.src}
                                    title={blog.title}
                                    date={blog.date}
                                    description={blog.description}
                                    author={blog.author}
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper> :
                <div className='tw-grid tw-grid-cols-3 tw-w-full tw-mt-[25px]'>
                    {latestBlogsData.slice(0, props.show ? +props.show : latestBlogsData.length).map(blog => <LatestBlogsCard
                        key={blog.id}
                        src={blog.src}
                        title={blog.title}
                        date={blog.date}
                        description={blog.description}
                        author={blog.author}
                    />)}
                </div>
            }
        </div>
    )
}
