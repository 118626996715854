import React from 'react';

// data
import { hireAngularDevData } from '../../../data/hireDev/hireAngularDev';
import angularDev1 from '../../../assets/pages/hireDev/angularDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireAngularJsDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="ANGULAR JS DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={angularDev1}
            data={hireAngularDevData}
            noFaq={true}
        />
    )
}
