import React from 'react';

// assets
import caseStudyBg from '../../../assets/background/caseStudyBg.png';

// components
import CaseStudies from './../../../components/home/caseStudies/CaseStudies';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';

export default function CaseStudy() {
    return (
        <div>
            <HeroTitleImage
                title="We turn client' s idea into"
                coloredTitle="fantastic results"
                bg={caseStudyBg}
            />

            <CaseStudies />
            <br />
            <ContactUsNoForm />
        </div>
    )
}
