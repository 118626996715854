import React from 'react'

export default function CoreValuesCard(props) {
    return (
        <div className='tw-col-span-1 tw-flex tw-flex-col tw-items-center tw-text-center tw-text-white tw-justify-end tw-p-8 tw-h-[350px]'
            style={{
                backgroundImage: `url(${props.src})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <h1 className='tw-text-xl tw-font-semibold'>{props.title}</h1>
            <h1>{props.description}</h1>
        </div>
    )
}
