import React from 'react';

// assets
import roundedUp from '../../../assets/processes/roundedUp.svg';
import roundedDown from '../../../assets/processes/roundedDown.svg';

export default function ProcessesCard(props) {
    return (
        <div className={`tw-col-span-1 tw-flex md:tw-flex-col tw-w-full tw-items-center md:tw-h-[19.987vw] tw-text-center ${props.index % 2 !== 0 ? 'md:tw-flex-col-reverse' : 'tw-flex-row-reverse'} tw-justify-between`}>
            <div className='tw-flex tw-flex-col md:tw-w-full tw-w-[50%]'>
                <h1 className='tw-font-semibold md:tw-text-[1.172vw] md:tw-leading-[1.823vw]'>{props.title}</h1>
                <h1 className='md:tw-text-[1.042vw] md:tw-leading-[1.563vw]'>{props.description}</h1>
            </div>
            <div className={`tw-flex tw-flex-col tw-items-center ${props.index % 2 !== 0 ? 'tw-flex-col-reverse' : ''} md:tw-w-full`}>
                <div className='md:tw-block tw-hidden tw-w-[0.130vw] tw-h-[1.628vw] tw-bg-black'></div>
                <img src={props.src} alt={props.title} className='md:tw-w-[5.208vw]' />
            </div>
            <img src={props.index % 2 !== 0 ? roundedDown : roundedUp} className={`tw-relative tw-w-[13.204vw] md:tw-block tw-hidden ${props.index % 2 !== 0 ? 'tw-top-[4.036vw]' : 'tw-bottom-[4.036vw]'} tw-scale-[1.145]`} alt="arrow" />
        </div>
    )
}
