import efficientProcess from '../../assets/pages/hireDev/onDemandTeams/efficientProcess.png';
import chooseTheBest from '../../assets/pages/hireDev/onDemandTeams/chooseTheBest.png';
import advanceTechnology from '../../assets/pages/hireDev/onDemandTeams/advanceTechnology.png';

export const onDemandTeamsData = [
    {
        id: 1,
        title: "Efficient Process",
        description: "Our quick and efficient process, so our clients do not have to wait much before selecting their ideal team. The process is easy and offers flexible timing from hourly to yearly.",
        src: efficientProcess,
    },
    {
        id: 2,
        title: "Choose The Best",
        description: "Alta Tech Mark believes in the best and delivers the best only. We offer a team or team of experts who provide quality and excellent work. Our success rate is high because of our work.",
        src: chooseTheBest,
    },
    {
        id: 3,
        title: "Advance Technology",
        description: "Our experts are proficient with advanced technology and implement it in their profession. Therefore, we embrace a new and modern approach and adept job accordingly.",
        src: advanceTechnology,
    }
]