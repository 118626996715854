export const hireKotlinDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Alta Tech Mark has been a trusted name in the industry for an extensive period, delivering unmatched services to a global clientele. When you opt to hire our Kotlin developers for your Android app development, rest assured, your projects are in the hands of seasoned professionals. Each member of our team comes with a rich blend of experience, expertise, technical proficiency, and innovation.",
            "Our systematic approach ensures transparent communication, keeping you in the loop throughout the development process. Moreover, our flexible contract terms are designed with a prime focus on maximizing client benefits and satisfaction.",
            "We pride ourselves on crafting some of the most powerful online and mobile applications for Android, browsers, and native platforms using Kotlin. As Kotlin continues to grow in popularity and strength over traditional languages like Java for Android development, Alta Tech Mark emerges as the prime destination to hire dedicated Kotlin app developers. If your vision aligns with leveraging Kotlin's robustness for Android app development, we're just the right team for you."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "What Sets Our Developers Apart?",
        description: "Unwavering Commitment to Quality Alta Tech Mark stands tall in its commitment to deliver superior quality in every project. Quality isn't just a buzzword for us – it's an ethos we live by. When you partner with us, be prepared to experience excellence at every stage of the process.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                title: "Competitive Pricing",
                description: "We understand the value of your investment. Thus, we ensure that our services are priced competitively, ensuring you get the best bang for your buck. Our aim is not just to meet but exceed client expectations within a budget that suits them."
            },
            {
                title: "Sturdy Solutions",
                description: "Our solutions aren't just about solving the immediate problem. We design and develop solutions that stand the test of time. At Alta Tech Mark, we blend innovation with functionality to ensure you get robust solutions tailored to your needs."
            },
            {
                title: "Swift and Streamlined Delivery",
                description: "Efficiency is at the core of what we do. Our dedicated team, armed with the right skills and technologies, ensures prompt and streamlined delivery of projects. With Alta Tech Mark, you can expect on-time delivery without compromising on the quality."
            }
        ]
    },
]

export const hireKotlinDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Kotlin developers?",
        answer: "The dedicated Kotlin developers will work only for you to deliver outstanding results with the highest optimization. The team of Kotlin developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Kotlin developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Kotlin developers. Just provide project details and the technical expertise you need. The team of Kotlin developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Kotlin developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Kotlin developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Kotlin developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Kotlin developer, then you can hire dedicated Kotlin developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Kotlin developers?",
        answer: "The cost estimation for dedicated Kotlin developers varies based on various factors such as development team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]