import React from 'react'
import { Link } from 'react-router-dom';

// flags
// import india from '../../assets/flags/india.png'
// import usa from '../../assets/flags/usa.png'
// import uk from '../../assets/flags/uk.png'

// icons
// import EmailIcon from '@mui/icons-material/Email';
// import skype from '../../assets/logo/skype.png'
import facebook from '../../assets/logo/facebook.png'
import twitter from '../../assets/logo/twitter.png'
import instagram from '../../assets/logo/instagram.png'
import linkedin from '../../assets/logo/linkedin.png'
// import youtube from '../../assets/logo/youtube.png'
// import top from '../../assets/logo/top.png'
import companyHoverBg1 from '../../assets/background/companyHoverBg-01.png'
import companyHoverBg2 from '../../assets/background/companyHoverBg-02.png'
import downloadIcon from '../../assets/icons/download.svg'

export default function AboutHover(props) {
    return (
        // <div className='tw-grid tw-grid-cols-3 tw-absolute tw-border-slate-300 tw-border-[1px] tw-h-[30vh] tw-z-10 tw-bg-purple tw-text-purple' onMouseLeave={props.hoverLeave}
        //     style={{
        //         backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
        //     }}
        // >
            <div className='tw-flex tw-flex-col tw-items-start tw-justify-between' onMouseLeave={props.hoverLeave}>
                <div className='tw-flex tw-flex-col tw-items-start tw-w-[100%]'>
                    <Link to='/company/aboutUs' className='tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-white hover:tw-bg-opacity-20'> About Company</Link>
                    <Link to='/company/ourTeam' className='tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-white hover:tw-bg-opacity-20'>Our Team</Link>
                    <Link to='/company/ourExpertise' className='tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-white hover:tw-bg-opacity-20'>Our Expertise</Link>
                    <Link to='/company/ourClients' className='tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-white hover:tw-bg-opacity-20'>Clients</Link>
                </div>

            </div>
        // </div>
    )
}
