import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

// icons
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import facebookWhite from "../../assets/icons/socials/facebookWhite.svg";
import twitterWhite from "../../assets/icons/socials/twitterWhite.svg";
import instagramWhite from "../../assets/icons/socials/instagramWhite.svg";
import linkedInWhite from "../../assets/icons/socials/linkedinWhite.svg";
import atmLogo from "../../assets/logo/logoWhite.png";
import { footerLocations } from "../../data/home/footer";
import AboutHover from "./AboutHover";
import HelpHover from "./HelpHover";
import ServicesHover from "./ServicesHover";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


// data
// import { servicesData } from '../navbar/servicesHover/servicesData';
import { servicesData } from "../../data/servicesData";

export default function Footer() {

   // effects
   useEffect(() => {
    // Add the event listener on component mount
    window.addEventListener('resize', handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
}, []);

// locals
const [navLinksHoverState, setNavLinksHoverState] = useState({
    about: false,
    help: false,
    hireDevelopers: false,
    caseStudy: false,
    resources: false,
    contactUs: false,
})
const [screenWidth, setScreenWidth] = useState(window.innerWidth);
const [menuOpen, setMenuOpen] = useState(false);
const [menuLinkHoverState, setMenuLinkHoverState] = useState({
    about: false,
    help: false,
    services: false,
    contactUs: false,
    links: true
})

// functions
// Function to update screenWidth on window resize
const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
};

const aboutHover = () => {
    setNavLinksHoverState({
        about: true,
        help: false,
        services: false,
    })
}


const helpHover = () => {
  setNavLinksHoverState({
      about: false,
      help: true,
      services: false,
  })
}

const servicesHover = () => {
  setNavLinksHoverState({
      about: false,
      help: false,
      services: true,
  })
}

const hoverLeave = () => {
    setNavLinksHoverState({
        about: false,
        services: false,
        hireDevelopers: false,
        caseStudy: false,
        resources: false,
        contactUs: false,
    })
}


  // Close all dropdowns when the back button is clicked
  const closeAllDropdowns = () => {
    setNavLinksHoverState({
      about: false,
      help: false,
      services: false,
    });
  };


  function insertLineBreaks(address) {
		// Split the address by commas and map each part into a span with a line break.
		return address.split(',').map((part, index, array) => (
			// Only add the <br /> if it's not the last part of the address
			<span key={index}>
				{part}{index !== array.length - 1 && <br />}
			</span>
		));
	}

  return (
    <>
      {/* Logo */}
      <div className="tw-col-span-1 tw-flex tw-flex-col tw-w-full tw-items-center tw-gap-y-7  tw-bg-darkPurple">
          <img src={atmLogo} alt="logo" className="tw-w-[200px] tw-mt-3 footerLogo" style={{marginBottom: '-10px'}} />
        </div>

      {/* Pages */}
      <div className="tw-text-white tw-grid md:tw-grid-cols-4 tw-grid-cols-1 md:tw-mx-auto fz-15 tw-mx-auto tw-w-full tw-gap-y-2 tw-bg-darkPurple pagesMain">
      <div className="tw-flex tw-items-start tw-gap-x-2 max-[768px]:tw-ml-[20%] sm:tw-mt-4 md:tw-mt-6 tw-lg:mt-10 tw-mb-5" style={{flexDirection: 'column'}}>
          {/* <div className="tw-absolute tw-h-12 tw-w-[1px] tw-bg-white tw-opacity-50 -tw-left-8 tw-rounded-lg"></div> */}
          <h1 className="tw-text-2xl tw-opacity-50" onMouseEnter={servicesHover}>Services</h1>
          <div className='tw-sticky tw-z-[11] tw-flex tw-w-full'>
                {navLinksHoverState.services && <ServicesHover hoverLeave={hoverLeave} />}
            </div>
        </div>

        <div className="tw-flex tw-items-start tw-gap-x-2 max-[768px]:tw-ml-[20%] sm:tw-mt-4 md:tw-mt-6 tw-lg:mt-10 tw-mb-5" style={{flexDirection: 'column'}}>
          {/* <div className="tw-absolute tw-h-12 tw-w-[1px] tw-bg-white tw-opacity-50 -tw-left-8 tw-rounded-lg"></div> */}
          <h1 className="tw-text-2xl tw-opacity-50" onMouseEnter={aboutHover}>About</h1>
          {/* <div onClick={closeAllDropdowns} id='links' className='tw-flex tw-w-full tw-p-[2vw] '>
            <ArrowRightIcon id='links' className='tw-rotate-180' />
            <h1 id='links' className='tw-font-semibold'>Back</h1>
          </div> */}
          <div className='tw-sticky tw-z-[11] tw-flex tw-w-full'>
                {navLinksHoverState.about && <AboutHover hoverLeave={hoverLeave} />}
            </div>
         
        </div>

        <div className="tw-flex tw-items-start tw-gap-x-2 max-[768px]:tw-ml-[20%] sm:tw-mt-4 md:tw-mt-6 tw-lg:mt-10 tw-mb-5" style={{flexDirection: 'column'}}>
          {/* <div className="tw-absolute tw-h-12 tw-w-[1px] tw-bg-white tw-opacity-50 -tw-left-8 tw-rounded-lg"></div> */}
          <h1 className="tw-text-2xl tw-opacity-50"  onMouseEnter={helpHover}>Help</h1>
          <div className='tw-sticky tw-z-[11] tw-flex tw-w-full'>
                {navLinksHoverState.help && <HelpHover hoverLeave={hoverLeave} />}
            </div>
        </div>

        {/* <div className="tw-flex tw-items-start tw-gap-x-2 max-[768px]:tw-ml-[20%] sm:tw-mt-4 md:tw-mt-6 tw-lg:mt-10 tw-mb-5" style={{flexDirection: 'column'}}>
          <h1 className="tw-text-2xl tw-opacity-50"  onMouseEnter={helpHover}>Hire Developers</h1>
          <div className='tw-sticky tw-z-[11] tw-flex tw-w-full'>
                {navLinksHoverState.help && <HelpHover hoverLeave={hoverLeave} />}
            </div>
        </div> */}
    
        <div className="tw-flex tw-items-start tw-gap-x-2 max-[768px]:tw-ml-[20%] sm:tw-mt-4 md:tw-mt-6 tw-lg:mt-10 tw-mb-5" style={{flexDirection: 'column'}}>
          {/* <div className="tw-absolute tw-h-12 tw-w-[1px] tw-bg-white tw-opacity-50 -tw-left-8 tw-rounded-lg"></div> */}
          <Link to='/resources/caseStudy' className="tw-text-2xl tw-opacity-50">Case Study</Link>
        </div>
    
    
      </div>

  {/* Location */}
        <div className="tw-grid md:tw-grid-cols-1 tw-grid-cols-1 md:tw-mx-auto fz-15 tw-mx-auto tw-w-full tw-gap-y-2 tw-bg-darkPurple lg:tw-px-10 md:tw-px-10">
          <h1 className="fz-20 tw-text-[#808080] tw-text-center tw-mt-6 headOfficeHeading" style={{marginTop: '-10px'}}> Alta Tech Mark is headquartered in United Arab Emirates with offices around the world. </h1>
          </div>
      <div className="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 md:tw-mx-auto fz-15 tw-mx-auto tw-w-full tw-gap-y-2 tw-bg-darkPurple locationMain">
					{footerLocations.map((location) => (
						<div key={location.id} className="tw-flex tw-items-start tw-gap-x-2 tw-w-fit tw-justify-center md:tw-justify-self-center max-[768px]:tw-ml-[20%] sm:tw-mt-4 md:tw-mt-6 tw-lg:mt-10 tw-mb-5">
							<img src={location.src} alt="location" style={{height: '125px'}} />
							<div className="tw-flex tw-flex-col tw-items-start">
								<p className="tw-text-[#fff]" style={{fontWeight: 'bold'}}>
									{location.country}
								</p>
								<p className="tw-text-white">
                  {/* {location.address} */}
									{insertLineBreaks(location.address)}
								</p>
								<p className="tw-text-white">
									{location.contact}
								</p>
							</div>
						</div>
					))}
				</div>

        {/* Bottom footer */}
      <div className="md:tw-bg-[#0f003f] tw-bg-darkPurple tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-px-[9.115vw] tw-py-[20px] tw-text-white tw-text-[14px] tw-w-full tw-gap-y-[25px]">
        <img
          src={atmLogo}
          alt="logo"
          className="md:tw-hidden tw-w-[150px] tw-opacity-50"
        />

        <h1 className="tw-opacity-50">
          © 2025 Alta Tech Mark LTD. All rights reserved.
        </h1>

        <div className="tw-flex tw-items-center tw-gap-x-[25px]">
          <a
            href="https://www.facebook.com/altatechmark"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebookWhite}
              className="tw-w-[25px] tw-h-[25px] hover:tw-opacity-100 tw-opacity-50"
              alt="facebook"
            />
          </a>
          <a
            href="https://twitter.com/altatechmark"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={twitterWhite}
              className="tw-w-[25px] tw-h-[25px] hover:tw-opacity-100 tw-opacity-50"
              alt="twitter"
            />
          </a>
          <a
            href="https://www.instagram.com/altatechmark"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagramWhite}
              className="tw-w-[25px] tw-h-[25px] hover:tw-opacity-100 tw-opacity-50"
              alt="instagram"
            />
          </a>
          <a
            href="https://pk.linkedin.com/about/altatechmark"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={linkedInWhite}
              className="tw-w-[25px] tw-h-[25px] hover:tw-opacity-100 tw-opacity-50"
              alt="linkedin"
            />
          </a>

          {/* <FacebookIcon className='tw-cursor-pointer hover:tw-text-blue-400' />
                    <TwitterIcon className='tw-cursor-pointer hover:tw-text-blue-400' />
                    <InstagramIcon className='tw-cursor-pointer hover:tw-text-blue-400' />
                    <LinkedInIcon className='tw-cursor-pointer hover:tw-text-blue-400' /> */}
        </div>

        <div className="tw-flex tw-items-center tw-gap-x-[5px] tw-opacity-50">
          <h1 className="tw-cursor-pointer">Privacy Policy</h1>
          <div className="tw-w-[1px] tw-h-[10px] tw-bg-slate-400"></div>
          <Link to="/cyberSecurityPolicy" className="tw-cursor-pointer">
            Cyber Security Policy
          </Link>
          <div className="tw-w-[1px] tw-h-[10px] tw-bg-slate-400"></div>
          <h1 className="tw-cursor-pointer">Terms & Conditions</h1>
        </div>
      </div>
    </>
  );
}