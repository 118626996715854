import React from 'react'

// icons
import facebook from '../../../assets/logo/facebook.png';
import twitter from '../../../assets/logo/twitter.png';
import instagram from '../../../assets/logo/instagram.png';
import linkedin from '../../../assets/logo/linkedin.png';
import youtube from '../../../assets/logo/youtube.png';
import top from '../../../assets/logo/top.png';

// components
import HeaderText from '../../headerText/HeaderText';

export default function SocialMedia() {
    return (
        <div className='tw-px-[9.1vw] tw-pb-[3.906vw] tw-grid tw-w-full tw-justify-between'>
            <HeaderText title="Social Media" description="Don't Miss To Follow Us On Our Social Networks Accounts." />

            <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-3 tw-gap-[25px] tw-my-[2vw]'>
                <div className='tw-transition-colors tw-ease-in-out tw-shadow-lg tw-rounded-lg tw-p-[25px] hover:tw-border-[#4842A8] tw-border-[1px] tw-cursor-pointer'><img className='tw-w-[35px] tw-h-[35px]' src={facebook} alt="logo" /></div>
                <div className='tw-transition-colors tw-ease-in-out tw-shadow-lg tw-rounded-lg tw-p-[25px] hover:tw-border-[#4842A8] tw-border-[1px] tw-cursor-pointer'><img className='tw-w-[35px] tw-h-[35px]' src={twitter} alt="logo" /></div>
                <div className='tw-transition-colors tw-ease-in-out tw-shadow-lg tw-rounded-lg tw-p-[25px] hover:tw-border-[#4842A8] tw-border-[1px] tw-cursor-pointer'><img className='tw-w-[35px] tw-h-[35px]' src={instagram} alt="logo" /></div>
                <div className='tw-transition-colors tw-ease-in-out tw-shadow-lg tw-rounded-lg tw-p-[25px] hover:tw-border-[#4842A8] tw-border-[1px] tw-cursor-pointer'><img className='tw-w-[35px] tw-h-[35px]' src={linkedin} alt="logo" /></div>
                <div className='tw-transition-colors tw-ease-in-out tw-shadow-lg tw-rounded-lg tw-p-[25px] hover:tw-border-[#4842A8] tw-border-[1px] tw-cursor-pointer'><img className='tw-w-[35px] tw-h-[35px]' src={youtube} alt="logo" /></div>
                <div className='tw-transition-colors tw-ease-in-out tw-shadow-lg tw-rounded-lg tw-p-[25px] hover:tw-border-[#4842A8] tw-border-[1px] tw-cursor-pointer'><img className='tw-w-[35px] tw-h-[35px]' src={top} alt="logo" /></div>
            </div>
        </div>
    )
}
