import juniorDevelopers from '../../assets/pages/hireDev/talent/juniorDevelopers.png';
import seniorDevelopers from '../../assets/pages/hireDev/talent/seniorDevelopers.png';
import projectManagers from '../../assets/pages/hireDev/talent/projectManagers.png';
import uiUxDesigners from '../../assets/pages/hireDev/talent/uiUxDesigners.png';
import webDevelopers from '../../assets/pages/hireDev/talent/webDevelopers.png';
import testers from '../../assets/pages/hireDev/talent/testers.png';

export const talentData = [
    {
        id: 1,
        title: "Junior Developers",
        description: "Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.",
        src: juniorDevelopers,
    },
    {
        id: 2,
        title: "Senior Developers",
        description: "Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.",
        src: seniorDevelopers,
    },
    {
        id: 3,
        title: "Project Managers",
        description: "Our project managers are well aware of how to handle and execute projects. Alta Tech Mark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.",
        src: projectManagers,
    },
    {
        id: 4,
        title: "UI/UX Designers",
        description: "Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.",
        src: uiUxDesigners,
    },
    {
        id: 5,
        title: "Web Developers",
        description: "Web Developers of Alta Tech Mark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.",
        src: webDevelopers,
    },
    {
        id: 6,
        title: "Testers",
        description: "Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.",
        src: testers,
    }
]