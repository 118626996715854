import React from 'react';

// data
import { hireCakePhpDevData, hireCakePhpDevFaqs } from '../../../data/hireDev/hireCakePhpDev';
import cpDev1 from '../../../assets/pages/hireDev/cpDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireCakePhpDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="CAKEPHP DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={cpDev1}
            data={hireCakePhpDevData}
            faq={hireCakePhpDevFaqs}
        />
    )
}
