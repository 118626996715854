import sc3 from '../../../assets/pages/service/blockchainDev/sc3.png';
import sc4 from '../../../assets/pages/service/blockchainDev/sc4.png';

export const smartContractDevData = [
    {
        id: 1,
        title: "What Is A Smart Contract?",
        descriptions: [
            `You may trade anything of value using smart contracts transparently and without the need for an intermediary. Digital computer protocols known as "smart contracts" are supported by the capacity to monitor the conditions of commercial agreements. Fair trade of products and services between the exchanging parties is made possible by the smart contracts. An advancement on the Ethereum blockchain is the smart contract. That is the primary component of the technology used to modify the Ethereum cryptocurrency from the Blockchain of Bitcoin. With the expansion of software development scenario options, smart contracts have expanded as well. With the advent of Ethereum in 2015, smart contracts were first put into practice.`,
            "One of the top Blockchain Development Companies, Alta Tech Mark excels at creating decentralized smart contracts. Our talented group of blockchain developers specializes in creating cutting-edge decentralized applications, or DAPPs, across a range of sectors. A group of Alta Tech Mark professionals collaborates with customers to verify, create, and enhance smart contracts, guaranteeing secure, precise, and truthful transactions."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Main Benefits of Smart Contracts:",
        description: "",
        onlyBullets: true,
        data: [
            "Quick, Reliable & Immutable",
            "Automatic Monitoring",
            "Clear Recordkeeping",
            "Decentralized Performance",
            "Creation of New Business Models",
            "Intermediary-free Transactions",
            "A Self-auditing Mechanism of Digital Value",
            "High Accuracy",
            "Associated with Cryptocurrency",
            "Automatic Fulfillment of Orders"
        ],
        bulletsPic: sc3
    },
    {
        id: 3,
        title: "Industry Segments Using Smart Contracts:",
        description: "",
        onlyBullets: true,
        data: [
            "Accounting and Finance",
            "Insurance",
            "Trading",
            "Healthcare",
            "Warehouse",
            "Delivery",
            "Transport & Logistics",
            "Real Estate",
            "Information Technology",
        ],
        bulletsPic: sc4
    },
    {
        id: 4,
        title: "The procedure we use to develop smart contracts is as follows:",
        description: "",
        onlyBullets: true,
        data: [
            "Requirement Gathering",
            "Technical Design",
            "Development",
            "Deployment",
            "Upgrades",
        ]
    },
    {
        id: 5,
        title: "Technologies Used in the Development of Smart Contracts:",
        description: "",
        onlyBullets: true,
        data: [
            "Solidity",
            "Ethereum",
            "Microsoft Azure",
            "Nodejs",
            "WM JS",
        ]
    },
    {
        id: 6,
        title: "The Reasons To Employ Alta Tech Mark For The Development Of Smart Contracts:",
        descriptions: ["Our outstanding team of developers at Alta Tech Mark has extensive experience and a track record of success in providing smart contract development services and solutions that are customized to meet the needs of our customers. Whether you need to use smart contracts to safeguard your transactions or accept payments in cryptocurrency, our services for developing smart contracts guarantee that your goals are achieved in the most economical and effective way possible."],
        onlyBullets: false,
        data: [],
    },
    {
        id: 7,
        title: "Our Capabilities for Developing Smart Contracts:",
        description: "",
        onlyBullets: true,
        data: [
            "Strong Blockchain & Smart contracts development team",
            "Customizable and scalable solutions",
            "Web platforms using Phoneix / Elixir and serverless architecture",
            "Quick - responder to your business needs",
            "Development with Web3, Solidity, Cakeshop, and Truffle",
        ]
    }
]