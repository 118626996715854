export const hireAngularDevData = [
    {
        id: 1,
        title: "Why AngularJS Stands Out in Web Development:",
        descriptions: [
            "AngularJS has transformed the landscape of web development since its inception. It brings with it a plethora of advantages that make it an ideal choice for developing dynamic and interactive web applications. Let's delve into the remarkable features of AngularJS and why businesses should consider hiring AngularJS developers."
        ],
        onlyBullets: false,
        noList: true,
        data: [
            {
                title: "Two-Way Data Binding",
                description: "AngularJS simplifies the synchronization between the DOM and the model, making it easier to build dynamic and real-time applications. This feature ensures that when the data model is updated, the view reflects the changes, and vice versa, without any additional effort."
            },
            {
                title: "Dependency Injection",
                description: "AngularJS has a built-in dependency injection system that makes the development and testing of applications easier by allowing developers to ask for dependencies rather than looking for them."
            },
            {
                title: "Directives",
                description: "AngularJS provides the ability to create custom HTML tags that serve as new, custom widgets. It can be mixed with existing HTML commands or to create reusable components."
            },
            {
                title: "Comprehensive",
                description: "AngularJS offers a full solution for seamless front-end development, eliminating the need to use other plugins or frameworks."
            },
            {
                title: "Testability",
                description: "AngularJS is designed with testability in mind. It supports both unit and end-to-end testing, ensuring that applications are robust and bug-free."
            }
        ]
    },
    {
        id: 2,
        title: "Alta Tech Mark's Edge in AngularJS Development:",
        description: "Experience: With a decade-long experience in AngularJS development, Alta Tech Mark has a proven track record of delivering exceptional web applications that cater to diverse industry needs.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 20,
                title: "Expertise",
                description: "Our developers possess a deep understanding of the AngularJS framework, ensuring that the applications developed are scalable, maintainable, and aligned with best practices."
            },
            {
                id: 21,
                title: "Affordability",
                description: "Alta Tech Mark believes in delivering quality without causing a dent in your pockets. Our cost-effective solutions guarantee the best ROI."
            },
            {
                id: 22,
                title: "Custom Solutions",
                description: "Every business is unique, and so are its requirements. Our developers adopt a tailored approach, ensuring that the applications are in sync with the client's vision and objectives."
            },
            {
                id: 23,
                title: "Support and Maintenance",
                description: "Beyond development, our team is committed to providing continuous support, ensuring that your application remains updated and aligned with the latest technological trends."
            }
        ]
    },
]