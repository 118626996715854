import React from 'react';

// assets
import img1 from '../../../../assets/pages/service/mobileAppDev/ionicAppDev/1.png';
import { ionicDevData } from '../../../../data/services/mobileAppDevData/ionicDevData';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from '../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function IonicAppDev() {
    return (
        <ServicesWrapper
            title="Ionic App Development"
            description="We Develop Powerful Ionic Based Apps For You!"
            img={img1}
        >
            <DevGridWrapper>
                <DataMapper data={ionicDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
