import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// data
import { pricingData } from '../../../data/hireDev/pricingData';

// components
import HeaderText from '../../headerText/HeaderText';
import PricingCard from './pricingCard/PricingCard';

export default function PricingPlan(props) {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className={`${!props.mini && "tw-px-[9.115vw] tw-py-[4vw]"}`}>
            <HeaderText
                className={`${props.mini ? '' : 'tw-text-center'} tw-my-4`}
                colorTitleStart="PRICING"
                description="Our group of specialists is well-versed in each of their specialized domains. Their strategy is innovative, cutting edge, and unmatched in the industry."
            />

            {screenWidth <= 750 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {pricingData.map(pricing => <SwiperSlide key={pricing.id}>
                        <div className='tw-h-[450px]'>
                            <PricingCard
                                mini={false}
                                title={pricing.title}
                                description={pricing.description}
                                price={pricing.price}
                                perTime={pricing.perTime}
                                inSwiper={true}
                                bgPolygon={pricing.bgPolygon}
                                bgPolygonCode={pricing.bgPolygonCode}
                            />
                        </div>
                    </SwiperSlide>
                    )}
                </Swiper>

                :
                <div className={`tw-flex tw-flex-col tw-w-full ${props.mini ? 'tw-items-start tw-p-4 tw-gap-y-[10px]' : 'tw-items-center tw-py-[2.081vw] tw-gap-y-[40px]'} ${props.mini && 'tw-shadow-lg tw-rounded-lg'}`}>
                    <div className={`tw-grid tw-w-full ${props.mini ? 'tw-grid-cols-1' : 'tw-grid-cols-3'} tw-gap-[30px]`}>
                        {pricingData.map(pricing => <PricingCard
                            key={pricing.id}
                            mini={props.mini}
                            title={pricing.title}
                            description={pricing.description}
                            price={pricing.price}
                            perTime={pricing.perTime}
                            bgPolygon={pricing.bgPolygon}
                            bgPolygonCode={pricing.bgPolygonCode}
                        />)}
                    </div>
                </div>
            }
        </div>
    )
}
