import React from 'react';

// assets
import chatLive from '../../../assets/icons/chatLive.png';

// component
import HeaderText from '../../headerText/HeaderText';
import ApplyNowForm from '../applyNowForm/ApplyNowForm';

export default function ApplyNow() {
    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='tw-px-[9.115vw] tw-bg-purple tw-h-[450px] tw-mt-[8.138vw]'>
                <HeaderText
                    colorTitleStart="APPLY"
                    title='NOW'
                    className='tw-text-center tw-mb-8 tw-relative tw-bottom-[300px]'
                    description="Interested in working together? Apply now to become a part of our team"
                />
                <ApplyNowForm className='tw-relative tw-bottom-[300px]' />
                <div className='tw-grid tw-w-full tw-place-items-center'>
                    <h1 className='md:tw-text-4xl tw-text-xl tw-text-center tw-font-semibold tw-text-white tw-my-8 tw-relative tw-bottom-[280px]'>Be a part of something great!</h1>
                    <div className='tw-flex md:tw-flex-row tw-flex-col md:tw-w-[70%] md:tw-items-center tw-items-start tw-justify-around tw-text-white tw-relative tw-bottom-[280px]'>
                        <div className='tw-flex tw-items-center tw-gap-x-[25px]'>
                            <img src={chatLive} alt="chat" />
                            <div className='tw-flex tw-flex-col tw-items-start'>
                                <h1 className='tw-text-lg tw-font-semibold'>Mail your resume</h1>
                                <h1>hr@altatechmark.com</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
