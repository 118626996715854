import React from 'react';

// assets
import m1 from '../../../../assets/pages/service/gameDev/m1.jpeg';
import m2 from '../../../../assets/pages/service/gameDev/m2.png';
import m5 from '../../../../assets/pages/service/gameDev/m5.png';
import m6 from '../../../../assets/pages/service/gameDev/m6.png';
import { metaverseDevData } from '../../../../data/services/gameDevData/metaverseDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function MetaverseDev() {
    return (
        <ServicesWrapper
            title="METAVERSE"
            descriptionCont="Employing game developers from a reputable firm that specializes in game creation may help you and every other player take your gameplay to the next level."
            img={m1}
        >
            <DevGridWrapper images={[m2, m5, m6]}>
                <DataMapper data={metaverseDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
