export const xamarinDevData = [
    {
        id: 0,
        title: "",
        descriptions: [
            "Xamarin allows you to create cross-platform iOS and Android mobile apps using the powerful C# language and common code. With the usage of a hybrid mobile application, companies may interact with their workers, valued clients, and physical assets at any time and from any location. Creating best-in-class apps for several mobile platforms, on the other hand, saves a lot of time and money.",
            "Clients and workers may benefit from a real-time business environment created by integrating Xamarin apps with cloud, IoT, and analytics technologies. It has the ability to reuse code from other applications. To reach more people it uses net libraries and creates cross-platform tablet and smartphone apps."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 1,
        title: "Advantages Of Xamarin Cross-platform App Development",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "High-Performance Compile Code",
                description: 'All apps are written in C# using Xamarin, and the code can be shared across all mobile development platforms.'
            },
            {
                id: 2,
                title: "Automated Testing",
                description: "A Xamarin app can be tested using the Xamarin Test Cloud. It saves time and effort for developers who would otherwise have to perform manual testing."
            },
            {
                id: 3,
                title: "Native User Interfaces",
                description: "Native UI controls used in Xamarin app development. This allows developers to construct native apps that make use of a variety of device-specific experiences."
            },
            {
                id: 4,
                title: "Fewer Bugs",
                description: "Xamarin helps developers to create applications with the fewest possible problems. Furthermore, it offers a wide variety of protection to the apps."
            },
            {
                id: 5,
                title: "Reduced Time-to-Market",
                description: "Using Xamarin to create a cross-platform app eliminates the need for several apps for different operating systems. It also eliminates the need for a high learning curve."
            }
        ]
    },
    {
        id: 2,
        title: "Top-Notch Xamarin App Development Company - Alta Tech Mark",
        descriptions: [
            "We have a skilled team of Xamarin engineers, designers, developers, and QA Engineers that accomplish Xamarin app development using Visual Studio for diverse user interfaces as an experienced Xamarin mobile app development business. Furthermore, we provide the best Xamarin development solutions that always fit with your company objectives and allow us to deliver cross-platform mobile apps for multiple Base Class Libraries by using our deep understanding of Xamarin. We at Alta Tech Mark are proud of our excellent Xamarin development process, which allows us to meet the expectations of a wide range of corporate app development projects.",
            "We create apps that appear the same across platforms and have a native feel in a short amount of time. We provide comprehensive Xamarin mobile app solutions thanks to our tight collaboration of product managers, business architects, designers, developers, server engineers, and marketers.",
            "We also develop custom Xamarin Mobile App Services for our customers, as we recognize the relevance of their requirements. We have a strong technical Xamarin development team that understands the client's needs and helps them achieve their project's goals. You contact us with your ideas, and we will create great apps tailored to your needs."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 3,
        title: "Find Out Xamarin App Development Services From Alta Tech Mark",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Business Centric Solutions",
                description: "We provide strong and scalable business-specific Xamarin cross-platform mobile application solutions with the goal of meeting your particular needs and assisting you in reaching your goals."
            },
            {
                id: 2,
                title: "Enterprise Xamarin App Development",
                description: "We create highly secure and scalable iOS and Android mobile apps from the ground up with Xamarin's next-generation components, as well as strong back, leveraging agile methods and best coding practices."
            },
            {
                id: 3,
                title: "Migration And Up-gradation Services",
                description: "We can also assist you with a system upgrade of an existing Xamarin cross-platform program to the most recent available version. Apart from that, if you already have a platform in Xamarin PCL or Shared frameworks, such as Android or iOS, we can help you shift the program to another platform with minimal effort and cost."
            },
            {
                id: 4,
                title: "Xamarin App Consulting Services",
                description: "Do you want to learn more about Xamarin cross-platform app development? If you have any queries about your hybrid app development needs, you can reach out to our knowledgeable and highly competent Xamarin consultants. We would be delighted to assist you at any time."
            },
            {
                id: 5,
                title: "Support And Maintenance Services",
                description: "Our Xamarin development team is accessible 24/7 to provide you with the technical help you need for architectural creation, cloud integration, and maintenance. We also provide an SLA-based ticket resolution system, in which ticket resolution time is rigorously adhered to a set and communicated SLA with you."
            },
        ]
    },
    {
        id: 4,
        title: "Why You Should Hire Alta Tech Mark For Xamarin App Development?",
        description: "",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Expert Team",
                description: "Alta Tech Mark is one of the finest Xamarin app development firms, has a team of professionals who build Xamarin apps that are faster and more cost-effective while supporting all mobile devices and platforms."
            },
            {
                id: 2,
                title: "In-depth Knowledge",
                description: "Our experts have extensive experience in Xamarin cross-platform app development, as well as C#, Java, and Objective-C, and can therefore efficiently design an app that operates on a variety of platforms and devices."
            },
            {
                id: 3,
                title: "Adhere Manage",
                description: "We do not leave our client and project just like that; we believe in long-term relations and manage the project with quality and relevant information."
            },
            {
                id: 4,
                title: "On-Time Delivery",
                description: "We do not like to waste our client's unnecessary time; we believe in punctuality and deliver the project on time. Our discipline and dedication towards work are what the client loved the most, and we tend to sustain it."
            },
            {
                id: 5,
                title: "Maintenance And Support",
                description: "Our Flutter developers ensure the support and maintenance to get reliable and scalable projects. The team is there at every stage of development, delivery, and deployment."
            }
        ]
    }
]