import clientCentric from '../assets/keyFeatures/client-centric-development.png';
import agile from '../assets/keyFeatures/agile.png';
import dedicatedDevelopers from '../assets/keyFeatures/dedicated-development-team.png';
import support from '../assets/keyFeatures/excellent-support.png';
import dataBackups from '../assets/keyFeatures/data-backups.png';
import dataProtection from '../assets/keyFeatures/data-protection.png';
import qa from '../assets/keyFeatures/qa.png';
import enhancement from '../assets/keyFeatures/enhancement.png';

export const keyFeaturesData = [
    {
        id: 1,
        src: clientCentric,
        title: 'Customer-Focused Development',
        description: "We shape solutions for web, mobile, and blockchain based on our clients' requirements, aiming to boost their business growth."
    },
    {
        id: 2,
        src: agile,
        title: 'Adaptive Development',
        description: "We adopt an Adaptive Development approach, ensuring high-quality outcomes and a robust product that supports scalable businesses."
    },
    {
        id: 3,
        src: dedicatedDevelopers,
        title: "Committed Developer Team",
        description: "Our proficient team tackles projects head-on, dedicating their utmost efforts and persisting until the task is completed."
    },
    {
        id: 4,
        src: support,
        title: "Unmatched Support",
        description: "We stand ready to support our clients, striving to exceed their expectations and requirements at every turn."
    },
    {
        id: 5,
        src: dataBackups,
        title: "Secure Data Backups",
        description: "Backups are crucial. We diligently maintain project backups for our clients, ensuring we're prepared for unforeseen challenges."
    },
    {
        id: 6,
        src: dataProtection,
        title: "Secure Data Handling",
        description: "With a signed NDA, we prioritize the security of your confidential data, consistently delivering top-notch products with a commitment to privacy."
    },
    {
        id: 7,
        src: qa,
        title: "Commitment to Quality",
        description: "We are devoted to delivering superior products to our clients, ensuring alignment with their project specifications at competitive industry prices."
    },
    {
        id: 8,
        src: enhancement,
        title: "Continuous Improvement",
        description: "Throughout the Discovery, Design, and Development stages, our tech team remains proactive, offering insights and modifications to optimize the product continually."
    },
]