import React from 'react';

// assets
import tts1 from '../../../../assets/pages/service/aiAndMl/tts1.jpeg';
import tts2 from '../../../../assets/pages/service/aiAndMl/tts2.png';
import { textToSpeechData } from '../../../../data/services/aiAndMl/textToSpeechData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function TextToSpeech() {
    return (
        <ServicesWrapper
            title="Text To Speech"
            descriptionCont="Use a custom AI and ML engine to turn your text into voice overs for a more effective application. It makes it possible for your application to speak and to build a whole product around voice commands."
            img={tts1}
        >
            <DevGridWrapper img={tts2}>
                <DataMapper data={textToSpeechData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
