import React from 'react';

// assets
import cakephp1 from '../../../../assets/pages/service/WebAppDev/cakephp1.png';
import cakephp2 from '../../../../assets/pages/service/WebAppDev/cakephp2.png';
import { cakePhpDevData } from '../../../../data/services/webAppDevData/cakePhpDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function CakePhpAppDev() {
    return (
        <ServicesWrapper
            title="CAKE PHP DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={cakephp1}
        >
            <DevGridWrapper img={cakephp2}>
                <DataMapper data={cakePhpDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
