export const drupalDevData = [
    {
        id: 1,
        title: "Why The Drupal Content Management System Is So Dependent on Organizations",
        descriptions: [
            "You are essentially following in the footsteps of several well-known websites, including those operated by the US government and numerous organizations, when you choose to use the Drupal open-source CMS (content management system). However, you may get this free CMS under a GNU General Public License even if all you desire is for your own personal blog site.",
            "The main features of Drupal may have some similarities with other content management systems (CMS) if you have used them before. The creation of user accounts, maintaining them, RSS feeds, managing menus, adjusting page layouts, and even system administration are a few instances. This implies that regardless of how basic or sophisticated your demands are, you could find it straightforward to modify Drupal to meet them. Creating a multi-user blog site using Drupal is a straightforward application, however creating a community discussion forum is a more complicated one. This might be the reason why significant organizations like the US government find this CMS beneficial.",
            "Since the CMS is free, the open-source community itself makes improvements to it. Consequently, in the event that you have questions or would want to make changes to any part of the CMS, you may seek assistance from the user community. Installing and maintaining it on your website should be rather simple, but in the event that you run into issues, it could be wise to have Alta Tech Mark handle the CMS installation and administration. By working with Alta Tech Mark, you can be sure that your CMS will run more efficiently and you won't have any downtime because of technical issues."
        ],
        onlyBullets: false,
        data: []
    }
]