import React from 'react'

export default function TalentCard(props) {
    return (
        <div className={`tw-col-span-1 tw-flex tw-flex-col ${props.talentCard ? "tw-items-start tw-gap-y-[20px] tw-bg-cardsGradient tw-text-white" : "tw-items-center tw-gap-y-[40px] tw-bg-white"} tw-shadow-lg tw-rounded-lg tw-p-[1.625vw] tw-px-[3.542vw] ${props.className}`}>
            <img src={props.src} alt='icon' className='tw-w-[55px] tw-h-[55px]' />
            <h1 className={`${props.talentCard ? 'tw-text-start' : 'tw-text-center'} tw-font-semibold tw-text-lg`}>{props.title}</h1>
            <h1 className={`${props.talentCard ? 'tw-text-start' : 'tw-text-center'}`}>{props.description}</h1>
        </div>
    )
}
