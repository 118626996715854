export const companyHoverData = [
    {
        id: 1,
        title: 'About Alta Tech Mark',
        url: '/company/aboutUs'
    },
    {
        id: 2,
        title: 'Portfolio',
        url: '/resources/caseStudy'
    },
    {
        id: 3,
        title: 'Alta Tech Blogs',
        url: '/resources/blog'
    },
    {
        id: 4,
        title: 'Careers',
        url: '/company/career'
    }
];