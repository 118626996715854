export const hireDartDevData = [
    {
        id: 1,
        title: "",
        description: "Dart, championed by Google, has emerged as a robust and versatile programming language. Introduced in 2011, its power lies in its open-source nature, object-oriented and class-defined features that enable developers to craft efficient mobile and web applications.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Dart is Making Waves:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Cross-Platform Development",
                description: "With Dart and the Flutter SDK, developers can create native applications that are compatible across multiple platforms, such as iOS, Android, and Windows. This means businesses can reach a broader audience without reinventing the wheel for each platform."
            },
            {
                id: 2,
                title: "Seamless Client-Server Integration",
                description: "Unlike many languages that specialize either in front-end or back-end development, Dart is adept at both. This ensures a seamless experience, as the same language powers both client and server sides of an application."
            },
            {
                id: 3,
                title: "Surge in Popularity",
                description: "The 2020 market research showcases Dart's meteoric rise, positioning it as the seventh most popular programming language. Its growth trajectory indicates a promising future, and its adoption is expected to rise further."
            },
            {
                id: 4,
                title: "Flutter's Influence",
                description: "Flutter, the UI toolkit for crafting natively compiled applications from a single codebase, leverages Dart. The increasing adoption of Flutter for mobile and web app development amplifies the demand for Dart developers."
            }
        ]
    },
    {
        id: 3,
        title: "Why Businesses Should Consider Dart:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Cost-Efficiency",
                description: "Instead of maintaining separate codebases for different platforms, businesses can invest in a single Dart codebase, saving time and resources."
            },
            {
                id: 2,
                title: "Unified Experience",
                description: "Applications built using Dart provide a consistent and unified user experience across various devices and platforms."
            },
            {
                id: 3,
                title: "Community Support",
                description: "Being backed by Google, Dart enjoys strong community support. Regular updates, a plethora of libraries, and extensive documentation ensure that developers have all the tools they need."
            },
            {
                id: 4,
                title: "Talent Pool",
                description: "As Dart's popularity rises, there is a growing talent pool of Dart and Flutter developers. Businesses can tap into this expertise to stay ahead in the competitive landscape."
            }
        ]
    },
]


export const hireDartDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Dart developers?",
        answer: "The dedicated Dart developers will work only for you to deliver outstanding results with the highest optimization. The team of Dart developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire Dart developers?",
        answer: "Alta Tech Mark offers a very simple process to hire Dart developers. Just provide project details and the technical expertise you need. The team of Dart developers will begin working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Dart developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Dart developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Dart developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Dart developer, then you can hire dedicated Dart developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Dart developers?",
        answer: "The cost estimation for dedicated Dart developers varies based on various factors such as development team size, hiring model, the complexity of the app development, the type of solution, features and functionalities and many more."
    }
];