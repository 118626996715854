import React from 'react';

// data
import { hirePhpDevData, hirePhpDevFaqs } from '../../../data/hireDev/hirePhpDev';
import phpDev1 from '../../../assets/pages/hireDev/phpDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HirePhpDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="PHP DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={phpDev1}
            data={hirePhpDevData}
            faq={hirePhpDevFaqs}
        />
    )
}
