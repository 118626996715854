import df3 from '../../../assets/pages/service/aiAndMl/df3.png';
import df4 from '../../../assets/pages/service/aiAndMl/df4.png';

export const dataForecastingData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Forecasting and data are the two elements that are now necessary for running a firm. Therefore, by offering Data Forecasting, Artificial Intelligence and Machine Learning facilitate it. It aids in making predictions about forthcoming events. A technique for producing historical and present data to get meaningful and useful information is called data forecasting. In the end, it facilitates and improves decision-making for enterprises.",
            "Real-time data will increase opportunities and reduce unclear dangers. Data forecasting increases the dependability of your company via data collection, analysis, visualization, and other methods. Understanding user behavior, preferences, and behaviors will be beneficial. Data forecasting will assist companies in implementing the required innovation and development. To forecast the future of a corporation, data forecasting aggregates information from the past and present. Excellent data forecasting services are offered by Alta Tech Mark to improve and accelerate work. Data forecasting has made prediction simpler and quicker."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Benefits of Data Forecasting:",
        description: "Gain Deeper Insight: Data forecasting makes it possible for a firm to obtain relevant information, which is necessary in order to gain deeper insight. The service aids in implementing the data at the proper and suitable moment. The likelihood of obtaining dependable and accurate data insight increases with data forecasting. It's a fairly scalable resource for enterprises.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Boost Business Performance",
                description: "Data forecasting is an excellent and convincing tool for making decisions. It may be changed by users or clients using predictive analytics. Additionally, it raises the bar for corporate performance improvement. Data forecasting improves corporate success whether dealing with little or large amounts of information."
            },
            {
                id: 2,
                title: "Correct Prediction",
                description: "Although there is always a chance of error, data forecasting software makes sure that predictions are generally correct. Accurately, it also demonstrates how dependable and handy it is for both consumers and companies. With a low risk ratio, an accurate forecast increases business. Accurate prediction also reduces the likelihood of losing."
            },
            {
                id: 3,
                title: "Recognize Future Trends",
                description: "Data forecasting makes it simpler to predict future trends. The prediction may swiftly predict future trends even if it is just based on facts and information obtained from the past and present. Businesses will benefit from being one step ahead of their rivals. Based on anticipated trends, organizations may also make a variety of choices."
            },
            {
                id: 4,
                title: "Improved Decision-Making",
                description: "Without data forecasting, decision-making would never be this simple. Moreover, having a trustworthy and accurate source of facts and information strengthens the ability to make decisions. Because it is executed in real-time, it saves time and is reasonably priced. You may utilize the time you save for other tasks or activities."
            },
            {
                id: 5,
                title: "Boost Productivity",
                description: "Accurate data and information combined with cost-effective and time-saving methods provide more productivity. Data forecasting contributes to increased productivity when other aspects are taken into account. The projection also tells you what areas of the company to ignore and where to concentrate more. Productivity categorizes necessary job roles and satisfactory performance."
            }
        ]
    },
    {
        id: 3,
        title: "Use Cases for Data Forecasting:",
        description: "",
        onlyBullets: true,
        data: [
            "Customer Segmentation",
            "Churn Prediction",
            "Credit Scoring",
            "Lifetime Value Optimization",
            "Recommendation Systems",
            "Dynamic Pricing",
        ],
        bulletsPic: df3
    },
    {
        id: 4,
        title: "Different Approaches And Strategies For Data Forecasting:",
        description: "The focus of time-series analysis is on data and information that changes over time. A time frame is used to prepare the analytics. Its resources are limited, and the analysis's outcome is only valid for a brief period of time.",
        onlyBullets: false,
        data: []
    },
    {
        id: 5,
        title: "Qualitative Technique:",
        description: "This method is best suited for professionals who like to measure a specific region or a few areas. Even if there isn't a lot of information or data, it provides you with a qualitative foundation. The lengthier and more involved activities are worthwhile.",
        onlyBullets: false,
        sectionPic: df4,
        data: []
    },  
    {
        id: 6,
        title: "For data forecasting, why Alta Tech Mark?",
        description: "The Alta Tech Mark team will assist you in developing the most effective data forecasting technique possible—a organized path from data to deployment. Alta Tech Mark prioritizes risk reduction while also taking hypothetical scenarios into account. Data-driven growth and scalable improvement are supported by our solution. When it comes to making effective company plans and choices, Alta Tech Mark gives the best and offers vital data. Our group tactics have shown to provide precise and insightful information to the company. Developers and analysts at Alta Tech Mark are skilled in analyzing markets and producing effective development models that advance businesses.",
        onlyBullets: false,
        data: []
    }
]