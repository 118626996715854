import r1 from '../assets/reviews/r1.png';
import r2 from '../assets/reviews/r2.png';
import r3 from '../assets/reviews/r3.png';
import r4 from '../assets/reviews/r4.png';
import r5 from '../assets/reviews/r5.png';
import r6 from '../assets/reviews/r6.png';
import r7 from '../assets/reviews/r7.png';
import r8 from '../assets/reviews/r8.png';
import r9 from '../assets/reviews/r9.png';
import r10 from '../assets/reviews/r10.png';
import r11 from '../assets/reviews/r11.png';
import r12 from '../assets/reviews/r12.png';
import r13 from '../assets/reviews/r13.png';
import r14 from '../assets/reviews/r14.png';

import usa from '../assets/reviews/usa.png';
import kenya from '../assets/reviews/kenya.png';
import hongkong from '../assets/reviews/hongkong.png';
import dubai from '../assets/reviews/dubai.png';
import australia from '../assets/reviews/australia.png';
import uk from '../assets/reviews/uk.png';
import canada from '../assets/reviews/canada.png';
import bolivia from '../assets/reviews/bolivia.png';
import egypt from '../assets/reviews/egypt.png';
import southAfrica from '../assets/reviews/southAfrica.png';

export const reviewsData = [
    {
        id: 1,
        src: r1,
        review: "To the teams of Alta Tech Mark - excellent job done with very smooth and communication! The teams are very knowledgeable and had given us lots of valuable advices.",
        name: "James",
        business: "Neighbor Shopper",
        location: "Massachusetts, USA",
        flag: usa
    },
    {
        id: 3,
        src: r3,
        review: "Alta Tech Mark is dependable and responsive to client's request. Always easy and convenient to communicate with them for any issues and support. Gladly recommend them to anyone who wants to build ideas into real products.",
        name: "Ivan Lau",
        business: "CEO & Founder - OhMyHelp",
        location: "Hong Kong",
        flag: hongkong
    },
    {
        id: 4,
        src: r4,
        review: "The client appreciates being able to speak in layman terms to Alta Tech Mark's project manager. Their technical knowledge and suggestions influenced the evolution of the client's vision.",
        name: "Sujai Soy",
        business: "Skkyn Technologies - Owner",
        location: "Dubai, UAE",
        flag: dubai
    },
    {
        id: 5,
        src: r5,
        review: "The founder of Alta Tech Mark and the rest of his project team have placed customer service through responsiveness and proper communication above all else, leading to a strong initial product and the opportunity to continue the partnership through multiple iterations and enhancements.",
        name: "Brendan Buchholz",
        business: "Founder, Cool Charm Friends",
        location: "Brisbane, Australia",
        flag: australia
    },
    {
        id: 6,
        src: r6,
        review: "Thoughtfully integrating consumer feedback to improve the UX/UI, Alta Tech Mark has developed a series of consistently improved mobile app iterations. Their competitive pricing structure pairs value with cost savings. The project manager was exceptionally skilled and communicative.",
        name: "Arhad Khwaja",
        business: "President, Fast Food Franchise",
        location: "New York, USA",
        flag: usa
    },
    {
        id: 7,
        src: r7,
        review: "Alta Tech Mark was able to communicate effectively with the client, despite the fact that both parties were using a second language, delivering on all project requirements. The client notes that Alta Tech Mark works well as a team and is very responsive.",
        name: "Matthew Berwick",
        business: "CoFounder, Buuurp!",
        location: "UK",
        flag: uk
    },
    {
        id: 8,
        src: r8,
        review: "Alta Tech Mark was diligent and responsive in solving any issues that came up throughout the development process and still provides excellent support when they are needed. The client feels that Alta Tech Mark has really gone above and beyond with their service.",
        name: "Omar Boyd",
        business: "CoFounder, OanZ Holdings",
        location: "Canada",
        flag: canada
    },
    {
        id: 9,
        src: r9,
        review: "Alta Tech Markonal, communicative, and understanding throughout this partnership, and their mobile apps have received very positive feedback from users, who note that it is much nicer and more user friendly than others of its kind.",
        name: "Sumood Almesallam",
        business: "General Manager, Al Salam, Kuwait City",
        location: "Kuwait",
        flag: dubai
    },
    {
        id: 10,
        src: r10,
        review: "Alta Tech Mark went above and beyond. Their accommodating and adaptable approach supplemented their professional management style. Alta Tech Mark takes scope changes in stride. Customers can expect a flexible and communicative team that delivers on time and within budget.",
        name: "Jorge Mealla",
        business: "CEO, Ticketing Startup",
        location: "Bolivia",
        flag: bolivia
    },
    // {
    //     id: 11,
    //     src: r11,
    //     review: "Users have reviewed the app positively. Alta Tech Mark was responsive and provided excellent support. Their follow-up is particularly noteworthy.",
    //     name: "Owner",
    //     business: "Food Ordering App",
    //     location: "United Arab Emirates",
    //     flag: dubai
    // },
    {
        id: 12,
        src: r12,
        review: "Alta Tech Mark met all of the client's requirements for the application and made suggestions for improvement when possible. The client describes Alta Tech Mark as flexible, knowledgeable, and understanding.",
        name: "Co-Founder",
        business: "Motor Video Company",
        location: "London, UK",
        flag: uk
    },
    {
        id: 13,
        src: r13,
        review: "Alta Tech Mark's team was responsive, professional, and accommodating to concerns. They delivered a high-quality game that surpassed all expectations; it achieved 1,700 downloads on Android in 3 weeks and 500 iOS downloads in only a week without much marketing.",
        name: "Tarek Oraby",
        business: "Click4Services - Owner",
        location: "Cairo, Egypt",
        flag: egypt
    },
    {
        id: 14,
        src: r14,
        review: "The apps meet high-quality standards and include many robust features. Alta Tech Mark is clearly invested in the project's success. The team suggests creative ideas, shares detailed progress reports, and always delivers on time.",
        name: "Dwayne Van Tonder",
        business: "Founder, Van Holdings",
        location: "Johannesburg, South Africa",
        flag: southAfrica
    },
]