import React from 'react';

// assets
import bi1 from '../../../../assets/pages/service/aiAndMl/bi1.jpeg';
import bi2 from '../../../../assets/pages/service/aiAndMl/bi2.png';
import { businessIntelData } from '../../../../data/services/aiAndMl/businessIntelData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function TextToSpeech() {
    return (
        <ServicesWrapper
            title="Business Intelligence"
            descriptionCont="The service's objective is to optimally accomplish aims and goals. Excellent business intelligence services are provided by Alta Tech Mark, which provides a comprehensive end-to-end solution for a variety of business intelligence and analytics services."
            img={bi1}
        >
            <DevGridWrapper img={bi2}>
                <DataMapper data={businessIntelData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
