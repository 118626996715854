import React from 'react';

export default function OurJourneyCard(props) {
    return (
        <div className={`tw-col-span-1 tw-grid tw-grid-cols-8 tw-gap-x-1 tw-relative ${props.id === 1 ? 'tw-top-[20%]' : props.id === 2 ? 'tw-top-[65%]' : props.id === 3 ? '' : props.id === 4 ? 'tw-top-[40%]' : 'tw-top-[20%]'}`}>
            <div className="tw-col-span-1 tw-w-fit">
                <img src={props.src} alt="icon" className='tw-w-[30px]' />
            </div>
            <div className='tw-flex tw-flex-col tw-col-span-7'>
                <h1 className='tw-font-semibold tw-text-[1.042vw] tw-leading-[1.563vw]'>{props.title}</h1>
                <h1 className='tw-text-[0.781vw] tw-leading-[1.042vw]'>{props.description}</h1>
            </div>
        </div>
    )
}
