import React from 'react';

// assets
import pd1 from '../../../../assets/pages/service/corporateBranding/pd1.png';
import { packagingDesignDevData, componentsOfPackagingDesignDevData, principlesOfPackagingDesignDevData, processOfPackagingDesignDevData } from '../../../../data/services/corporateBranding/packagingDesignDevData';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';
import CaseStudies from '../../../../components/home/caseStudies/CaseStudies';
import HeaderText from '../../../../components/headerText/HeaderText';

export default function PackagingDesign() {
    return (
        <ServicesWrapper
            title="PACKAGING DESIGN"
            descriptionCont="Packaging design is the connection of form, structure, materials, color, imagery, typography, and regulatory information with ancillary design elements to make a product suitable for marketing. To put that into a simpler form: Your packaging is an element of your marketing strategy."
            img={pd1}
        >
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={packagingDesignDevData} />

                <HeaderText
                    title="Components of"
                    colorTitleEnd="Packagin Design"
                    className="tw-text-center tw-mb-8 md:tw-mt-[11.458vw]"
                />
                <DataMapper data={componentsOfPackagingDesignDevData} />

                <HeaderText
                    title="Principles of"
                    colorTitleEnd="Packaging Design"
                    className="tw-text-center md:tw-mt-16"
                />
                <DataMapper data={principlesOfPackagingDesignDevData} />

                <HeaderText
                    title="Technical Processes and Tools"
                    className="tw-text-center md:tw-mt-16"
                />
                <DataMapper data={processOfPackagingDesignDevData} />
            </div>

            <CaseStudies colorTitleStart="PORTFOLIO" title=" " />
        </ServicesWrapper>
    )
}
