export const flutterDevData = [
    {
        id: 5,
        title: "",
        descriptions: [
            "Google flutter app development SDK, is the newly launched framework designed to support native development for both iOS & Android mobile apps by using a single code structure. Flutter is robust and reactive with widgets and tools that enable the development and deployment of UI with animations and a single code which works for different platforms.",
            "The most lucrative thing about Flutter framework is it provides a bridge to native, so developers can do almost everything that Kotlin/Swift/Java does. The core concepts for Flutter are connecting and drawing. Flutter can be rightly observed in applications that make use of the camera, Geolocation, network, storage, and 3rd party SDKs. All of this attributes to the latest standards in cross-platform developments which can be accomplished using the flutter app development framework.",
            "Alta Tech Mark is one of the top flutter application development companies. Despite the latest existence of flutter compared to other frameworks, we have still managed to get accustomed to its styles and methods of functioning by making us one of the best flutter application development companies. Our diverse and skilled team of developers can help sketch you the smartest and quickest solution for your cross-platform development projects and bring to life the expectations you aspire for your projects.",
            "We have been known far and wide for our endeavors taken under flutter frameworks. We passionately build beautiful and functionally-rich mobile apps, crafted to meet your specific demands. They help businesses, around the globe, develop next-gen apps that are expressive, flexible, and gives a delightful experience to their users - all in record time."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 1,
        onlyBullets: false,
        title: "Why You Should Choose Flutter App Development?",
        description: "",
        noList: true,
        data: [
            {
                id: 1,
                title: "Quick & Easy Code",
                description: "This platform entices developers by leveraging them with faster coding process. It enables faster debugging and analyzes the process to fix bugs. Moreover, the simple process enables for easy experimentation in the app features that improve functionality and the richness of the UI."
            },
            {
                id: 2,
                title: "Easy To Use",
                description: "Flutter architecture analyzes the development process for developers. It does this by providing integration when developers use other programming languages."
            },
            {
                id: 3,
                title: "Single Coding Based",
                description: "With the use of Flutter architecture, developers no longer have to give duplicate codes for different platforms. It allows only single code for both iOS and Android."
            },
            {
                id: 4,
                title: "Smooth Experience",
                description: "Flutter’s modern technology allows developers to build a faster and more fluent experience for end users, that makes it easier."
            },
            {
                id: 5,
                title: "Fast Testing",
                description: "As you don't have to develop different apps for different platforms so the testing becomes much faster. This also boosts your testing process."
            },
            {
                id: 6,
                title: "Widgets Creation",
                description: "Flutter architecture allows developers to make new widgets. The great widget library in the SDK allows it simple to customize a widget very quick."
            }
        ]
    },
    {
        id: 2,
        onlyBullets: true,
        title: "Our Flutter Application Development Services",
        description: "",
        data: [
            "Hire dedicated Flutter developers",
            "Quick support & maintenance",
            "Cross-Platform App Development",
            "Get personalized services with us",
            "Minimum Viable Product (MVP)",
            "End-to-End Project Management",
            "Custom server-side APIs for mobile",
            "Advanced app development approach",
            "Eye-Catching UI",
            "Accessible SDKs",
            "Native Features",
            "Hot Reload",
        ]
    },
    {
        id: 3,
        title: "Why You Should Choose Alta Tech Mark For Flutter App Development?",
        description: "Making use of Flutter framework, our Alta Tech Mark have skilled native app developers who can develop next-generation apps for iOS and Android in record time. Our cross-platform app developers are our most prized asset. Mixing the capabilities of Flutter with the sensible visually appeal of Material Design, we are making digital experiences for our clients’ end users to enjoy and return to every day.",
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Benefits of Hiring Team of Flutter App Developers From Alta Tech Mark",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "Skilled Team",
                description: "At Alta Tech Mark, we assign a skilled and dedicated team of experts for every project. This helps us to make a better solution for them."
            },
            {
                id: 2,
                title: "Long Experience",
                description: "Here, You will get decades of industry experience which makes us unbeatable in this IT industry and empowers us to serve a good product to clients with excellence."
            },
            {
                id: 3,
                title: "Unique Solutions",
                description: "Our team of experienced developers is presented with an invincible combination of creativity that helps us to make a unique and extraordinary solution."
            },
            {
                id: 4,
                title: "Support Team",
                description: "Our support team assures that all your queries are resolved on a priority basis."
            },
            {
                id: 5,
                title: "Well-defined Process",
                description: "We have a well-defined process to perform every web and app development project."
            }
        ]
    }
]