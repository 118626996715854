import React from 'react'

export default function HeaderText(props) {
    return (
        <div className={`${props.className}`}>
            <h1 className={`tw-font-semibold md:tw-text-3xl tw-text-xl tw-mb-[10px] ${props.titleClass}`}>
                <span className='tw-text-purple'>{props.colorTitleStart} </span>
                <span>{props.title}</span>
                <span>{props.title1} </span>
                <span className='tw-text-purple'>{props.colorTitleBetween} </span>
                <span>{props.title2}</span>
                <span className='tw-text-purple'> {props.colorTitleEnd}</span>
            </h1>
            {props.description &&
                <h1 className={`${props.descriptionClassName}`}>{props.description}</h1>
            }
        </div>
    )
}
