import React from 'react';

// assets
import bc1 from '../../../../assets/pages/service/ecommerceDev/bc1.jpeg';
import bc2 from '../../../../assets/pages/service/ecommerceDev/bc2.png';
import bc3 from '../../../../assets/pages/service/ecommerceDev/bc3.png';
import { bigCommerceDevData } from '../../../../data/services/ecommerceDevData/bigCommerceDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function BigCommerceDev() {
    return (
        <ServicesWrapper
            title="ECOMMERCE DEVELOPMENT BIGCOMMERCE"
            descriptionCont="Our E-Commerce website development professionals are experts. Alta Tech Mark developers provide high-quality, fast E-Commerce products. Our skilled staff develops E-Commerce websites utilizing cutting-edge, trustworthy technology. Magento, Shopify, BigCommerce, WooCommerce, and others"
            img={bc1}
        >
            <DevGridWrapper images={[bc2, bc3]}>
                <DataMapper data={bigCommerceDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
