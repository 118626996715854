import React from 'react'

export default function IndustriesCard(props) {
    return (
        <div className='tw-col-span-1 tw-mt-[16px] md:tw-px-[15px] tw-px-[5px] md:tw-py-[35px] tw-py-[15px] tw-flex tw-flex-col tw-items-center tw-gap-y-[25px] tw-shadow-xl tw-rounded-lg tw-bg-[#FFFFFF]'>
            <div className='tw-w-[50px] tw-h-[50px]'>
                {props.src}
            </div>
            <h1 className='md:tw-text-lg tw-text-[10px] tw-font-semibold tw-text-center'>{props.title}</h1>
        </div>
    )
}
