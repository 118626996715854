import React from 'react';

// assets
import bg from '../../../assets/brands/qatarTransport/bg.svg';
import logo from '../../../assets/brands/qatarTransport/logo.svg';
import right from '../../../assets/brands/qatarTransport/right.svg';
import overviewFloatingSpan from '../../../assets/brands/qatarTransport/overviewFloatingSpan.svg';
import easyToUse from '../../../assets/brands/qatarTransport/easyToUse.svg';
import seamlessExperience from '../../../assets/brands/qatarTransport/seamlessExperience.svg';

// components
import BrandsHero from '../../../components/home/brands/brandsHero/BrandsHero';
import ProjectOverview from '../../../components/home/brands/projectOverview/ProjectOverview';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function QatarTransport() {
    return (
        <div>
            <BrandsHero
                bg={bg}
                logo={logo}
                right={right}
                underlineTitle="QATAR"
                simpleTitle="Ministry of Transportation"
                description="The Qatar Ministry aimed to carry out an online survey via a mobile application, and the team at Alta Tech Mark accomplished this with the creation of a user-friendly app."
                rightClasses="md:tw-w-[30vw] md:tw-h-[20vw]"
            />

            <ProjectOverview
                overview="The Qatar Ministry sought to implement an online survey through a mobile application, and the team at Alta Tech Mark delivered by developing an app with a user-friendly interface and seamless functionality."
                overviewFloatingSpan={overviewFloatingSpan}
                overviewFloatingText="Since the website was aimed to promote the quest, we utilized all the possible touchpoints to draw visitors’ attention towards the challenge. From a visible registration form on the header banner to showcasing the leadership board, we attempted everything possible to maintain the spirit of the competition."
            />

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-gap-y-4 tw-relative tw-bg-[#8a0f48]'>
                <h1 className='md:tw-text-[2.635vw] tw-text-white tw-font-bold tw-text-center'>Easy-to-Use across all Devices</h1>
                <h1 className='md:tw-text-[1.171vw] tw-text-center tw-text-white'>The application needed to contain a mobile-friendly, easy to use user experience and hence it carried that essence. Team INGIC worked endlessly to achieve this goal and as a result, the application looked great and the output was great!</h1>
                <img className='tw-mx-auto' src={easyToUse} alt="ease" />
            </div>

            <div className='tw-py-[4vw] tw-px-[9.115vw] tw-grid tw-grid-cols-3 tw-gap-4 tw-bg-[#ffdf54]'>
                <img className='tw-mx-auto tw-col-span-2' src={seamlessExperience} alt="quest" />
                <div className='tw-flex tw-flex-col tw-items-center tw-col-span-1'>
                    <h1 className='md:tw-text-[2.635vw] tw-text-[#8a0f48] tw-font-bold tw-text-center'>A seamless and friendly Experience</h1>
                    <h1 className='md:tw-text-[1.171vw] tw-text-center tw-text-[#8a0f48]'>The app is meticulously designed and build to ensure a pleasing and effective consumer journey</h1>
                </div>
            </div>

            <ContactUsNoForm />
        </div>
    )
}
