export const hireDeveloperData = [
    {
        id: 1,
        name: 'appDevelopers',
        value: 'App Developers',
        url: '/hireDevs/hireAppDevs'
    },
    {
        id: 2,
        name: 'webDevelopers',
        value: 'Web Developers',
        url: '/hireDevs/hireWebDevs'
    },
    {
        id: 3,
        name: 'softwareDevelopers',
        value: 'Software Developers',
        url: '/hireDevs/hireSoftwareDevs'
    },
    {
        id: 4,
        name: 'fullStackDevelopers',
        value: 'Full-stack Developers',
        url: '/hireDevs/hireFullStackDevs'
    },
    {
        id: 5,
        name: 'frontEndDevelopers',
        value: 'Frontend Developers',
        url: '/hireDevs/hireFrontEndDevs'
    },
    {
        id: 6,
        name: 'backEndDevelopers',
        value: 'Backend Developers',
        url: '/hireDevs/hireBackEndDevs'
    },
    {
        id: 7,
        name: 'uiUxDesigners',
        value: 'UI/UX Designers',
        url: '/hireDevs/hireUiUxDesigners'
    },
    {
        id: 8,
        name: 'iotSolutionArchitects',
        value: 'IoT Solution Architects',
        url: '/hireDevs/hireIotSolutionArchitects'
    },
    {
        id: 9,
        name: 'dataScientists',
        value: 'Data Scientists',
        url: '/hireDevs/hireDataScientists'
    },
    {
        id: 10,
        name: 'salesforceDevelopers',
        value: 'Salesforce Developers',
        url: '/hireDevs/hireSalesforceDevs'
    },
    {
        id: 11,
        name: 'blockchainDevelopers',
        value: 'Blockchain Developers',
        url: '/hireDevs/hireBlockchainDevs'
    },
    {
        id: 12,
        name: 'androidDevelopers',
        value: 'Android Developers',
        url: '/hireDevs/hireAndroidDevs'
    },
    {
        id: 13,
        name: 'iosDevelopers',
        value: 'IOS Developers',
        url: '/hireDevs/hireIosDevs'
    },
    {
        id: 14,
        name: 'iPhoneDevelopers',
        value: 'iPhone Developers',
        url: '/hireDevs/hireIphoneDevs'
    },
    {
        id: 15,
        name: 'flutterDevelopers',
        value: 'Flutter Developers',
        url: '/hireDevs/hireFlutterDevs'
    },
    {
        id: 16,
        name: 'reactNativeDevelopers',
        value: 'React Native Developers',
        url: '/hireDevs/hireReactNativeDevs'
    },
    {
        id: 17,
        name: 'swiftDevelopers',
        value: 'Swift Developers',
        url: '/hireDevs/hireSwiftDevs'
    },
    {
        id: 18,
        name: 'ionicDevelopers',
        value: 'Ionic Developers',
        url: '/hireDevs/hireIonicDevs'
    },
    {
        id: 19,
        name: 'kotlinDevelopers',
        value: 'Kotlin Developers',
        url: '/hireDevs/hireKotlinDevs'
    },
    {
        id: 20,
        name: 'javaDevelopers',
        value: 'Java Developers',
        url: '/hireDevs/hireJavaDevs'
    },
    {
        id: 21,
        name: 'angularjsDevelopers',
        value: 'AngularJS Developers',
        url: '/hireDevs/hireAngularJsDevs'
    },
    {
        id: 22,
        name: 'reactjsDevelopers',
        value: 'ReactJS Developers',
        url: '/hireDevs/hireReactJsDevs'
    },
    {
        id: 23,
        name: 'nodejsDevelopers',
        value: 'NodeJS Developers',
        url: '/hireDevs/hireNodeJsDevs'
    },
    {
        id: 24,
        name: 'vuejsDevelopers',
        value: 'VueJS Developers',
        url: '/hireDevs/hireVueJsDevs'
    },
    {
        id: 25,
        name: 'phpDevelopers',
        value: 'PHP Developers',
        url: '/hireDevs/hirePhpDevs'
    },
    {
        id: 26,
        name: 'wordpressDevelopers',
        value: 'WordPress Developers',
        url: '/hireDevs/hireWordpressDevs'
    },
    {
        id: 27,
        name: 'cakephpDevelopers',
        value: 'CakePHP Developers',
        url: '/hireDevs/hireCakePhpDevs'
    },
    {
        id: 28,
        name: 'codeigniterDevelopers',
        value: 'CodeIgniter Developers',
        url: '/hireDevs/hireCodeIgniterDevs'
    },
    {
        id: 29,
        name: 'laravelDevelopers',
        value: 'Laravel Developers',
        url: '/hireDevs/hireLaravelDevs'
    },
    {
        id: 30,
        name: 'yiiDevelopers',
        value: 'Yii Developers',
        url: '/hireDevs/hireYiiDevs'
    },
    {
        id: 31,
        name: 'magentoDevelopers',
        value: 'Magento Developers',
        url: '/hireDevs/hireMagentoDevs'
    },
    {
        id: 32,
        name: 'shopifyDevelopers',
        value: 'Shopify Developers',
        url: '/hireDevs/hireShopifyDevs'
    },
    {
        id: 33,
        name: 'drupalDevelopers',
        value: 'Drupal Developers',
        url: '/hireDevs/hireDrupalDevs'
    },
    {
        id: 34,
        name: 'woocommerceDevelopers',
        value: 'WooCommerce Developers',
        url: '/hireDevs/hireWooCommerceDevs'
    },
    {
        id: 35,
        name: 'pythonDevelopers',
        value: 'Python Developers',
        url: '/hireDevs/hirePythonDevs'
    },
    {
        id: 36,
        name: 'golangDevelopers',
        value: 'Golang Developers',
        url: '/hireDevs/hireGolangDevs'
    },
    {
        id: 37,
        name: 'solidityDevelopers',
        value: 'Solidity Developers',
        url: '/hireDevs/hireSolidityDevs'
    },
    {
        id: 38,
        name: 'dartDevelopers',
        value: 'Dart Developers',
        url: '/hireDevs/hireDartDevs'
    },
    {
        id: 39,
        name: 'springBootDevelopers',
        value: 'Spring Boot Developers',
        url: '/hireDevs/hireSpringBootDevs'
    },
    {
        id: 40,
        name: 'djangoDevelopers',
        value: 'Django Developers',
        url: '/hireDevs/hireDjangoDevs'
    },
]