import uiUxDesign from '../../../assets/icons/corporateBranding/uiUxDesign.svg';
import uxAuditReview from '../../../assets/icons/corporateBranding/uxAuditReview.svg';
import visualIdentityDesign from '../../../assets/icons/corporateBranding/visualIdentityDesign.svg';
import digitalBranding from '../../../assets/icons/corporateBranding/digitalBranding.svg';
import packagingDesign from '../../../assets/icons/corporateBranding/packagingDesign.svg';

export const corporateBrandingGridBlockData = [
    "UI UX Design",
    "UX Audit & Review",
    "Visual Identity Design",
    "Digital Branding",
    "Packaging Design",
]

export const corporateBrandingServicesData = [
    {
        id: 1,
        title: "UI/UX Design",
        description: "UX design is all about identifying and solving user problems; UI design is all about creating intuitive, aesthetically-pleasing, interactive interfaces.",
        src: uiUxDesign
    },
    {
        id: 2,
        title: "UX Audit & Review",
        description: "A user experience (UX) audit an in-depth, data-driven assessment of the experience your app or website provides to users helps you evaluate your product methodically, so you can find new ways to improve.",
        src: uxAuditReview
    },
    {
        id: 3,
        title: "Visual Identity Design",
        description: "Visual identity is all of the imagery and graphical information that expresses who a brand is and differentiates it from all the others.",
        src: visualIdentityDesign
    },
    {
        id: 4,
        title: "Digital Branding",
        description: "It is a technique that uses a combination of internet branding and digital marketing to develop a brand over a range of digital venues, including internet-based relationships, device-based applications or media content.",
        src: digitalBranding
    },
    {
        id: 5,
        title: "Packaging Design",
        description: "Packaging design is the connection of form, structure, materials, colour, imagery, typography, and regulatory information.",
        src: packagingDesign
    }
]