import React from 'react';

// assets
import ps1 from '../../../../assets/pages/service/ecommerceDev/ps1.jpeg';
import ps2 from '../../../../assets/pages/service/ecommerceDev/ps2.png';
import ps3 from '../../../../assets/pages/service/ecommerceDev/ps3.png';
import ps4 from '../../../../assets/pages/service/ecommerceDev/ps4.png';
import { prestaShopDevData } from '../../../../data/services/ecommerceDevData/prestaShopDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function PrestashopDev() {
    return (
        <ServicesWrapper
            title="ECOMMERCE DEVELOPMENT PRESSTASHOP"
            descriptionCont="Our E-Commerce website development professionals are experts. Alta Tech Mark developers provide high-quality, fast E-Commerce products. Our skilled staff develops E-Commerce websites utilizing cutting-edge, trustworthy technology. Magento, Shopify, BigCommerce, WooCommerce, and others"
            img={ps1}
        >
            <DevGridWrapper images={[ps2, ps3, ps4]}>
                <DataMapper data={prestaShopDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
