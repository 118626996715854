export const codeIgniterDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "CodeIgniter stands out as one of the foremost open-source PHP development frameworks in the web development landscape. It operates on the MVC (Model-View-Controller) model, facilitating the creation of dynamic and intuitive websites. When stacked against other popular frameworks, CodeIgniter shines as one of the quickest and most developer-friendly tools available. Its low-overhead architecture and adaptability enable swift, agile web app development within budgetary constraints and tight timelines.",
            "A proficient CodeIgniter PHP framework solution is recognized for its minimalistic footprint, ensuring top-tier performance. Key features like compatibility and zero configuration underscore its worth among open-source platforms. The framework's growth trajectory remains upward, courtesy of ongoing developmental efforts that continue to enhance its capabilities.",
            "About Alta Tech Mark Alta Tech Mark is a premier CodeIgniter development firm dedicated to propelling clients to success through high-caliber CodeIgniter development services. Our team boasts seasoned CodeIgniter developers, passionate about crafting exceptional products using this advanced technology. Their profound understanding of the CodeIgniter framework sets them apart.",
            "At Alta Tech Mark, our ethos revolves around precision and excellence. We've curated a team of professionals adept at navigating intricate web app projects. Our bespoke CodeIgniter web development solutions cater to diverse requirements, promising a swift turnaround. Our industry tenure equips us to deliver well-structured, efficient, and speed-optimized web solutions."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "We aim to deliver unparalleled services:",
        description: "",
        onlyBullets: false,
        listNum: true,
        data: [
            {
                id: 1,
                title: "CodeIgniter Web Creation",
                description: "At Alta Tech Mark, we pioneer top-tier web development services that pave the way for your business's growth.",
            },
            {
                id: 2,
                title: "Web Portals via PHP CodeIgniter & MySQL",
                description: "Expand your digital footprint with our CodeIgniter-based web portals, designed to spotlight your burgeoning enterprise.",
            },
            {
                id: 3,
                title: "CodeIgniter Social Media Solutions",
                description: "Harness the power of social media for business amplification with our comprehensive CodeIgniter solutions.",
            },
            {
                id: 4,
                title: "Web 2.0 Based CMS Development",
                description: "Our developers craft robust CMS platforms, tailored to your specifications, ensuring timely delivery.",
            },
            {
                id: 5,
                title: "CodeIgniter E-commerce Solutions",
                description: "Maximize your ROI with our extensive range of e-commerce solutions.",
            },
            {
                id: 6,
                title: "Corporate Application Creation",
                description: "We design robust, scalable applications tailored for the corporate environment, ensuring easy management and scalability.",
            },
            {
                id: 7,
                title: "Website Maintenance & Upgrade",
                description: "Alta Tech Mark is at your service round-the-clock for website maintenance and updates.",
            },
            {
                id: 8,
                title: "Enterprise Solutions via CodeIgniter",
                description: "Opt for top-grade enterprise solutions that streamline your business operations.",
            },
            {
                id: 9,
                title: "Integration with External APIs",
                description: "We seamlessly integrate third-party services, from payment gateways to analytics, ensuring a holistic service experience.",
            },
        ]
    },
    {
        id: 3,
        title: "Highlight Features of CodeIgniter:",
        description: "",
        onlyBullets: true,
        data: [
            "Scalability",
            "MVC Structure",
            "Flexibility in URL Routing",
            "Stellar Performance",
            "Robust Security Measures",
            "Comprehensive Documentation",
            "User-friendly Features",
            "Active Database Support",
            "XSS Filtering",
            "Simplified Coding Norms",
            "Editable PHP Frameworks",
            "Efficient Time Management",
            "Seamless Installation Process",
            "Compatibility Across Servers and Platforms"
        ]
    },
    {
        id: 4,
        title: "Why Partner with Alta Tech Mark?",
        description: "Alta Tech Mark stands out as your quintessential partner for crafting top-tier web applications using CodeIgniter. Our state-of-the-art infrastructure and adept team make the best use of CodeIgniter's expansive library and tools, tailored to meet our clients' unique needs.",
        onlyBullets: false,
        data: []
    },
    {
        id: 5,
        title: "Our Core Strengths:",
        description: "",
        onlyBullets: true,
        data: [
            "Industry Expertise",
            "Technical Prowess",
            "Diverse Engagement Models",
            "24x7 Client Support",
            "Post-Launch Assistance",
            "Adaptable Engagement Blueprint",
            "Transparent Communication Channels",
            "Agile Development Methodology"
        ]
    }
]