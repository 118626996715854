export const hireCodeIgniterDevData = [
    {
        id: 1,
        title: "",
        description: "Based on PHP, CodeIgniter is an open-source framework for developing applications. From a variety of angles, including speed, ease of setting, security, file organization, simple error handling, and many more, it is more well-liked and potent than competing PHP frameworks. It includes a variety of tools, like as libraries, plugins, and interfaces, to help you quickly and effectively create a robust, fully interactive website.",
        onlyBullets: false,
        noList: true,
        data: [
            {
                id: 1,
                title: "Lightweight and Performance",
                description: "CodeIgniter is known for its lightweight framework. The core system requires only a few small libraries, with additional libraries loaded dynamically upon request, minimizing the base system’s footprint. This contributes to faster performance compared to many other frameworks."
            },
            {
                id: 2,
                title: "Easy Configuration",
                description: "One of the major strengths of CodeIgniter is its hassle-free configuration. It has an easy-to-use configuration and customization of files. This is beneficial for developers as they can spend more time on coding rather than on configuring the framework."
            },
            {
                id: 3,
                title: "MVC Architecture",
                description: "Like several other frameworks, CodeIgniter works on the Model-View-Controller (MVC) model which offers easy separation of logic and presentation. This modular approach allows developers to build neat and manageable code."
            },
            {
                id: 4,
                title: "Active Record Database Support",
                description: "The framework provides a rich set of functionalities in order to work with different types of databases. It utilizes a simplified means of generating queries by using its own version of Active Record Database Support."
            },
            {
                id: 5,
                title: "Built-in Security Tools",
                description: "CodeIgniter comes equipped with built-in security tools that assist developers in protecting web applications against threats like SQL injection, CSRF attacks, and XSS attacks. The framework also has features for data encryption and decryption."
            },
            {
                id: 6,
                title: "Error Handling",
                description: "The user-friendly interface of CodeIgniter provides simple and effective error handling methods. It offers user-friendly error messages during development and debugging stages."
            },
            {
                id: 7,
                title: "Extensibility",
                description: "The ability to easily extend and customize the core libraries of CodeIgniter is one of its notable features. Developers can incorporate their own existing scripts, or develop core libraries for the system."
            },
            {
                id: 8,
                title: "Documentation and Community Support",
                description: "CodeIgniter boasts of detailed documentation which is clear and concise. This is supported by a vast and active community of developers, which ensures that developers have ample resources and help at hand."
            },
            {
                id: 9,
                title: "URI Routing",
                description: "The framework offers flexibility to developers in terms of URI routing. URLs generated by CodeIgniter are search-engine friendly and clean."
            },
            {
                id: 10,
                title: "Less Code and Faster Development",
                description: "With its rich libraries, CodeIgniter requires significantly lesser code to accomplish tasks like form validation, email sending, and more. This results in faster development and deployment."
            }
        ]
    },
]

export const hireCodeIgniterDevFaqs = [
    {
        id: 1,
        question: "1. Why should I hire dedicated Codeigniter developers?",
        answer: "The dedicated Codeigniter developers will work only for you to deliver outstanding results with the highest optimization. The team of dedicated Codeigniter developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 2,
        question: "2. What steps should I follow to Hire dedicated Codeigniter developers?",
        answer: "Alta Tech Mark offers a very simple process to hire dedicated Codeigniter developers. Just provide project details and the technical expertise you need. The Codeigniter developer begins working on your project based on your selection."
    },
    {
        id: 3,
        question: "3. Can I check the technical skill set of the dedicated Codeigniter developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the Codeigniter developers before making a final move."
    },
    {
        id: 4,
        question: "4. Can I hire dedicated Codeigniter developers for hourly or project-based Tasks?",
        answer: "Yes, If you know the task to get done from the dedicated Codeigniter developer, then you can hire a team of dedicated Codeigniter developers on an hourly or project/task base."
    },
    {
        id: 5,
        question: "5. How much does it cost to hire dedicated Codeigniter developers?",
        answer: "The cost estimation for dedicated Codeigniter developers varies based on various factors such as team size, hiring model, the complexity of the web development, the type of solution, features and functionalities and many more."
    }
]