import React from 'react';

// assets
import sc1 from '../../../../assets/pages/service/salesforceDev/sc1.png';
import sc2 from '../../../../assets/pages/service/salesforceDev/sc2.png';
import { salesforceDevData2 } from '../../../../data/services/salesforceDevData/salesforceDevData2';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function SalesforceConsulting() {
    return (
        <ServicesWrapper
            title="SALESFORCE CONSULTING"
            descriptionCont="One of the greatest CRM platforms is Salesforce, which gives businesses the ability to change the way their business operates, optimize employee collaboration, track sales, service, and marketing activities, and create apps that meet specific business needs."
            img={sc1}
        >
            <DevGridWrapper img={sc2}>
                <DataMapper data={salesforceDevData2} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
