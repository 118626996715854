import React from 'react';

// data
import { hireFullStackDevData, hireFullStackDevFaq } from '../../../data/hireDev/hireFullStackDev';
import fullStackDev1 from '../../../assets/pages/hireDev/fullStackDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireFullStackDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="FULL-STACK DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={fullStackDev1}
            data={hireFullStackDevData}
            faq={hireFullStackDevFaq}
        />
    )
}
