import ur3 from '../../../assets/pages/service/gameDev/ur3.png';
import ur4 from '../../../assets/pages/service/gameDev/ur4.png';

export const unrealDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "The open-source Unreal Engine platform is specialized in producing 2D and 3D game development. One new piece of high definition software specifically designed for game production is the Unreal engine. It was used to create 2D and 3D games in the past, but it is now used to create gaming services with 3D features. All game development platforms, including iOS, Android, and AR/VR technologies, are supported by the software development.",
            "One of the many advantages of the Unreal Engine is that it makes gaming app creation easier and more advanced. Unreal engine software has a wider variety of capabilities that are utilized to create games that are of a high caliber. With the Unreal Engine, Alta Tech Mark offers a flawless gaming experience. Our developers produce game apps that are fluid, effective, and enhance the gaming experience. We provide the greatest service possible, without sacrificing quality and with flawless outcomes. Experts can work quickly thanks to software development, which also establishes various corporate objectives.",
            "Furthermore, a lot of game developers use Unreal Engines to create their games, and the program has been used to create several well-known titles. The engine provides a solid workflow that makes use of expertise and speedy outcomes. Our firm specializes in developing Unreal Engine gaming apps and has extensive understanding of engines. For gaming app development, Alta Tech Mark is at your side whether you run a small or large business. Our game developers maintain a high standard of quality throughout the whole game creation process, from development to release. The Unreal Engine produces 3D games for many platforms with superior quality in every way. The creators create photorealistic models that, when visualized, provide a strange experience. Experts with expertise in current designs, such as designers, architects, and many others, can benefit from it. In accordance with the needs of the customer, our skilled developers give your team with exceptional performance and quality as well as professional unreal engine consultation. It will help you and provide a more realistic method with regards to effects, visuals, shadows, and much more.",
            "Do you believe that creating great gaming software would take a lot of effort and time? Well, you're not correct. It is rather brief, and the engine helps you save time while creating gaming applications. The technology gives creators the flexibility to create whatever kind of game they choose, including 3D games. All games are compatible with the Unreal game creation engine. It gives the developer the ability to design various game features using simple prototypes that need no code. Consequently, it verifies your concept and lowers the price of developing a gaming software. With the aid of an Unreal Engine, you can create 3D, cinematic experiences for in-game apps. The game may include character design, face animation, photogrammetry, narrative, virtual reality elements, and much more. It tends to provide the best outcome and speeds up the development in game interaction. You may receive flexible Unreal Engine game development in a short amount of time by working with Alta Tech Mark. The most recent version of the Unreal Engine is called Unreal Engine 5, however there are numerous more as well."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Uses for Unreal Engine in the Development of Game Apps?",
        description: "",
        onlyBullets: true,
        data: [
            "VFX",
            "Particle Simulation",
            "Real-time photoreal rendering",
            "Film Quality Post-Process Effects",
            "Unreal Audio Engine",
            "Unlimited Extensibility",
            "Multiplayer Framework",
            "Easy data import",
            "Without coding blueprints",
            "Friendly external software",
            "Modern Artificial Intelligence"
        ],
        bulletsPic: ur3
    },
    {
        id: 3,
        title: "Various Sectors for the Creation of Unreal Engine Games:",
        description: "",
        onlyBullets: true,
        data: [
            "Gaming",
            "Automobile",
            "E-commerce",
            "Advertising",
            "Architecture",
            "Healthcare",
        ],
        bulletsPic: ur4
    },
    {
        id: 4,
        title: "When Choosing The Unreal Engine, Why Alta Tech Mark?",
        descriptions: [
            "Alta Tech Mark has led by example by offering some incredible development services for gaming apps. Unreal Engine is used by our skilled developers to provide the greatest results. A 3D gaming software may be developed more cheaply and effectively using an Unreal Engine. Our assistance to the Unreal Engine ensures the best possible result for the creation of gaming apps. C++ and Blueprint are two of the original scripting languages used by Unreal Engine. Being a top Unreal Engine provider, we use the Unreal Engine game development platform to provide customized solutions and a vibrant array of services. To create game apps, our engine game developers use a powerful engine.",
            "Every game app development project receives a notable solution from our talented engine engineers. We contribute to the development of an exciting and fun gaming application using the Unreal Engine by offering an alternative version of the engine. We attempted to equal their level of fame by developing a gaming app that gained traction, since it is one of the well-known game creation engines. Similarly, the production of gaming apps is transformed by an Unreal Engine. The greatest Unreal Engine technique is used by Alta Tech Mark to grow and change your gaming apps. Our knowledgeable specialists know how to use the Unreal Engine and know when and where to execute. We provide incredible engine solutions on schedule and with exceptional quality. Alta Tech Mark guarantees flawless and error-free service."
        ],
        onlyBullets: false,
        data: []
    }
]