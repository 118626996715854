import React from 'react';

// assets
import uxr1 from '../../../../assets/pages/service/corporateBranding/uxr1.png';
import { uxrDevData, uxrDevData2 } from '../../../../data/services/corporateBranding/uxrDevData';

// components
import ServicesWrapper from '../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';
import CaseStudies from '../../../../components/home/caseStudies/CaseStudies';

export default function UxAuditReview() {
    return (
        <ServicesWrapper
            title="USER EXPERIENCE AUDIT & REVIEW"
            descriptionCont="A UX audit or UX review is a process used to identify areas of improvement when it comes to usability, visual design, content, performance, user engagement as well as accessibility of any digital product, and provide recommendations to enhance the overall user experience."
            img={uxr1}
        >
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={uxrDevData} />

                <h1 className='tw-text-center tw-font-semibold md:tw-text-4xl tw-text-2xl md:tw-mt-[11.458vw]'>Recommended 6 step approach</h1>
                <h1 className='tw-text-center tw-font-semibold md:tw-text-4xl tw-text-2xl tw-mb-8'> to conducting a <span className='tw-text-purple'>UX audit</span></h1>

                <DataMapper data={uxrDevData2} />
            </div>

            <CaseStudies colorTitleStart="PORTFOLIO" title=" " />
        </ServicesWrapper>
    )
}
