import game6 from '../../../assets/pages/service/gameDev/game6.png';
import game7 from '../../../assets/pages/service/gameDev/game7.png';
import game8 from '../../../assets/pages/service/gameDev/game8.png';
import game9 from '../../../assets/pages/service/gameDev/game9.png';

export const gameServicesData = [
    {
        id: 1,
        title: "Mobile Game Development",
        description: "Have a great idea for a gaming app? Hire Alta tech mark's game developers to create a top-notch mobile gaming software that runs flawlessly on iOS, Android, and other operating systems."
    },
    {
        id: 2,
        title: "Web Game Development",
        description: "Seek assistance from the professionals at the best game development business that can provide an exceptional user experience while using the newest technology in game production."
    },
    {
        id: 3,
        title: "Casual Game Development",
        description: "Hire Alta Tech Mark's game creators, who value casual players just as much as ardent ones. Together, let's create a casual game that prioritizes player enjoyment above all other considerations."
    },
    {
        id: 4,
        title: "Custom Game Development",
        description: "Do you have any innovative ideas for making games? Then, you will need support from exceptional game developers. Some are in the possession of Alta Tech Mark."
    },
    {
        id: 5,
        title: "Character Or Avatar Creation",
        description: "Employ committed game developers from Alta Tech Mark who are skilled in crafting engaging avatars and characters that can work their magic in mobile and metaverse games, depending on your needs."
    },
    {
        id: 6,
        title: "Concept Art",
        description: "Before transforming your concept for a game creation into a functional gaming solution, get the feel and appearance of it. Work with Alta Tech Mark's game developers; they can assist you with landscape, prop, and character design."
    }
];

export const gameDevTeamsData = [
    {
        id: 1,
        role: "Game Animator",
        description: "The 2D/3D artists, rigging creators, texturing creators, lighting creators, animators, and more make up Alta Tech Mark's team of game animators. They work on the game's concept art by using their creative thinking and abilities, and they serve as the foundation for the realistic appearance and feel of the gaming solution."
    },
    {
        id: 2,
        role: "Game Designer",
        description: "The Alta Tech Mark game designers use all of their imagination and expertise to create a complete gaming experience, from the logo to the plot, characters, and gaming environment, and everything in between. They maintain communication with other members of the game development team to accurately represent your thoughts."
    },
    {
        id: 3,
        role: "Game Developer",
        description: "Skilled game developers at Alta Tech Mark create and provide an immersive gaming environment as a final product enhanced with various gaming features, sceneries, and situations while adhering to various gaming algorithms and including APIs. In addition, Alta Tech Mark's game creators are in charge of providing post-purchase assistance."
    },
    {
        id: 4,
        role: "Game Tester",
        description: "As avid gamers and some of the greatest critics around, our team of testers uses both automated and manual testing techniques to thoroughly examine every gaming situation and solution to make sure your game is free of bugs and provides players with an engaging experience."
    }
];

export const gameProcessData = [
    {
        id: 1,
        title: "Project Requirement Analysis",
        description: "We first conducted a complete analysis of the idea, encompassing all the points, diagrams, scenarios, challenges, flow charts, and SRS for the whole process, after the customer contacted us with their needs for game creation."
    },
    {
        id: 2,
        title: "Concept Art",
        description: "The next stage is concept art, when our talented team of animators, rigger artists, modellers, and designers produce 2D and 3D drawings and artwork for the gaming apparatus, characters, settings, accessories, and more."
    },
    {
        id: 3,
        title: "Game Design",
        description: "Do you like the concept art for the game that was sent to you? If so, here is where our creators of games come into play. The group of accomplished game designers will meticulously create the complete game by combining their incredible artistic abilities with top-notch design tools."
    },
    {
        id: 4,
        title: "Game Development",
        description: "Did the game design turn out to be exactly as you had imagined it would? After then, the game development process will continue. Our talented team of game developers will make use of their expertise, game development abilities, and familiarity with various technologies and tools to create the game just as you have specified in the project scope."
    },
    {
        id: 5,
        title: "Testing and Automation",
        description: "Is this everything for now? Obviously not. Our quality assurance experts will thoroughly test the game in an effort to identify any bugs or defects that may have crept in during the development process. Prior to the game deployment phase, the complete game will be tested by the QA engineers."
    },
    {
        id: 6,
        title: "Game Deployment",
        description: "Do we assist in the process of deployment? Yes, of course. We will support you whether you need to launch your gaming solution on the Epic Game Store, Google Play Store, Apple App Store, or any other platform. To get your game in front of end consumers, the Alta Tech Mark game developers may provide a lot of help."
    },
    {
        id: 7,
        title: "Support and Assistance",
        description: "The task remains unfinished. The team responsible for game development makes sure that your game is always up to date and free from bugs on all platforms. In order to protect you and your game from criticism, we ensure that it complies with all industry norms and regulations."
    }
]

export const whyUsForGameDevData = [
    {
        id: 1,
        title: "Team of Passionate Game Developers",
        description: "Alta Tech Mark's game development team consistently delivers top-notch performance throughout the creation process. They are committed to crafting unique gaming experiences, be it for single-player, multiplayer, or AR/VR game requirements.",
        src: game6
    },
    {
        id: 2,
        title: "Enhanced Gaming Portfolio",
        description: "We have experience with every kind of gaming solution imaginable, from casual gaming applications to solutions based on the metaverse, and we are here to meet all of your gaming needs. Our team of game developers has created and distributed gaming solutions for a wide variety of platforms, such as the web, iOS, Android, PSP, Xbox, and a number of others.",
        src: game7
    },
    {
        id: 3,
        title: "Multiple Engagement Models",
        description: "You decide which engagement model would work best for your project, and then you employ specialized game developers on a resource base, a time and materials base, or a set fee basis, depending on which option best suits your needs. The members of the Alta Tech Mark team have got you taken care of in every conceivable manner.",
        src: game8
    },
    {
        id: 4,
        title: "Diversity of Clients",
        description: "As a leading game development firm, we collaborate with a wide variety of well-known brands from across the world, ranging from fledgling startups to Fortune 500 corporations in a variety of fields. Therefore, we are experts in developing gaming solutions that effectively convey both your company's mission and its image to customers.",
        src: game9
    }
]