import React from 'react'

export default function Divider() {
    return (
        <div className='tw-w-full tw-px-[45%]'>
            <div className='tw-w-full tw-h-[1px] tw-bg-slate-300'></div>
            <div className='tw-w-[40px] tw-h-[15px] tw-bg-purple tw-rounded-full tw-relative tw-left-[35%] tw-bottom-[7px]'></div>
        </div>
    )
}
