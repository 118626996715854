import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import web1 from '../../../assets/pages/service/WebAppDev/web1.png';
import web2 from '../../../assets/pages/service/WebAppDev/web2.png';
import { webProcessData, webServicesData } from '../../../data/services/webAppDevData/webData';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import KeyFeaturesCard from '../../../components/home/keyFeatures/KeyFeaturesCard';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function WebAppDev() {
    // initializations
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet
                ||
                <div>
                    <HeroTitleImage
                        title="WEB & CMS DEVELOPMENT"
                        bg={web1}
                        inWrapper={true}
                        simpleTextBg={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleEnd="WEB DEVELOPMENT?"
                            title="WHAT IS"
                            className='tw-text-center tw-mb-8'
                        />
                        <ol className='tw-list-decimal tw-grid tw-gap-2'>
                            <li>
                                <span className='tw-text-purple'>Front-end Development (or Client-Side Development): </span>
                                This involves creating what users interact with directly – everything that they experience directly in their browser or app. Front-end developers use languages like HTML (for structuring content), CSS (for visual design and layout), and JavaScript (for interactivity and dynamic content).
                            </li>

                            <li>
                                <span className='tw-text-purple'>Back-end Development (or Server-Side Development): </span>
                                This deals with the server side of an application and everything that communicates between the database and the browser. Back-end developers usually work with databases and servers. They use server-side languages like PHP, Ruby, Python, Java, and .NET to build an application, and tools like MySQL, Oracle, and SQL Server to find, save, or change data and serve it to the front-end.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Full-Stack Development: </span>
                                A full-stack developer is competent in both front-end and back-end development and can handle projects that involve databases, building user-facing websites, or even work with clients during the planning phase of projects.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Content Management System (CMS) Development: </span>
                                A CMS is a tool that helps users create and manage digital content. Popular CMSs include WordPress, Drupal, and Joomla. A CMS developer specializes in creating and customizing these platforms and ensuring they meet individual business needs.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Web Application Development: </span>
                                This refers to building application programs that reside on remote servers and are delivered to a user's device over the internet. Examples include online banking platforms, Google Docs, and more.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Responsive Web Development: </span>
                                As more people use mobile devices to access the internet, it's important for websites to look good and function well on smartphones, tablets, and desktops. Responsive web design makes sites appear and function properly on all device types.
                            </li>

                            <li>
                                <span className='tw-text-purple'>E-commerce Development: </span>
                                This is focused on creating websites for buying and selling products online. Platforms like Magento, Shopify, and WooCommerce make this easier.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Website Testing: </span>
                                Just as software development requires testing to identify bugs, websites also need professionals to test their designs and functionalities.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Website Maintenance: </span>
                                After a website is launched, it needs regular updates and maintenance to ensure it remains relevant, functional, and secure.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Web Design: </span>
                                While not necessarily development in the coding sense, web design is closely related and involves the design (both visual and interactive) of a website's user interface. Tools like Adobe XD, Sketch, and Figma might be used for this purpose.
                            </li>

                            <li>
                                <span className='tw-text-purple'>Web Hosting and Deployment: </span>
                                Once a website is built, it needs to be hosted on servers and deployed for public or private access. This involves choosing hosting platforms, ensuring proper server-side settings, handling domains, etc.
                            </li>
                        </ol>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleStart="PROCESS"
                            title="WE FOLLOW"
                            className='tw-text-center tw-mb-8'
                        />
                        <img src={web2} alt="process" className='tw-mx-[auto]' />
                        <div className='tw-grid tw-gap-4'>
                            {webProcessData.map(process =>
                                <div key={process.id} className='tw-flex tw-flex-col tw-items-start'>
                                    <h1 className='tw-text-purple tw-font-semibold'>{process.title}</h1>
                                    <h1>{process.description}</h1>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-bg-lightPurple'>
                        <HeaderText
                            colorTitleEnd="SERVICE PROVIDER!"
                            title="AN OPTIMAL IT"
                            className='tw-text-center tw-mb-8'
                            description="A range of services are available from Alta Tech Mark to enhance the App development process."
                        />
                        <div className='md:tw-grid md:tw-grid-cols-3 tw-flex tw-items-center tw-gap-[2vw] tw-overflow-x-scroll md:tw-overflow-x-hidden tw-pb-4'>
                            {webServicesData.map(service =>
                                <KeyFeaturesCard
                                    key={service.id}
                                    title={service.title}
                                    description={service.description}
                                    src={service.src}
                                    height="400"
                                />
                            )}
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
