import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// data
import { reviewsData } from '../../../data/reviewsData';

// components
import ReviewsCard from './ReviewsCard';
import HeaderText from '../../headerText/HeaderText';

export default function Reviews() {
    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-px-[9.115vw] tw-pt-[8.138vw] tw-bg-[#FCFCFC]'>
            <HeaderText colorTitleStart="Testimonials" description='Hear what clients have to say about us' className='tw-text-center tw-mb-8' />
            <Swiper
                style={{
                    "--swiper-pagination-color": "#9065FD",
                    "--swiper-pagination-bullet-inactive-color": "#999999",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                }}
                modules={[Autoplay, Pagination]}
                spaceBetween={10}
                slidesPerView={screenWidth <= 750 ? 1 : 3}
                loop={true}
                centeredSlides={true}
                autoplay={{
                    delay: 2000,
                }}
                pagination={{
                    clickable: true,
                }}
            >
                {reviewsData.map(review =>
                    <SwiperSlide key={review.id}>
                        <ReviewsCard
                            src={review.src}
                            review={review.review}
                            name={review.name}
                            business={review.business}
                            location={review.location}
                            flag={review.flag}
                        />
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    )
}
