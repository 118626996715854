import hp1 from '../../assets/hiringProcess/hp1.svg';
import hp2 from '../../assets/hiringProcess/hp2.svg';
import hp3 from '../../assets/hiringProcess/hp3.svg';
import hp4 from '../../assets/hiringProcess/hp4.svg';

export const hiringProcessData = [
    {
        id: 1,
        src: hp1,
        title: "Step 1",
        description: "Application Process"
    },
    {
        id: 2,
        src: hp2,
        title: "Step 2",
        description: "Assessment & Application Review"
    },
    {
        id: 3,
        src: hp3,
        title: "Step 3",
        description: "Interview Process"
    },
    {
        id: 4,
        src: hp4,
        title: "Step 4",
        description: "Job Offer & Employment Process"
    }
]