import React from 'react';

// assets
import ida1 from '../../../../assets/pages/service/iot/ida1.jpeg';
import ida2 from '../../../../assets/pages/service/iot/ida2.png';
import { iotDashboardAnalyticsDevData } from '../../../../data/services/iotDevData/iotDashboardAnalyticsDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function IotDashboardAndAnalytics() {
    return (
        <ServicesWrapper
            title="IoT Dashboard And Analytics"
            descriptionCont="IoT dashboards are a visually appealing method of data analysis. Analytics included in IoT are displayed on the Dashboard. Dashboards process device data for subsequent processing and are easily customized without causing any interruptions."
            img={ida1}
        >
            <DevGridWrapper img={ida2}>
                <DataMapper data={iotDashboardAnalyticsDevData} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
