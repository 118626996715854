import React from 'react';

// data
import { workData } from '../../../data/workData';

// components
import WorkCard from './WorkCard';
import HeaderText from '../../headerText/HeaderText';

export default function Work() {
    return (
        <div className='tw-px-[9.115vw] tw-pb-[3.906vw]'>
            <HeaderText title='Glimpse Of Our Work And Presence' />

            <div className='tw-grid md:tw-grid-cols-4 tw-grid-cols-2 tw-w-full tw-gap-x-[30px] tw-gap-y-[45px] tw-mt-[45px]'>
                {workData.map(work => <WorkCard 
                    key={work.id}
                    id={work.id}
                    stat={work.stat}
                    title={work.title}
                />)}
            </div>
        </div>
    )
}
