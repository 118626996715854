import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// assets
import { talentData } from '../../../data/hireDev/talentData';

// components
import HeaderText from '../../headerText/HeaderText';
import TalentCard from './talentCard/TalentCard';

export default function Talent() {
    // locals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <div className='tw-py-[4vw] tw-px-[9.115vw]'>
            <HeaderText
                colorTitleStart="DIVERSITY"
                title="IN WORLD-CALSS TALENT"
                description="Our group of specialists is well-versed in each of their specialized domains. Their strategy is innovative, cutting edge, and unmatched in the industry."
                className='tw-text-center tw-mb-8'
            />

            {screenWidth <= 750 ?
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#9065FD",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "16px",
                    }}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={100}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {talentData.map(talent =>
                        <SwiperSlide>
                            <div className='tw-h-[400px]'>
                                <TalentCard
                                    key={talent.id}
                                    title={talent.title}
                                    description={talent.description}
                                    src={talent.src}
                                    talentCard={true}
                                    className="tw-h-[90%]"
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
                :
                <div className='tw-grid tw-grid-cols-3 tw-gap-[25px]'>
                    {talentData.map(talent => <TalentCard
                        key={talent.id}
                        title={talent.title}
                        description={talent.description}
                        src={talent.src}
                        talentCard={true}
                    />)}
                </div>
            }
        </div>
    )
}
