import React from 'react';

// data
import { hireDataScientistData, hireDataScientistFaq } from '../../../data/hireDev/hireDataScientist';
import ds1 from '../../../assets/pages/hireDev/ds1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireDataScientistDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="DATA SCIENTIST"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={ds1}
            data={hireDataScientistData}
            faq={hireDataScientistFaq}
        />
    )
}
