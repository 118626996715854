import React from 'react';

// components
// import ServicesWrapper from '../../services/wrapper/servicesWrapper/ServicesWrapper';
// import DevGridWrapper from '../../services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../services/dataMapper/DataMapper';
import PricingPlan from '../pricingPlan/PricingPlan';
// import Faq from '../../services/faq/Faq';
import Talent from '../talent/Talent';
import OnDemandTeams from '../onDemandTeams/OnDemandTeams';
import HiringSteps from '../hiringSteps/HiringSteps';
import FreeQuoteForm from '../../services/freeQuoteForm/FreeQuoteForm';
import HeaderText from '../../headerText/HeaderText';
import ContactUsNoForm from '../../contactUs/ContactUsNoForm';
import TechWeWorkOn from '../../services/techWeWorkOn/TechWeWorkOn';

export default function HireDevWrapper(props) {
    return (
        <div className=''>
            <div className='tw-px-[9.115vw] tw-pt-[4vw]'>
                <HeaderText
                    title={props.title}
                    colorTitleEnd={props.colorTitleEnd}
                    colorTitleStart={props.colorTitleStart}
                    className='tw-text-center tw-mb-8'
                    description={props.description}
                />
                <FreeQuoteForm />

            </div>
            
            <div className='tw-px-[9.115vw]'>
                <DataMapper data={props.data} />
            </div>

            <TechWeWorkOn />

            <PricingPlan />

            <HiringSteps />

            <OnDemandTeams />

            <div className='tw-bg-lightPurple'>
                <Talent />
            </div>

            <ContactUsNoForm />
        </div>
    )
}
