import iosDev2 from '../../assets/pages/hireDev/iosDev2.png';
import iosDev3 from '../../assets/pages/hireDev/iosDev3.png';
import iosDev4 from '../../assets/pages/hireDev/iosDev4.png';
import iosDev5 from '../../assets/pages/hireDev/iosDev5.png';
import iosDev6 from '../../assets/pages/hireDev/iosDev6.png';

export const hireIosDevData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "iPhone applications are just as popular as the iPhone itself. Although there are a ton of iPhone applications available, creating an app requires much more than just a basic understanding of coding. Top iPhone app development businesses like Alta Tech Mark can provide you with the help you need to create an effective iPhone app that will operate very well for your target audience.",
            "In addition to having experience with app development, Alta Tech Mark's app development team focuses on creating unique iOS mobile apps that fit your specifications and complement your industrial market, setting you apart from the competition. Our team of iPhone app developers is skilled enough to create apps that work flawlessly on the iPad, iPhone, Apple TV, Apple Watch, and other gadgets.",
            "Boost Your Company with the Services Provided by an iOS App Development Firm",
            "We provide a wide range of app development services as a leading iPhone app development business. Look over a few of the services we provide for developing iPhone apps."
        ],
        sectionPic: iosDev2,
        sectionPicRow: true,
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "",
        description: "",
        onlyBullets: false,
        grid3Display: true,
        data: [
            {
                id: 1,
                title: "Full-Stack iOS App Development",
                description: "Ensure user-friendly iPhone apps that function flawlessly on iOS devices, wearables, Macs, and TV applications by enlisting the help of specialists from the beginning of the app development planning process.",
                descPic: iosDev3,
                descPicOrder: 1
            },
            {
                id: 2,
                title: "Business iPhone Application Development",
                description: "Do you want to turn your concept for a functioning iPhone mobile app into a digital solution? Speak with us. We have years of experience creating iPhone applications with a business focus.",
            },
            {
                id: 3,
                title: "iPhone App Revamp & Migration",
                description: "Alta Tech Mark's iPhone app developers are skilled in updating your present iOS application to reflect current market trends and migrating your application from another platform to the iOS operating system.",
                descPic: iosDev4,
                descPicOrder: 3
            },
            {
                id: 4,
                title: "iPhone UI/UX Design",
                description: "By using the newest design trends and technological advancements, our team of iOS app designers is famous for producing unforgettable experiences."
            },
            {
                id: 5,
                title: "iPhone App Development Consultation",
                description: "As a top iPhone app development business, we have professionals with years of experience in the field on staff who can provide precise and perceptive advice on iPhone app development."
            },
            {
                id: 6,
                title: "API Development and Integration",
                description: "Whether it's integrating third-party APIs or creating a bespoke iPhone API suited to your complex app development requirements, our iOS app development maestros provide flawless solutions.",
                descPic: iosDev5,
                descPicOrder: 3
            }
        ]
    },
    {
        id: 3,
        title: "",
        description: "",
        onlyBullets: false,
        grid2Display: true,
        sectionPicGrid: iosDev6,
        data: [
            {
                id: 1,
                title: "Requirement Gathering",
                description: "We make sure that your demands are well understood. We work together with our customers to collect data on the app development project needs."
            },
            {
                id: 2,
                title: "iOS App Design",
                description: "The visually striking and user-focused designs are created by our in-house iPhone app designers. To guarantee conformity with their goal, they collaborate closely with clients."
            },
            {
                id: 3,
                title: "iPhone App Development",
                description: "Using the newest tools and technology, our developers bring your vision to life while maintaining transparency all the way through."
            },
            {
                id: 4,
                title: "Quality Assurance Testing",
                description: "We carry out thorough testing to make sure your target audience has a flawless mobile app experience."
            },
            {
                id: 5,
                title: "App Deployment",
                description: "After your app is approved, we help you release it on the Apple App Store while following all the rules."
            },
            {
                id: 6,
                title: "Support & Maintenance",
                description: "We provide post-launch assistance and incremental enhancements grounded on actual user input, standing by our customers."
            },
            {
                id: 7,
                title: '',
                description: "Our Specialties Make Us the Top Choice for iPhone App Development Services"
            },
            {
                id: 7,
                title: '',
                description: "With its creative approach to iPhone app creation, Alta Tech Mark has won praise and revolutionized businesses all over the world. Our iPhone app developers are committed to creating feature-rich, scalable mobile applications for users all around the globe."
            }
        ]
    },
    {
        id: 4,
        title: "",
        description: "",
        onlyBullets: false,
        grid3Display: true,
        data: [
            {
                id: 7,
                title: "Rich in Features User Experience",
                description: "Your company goals and user experience come first."
            },
            {
                id: 8,
                title: "Integrity & Secrecy",
                description: "We respect the privacy of our clients. Our bespoke iOS app development process guarantees the integrity and security of the coding structure by ensuring that every code is distinct."
            },
            {
                id: 9,
                title: "App Upkeep and Post-Sale Support Backing",
                description: "Our dedication goes beyond the act of deployment. Our priorities are efficiency improvement and expansion."
            },
            {
                id: 10,
                title: "Comprehensible Coding Structure",
                description: "We guarantee easy adaptation for next changes with our neat and organized coding."
            },
            {
                id: 11,
                title: "Solutions for Pocket-Friendly iPhone Development",
                description: "We ensure a return on your investment by providing competitive solutions that use cutting-edge technology."
            },
            {
                id: 12,
                title: "Always Learning Approach",
                description: "To keep ahead of market developments, our iPhone developers constantly improve their abilities."
            },
            {
                id: 13,
                title: "Technical Proficiency Our iPhone App Creators Own",
                description: "As much as we like the old-fashioned approach, we also thrive at using cutting edge technology to provide the best iPhone app solutions."
            }
        ]
    }
]

export const hireIosDevFaqs = [
    {
        id: 1,
        question: "1. Can I check the technical skill set of the dedicated iOS developers?",
        answer: "Yes, with a minimal development charge; you can test the technical and development skills of the iOS developers before making a final move."
    },
    {
        id: 2,
        question: "2. Why should I hire dedicated iOS app developers?",
        answer: "The dedicated iOS developers will work only for you to deliver outstanding results with the highest optimization. The team of dedicated iOS developers at Alta Tech Mark offers ample benefits like flexibility, cost-effectiveness, higher output, special skill sets and various others."
    },
    {
        id: 3,
        question: "3. How Do I Hire iOS Developers?",
        answer: "Alta Tech Mark offers a very simple process to hire dedicated iOS developers. Just provide project details and the technical expertise you need. One of our executives will reach out to you with the best possible iOS development team that can deliver the best possible solution based on your project based on your selection."
    },
    {
        id: 4,
        question: "4. How much does it cost to hire iOS developers?",
        answer: "The cost estimation for dedicated iOS developers varies based on various factors such as team size, hiring model, the complexity of the app development, the type of solution, features and functionalities and many more."
    },
    {
        id: 5,
        question: "5. Are iOS developers Still in demand?",
        answer: "Yes, iOS developers are still in demand. And they will stay in demand. As the demand for new and innovative apps is constantly increasing. This means that businesses are always looking to hire iOS developers who can help them create cutting-edge mobile apps."
    }
]