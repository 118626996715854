import img1 from "../../../assets/icons/iotPage/1.png";
import img2 from "../../../assets/icons/iotPage/2.png";
import img3 from "../../../assets/icons/iotPage/3.png";
import img4 from "../../../assets/icons/iotPage/4.png";
import img5 from "../../../assets/icons/iotPage/5.png";
import img6 from "../../../assets/icons/iotPage/6.png";
import img7 from "../../../assets/icons/iotPage/7.png";
import img8 from "../../../assets/icons/iotPage/8.png";
import img9 from "../../../assets/icons/iotPage/9.png";
import img10 from "../../../assets/icons/iotPage/10.png";
import img11 from "../../../assets/icons/iotPage/11.png";

export const iotGridBlockData = [
    "Real time effective decision making",
    "Optimized use of resources",
    "Personalize offering",
    "Automated & optimized process",
    "Improved marketing automation",
    "Increased sales",
    "Enhanced process in Complex Scenario",
    "Smart & enhanced supply chain",
    "Better Revenue Generation"
]

export const iotServicesData = [
    {
        id: 1,
        title: "IoT Development Consultancy",
        description: "At Alta Tech Mark, we provide consulting services for internet of things development, led by our adept IoT app specialists.",
        src: img1,
    },
    {
        id: 2,
        title: "IoT App Development",
        description: "You may get the ideal IoT development solution with the assistance of our IoT mobile app programmers and developers.",
        src: img2,
    },
    {
        id: 3,
        title: "Backend & API Development",
        description: "For a successful IoT app, a proficient backend team is essential. At Alta Tech Mark, our team of seasoned IoT programmers provides comprehensive backend.",
        src: img3,
    },
    {
        id: 4,
        title: "App Development for IoT Devices",
        description: "Our team assists you in creating a customized app for a range of devices, enabling you to accomplish your business objective and take full use of IoT development technologies.",
        src: img4,
    },
    {
        id: 5,
        title: "IoT Gateway Development",
        description: "We create an IoT gateway for your app since our team is constantly prepared for needs pertaining to IoT development.",
        src: img5,
    },
    {
        id: 6,
        title: "IoT Implementation & Support",
        description: "By providing internet of things app development services we also provide complete support & maintenance services to our prestigious clients.",
        src: img6,
    },
    {
        id: 7,
        title: "Data Analytics",
        description: "Our custom-built IoT mobile applications are capable of sophisticated data analysis to better meet your company's needs.",
        src: img7,
    },
    {
        id: 8,
        title: "BLE Technology",
        description: "Alta Tech Mark specializes in creating iBeacon-enabled mobile apps that help business owners, merchants, and others effectively build their brands.",
        src: img8,
    },
    {
        id: 9,
        title: "Wearable Technology",
        description: "we deliver best-in-class services for Android and iOS compatible wearable mobile app development at affordable prices.",
        src: img9,
    },
    {
        id: 10,
        title: "Voice-Enabled Technology",
        description: "Our internet of things app development firm always strives to provide voice-enabled technology solutions as well as cutting-edge IoT mobile app development solutions.",
        src: img10,
    },
    {
        id: 11,
        title: "Raspberry Pi Solutions",
        description: "Alta Tech Mark has completed a number of proof-of-concept (POC) development projects that provide Internet of Things solutions.",
        src: img11,
    }
]

export const sectorSpecificIotServicesData = [
    {
        id: 1,
        title: "Manufacturing",
        children: [
            "Link Machines",
            "Oversees Activities from Afar",
            "Provides Instantaneous Analysis",
            "Continuously Monitors Production Data"
        ]
    },
    {
        id: 2,
        title: "Healthcare",
        children: [
            "Elevates Hand Sanitation",
            "Provides Instantaneous Insights on Services & Locations",
            "Monitors Equipment Usage",
            "Oversees Patients Remotely"
        ]
    },
    {
        id: 3,
        title: "Agriculture",
        children: [
            "Paves Way for Accurate Farming",
            "Assists Agricultural Drones",
            "Constructs Intelligent Greenhouses",
            "Elevates Livestock Tracking"
        ]
    },
    {
        id: 4,
        title: "Warehouse & Logistics",
        children: [
            "Aids in Cost Reduction",
            "Detects Possible Threats",
            "Offers Enhanced Real-Time Tracing",
            "Refines Prediction Abilities"
        ]
    },
    {
        id: 5,
        title: "Energy",
        children: [
            "Boosts Production",
            "Hastens Decision Processes",
            "Reduces Expenses",
            "Enhances Safety for Workers"
        ]
    },
    {
        id: 6,
        title: "Education",
        children: [
            "Augments Student Results",
            "Upgrades Campus Surveillance",
            "Bolsters Security"
        ]
    },
    {
        id: 7,
        title: "Transportation",
        children: [
            "Refines Fleet Operations",
            "Reduces Maintenance Expenses",
            "Boosts Instant Visibility on Roads, Seas, and Skies",
            "Enhances Predictive Maintenance",
            "Oversees Fleet and Management",
            "Expands Transit Systems and Smart Car Features"
        ]
    },
    {
        id: 8,
        title: "Cities and Communities",
        children: [
            "Economical City Services (Intelligent Street Lights and Waste Bins)",
            "Raises Quality of Public Transit",
            "Projects Intelligent Buildings"
        ]
    },
    {
        id: 9,
        title: "Retail",
        children: [
            "Streamlines Operations",
            "Engages More Effectively with Patrons",
            "Cuts Down Expenses",
            "Sped-up Innovations"
        ]
    }
]

export const iotTechStackData = [
    {
        id: 1,
        title: "DATA Analytics",
        children: [
            "Data Processing and Insights",
            "BIGDATA",
            "Machine Learning"
        ]
    },
    {
        id: 2,
        title: "Web Protocols",
        children: [
            "OAuth Authorization",
            "RestFul Interfaces",
            "SOAP Protocols",
            "Thing API"
        ]
    },
    {
        id: 3,
        title: "Device Connectivity",
        children: [
            "Wireless",
            "Bluetooth",
            "4G LTE",
            "Ethernet",
            "NFC",
            "GSM Connectivity"
        ]
    },
    {
        id: 4,
        title: "Hardware/Modules",
        children: [
            "Arduino",
            "RaspberryPI",
            "Dragon Board",
            "Esp8266",
            "Esp32",
            "STM Modules"
        ]
    },
    {
        id: 5,
        title: "Upheld Protocols",
        children: [
            "HTTP",
            "HTTPS",
            "TCP",
            'CoAP',
            'UDP',
            'MQTT',
            'XMPP',
            'AllJoyn',
            'AMQP',
            'SNMP',
            'Weave',
            'MODBUS'
        ]
    },
    {
        id: 6,
        title: "Sensors",
        children: [
            "Heat Detector",
            "Moisture Detector",
            'Pressure Detector',
            'Depth Detector',
            'Infrared Detector',
            'Sonic Detector',
            'Tactile Detector',
            'Nearness Detector',
            'Smoke & Vapor Detector',
            'Magnetic Field Detector',
            'Motion Detection',
            'Magnetic Switch'
        ]
    },
    {
        id: 7,
        title: "Cloud Services",
        children: [
            'AWS',
            'Windows Azure',
            'Google Cloud',
            'IBM Bluemix',
            'ThingWarx'
        ]
    },
    {
        id: 8,
        title: "IoT Systems",
        children: [
            'Azure IoT',
            'AWS IoT'
        ]
    },
]