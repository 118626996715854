export const csCartDevData = [
    {
        id: 1,
        title: "The Reasons Cs-Cart Is Known As A Shopping Cart Solution for Any E-Commerce Company",
        descriptions: [
            "Shopping Cart Solution is how Cs-Cart is referred as, with an emphasis on improving the customer's shopping experience. Positioning your company as a center for in-demand goods and services is the aim of Cs-Cart developers. Because Cs-Cart is an open-source code system, you may alter the source code to suit your own needs and requirements. The Cs-Cart team can customize and design your business for a price, but if you don't know how to code, Alta Tech Mark can help.",
            "Obtaining precise and current shipment information with manual shipping options is a noteworthy benefit of using Cs-Cart. In order to mitigate concerns linked to missing shipments or false delivery estimates and to ensure customer satisfaction, it easily combines real-time data from respected carriers such as DHL, Australia Post, Canada Post, USPS, and FedEx.",
            "It is also possible to control how sensitive data is shared and accessed inside the e-commerce infrastructure using Cs-Cart. Cs-Cart has you covered whether it comes to limiting certain personnel or protecting sensitive information from everyone except you. A server that has been SSL-adjusted and has the necessary certificate is required for security.",
            "Cs-Cart doesn't sacrifice SEO performance in favor of a security and data accuracy emphasis. It allows the insertion of keywords and meta tags to online shop pages, increasing exposure and reach, and it provides the construction of dynamic URLs from static URLs.",
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why CS Cart development with Alta Tech Mark?",
        description: "It seems sense that those who aren't familiar with coding would be wary of adopting Cs-Cart given that it is an open-source project. In order to guarantee a seamless transfer to the Cs-Cart platform, Alta Tech Mark comes in and offers the required knowledge and assistance.",
        onlyBullets: false,
        data: []
    }
]