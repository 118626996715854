import React from 'react';
import { Link } from 'react-router-dom';

// components
import SimpleButton from '../../ui/simpleButton/SimpleButton';

export default function ServicesCard(props) {
    return (
        <div className={`tw-px-[15px] tw-mt-[1.042vw] tw-shadow-lg tw-rounded-lg max-md:tw-min-w-[80vw] tw-w-full tw-h-full ${props.span === 2 ? 'tw-col-span-2' : 'tw-col-span-1'}`}>
            <div className='tw-py-[2.930vw] tw-px-[1.628vw] tw-w-full tw-h-full tw-flex tw-flex-col tw-items-start'>
                <div className='tw-flex tw-items-center tw-gap-[1.042vw]'>
                    <img src={props.src} alt={props.title} className='tw-scale-75 md:tw-scale-100' />
                    <h1 className='md:tw-text-xl tw-text-base tw-font-bold'>
                        <span className='tw-text-purple'>{props.colorTitleStart} </span>
                        {props.title}
                        <span className='tw-text-purple'> {props.colorTitleEnd}</span>
                    </h1>
                </div>

                <h1 className='tw-my-4 tw-text-sm'>{props.description}</h1>

                <div className='tw-flex tw-flex-col tw-gap-y-[25px] tw-justify-between tw-h-full tw-w-full'>
                    <div className={`tw-grid ${props.span === 2 ? 'tw-grid-cols-2' : 'tw-grid-cols-1'} tw-w-full tw-gap-y-[20px] tw-justify-start`}>
                        {props.children.map(child => {
                            return (
                                <div key={child.id} className='tw-flex tw-items-center tw-gap-x-[16px] tw-w-full'>
                                    <img className='tw-w-[23px] tw-h-[23px]' src={child.src} alt={child.title} />
                                    <Link to={child.url} className='md:tw-text-lg tw-text-sm'>{child.title}</Link>
                                </div>
                            )
                        })}
                    </div>

                    <SimpleButton text='Read More' className='tw-mb-0 tw-w-[160px] tw-my-1' to={props.url} />
                </div>
            </div>
        </div>
    )
}