import React from 'react';

// assets
import ourExpertiseBg from '../../../assets/background/ourExpertiseBg.svg';
import { hireDeveloperData } from '../../../components/navbar/hireDeveloperHover/hireDeveloperData';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function OurExpertise() {
    return (
        <div>
            <HeroTitleImage
                bg={ourExpertiseBg}
                title='Our Expertise'
                titleDesc='Our development expertise provides you with reliable and fun experiences.'
                simpleTextDesc={true}
            />

            <ul className='tw-px-[9.115vw] tw-my-8 tw-grid tw-grid-cols-2 tw-list-disc tw-justify-content-center tw-gap-4'>
                {hireDeveloperData.map(dev => (
                    <li key={dev.id} className='md:tw-ml-[30%] tw-text-purple tw-text-xl tw-font-[500]'>
                        {dev.value.replace(/Developers/g, "Development")}
                    </li>
                ))}
            </ul>

            <ContactUsNoForm />
        </div >
    )
}
