export const reactJsDevData = [
    {
        id: 1,
        title: "",
        description: "React JS is a powerful JavaScript framework that has become rather popular because of its unique features and unmatched flexibility. With the help of this open-source, cross-platform framework, ReactJS programmers may create high-quality solutions for creating mobile and online apps. Large-scale online applications that use data and update in real-time without requiring a page refresh may be made easier with its help. By using ReactJS, one may create native mobile applications for iOS and Android platforms as well as a single application that works with all web browsers when utilizing traditional web technologies. ReactJS places a strong focus on scalability, performance, and simplicity.",
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Why Opt for the ReactJS Framework?",
        description: "",
        onlyBullets: true,
        data: [
            "Unidirectional data flow",
            "Embraces the Virtual DOM",
            "Utilizes JSX",
            "Completely independent with no dependencies",
            "Merges JavaScript & HTML effortlessly",
            "Enables nesting of elements",
            "Streamlined component architecture",
            "Facilitates code reusability",
            "Components are state-aware",
            "Components can incorporate external plugins",
            "Enables creation of SEO-optimized applications"
        ]
    },
    {
        id: 3,
        title: "About Alta Tech Mark",
        descriptions: [
            "At Alta Tech Mark, we provide a wide range of ReactJS online and mobile app development services by using this outstanding open-source framework. We have reached significant milestones in providing top-notch ReactJS programming services thanks to our skilled staff that is knowledgeable about the ReactJS technology. We are proud to be known across the world for offering our exclusive customers the best technological solutions available. Our dedication is to creating really cutting-edge online and mobile apps that are suited to particular company requirements.",
            "In order to build long-lasting relationships with our customers, we place a high value on open communication and openness in all of our interactions as we strive for excellence. Our commitment is in providing exceptional performance and creative solutions, always keeping in mind the return on your investment. Before launching your apps, our skilled development team does thorough quality assurance to ensure flawless performance.",
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 4,
        title: "Why Choose Alta Tech Mark for ReactJS Development?",
        description: "In addition to being skilled programmers, our talented ReactJS developers also have an intuitive understanding of your unique requirements and a constant desire to produce high-quality work. In addition, we provide organizations looking for specialist technical knowledge for their projects with professional React developers. Working together with Alta Tech Mark has the following advantages:",
        onlyBullets: true,
        data: [
            "Engaging and Interactive Interfaces",
            "Tailored ReactJS Solutions",
            "Swift Support and Agile Maintenance",
            "Extensive Industry Expertise",
            "Innovative and Adaptable Solutions",
            "Scalability and Resilience",
            "Solutions Customized for Unique Business Requirements",
            "Uninterrupted Communication Channels",
            "Fusing Creativity with Cutting-Edge Technology",
            "A Work Ethic Rooted in Integrity and Excellence"
        ]
    },
    {
        id: 5,
        title: "",
        description: "You're working with a group that is distinguished by creativity, knowledge, and an unshakable dedication to quality when you join with Alta Tech Mark.",
        onlyBullets: false,
        data: []
    }
]