import React from 'react';

// data
import { hireDjangoDevData, hireDjangoDevFaqs } from '../../../data/hireDev/hireDjangoDev';
import djangoDev1 from '../../../assets/pages/hireDev/djangoDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireDjangoDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="DJANGO DEVELOPER"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={djangoDev1}
            data={hireDjangoDevData}
            faq={hireDjangoDevFaqs}
        />
    )
}
