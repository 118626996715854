import React from 'react';

// data
import { hireIphoneDevData } from '../../../data/hireDev/hireIphoneDev';
import iosDev1 from '../../../assets/pages/hireDev/iosDev1.png';

// components
import HireDevWrapper from '../../../components/hireDev/hireDevWrapper/HireDevWrapper';

export default function HireIphoneDevs() {
    return (
        <HireDevWrapper
            title="HIRE"
            colorTitleEnd="IPHONE DEVELOPERS"
            description="Fill out the form below, our team of professionals will send you a free estimate of the service(s) required."
            img={iosDev1}
            data={hireIphoneDevData}
            // faq={hireIosDevFaqs}
        />
    )
}
