import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import mobile1 from '../../../assets/pages/service/mobileAppDev/mobile1.png';
import mobile2 from '../../../assets/pages/service/mobileAppDev/mobile2.png';
import mobile3 from '../../../assets/pages/service/mobileAppDev/mobile3.png';
import { mobileGridBlockData, mobileServicesData } from '../../../data/services/mobileAppDevData/mobileData';

// components
import HeroTitleImage from '../../../components/heroTitleImage/HeroTitleImage';
import Brands from '../../../components/home/brands/Brands';
import HeaderText from '../../../components/headerText/HeaderText';
import GridBlock from '../../../components/services/gridBlock/GridBlock';
import KeyFeaturesCard from '../../../components/home/keyFeatures/KeyFeaturesCard';
import ContactUs from '../../../components/contactUs/ContactUs';
import ContactUsNoForm from '../../../components/contactUs/ContactUsNoForm';

export default function MobileAppDev() {
    // initializations
    const outlet = useOutlet();

    // globals
    const screenWidth = useSelector(state => state.responsive.screenWidth);

    return (
        <>
            {outlet
                ||
                <div>
                    <HeroTitleImage
                        bg={mobile1}
                        title="MOBILE APP DEVELOPMENT"
                        inWrapper={true}
                        simpleTextBg={true}
                    />

                    <Brands />

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <HeaderText
                            colorTitleStart="MOBILE APPLICATION"
                            title="DEVELOPMENT"
                            className='tw-text-center tw-mb-8'
                        />

                        <h1>
                            Apps for smartphones have altered our understanding of what constitutes normal daily activities. Why not? It has been a vital aspect of our lives. We use apps for nearly everything that we do. Mobile applications have linked the globe. Alta Tech Mark can assist you in creating some outstanding mobile applications for your company and start-ups in this highly technologically advanced age. We are the world's top custom mobile app development firm, providing the best applications to customers worldwide.
                        </h1>
                        <h1>
                            We have excellent mobile app developers on staff who are very knowledgeable about the newest advancements in technology. Our team collaborates with international companies to turn well-known concepts into gorgeous and creative mobile applications. We have a wealth of creativity and expertise creating beautiful mobile applications for a variety of global clientele.
                        </h1>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw]'
                        style={{
                            backgroundImage: `linear-gradient(${screenWidth < 768 ? '0deg' : '-90deg'}, #9065FD 0.03%, #9065FD 24.31%, #8256F1 38.36%, rgba(102, 73, 180, 0.94) 60.41%, rgba(55, 42, 96, 0.80) 87.05%), url(${mobile2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className='tw-text-white'>
                            <HeaderText
                                title="WHY MOBILE APPS FOR BUSINESS?"
                                description="It may seem little, but the mobile application may make little adjustments that have a big impact on enterprises. Businesses may utilize the mobile application to deliver engaging messages to their target user base in an effort to build a commercial connection and increase lead generation. These messages are accurately communicated. with the intention of having visitors become potential users. The best kind of communication that mobile apps may provide is even a push notification."
                                className='tw-text-white tw-mb-[2vw] md:tw-text-left tw-text-center'
                                descriptionClassName='tw-text-left'
                            />
                        </div>
                        <div className='tw-flex md:tw-items-start md:tw-flex-row tw-flex-col tw-justify-between tw-w-full tw-mt-8 tw-gap-y-4'>
                            <div className='tw-grid md:tw-w-[50%] tw-justify-items-center'>
                                <img src={mobile3} alt="pic" />
                            </div>
                            <div className='tw-col-span-2 tw-grid tw-grid-cols-3 tw-gap-[25px] tw-justify-items-center md:tw-w-[75%]'>
                                {mobileGridBlockData.map((data, index) =>
                                    <GridBlock
                                        key={index}
                                        text={data}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg'>
                        <ol className='tw-list-decimal tw-grid tw-gap-4'>
                            <li>
                                <span className="tw-text-purple">Increased Visibility: </span>
                                With people spending a significant amount of time on their smartphones daily, having a mobile app can keep your business at the forefront, increasing brand visibility.
                            </li>

                            <li>
                                <span className="tw-text-purple">Customer Engagement: </span>
                                Mobile apps provide a direct communication channel between businesses and their customers. Through features like push notifications, businesses can engage their audience with updates, promotions, and more.
                            </li>

                            <li>
                                <span className="tw-text-purple">Improve Sales: </span>
                                With the convenience of shopping through mobile apps, many users prefer apps over traditional websites. This can lead to increased sales, especially with features like personalized recommendations and seamless mobile payment solutions.
                            </li>

                            <li>
                                <span className="tw-text-purple">Data Collection: </span>
                                Mobile apps can gather a wealth of data on user behavior, preferences, and more. This data is invaluable for refining marketing strategies, improving the user experience, and making informed business decisions.
                            </li>

                            <li>
                                <span className="tw-text-purple">Brand Recognition & Trust: </span>
                                A well-designed app can greatly contribute to a brand's image. If a customer has your app on their phone, it can work as a constant reminder of your brand, helping build trust and recognition.
                            </li>

                            <li>
                                <span className="tw-text-purple">Personalized User Experience: </span>
                                Mobile apps can offer personalized experiences based on user behavior, location, preferences, etc. This level of personalization can lead to greater user satisfaction and increased sales.
                            </li>
                        </ol>
                    </div>

                    <div className='tw-px-[9.115vw] tw-py-[4vw] tw-text-lg tw-bg-lightPurple'>
                        <HeaderText
                            colorTitleEnd="INDUSTRIES"
                            title="APP DEVELOPMENT IN DIFFERENT"
                            className='tw-text-center tw-mb-8'
                            description="A range of services are available from Alta Tech Mark to enhance the App development process."
                        />
                        <div className='md:tw-grid md:tw-grid-cols-3 tw-flex tw-items-center tw-gap-[2vw] tw-overflow-x-scroll md:tw-overflow-x-hidden tw-pb-4'>
                            {mobileServicesData.map(service =>
                                <KeyFeaturesCard
                                    key={service.id}
                                    title={service.title}
                                    description={service.description}
                                    src={service.src}
                                    height='450'
                                />
                            )}
                        </div>
                    </div>

                    {screenWidth < 768 ?
                        <ContactUsNoForm /> :
                        <ContactUs />
                    }
                </div>
            }
        </>
    )
}
