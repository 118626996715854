import React from 'react';

// assets
import angular1 from '../../../../assets/pages/service/WebAppDev/angular1.png';
import angular2 from '../../../../assets/pages/service/WebAppDev/angular2.png';
import { angularJsDevData } from '../../../../data/services/webAppDevData/angularJsDevData';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function AngularJsAppDev() {
    return (
        <ServicesWrapper
            title="ANGULAR JS DEVELOPMENT"
            descriptionCont="Our team creates a platform that elevates your company above a simple website. Our front-end designers combine knowledge, creativity, and technology to create a work of art just for you. For us at Alta Tech Mark, customer happiness is the most important factor."
            img={angular1}
        >
            <DevGridWrapper img={angular2}>
                <DataMapper data={angularJsDevData}/>
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
