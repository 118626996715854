import React from 'react';

// assets
import si1 from '../../../../assets/pages/service/salesforceDev/si1.jpeg';
import si2 from '../../../../assets/pages/service/salesforceDev/si2.png';
import { salesforceDevData3 } from '../../../../data/services/salesforceDevData/salesforceDevData3';

// components
import ServicesWrapper from './../../../../components/services/wrapper/servicesWrapper/ServicesWrapper';
import DevGridWrapper from './../../../../components/services/wrapper/devGridWrapper/DevGridWrapper';
import DataMapper from '../../../../components/services/dataMapper/DataMapper';

export default function SalesforceImplementation() {
    return (
        <ServicesWrapper
            title="SALES FORCE IMPLEMENTATION"
            descriptionCont="Customers of Salesforce are offered cutting-edge, software-as-a-service solutions that are hosted in the cloud. These solutions make it possible for the software to be extremely customizable and available from any location."
            img={si1}
        >
            <DevGridWrapper img={si2}>
                <DataMapper data={salesforceDevData3} />
            </DevGridWrapper>
        </ServicesWrapper>
    )
}
