import React from 'react'
import { Link } from 'react-router-dom';

// flags
// import india from '../../assets/flags/india.png'
// import usa from '../../assets/flags/usa.png'
// import uk from '../../assets/flags/uk.png'

// icons
// import EmailIcon from '@mui/icons-material/Email';
// import skype from '../../assets/logo/skype.png'
import facebook from '../../assets/logo/facebook.png'
import twitter from '../../assets/logo/twitter.png'
import instagram from '../../assets/logo/instagram.png'
import linkedin from '../../assets/logo/linkedin.png'
// import youtube from '../../assets/logo/youtube.png'
// import top from '../../assets/logo/top.png'
import companyHoverBg1 from '../../assets/background/companyHoverBg-01.png'
import companyHoverBg2 from '../../assets/background/companyHoverBg-02.png'
import downloadIcon from '../../assets/icons/download.svg'

export default function CompanyHover(props) {
    return (
        <div className='tw-grid tw-grid-cols-3 tw-absolute tw-border-slate-300 tw-border-[1px] tw-h-[60vh] tw-z-10 tw-bg-purple tw-text-purple' onMouseLeave={props.hoverLeave}
            style={{
                backgroundImage: 'linear-gradient(166deg, #F4F0FF 0%, rgba(219, 205, 255, 0.30) 100%)'
            }}
        >
            <div className='tw-flex tw-flex-col tw-items-start tw-justify-between'>
                <div className='tw-flex tw-flex-col tw-items-start tw-w-[90%]'>
                    <Link to='/company/aboutUs' className='tw-mx-2 tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-black hover:tw-bg-opacity-20'>About Alta Tech Mark</Link>
                    <Link to='/resources/caseStudy' className='tw-mx-2 tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-black hover:tw-bg-opacity-20'>Portfolio</Link>
                    <Link to='/resources/blog' className='tw-mx-2 tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-black hover:tw-bg-opacity-20'>Alta Tech Blogs</Link>
                    <Link to='/company/career' className='tw-mx-2 tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-black hover:tw-bg-opacity-20'>Careers</Link>
                    <h1 className='tw-mx-2 tw-px-2 tw-border-transparent tw-w-full tw-py-2 tw-cursor-pointer tw-border-[1px] hover:tw-border-purple hover:tw-bg-purple hover:tw-text-black hover:tw-bg-opacity-20 tw-flex tw-items-center tw-gap-x-1'>
                        <h1>Download Digital Brochure</h1>
                        <img src={downloadIcon} alt="icon" />
                    </h1>
                </div>

                <div className='tw-px-4 tw-mb-6 tw-flex tw-flex-col tw-items-start tw-gap-y-2'>
                    <h1 className='tw-text-lg'>Follow Us</h1>
                    <div className='tw-flex tw-items-center tw-gap-x-4 tw-w-full'>
                        <a href="https://www.facebook.com/altatechmark" target="_blank" rel="noopener noreferrer">
                            <img className='tw-cursor-pointer' src={facebook} alt="facebook logo" width={30} height={24} />
                        </a>
                        <a href="https://twitter.com/altatechmark" target="_blank" rel="noopener noreferrer">
                            <img className='tw-cursor-pointer' src={twitter} alt="twitter logo" width={30} height={24} />
                        </a>
                        <a href="https://www.instagram.com/altatechmark" target="_blank" rel="noopener noreferrer">
                            <img className='tw-cursor-pointer' src={instagram} alt="instagram logo" width={30} height={24} />
                        </a>
                        <a href="https://pk.linkedin.com/company/altatechmark" target="_blank" rel="noopener noreferrer">
                            <img className='tw-cursor-pointer' src={linkedin} alt="linkedin logo" width={30} height={24} />
                        </a>

                        {/* <img className='tw-cursor-pointer' src={youtube} alt="youtube logo" width={30} height={24} /> */}
                    </div>
                </div>
            </div>

            <div className='tw-border-l-[2px] tw-border-purple tw-my-4 tw-px-4'>
                <div className='tw-text-2xl tw-font-semibold tw-text-white tw-rounded-lg tw-h-full tw-p-4 tw-flex tw-flex-col tw-justify-end'
                    style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${companyHoverBg1})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <h1 className=''>
                        Our
                        <br /> Work Preview
                    </h1>
                </div>
            </div>

            <div className='tw-border-l-[2px] tw-border-purple tw-my-4 tw-px-4'>
                <div className='tw-text-2xl tw-font-semibold tw-text-white tw-rounded-lg tw-h-full tw-p-4 tw-flex tw-flex-col tw-justify-end'
                    style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${companyHoverBg2})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <h1 className=''>
                        <span className='tw-text-purple'>Together</span>
                        <br /> create something amazing
                    </h1>
                </div>
            </div>
        </div>
    )
}
