import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    contactUsSliceLoading: false,
    refreshContactUs: false,
    allQueries: []
}

const contactUsSlice = createSlice({
    name: "contactUs",
    initialState,
    reducers: {
        refreshContactUsSlice: (state, action) => {
            state.refreshContactUs = !state.refreshContactUs;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(contactUs.pending, (state, action) => {
            state.contactUsSliceLoading = true;
        })
        builder.addCase(contactUs.fulfilled, (state, action) => {
            state.contactUsSliceLoading = false;
            alert(action.payload.message)
        })
        builder.addCase(contactUs.rejected, (state, action) => {
            state.contactUsSliceLoading = false;
            alert(action.payload.message)
        })

        // get all queries
        builder.addCase(getAllQueries.pending, (state, action) => {
            state.contactUsSliceLoading = true;
            state.allQueries = []
        })
        builder.addCase(getAllQueries.fulfilled, (state, action) => {
            state.allQueries = action.payload;
            state.contactUsSliceLoading = false;
        })
        builder.addCase(getAllQueries.rejected, (state, action) => {
            state.contactUsSliceLoading = false;
            alert(action.payload);
        })
    }
})

export const contactUs = createAsyncThunk('contactUs/contactUs', async ({ contactUsData }, { rejectWithValue }) => {
    try {
        console.log(contactUsData.file);
        const response = await axios.post('https://altatechmark.com/server/contactUs', contactUsData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.message)
    }
})

export const getAllQueries = createAsyncThunk('contactUs/getAllQueries', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get('https://altatechmark.com/server/getAllQueries')

        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.message)
    }
})

export default contactUsSlice;

export const { refreshContactUsSlice } = contactUsSlice.actions;