import React from 'react';
import { useNavigate } from 'react-router-dom';

// icons
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

export default function ArrowButton(props) {
    // initializations
    const navigate = useNavigate();

    return (
        <div className={`tw-flex tw-px-[15px] tw-py-[10px] tw-rounded-lg tw-gap-4 tw-justify-between tw-items-center tw-cursor-pointer ${props.className}`}
            onClick={() => {
                props.clickFunction && props.onClick();
                !props.noUrl && navigate(props.url)
            }}
        >
            <h1>{props.text}</h1>
            <TrendingFlatIcon />
        </div>
    )
}
