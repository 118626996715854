export const hireIotArchitectData = [
    {
        id: 1,
        title: "",
        descriptions: [
            "Many industries have adopted data science to enhance their functioning. The appointment of a data scientist is vital for leveraging data science. They help derive insights from both structured and messy data. Using methodologies, processes, algorithms, and systematic methods, they interpret data. They play a pivotal role in converting vast data sets into actionable business intelligence. Our Data Scientists are here to revolutionize your business and refine processes across various domains. With this data, your predictive capabilities and decision-making prowess will be enhanced. Therefore, it's crucial to hire data scientists for businesses aiming for expansion.",
            "Our data scientist provides deep insights and analytics, helping you spot industry patterns. With their assistance, gain a better understanding of the modern business landscape and market dynamics. We boast experts in data analytics and mining. Data science specialists perform meticulous operations using sophisticated mathematical techniques and purpose-built algorithms. Their insights will guide you through numerous complex problems. Our data scientist is always available to address your queries and ensures transparent communication throughout the engagement. We leverage this data to align with and cater to our client's requirements."
        ],
        onlyBullets: false,
        data: []
    },
    {
        id: 2,
        title: "Premier IoT Solution Architect at Alta Tech Mark",
        descriptions: [
            "Our IoT professionals excel in melding hardware, data analysis, and software to shape a sophisticated connected world via the Internet of Things (IoT). Every IoT developer at Alta Tech Mark brings a rich history of crafting robust business and mobile applications.",
            "Our collaboration with numerous global firms has led them to seek our IoT development expertise for mobile-compatible IoT solutions. The experts you engage will align with your timezone and specific demands. They've also demonstrated prowess in creating solutions based on cloud for IoT.",
            "The IoT solution designers at Alta Tech Mark are adept at materializing even the most nebulous of ideas into tangible IoT solutions. Being involved in every phase, from conception to execution, they have a holistic view.",
            "Boasting considerable experience in data analytics, application management, consultancy, automation, and IoT solution creation, our IoT solution architects employ their vast knowledge to ensure cost-effectiveness and time efficiency for your solution."
        ],
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Development of IoT Sensors",
                description: "Our focus is on enhancing end-user experiences through sensor development. By leveraging our IoT expertise, features like vehicle tracking, advanced locking systems, and meteorological monitoring become feasible."
            },
            {
                id: 2,
                title: "Bespoke IoT Development",
                description: "We prioritize understanding the distinct needs and objectives of every client. Drawing from this understanding and our IoT expertise, we deliver tailor-made solutions aligning with your business demands."
            },
            {
                id: 3,
                title: "Integration of Cloud & IoT",
                description: "Leveraging cloud support, our IoT experts enhance the features, scalability, performance, and responsiveness of your IoT apps. We seamlessly integrate IoT with your existing infrastructure, ensuring alignment with your business goals."
            },
            {
                id: 4,
                title: "Development of IoT Wearables",
                description: "Wearable tech heavily banks on IoT. Our adept IoT developers harness cutting-edge technologies to craft wearables compatible with Android and iOS platforms."
            },
            {
                id: 5,
                title: "Data Analytics Enabled by IoT",
                description: "Linking IoT with apps and various systems generates vast data. Our developers analyze this data to provide deeper insights into user behavior, ultimately enhancing outcomes."
            },
            {
                id: 6,
                title: "Support & Maintenance for IoT",
                description: "Seamless functioning of advanced integrations like IoT requires continuous upkeep. Our team ensures the reliability of these systems with our comprehensive support and maintenance services."
            }
        ]
    },
    {
        id: 3,
        title: "Reasons to Opt for IoT Developers from Alta Tech Mark",
        description: "Engage IoT developers from the reputable Alta Tech Mark for seamless IoT integration and development. Let them craft customized solutions that come with an assurance of quality.",
        onlyBullets: false,
        data: [
            {
                id: 1,
                title: "Demonstrated Mastery",
                description: "Over the past decade, we've catered to clients globally, spanning varied industries. This has equipped us with invaluable experience, making us adept at serving diverse business goals and needs."
            },
            {
                id: 2,
                title: "Highly Skilled",
                description: "At Alta Tech Mark, we ensure our dedicated IoT developers are exposed to a multitude of challenges. Hence, unlike in-house teams, there's minimal ramp-up time required with our offshore IoT teams."
            },
            {
                id: 3,
                title: "Delivering Beyond Promises",
                description: "Our IoT solutions are crafted for efficiency and speed, ensuring optimal return on your investment."
            },
            {
                id: 4,
                title: "Targeted Outcomes",
                description: "We delve deep to grasp your requirements before initiating any project. Our focus is to ensure all strategies are executed flawlessly, meeting the expected results."
            }
        ]
    }
]
